import io from "socket.io-client";

const setupSocket = (path) => {
  const socket = io(`//${process.env.REACT_APP_BACKEND_IP}`, {
    transports: ["websocket"],
    query: {
      Authorization: localStorage.getItem("token"),
    },
    path: `${path}/socket.io`,
  });

  return socket;
};
export default setupSocket;

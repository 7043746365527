import React, { useEffect } from "react";
import setupSocket from "utils/socket_setup";
import { SocketEvent, SocketListener } from "../../../utils/helpers";
import { UPDATE_ACDM_SOCKET } from "redux/constants";
import { useDispatch, useSelector } from "react-redux";

const ACDMSocket = (props) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const { ids: selectedFlights = [] } = useSelector(
    (state) => state.UserInfo.selectedFlights,
  );
  const { roles, airlines, id: userId } = auth.user;
  let socketData = {
    updated: {},
    deleted: [],
  };

  useEffect(() => {
    let rooms = [];
    const newSocket = setupSocket(process.env.REACT_APP_ACDM_SOCKET_PATH);
    newSocket.on(SocketListener.CONNECT, () => {
      rooms = onSocketConnect();
      newSocket.emit(SocketEvent.ROOM, rooms);
    });
    newSocket.on(SocketListener.ACDM_TOBT, tobtSocket);
    newSocket.on(props.alias, acdmWidgetSocket);
    return () => {
      newSocket.emit(SocketEvent.Leave_ROOM, rooms);
    };
  }, []);

  const onSocketConnect = () => {
    let rooms = [];
    if (userId && !selectedFlights?.length) {
      rooms = airlines.map((e) => {
        return e.iata;
      });
    } else if (selectedFlights.length) {
      rooms = selectedFlights.map((ele) => `selectedFlight_${ele}`);
    }
    return rooms;
  };

  const tobtSocket = ({ message, action }) => {
    const responsibleForTobt = roles.some((role) => role.responsible_for_tobt);
    if (!responsibleForTobt) {
      return;
    }
    props.updateManualQueue(message, action);
  };

  const debounceData = _.debounce(() => {
    const currentData = { ...socketData };
    socketData = {
      updated: {},
      deleted: [],
    };
    if (
      Object.keys(currentData.updated).length > 0 ||
      currentData.deleted.length > 0
    ) {
      dispatch({ type: UPDATE_ACDM_SOCKET, data: currentData });
    }
  }, 1000);

  const acdmWidgetSocket = ({ message, action }) => {
    let { deleted, updated } = { ...socketData };
    const { id } = message;
    if (!message) {
      return;
    }
    if (action === "delete") {
      deleted.push(id);
    } else {
      updated = { ...updated, [id]: message };
    }
    socketData = { updated, deleted };
    debounceData();
  };

  return <></>;
};

export default ACDMSocket;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { convertTimeZoneWithFormat } from "utils/helpers";

export default function TimeHeader() {
  const [time, setTime] = useState();
  const { timeZone } = useSelector((state) => state.SystemParams);
  useEffect(() => {
    const timer = setInterval(() => {
      const formatTime = convertTimeZoneWithFormat({
        time: new Date(),
        timeZone,
        requiredFormat: "HH:mm:ss",
      });
      setTime(formatTime);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [timeZone]);

  return (
    <div className="current-time">
      <div className="time">{time}</div>
      <div className="time-utc">{timeZone === "utc" ? "UTC" : timeZone}</div>
    </div>
  );
}

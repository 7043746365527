import React, { useEffect, useState } from "react";
import "./assets/style/app.css";
import PDSConstraints from "./pds_constraints";
import moment from "moment";
import { PDS_states, columns, runways } from "components/common/constant";

const PDS = () => {
  const [constraintsPopup, setConstraintsPopup] = useState(false);
  const [tab, setTab] = useState(1);
  const [interval, setInterval] = useState({
    startDate: new Date().getTime(),
    endDate: new Date().getTime() + 3600 * 1000,
  });
  const [warning, setWarning] = useState("");

  const activeIndicator = (indicator, left, right) => {
    document.querySelector(`.${indicator}`).style.left = left;
    document.querySelector(`.${indicator}`).style.right = right;
  };

  const resetPDSInterval = ({ start, end }) => {
    if (end && start > end) {
      setWarning(
        "Start date is after end date, please choose another interval",
      );
    } else {
      setConstraintsPopup(false);
      setInterval({ startDate: start, endDate: end });
    }
  };

  const showCalendar = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    setConstraintsPopup(!constraintsPopup);
  };

  const chooseRunwayTab = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    const runwaystabSize = 100 / runways.length;
    const { id } = ev.target.dataset;
    const i = +id - 1;
    setTab(+id);

    activeIndicator(
      "pds-sequence-indicator",
      `${runwaystabSize * i}%`,
      `${100 - runwaystabSize - runwaystabSize * i}%`,
    );
  };

  const chooseSubTab = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    const subTabSize = 100 / PDS_states.length;
    const { index } = ev.target.dataset;
    activeIndicator(
      "runways-indicator",
      `${subTabSize * +index}%`,
      `${100 - subTabSize - subTabSize * +index}%`,
    );
  };

  const runwaystabSize = 100 / runways?.length;
  const subTabSize = 100 / PDS_states.length;
  return (
    <div className="pds-container">
      <div className="pds-main-header">
        <div className="widget-header pds-header">PDS</div>
      </div>
      {constraintsPopup && (
        <PDSConstraints
          resetPDSInterval={resetPDSInterval}
          skip={showCalendar}
          tab={tab}
          start={interval.start}
          end={interval.end}
          warning={warning}
        />
      )}
      <div>
        Data in <span className="red-font">red</span> is not included in the
        interval
        <span
          className="pds-fetch-btn"
          role="presentation"
          onClick={showCalendar}
          onKeyDown={showCalendar}
        >
          {` ${moment(interval.startDate).format(
            "dddd MMM-DD , YYYY",
          )} - ${moment(interval.endDate).format("dddd MMM-DD , YYYY")}`}
        </span>
      </div>

      <div>
        To get new sequence for runway {tab}, click{" "}
        <span className="pds-fetch-btn" role="presentation">
          here
        </span>
      </div>
      <div className="pds-tabs">
        <ul className="tabs column s12 tabs-fixed-width">
          {runways.map((e, i) => (
            <li
              key={e.id}
              className={`tab col s${12 / runways.length}`}
              data-id={e.id}
            >
              <button
                type="button"
                data-id={e.id}
                className={tab === `tab${i + 1}` ? "active" : ""}
                onClick={chooseRunwayTab}
                onKeyDown={chooseRunwayTab}
              >
                <div data-id={e.id}>{e.alias}</div>
              </button>
            </li>
          ))}
          <div
            style={{
              left: `${runwaystabSize * 0}%`,
              right: `${100 - runwaystabSize - runwaystabSize * 0}%`,
            }}
            className="indicator pds-sequence-indicator"
          />
        </ul>
        <hr className="pds-splitter" />
        <ul className="tabs column s12 tabs-fixed-width">
          {PDS_states.map((e, i) => (
            <li
              key={i}
              className={`tab col s${12 / PDS_states.length}`}
              data-index={i}
              data-name={e.name}
            >
              <button
                type="button"
                data-index={i}
                data-name={e.name}
                onClick={chooseSubTab}
                onKeyDown={chooseSubTab}
              >
                <div data-index={i} data-name={e.name}>
                  {e.alias}
                </div>
              </button>
            </li>
          ))}
          <div
            style={{
              left: `${subTabSize * 0}%`,
              right: `${100 - subTabSize - subTabSize * 0}%`,
            }}
            className="indicator runways-indicator"
          />
        </ul>
        <hr className="pds-splitter" />
      </div>
      <div className="widget-table pds-table">
        {columns.map((column, i) => (
          <div key={i}>{column}</div>
        ))}
      </div>
    </div>
  );
};

export default PDS;

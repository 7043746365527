import { combineReducers } from "redux";
import { reducer as tooltip } from "redux-tooltip";
import { authReducer } from "./auth";
import activeFlightsReducer from "./active-flights";
import alertsReducer from "./active-alerts";
import sidebarReducer from "./sidebar";
import TostAlert from "./tost-alert";
import UserInfo from "./user-reducer";
import airplanesReducer from "./airplanes";
import Messages from "./messages-reducer";
import Airlines from "./airlines-reducer";
import Roles from "./roles-reducer";
import ACDM from "./acdm-reducer";
import Organizations from "./organizations";
import Users from "./users-reducer";
import Alerts from "./alerts-reducer";
import Notifications from "./notifications-reducer";
import Logs from "./logs-reducer";
import manualUpdateLogs from "./manual_update_logs-reducer";
import AuditTrails from "./audit-reducer";
import generalALertLogs from "./general-alert-logs-reducer";
import SystemParams from "./system_paramas_reducer";
import Notification from "./notification-reducer";
import Layouts from "./layouts-reducer";
import APM from "./apm-reducer";
import BHS from "./bhs-reducer";

const emmaClientReducers = combineReducers({
  activeFlight: activeFlightsReducer,
  ActiveAlerts: alertsReducer,
  auth: authReducer,
  airplanes: airplanesReducer,
  tooltip,
  sidebar: sidebarReducer,
  Messages,
  Airlines,
  Roles,
  TostAlert,
  SystemParams,
  ACDM,
  UserInfo,
  Organizations,
  Users,
  Alerts,
  Notifications,
  Logs,
  manualUpdateLogs,
  AuditTrails,
  generalALertLogs,
  Notification,
  Layouts,
  APM,
  BHS,
});

export default emmaClientReducers;

import React, { useState, useEffect } from "react";
import dataSort from "../../common/sort_manager/data_sort";
import InfoModal from "../../common/modal_manager/info_alert";
import Rating from "../../common/modal_rating/rating";
import "./style/app.css";
import Tooltip from "components/common/Tooltip";
import {
  isAuthorized,
  sortTypes,
  convertTimeZoneWithFormat,
} from "utils/helpers";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const TooltipContent = ({ flight }) => {
  return (
    <span key={`${flight.aircraft_registration}`}>
      {flight.alert_description}
    </span>
  );
};

const base = (props) => {
  const { data, active, headers, searchBy, widgetSize, auth } = props;
  const [sortBy, setSortBy] = useState({});
  const [actionInfo, setActionInfo] = useState(false);
  const [infoData, setInfoData] = useState("");
  const [activitys, setActivitys] = useState(data);
  const [canClose, setCanClose] = useState(false);
  const { activeFlight } = useSelector((state) => state);
  const { timeZone } = useSelector((state) => state.SystemParams);
  useEffect(() => {
    searchBy ? setActivitys(searchBy) : setActivitys(data);

    const { canDelete: popupPermission } = isAuthorized(
      auth.user.roles,
      "close_silent_alert"
    );
    setCanClose(popupPermission);
  }, [activitys, data, searchBy]);

  const showRequiredAction = (flight) => {
    setInfoData(flight);
    setActionInfo(true);
  };

  const closeAlert = () => {
    setActionInfo(false);
  };

  const renderData = (flight, field) => {
    const { name, type } = field;

    let data = null;
    if (type === "date") {
      data = convertTimeZoneWithFormat({
        time: flight[name],
        timeZone,
        requiredFormat: "MMMM D, YYYY / h:mm A",
      });
    } else {
      data = flight[name];
    }
    return (
      <div
        data-value={flight[name]}
        data-name={name}
        tabIndex="0"
        role="button"
      >
        {name !== "alert_description" ? (
          <span data-value={flight[name]} data-name={name}>
            {data}
          </span>
        ) : (
          <div>
            <span data-value={flight[name]} data-name={name} className="tool">
              {!props.fullscreenBlock && data.length > 30 ? (
                <Tooltip
                  data={`${flight.alert_description.substring(0, 30)}...`}
                  Compo={<TooltipContent flight={flight} />}
                />
              ) : (
                data
              )}
            </span>
          </div>
        )}
      </div>
    );
  };

  const renderFields = (alert) => {
    return dataSort(alert, sortBy).map((row) => {
      const className =
        activeFlight?.flight_number &&
        activeFlight.flight_number === row.flight_number
          ? "activeFlight"
          : null;

      return (
        <TableRow key={row.id} className={`${className} editable-field-cbcbcb`}>
          {headers.map((column) => {
            const value = row[column.name];
            return (
              <TableCell
                key={column.name}
                className={`cell-table ${
                  active === "notifications" && column.name === "action"
                    ? "color-tranparent"
                    : ""
                }`}
              >
                {column.type !== "action" ? (
                  column.name !== "level" ? (
                    renderData(row, column)
                  ) : (
                    <Rating value={row[column.name]} />
                  )
                ) : active === "alerts" || active === "general alerts" ? (
                  <a
                    className="btn-action"
                    onClick={(e) => showRequiredAction(row)}
                  >
                    Action
                  </a>
                ) : null}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  const sortData = (ev) => {
    const element = ev.target.dataset.name;

    setSortBy((prevState) => ({
      [element]:
        prevState[element] === sortTypes.ASCEND
          ? sortTypes.DESCEND
          : sortTypes.ASCEND,
    }));
  };

  const renderActivityHeader = () => {
    return (
      <>
        {props.activeColumn.includes("level") ? (
          <TableCell
            style={{ width: "10%", borderBottom: "none" }}
            key={"level"}
          >
            <div
              style={{
                width: " 35px",
                textAlign: "center",
                marginLeft: "15px",
              }}
            >
              level
              <div
                title={"level"}
                className={`acdm-icon ${sortBy["level"] ? "active" : ""}`}
                role="button"
                onKeyDown={sortData}
                onClick={sortData}
              >
                <i
                  aria-hidden="true"
                  data-name={"level"}
                  className={`fa fa-sort-${
                    sortBy["level"] === sortTypes.ASCEND ? "up " : "down "
                  } fa-1g`}
                />
              </div>
            </div>
          </TableCell>
        ) : null}
        {props.activeColumn.includes("alert_code") ? (
          <TableCell
            style={{ width: "10%", borderBottom: "none" }}
            key={"alert_code"}
          >
            <div style={{ width: " 35px", textAlign: "center" }}>
              Code
              <div
                title={"Code"}
                className={`acdm-icon ${sortBy["alert_code"] ? "active" : ""}`}
                role="button"
                onKeyDown={sortData}
                onClick={sortData}
              >
                <i
                  aria-hidden="true"
                  data-name={"alert_code"}
                  className={`fa fa-sort-${
                    sortBy["alert_code"] === sortTypes.ASCEND ? "up " : "down "
                  } fa-1g`}
                />
              </div>
            </div>
          </TableCell>
        ) : null}

        {active === "general alerts" ? null : props.activeColumn.includes(
            "flight_number"
          ) ? (
          <>
            <TableCell style={{ width: "10%", borderBottom: "none" }}>
              <div style={{ width: " 35px", textAlign: "center" }}>
                Flight
                <div
                  title={"Flight"}
                  className={`acdm-icon ${
                    sortBy["flight_number"] ? "active" : ""
                  }`}
                  role="button"
                  onKeyDown={sortData}
                  onClick={sortData}
                >
                  <i
                    aria-hidden="true"
                    data-name={"flight_number"}
                    className={`fa fa-sort-${
                      sortBy["flight_number"] === sortTypes.ASCEND
                        ? "up "
                        : "down "
                    } fa-1g`}
                  />
                </div>
              </div>
            </TableCell>
          </>
        ) : null}
        {props.activeColumn.includes("alert_description") ? (
          <TableCell
            style={{
              width: active === "general alerts" ? "40%" : "30%",
              borderBottom: "none",
            }}
          >
            <div style={{ width: " 100px", textAlign: "center" }}>
              Description
              <div
                title={"Flight"}
                className={`acdm-icon ${
                  sortBy["alert_description"] ? "active" : ""
                }`}
                role="button"
                onKeyDown={sortData}
                onClick={sortData}
              >
                <i
                  aria-hidden="true"
                  data-name={"alert_description"}
                  className={`fa fa-sort-${
                    sortBy["alert_description"] === sortTypes.ASCEND
                      ? "up "
                      : "down "
                  } fa-1g`}
                />
              </div>
            </div>
          </TableCell>
        ) : null}
        {props.activeColumn.includes("created_at") ? (
          <TableCell
            style={{
              width: active === "notifications" ? "30%" : "20%",
              borderBottom: "none",
            }}
          >
            <div style={{ width: " 100px", textAlign: "center" }}>
              Date / Time
              <div
                title={"Date / Time"}
                className={`acdm-icon ${sortBy["created_at"] ? "active" : ""}`}
                role="button"
                onKeyDown={sortData}
                onClick={sortData}
              >
                <i
                  aria-hidden="true"
                  data-name={"created_at"}
                  className={`fa fa-sort-${
                    sortBy["created_at"] === sortTypes.ASCEND ? "up " : "down "
                  } fa-1g`}
                />
              </div>
            </div>
          </TableCell>
        ) : null}

        {active === "notifications" ? null : props.activeColumn.includes(
            "required_actions"
          ) ? (
          <TableCell style={{ width: "10%", borderBottom: "none" }}>
            Required Actions
          </TableCell>
        ) : null}
      </>
    );
  };

  return (
    <div style={{ overflowY: "auto" }}>
      {actionInfo ? (
        <InfoModal
          message={infoData}
          canClose={canClose}
          icon="warning"
          color="red"
          cb={closeAlert}
          overlay
        />
      ) : null}

      <TableContainer sx={{ overflowX: "initial" }}>
        <Table
          aria-label="simple table"
          className="widget-table-activity acdm-table"
          stickyHeader
        >
          <TableHead>
            <TableRow>{renderActivityHeader()}</TableRow>
          </TableHead>
          <TableBody>
            {activitys ? renderFields(activitys, headers) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default base;

import zipcelx from "zipcelx";

const dataExport = (filename = `ACDM_${new Date()}`, data) => {
  const config = {
    mimeType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    filename,
    sheet: {
      data,
    },
  };

  zipcelx(config);
};

export default dataExport;

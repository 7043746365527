import {
  FETCH_BHS_START,
  FETCH_BHS_SUCCESS,
  FETCH_BHS_FAIL,
  FETCH_BHS_BELTS_START,
  FETCH_BHS_BELTS_SUCCESS,
  FETCH_BHS_BELTS_FAIL,
  NEW_BELT_SOCKET,
  REINIT_BHS,
} from "../constants";

import { getRequest } from "utils/APIRequest";

export const reinitBHS = () => {
  return async (dispatch) => {
    dispatch({ type: REINIT_BHS });
  };
};

export const newBeltSocket = (data) => {
  return (dispatch) => {
    dispatch({ type: NEW_BELT_SOCKET, data });
  };
};

const fetchBHSStart = () => ({
  type: FETCH_BHS_START,
});

const fetchBHSSuccess = (data) => ({
  type: FETCH_BHS_SUCCESS,
  data,
});

const fetchBHSFail = () => ({
  type: FETCH_BHS_FAIL,
});

export const fetchBHS = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchBHSStart());

    const response = await getRequest("/api/bhs/index", data);

    if (!response.error) {
      dispatch(fetchBHSSuccess(response));
    } else {
      dispatch(fetchBHSFail());
    }
  };
};

const fetchBHSBeltsStart = () => ({
  type: FETCH_BHS_BELTS_START,
});

const fetchBHSBeltsSuccess = (data) => ({
  type: FETCH_BHS_BELTS_SUCCESS,
  data,
});

const fetchBHSBeltsFail = () => ({
  type: FETCH_BHS_BELTS_FAIL,
});

export const fetchBHSBelts = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchBHSBeltsStart());

    const response = await getRequest("/api/bhs/belts", data);

    if (!response.error) {
      dispatch(fetchBHSBeltsSuccess(response));
    } else {
      dispatch(fetchBHSBeltsFail());
    }
  };
};

import React from "react";
import { convertTimeZoneWithFormat } from "utils/helpers";

import { Tooltip } from "react-redux-tooltip";

const StandInfo = (props) => {
  const {
    hideToolTips,
    aircraft_registration,
    name,
    aircraft_type,
    iata,
    status,
    block_time,
    estimate_block_time,
    color,
    dataId,
    timeZone,
  } = props;
  const blockTime = block_time || estimate_block_time;
  const ready = status === "Ready";

  const onCloseToolTip = () => {
    hideToolTips({ name: `stand-${dataId}` });
  };

  return (
    <Tooltip
      id={`stand${dataId}-tooltip`}
      className="tooltip stand-tooltip"
      name={`stand-${dataId}`}
    >
      <div
        className="close-asmgcs-tooltip"
        role="presentation"
        onClick={onCloseToolTip}
      >
        <i aria-hidden="true" className="fa fa-times" />
      </div>

      <div className="stand-tooltip-info">
        <div className="stand-data stand-status">
          <div className="stand-info-label">Status</div>
          <div className="stand-info-unit">
            <span>{status}</span>
            <span style={{ backgroundColor: color }} className="status-color" />
          </div>
        </div>
        <div className="stand-data stand-name">
          <div className="stand-info-label">Stand</div>
          <div className="stand-info-unit">{name}</div>
        </div>
        <div className="stand-data stand-time">
          <div className="stand-info-label">Block Time</div>
          <div className="stand-info-unit">
            {blockTime && !ready
              ? convertTimeZoneWithFormat({
                  time: blockTime,
                  timeZone: timeZone,
                  requiredFormat: "HH:mm",
                })
              : null}
          </div>
        </div>
      </div>

      <div className="stand-tooltip-info">
        <div className="stand-data stand-reg">
          <div className="stand-info-label">Aircraft Reg</div>
          <div className="stand-info-unit">
            {!ready ? aircraft_registration : null}
          </div>
        </div>
        <div className="stand-data stand-iata">
          <div className="stand-info-label">IATA</div>
          <div className="stand-info-unit">{!ready ? iata : null}</div>
        </div>
        <div className="stand-data stand-type">
          <div className="stand-info-label">Aircraft Type</div>
          <div className="stand-info-unit">{!ready ? aircraft_type : null}</div>
        </div>
      </div>
    </Tooltip>
  );
};

export default StandInfo;

const validateCondition = (condition, message) => {
  if (condition) {
    return { canEdit: true };
  }
  return { canEdit: false, message };
};
const handleEditableFieldsConditions = (
  field,
  flight,
  roles,
  tobtUpdate,
  action,
) => {
  const canEdit = {
    acgt: () => validateCondition(flight.aibt, "Can not edit ACGT before AIBT"),
    aegt: () => validateCondition(flight.acgt, "Can not edit AEGT before ACGT"),
    aibt: () =>
      validateCondition(
        flight.aldt && !flight.aobt,
        "Can not edit AIBT before ALDT or after AOBT",
      ),
    aldt: () => validateCondition(!flight.aibt, "Can not edit ALDT after AIBT"),
    aobt: () =>
      validateCondition(
        flight.aibt && !flight.atot,
        "Can not edit AOBT before AIBT or after ATOT",
      ),
    asat: () =>
      validateCondition(
        flight.asrt && !flight.aobt,
        "Can not edit ASAT before ASRT or after AOBT",
      ),
    asbt: () =>
      validateCondition(
        flight.aibt && !flight.aobt,
        "Can not edit ASBT before AIBT or after AOBT",
      ),
    asrt: () =>
      validateCondition(
        flight.ardt && !flight.aobt,
        "Can not edit ASRT before ARDT or after AOBT",
      ),
    ardt: () =>
      validateCondition(
        flight.asbt && !flight.aobt,
        "Can not edit ARDT before ASBT or after AOBT",
      ),
    atot: () => validateCondition(flight.aobt, "Can not edit ATOT before AOBT"),
    eibt: () => validateCondition(!flight.aibt, "Can not edit EIBT after AIBT"),
    eobt: () => validateCondition(!flight.aobt, "Can not edit EOBT after AOBT"),
    ettt: () => UpdateTobtAfterFreeze(flight, roles, tobtUpdate, "ETTT"),
    exit: () => validateCondition(!flight.aibt, "Can not edit EXIT after AIBT"),
    exot: () => validateCondition(!flight.atot, "Can not edit EXOT after ATOT"),
    tobt: () =>
      UpdateTobtAfterFreeze(flight, roles, tobtUpdate, "TOBT", action),
    tsat: () => validateCondition(!flight.aobt, "Can not edit TSAT after AOBT"),
  };
  return canEdit[field.toLowerCase()]();
};

const UpdateTobtAfterFreeze = (
  flight,
  roles,
  tobtUpdate,
  updatedField,
  action,
) => {
  if (flight.aobt !== null)
    return {
      canEdit: false,
      message: `Can not update ${updatedField} after OFF-BLOCK (AOBT)`,
    };

  if (action) return { canEdit: true };

  if (flight.aibt === null)
    return {
      canEdit: false,
      message: `Can not update ${updatedField} before IN-BLOCK (AIBT)`,
    };

  const date = new Date().getTime();

  const updateTobtAfterFreeze = roles.some(
    (role) => role.update_tobt_after_freeze,
  );

  if (updateTobtAfterFreeze) {
    return { canEdit: true };
  }

  const {
    freezeTobt = {},
    isEnabled: isEnabledFreeze,
    maxTobtUpdate,
  } = tobtUpdate;

  const { field: freezeField, value: freezeValue } = freezeTobt;
  const { value: maxUpdate } = maxTobtUpdate;

  if (!isEnabledFreeze) return { canEdit: true };

  const fieldFreezeValue = new Date(flight[freezeField.toLowerCase()]);
  const timeToFreeze = new Date(
    fieldFreezeValue - Number(freezeValue) * 60000,
  ).getTime();

  if (date < timeToFreeze) return { canEdit: true };

  if (
    date > timeToFreeze &&
    flight.tobt_updated_times_after_freeze < maxUpdate
  ) {
    return { canEdit: true };
  }

  return {
    canEdit: false,
    message:
      "Exceed the maximum number of allowed TOBT update. Please contact EMMA Manager",
  };
};

export default handleEditableFieldsConditions;

import React, { useEffect, useState } from "react";

const NoiseInfo = ({ tooltipPosition, activeFlights, hideToolTips, size }) => {
  const [active, setActive] = useState(0);
  const sizeMultiplier = size.width > 1000 ? 1 : 2;

  const onCloseToolTip = () => {
    hideToolTips({ name: `noise-block` });
  };

  useEffect(() => {
    const activeFlight = activeFlights.filter(
      ({ aldt, aibt, aobt, atot }) =>
        (aldt && aibt === null) || (aobt && atot === null),
    ).length;
    setActive(activeFlight);
  }, [activeFlights]);

  useEffect(() => {
    const element = document.querySelector(".airport");
    element?.addEventListener("click", onCloseToolTip);
    return () => {
      element?.removeEventListener("click", onCloseToolTip);
    };
  }, []);

  return (
    <div
      className="noise-tooltip noise-info"
      name="noise-block"
      style={{
        top: tooltipPosition.top + 14 / sizeMultiplier,
        left: tooltipPosition.left,
      }}
    >
      <div className="col-noise">
        <div className="total-active-flight">TOTAL</div>
        <div className="active-flight">{`Active flights (${active})`}</div>
      </div>
      <div className="col-noise">
        <div className="header-col">Est total fuel consum</div>
        <div className="noise-value">{`${active * 12} liters`}</div>
      </div>
      <div className="col-noise">
        <div className="header-col">Est total noise level</div>
        <div className="noise-value">{`${active * 140} dB`}</div>
      </div>
      <div className="col-noise">
        <div className="header-col">Est total emissions</div>
        <div className="noise-value">{`${active * 2.5} PM10`}</div>
      </div>
    </div>
  );
};

export default NoiseInfo;

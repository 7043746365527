import {
  REINIT_LAYOUTS,
  FETCH_LAYOUTS_START,
  FETCH_LAYOUTS_SUCCESS,
  FETCH_LAYOUTS_FAIL,
  SAVE_USER_LAYOUT_START,
  SAVE_USER_LAYOUT_SUCCESS,
  SAVE_USER_LAYOUT_FAIL,
  UPDATE_FAVORITE_LAYOUT_START,
  UPDATE_FAVORITE_LAYOUT_SUCCESS,
  UPDATE_FAVORITE_LAYOUT_FAIL,
  UPDATE_USER_LAYOUT_CONFIG_START,
  UPDATE_USER_LAYOUT_CONFIG_SUCCESS,
  UPDATE_USER_LAYOUT_CONFIG_FAIL,
  UPDATE_USER_LAYOUT,
  CHANGE_LAYOUT,
  REINIT_SELECTED_LAYOUT,
} from "../constants";

import { getRequest, postRequest, putRequest } from "utils/APIRequest";
import { showSuccessNotification } from "./notification-action";

export const reinitLayouts = () => {
  return (dispatch) => {
    dispatch({ type: REINIT_LAYOUTS });
  };
};

export const reinitSelectedLayout = () => {
  return (dispatch) => {
    dispatch({ type: REINIT_SELECTED_LAYOUT });
  };
};

export const changeLayout = (data) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_LAYOUT, data });
  };
};

const fetchLayoutsStart = () => ({
  type: FETCH_LAYOUTS_START,
});

const fetchLayoutsSuccess = (data) => ({
  type: FETCH_LAYOUTS_SUCCESS,
  data,
});

const fetchLayoutsFail = () => ({
  type: FETCH_LAYOUTS_FAIL,
});

export const fetchLayouts = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchLayoutsStart());

    const response = await getRequest("/api/layouts", data);

    if (!response.error) {
      dispatch(fetchLayoutsSuccess(response));
    } else {
      dispatch(fetchLayoutsFail());
    }
  };
};

const updateUserLayoutConfigStart = () => ({
  type: UPDATE_USER_LAYOUT_CONFIG_START,
});

const updateUserLayoutConfigSuccess = () => ({
  type: UPDATE_USER_LAYOUT_CONFIG_SUCCESS,
});

const updateUserLayoutConfigFail = () => ({
  type: UPDATE_USER_LAYOUT_CONFIG_FAIL,
});

export const updateUserLayoutConfig = (roleId, data = {}, cb) => {
  return async (dispatch) => {
    dispatch(updateUserLayoutConfigStart());
    const response = await putRequest(`/api/layouts/user/${roleId}`, data);

    if (!response.error) {
      dispatch({
        type: UPDATE_USER_LAYOUT,
        data: { ...response.layout_config },
      });

      dispatch(updateUserLayoutConfigSuccess());
      cb && cb();
    } else {
      dispatch(updateUserLayoutConfigFail());
    }
  };
};

const saveUserLayoutStart = () => ({
  type: SAVE_USER_LAYOUT_START,
});

const saveUserLayoutSuccess = (data) => ({
  type: SAVE_USER_LAYOUT_SUCCESS,
  data,
});

const saveUserLayoutFail = () => ({
  type: SAVE_USER_LAYOUT_FAIL,
});

export const saveUserLayout = (data = {}, cb) => {
  return async (dispatch) => {
    dispatch(saveUserLayoutStart());
    const response = await postRequest(`/api/layouts`, data);

    if (!response.error) {
      dispatch(showSuccessNotification("Save layout successfully"));
      dispatch({ type: UPDATE_USER_LAYOUT, data: response.data.layout_config });

      dispatch({
        type: UPDATE_USER_LAYOUT,
        data: {
          blocks: response.data.layout_config,
          direction: response.data.direction,
        },
      });
      dispatch(
        showSuccessNotification(
          `Layout ${response.data.alias} saved successfully`,
        ),
      );
      dispatch(saveUserLayoutSuccess(response.data));
      cb && cb();
    } else {
      dispatch(saveUserLayoutFail());
    }
  };
};

const updateFavoriteLayoutStart = () => ({
  type: UPDATE_FAVORITE_LAYOUT_START,
});

const updateFavoriteLayoutSuccess = (data) => ({
  type: UPDATE_FAVORITE_LAYOUT_SUCCESS,
  data,
});

const updateFavoriteLayoutFail = () => ({
  type: UPDATE_FAVORITE_LAYOUT_FAIL,
});

export const updateFavoriteLayout = (data = {}, action = "", cb) => {
  return async (dispatch) => {
    dispatch(updateFavoriteLayoutStart());
    const response = await putRequest(`/api/layouts`, data);

    if (!response.error) {
      dispatch(showSuccessNotification(`${action} layout successfully`));
      dispatch(updateFavoriteLayoutSuccess(data));
      cb && cb();
    } else {
      dispatch(updateFavoriteLayoutFail());
    }
  };
};

import _ from "lodash";
import {
  SET_ALERTS,
  FETCH_ACTIVE_ALERTS_START,
  FETCH_ACTIVE_ALERTS_SUCCESS,
  FETCH_ACTIVE_ALERTS_FAIL,
  UPDATE_ALERTS_SOCKET_DATA,
  FETCH_GENERAL_ALERTS_START,
  FETCH_GENERAL_ALERTS_SUCCESS,
  FETCH_GENERAL_ALERTS_FAIL,
  REINIT_ACTIVE_ALERTS,
} from "../constants";
import { updateObject } from "./utility";

const initialState = {
  alerts: {},
  notifications: {},
  "general alerts": {},
  loading: false,
  total: 0,
};

const updateSocketData = (currentState, alertData = {}) => {
  const result = { ...currentState };

  Object.entries(alertData).forEach(([key, newData]) => {
    const currentData = currentState[key];
    const { deleted, updated } = newData;
    const updatedData = { ...currentData, ...updated };

    result[key] = _.omit(updatedData, deleted);
  });

  return {
    ...result,
    loading: false,
  };
};

const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_ACTIVE_ALERTS:
      return updateObject(state, initialState);
    case FETCH_ACTIVE_ALERTS_START:
      return updateObject(state, { loading: true });
    case FETCH_ACTIVE_ALERTS_SUCCESS:
      const { alerts, notifications } = action.data || {};
      return updateObject(state, {
        alerts,
        notifications,
        loading: false,
      });
    case FETCH_ACTIVE_ALERTS_FAIL:
      return updateObject(state, { loading: false });

    case UPDATE_ALERTS_SOCKET_DATA:
      return updateSocketData(state, action.socketData);

    case FETCH_GENERAL_ALERTS_START:
      return updateObject(state, { loading: true });
    case FETCH_GENERAL_ALERTS_SUCCESS:
      return updateObject(state, {
        "general alerts": action.data,
        loading: false,
      });
    case FETCH_GENERAL_ALERTS_FAIL:
      return updateObject(state, { loading: false });

    case SET_ALERTS:
      return action.payload;
    default:
      return state;
  }
};

export default alertsReducer;

import React from "react";
import { Box } from "@mui/material";
import moment from "moment-timezone";
import UiDatePicker from "../../../../common/date/date";
import InputForm from "components/common/UiLibrary/input_form";
import TimePickerUi from "components/common/TimePicker/TimePicker";
import styles from "./blocked.module.css";
import GeneralButton from "components/common/Button";

export default class BlockedAreaRectTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warning: null,
      note: this.props.currentNote || "",
      startDate: "",
      endDate: "",
      startTime: null,
      endTime: null,
    };
    this.dismiss = this.dismiss.bind(this);
    this.skip = this.skip.bind(this);
    this.saveBlockedArea = this.saveBlockedArea.bind(this);
    this.updateBlockedArea = this.updateBlockedArea.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.addOrEdit = this.addOrEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
  }

  handleDateChange(startDate, endDate) {
    this.setState({
      startDate,
      endDate: endDate || startDate,
      warning: null,
    });
  }

  dismiss() {
    this.props.unmountMe();
  }

  skip() {
    this.props.skip();
    this.dismiss();
  }

  formatDate(date, time) {
    const dateFormat = moment(date).format("MM/DD/YYYY");
    const fullDateFormat = moment(
      `${dateFormat} ${time}`,
      "MM/DD/YYYY hh:mm A",
    );

    if (this.props.timeZone === "utc") {
      return moment(fullDateFormat.format("YYYY-MM-DDTHH:mm:ss[Z]"));
    }
    return moment(fullDateFormat).tz(this.props.timeZone);
  }

  saveBlockedArea() {
    const { startDate, endDate, note, startTime, endTime } = this.state;
    const start = this.formatDate(startDate, startTime);
    const end = this.formatDate(endDate, endTime);
    if (!startDate && !endDate) {
      this.setState({ warning: "Please select start/end date" });
    } else if (!endDate) {
      this.setState({ warning: "Please select end date" });
    } else if (!startDate) {
      this.setState({ warning: "Please select start date" });
    } else if (startDate > endDate) {
      this.setState({ warning: "Start date is after end date" });
    } else {
      this.props.addBlockedArea({
        shape: "rect",
        color: startDate >= new Date().getTime() ? "orange" : "red",
        note,
        status: startDate >= new Date().getTime() ? "On schedule" : "Blocked",
        start_date: start,
        end_date: end,
      });
      this.setState({ warning: null });
      this.dismiss();
    }
  }

  updateBlockedArea() {
    const { startDate, endDate, startTime, endTime } = this.state;
    const start = this.formatDate(startDate, startTime);
    const end = this.formatDate(endDate, endTime);

    if (!startDate && !endDate) {
      this.setState({ warning: "Please select start/end date" });
    } else if (!endDate) {
      this.setState({ warning: "Please select end date" });
    } else if (!startDate) {
      this.setState({ warning: "Please select start date" });
    } else if (startDate > endDate) {
      this.setState({ warning: "Start date is after end date" });
    } else {
      this.props.updateBlockedArea({
        id: this.props.currentBlockId,
        shape: "rect",
        color: this.state.startDate >= new Date().getTime() ? "orange" : "red",
        note: this.state.note,
        status:
          this.state.startDate >= new Date().getTime()
            ? "On schedule"
            : "Blocked",
        start_date: start,
        end_date: end,
      });
      this.setState({ warning: null });
      this.dismiss();
    }
  }

  addOrEdit(ev) {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    return this.props.currentNote !== null
      ? this.updateBlockedArea()
      : this.saveBlockedArea();
  }

  handleChange(ev) {
    const newValue = {};
    newValue[ev.target.name] = ev.target.value;
    this.setState(newValue);
  }

  handleStartTimeChange = (value) => {
    this.setState({
      startTime: value,
    });
  };

  handleEndTimeChange = (value) => {
    this.setState({
      endTime: value,
    });
  };

  handleStartDateChange = (date) => {
    this.setState({
      startDate: moment(date).format("MM/DD/YYYY"),
    });
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: moment(date).format("MM/DD/YYYY"),
    });
  };

  render() {
    return (
      <>
        <Box className={styles.boxModal}>
          <div className={styles.headerWrapper}>
            <div className={styles.headerText}>Insert blocked area info</div>
            <div
              onClick={this.dismiss}
              role="presentation"
              className={styles.cancelButton}
            >
              X
            </div>
          </div>
          <InputForm
            label="Note"
            name="note"
            id="note"
            value={this.state.note}
            onChange={this.handleChange}
            key="note"
          />
          <TimePickerUi
            startTime={this.state.startTime}
            endTime={this.state.endTime}
            handleStartTimeChange={this.handleStartTimeChange}
            handleEndTimeChange={this.handleEndTimeChange}
          />
          <div className={styles.dateFilterWrapperAsmgcs}>
            <UiDatePicker
              label="Start date"
              value={this.state.startDate}
              handleChange={this.handleStartDateChange}
            />
            <UiDatePicker
              label="End date"
              value={this.state.endDate}
              handleChange={this.handleEndDateChange}
            />
          </div>
          <div className={styles.containerWrapperBtn}>
            <GeneralButton type="text" onClick={this.skip}>
              Skip
            </GeneralButton>
            <GeneralButton onClick={this.addOrEdit} type="primary">
              {this.props.currentNote !== null ? "Edit" : "Save"}
            </GeneralButton>
            {this.state.warning ? (
              <div className="warning">{this.state.warning}</div>
            ) : null}
          </div>
        </Box>
      </>
    );
  }
}

import React, { useEffect, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import Tooltip from "components/common/Tooltip";
import { useSelector } from "react-redux";

function TobtLeadTime({ flight }) {
  const [timeDifference, setTimeDifference] = useState(null);
  const [toolTip, setToolTip] = useState(false);

  const { tobt_lead_time : leadTime } = useSelector((state) => state.SystemParams.systemParams);
	
  useEffect(() => {
		const calculateTimeDifference = () => {
			const obtTime = new Date(flight.obt).getTime();
      const currentTime = new Date().getTime();
			const timeDiffInMilliseconds = obtTime - currentTime;
      const timeDiffInMinutes = Math.ceil(timeDiffInMilliseconds / (1000 * 60));
				return timeDiffInMinutes;
			};
			
			const updateTimer = () => {
				const newTimeDiffInMinutes = calculateTimeDifference();
      if (newTimeDiffInMinutes <= leadTime?.value) {
        setTimeDifference(newTimeDiffInMinutes);
      } else {
        setTimeDifference(null);
      }
    };

    updateTimer();

    const intervalId = setInterval(() => {
      updateTimer();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [flight.obt]);

  const showTooltip = () => {
    setToolTip(true);
  };

  return (
    <>
      {timeDifference !== null && !flight.aobt && (
        <span onMouseEnter={showTooltip} className="tobt-alert">
          {!toolTip ? (
            <CircleIcon style={{ color: "#E49318", fontSize: 10 }} />
          ) : (
            <Tooltip
              data={<CircleIcon style={{ color: "#E49318", fontSize: 10 }} />}
              Compo={`Time to TOBT: ${timeDifference} min`}
            />
          )}
        </span>
      )}
    </>
  );
}

export default TobtLeadTime;

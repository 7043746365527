import React, { memo, useEffect, useRef, useState } from "react";
import "./style.css";

const Tooltip = memo(({ data, Compo, classConfig, acdmRef }) => {
  const [tooltipPositionEdge, setTooltipPositionEdge] = useState(false);
  const ref = useRef();

  useEffect(() => {
    getTooltipPosition();
  }, [ref, acdmRef]);

  const getTooltipPosition = () => {
    const cellRightPosition = ref?.current?.getBoundingClientRect().left + 110;
    const acdmWidth = acdmRef?.current?.getBoundingClientRect().width;
    cellRightPosition > acdmWidth
      ? setTooltipPositionEdge(true)
      : setTooltipPositionEdge(false);
  };

  return (
    <span ref={ref} className={`toolTip`}>
      {data}

      <span
        className={classConfig ? classConfig : "tooltiptext"}
        data-tooltip-edge={tooltipPositionEdge}
      >
        {Compo}
      </span>
    </span>
  );
});

export default Tooltip;

import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/APIRequest";
import {
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  SUCCESS_MESSAGE,
  DELETE_USERS_START,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
  RESTORE_USERS_START,
  RESTORE_USERS_SUCCESS,
  RESTORE_USERS_FAIL,
  CREATE_USERS_START,
  CREATE_USERS_SUCCESS,
  CREATE_USERS_FAIL,
  UPDATE_USERS_START,
  UPDATE_USERS_FAIL,
  ERROR_MESSAGE,
  FETCH_USER_DETAILS_USER_START,
  FETCH_USER_DETAILS_USER_SUCCESS,
  FETCH_USER_DETAILS_USER_FAIL,
} from "../constants";

const fetchUsersStart = () => ({
  type: FETCH_USERS_START,
});

const fetchUsersSuccess = (data, total) => ({
  type: FETCH_USERS_SUCCESS,
  data,
  total,
});

const fetchUsersFail = () => ({
  type: FETCH_USERS_FAIL,
});

export const fetchUsers = (data) => {
  return async (dispatch) => {
    dispatch(fetchUsersStart());

    const response = await getRequest(`/api/users`, data);

    if (!response.error) {
      dispatch(fetchUsersSuccess(response.data, response.total));
    } else {
      dispatch(fetchUsersFail());
    }
  };
};

const deleteUsersStart = () => ({
  type: DELETE_USERS_START,
});

const deleteUsersSuccess = (data) => ({
  type: DELETE_USERS_SUCCESS,
  data,
});

const deleteUsersFail = () => ({
  type: DELETE_USERS_FAIL,
});

export const deleteUsers = (userIds, cb) => {
  return async (dispatch) => {
    dispatch(deleteUsersStart());
    const url = `/api/users`;
    const response = await deleteRequest(url, { ids: userIds });

    if (!response.error) {
      dispatch({ type: SUCCESS_MESSAGE, message: response.message });
      dispatch(deleteUsersSuccess(userIds));
      cb && cb();
    } else {
      dispatch(deleteUsersFail());
    }
  };
};

const restoreUsersStart = () => ({
  type: RESTORE_USERS_START,
});

const restoreUsersSuccess = (data) => ({
  type: RESTORE_USERS_SUCCESS,
  data,
});

const restoreUsersFail = () => ({
  type: RESTORE_USERS_FAIL,
});

export const restoreUsers = (userIds, cb) => {
  return async (dispatch) => {
    dispatch(restoreUsersStart());
    const url = `/api/users/restore/multi`;
    const response = await putRequest(url, { ids: userIds });

    if (!response.error) {
      dispatch({ type: SUCCESS_MESSAGE, message: response.message });
      dispatch(restoreUsersSuccess(userIds));
      cb && cb();
    } else {
      dispatch(restoreUsersFail());
    }
  };
};

const createUserStart = () => ({
  type: CREATE_USERS_START,
});

const createUserSuccess = (data) => ({
  type: CREATE_USERS_SUCCESS,
  data,
});

const createUserFail = () => ({
  type: CREATE_USERS_FAIL,
});

export const createUser = (data) => {
  return async (dispatch) => {
    dispatch(createUserStart());

    const response = await postRequest(`/api/users?`, data);

    if (!response.error) {
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "User created successfully",
        back: true,
      });
      dispatch(createUserSuccess(response.data));
    } else {
      dispatch({
        type: ERROR_MESSAGE,
        message: response.message,
      });
      dispatch(createUserFail());
    }
  };
};

const updateUserStart = () => ({
  type: UPDATE_USERS_START,
});

const updateUserFail = () => ({
  type: UPDATE_USERS_FAIL,
});

export const updateUser = (userId, data) => {
  return async (dispatch) => {
    dispatch(updateUserStart());

    const response = await putRequest(`/api/users/${userId}?`, data);

    if (!response.error) {
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "User updated successfully",
        back: true,
      });
    } else {
      dispatch(updateUserFail());
      dispatch({ type: ERROR_MESSAGE, message: response.message });
    }
  };
};

const fetchUserDetailsStart = () => ({
  type: FETCH_USER_DETAILS_USER_START,
});

const fetchUserDetailsSuccess = (data) => ({
  type: FETCH_USER_DETAILS_USER_SUCCESS,
  data,
});

const fetchUserDetailsFail = () => ({
  type: FETCH_USER_DETAILS_USER_FAIL,
});

export const fetchUserDetails = (userId) => {
  return async (dispatch) => {
    dispatch(fetchUserDetailsStart());

    const response = await getRequest(`/api/users/${userId}`);

    if (!response.error) {
      dispatch(fetchUserDetailsSuccess(response));
    } else {
      dispatch(fetchUserDetailsFail());
    }
  };
};

import React from "react";
import "./style.css";
import ChooseField from "./choose_field";

const UpdateField = (props) => {
  const { modalUpdateCallBack, data, handleClose, x, y } = props;
  const { field, flight, inputModalDesc } = data;
  const { flight_number } = flight;
  const { name } = field;

  const _getTopPopup = (y) => {
    let top;
    if (y < 400) {
      top = y / 1.1;
    }
    if (y > 400 && y < 800) {
      top = y / 1.4;
    }
    if (y > 800 && y < 1000) {
      top = y - y / 4.5;
    }
    if (y > 1000) {
      top = y - 270;
    }
    return top;
  };

  const _getTop = (y) => {
    let top;
    if (field?.type === "number") {
      return _getTopPopup(y);
    }
    if (y < 400) {
      top = y / 1.1;
    }
    if (y > 400 && y < 800) {
      top = y / 1.62;
    }
    if (y > 800 && y < 1000) {
      top = y - y / 3.3;
    }
    if (y > 1000) {
      top = y - 290;
    }

    return top;
  };

  const _popUpLocation = () => {
    if (x && y)
      return {
        position: "fixed",
        top: `${_getTop(y)}px`,
        left: `${x < 470 ? x + 80 : x - 393}px`,
      };

    return {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
  };

  const polygonLocation = () => ({
    position: "fixed",
    top: `${y + 19}px`,
    left: `${x < 470 ? x - 220 : x - 303}px`,
  });

  const closeHandler = () => {
    handleClose(inputModalDesc);
  };

  return (
    <div className="modal-content-popup" style={_popUpLocation()}>
      <div className="polygon" style={polygonLocation()} />
      <div className="modal-header">
        <span className="modal-header-main">{`Edit ${name.toUpperCase()} for flight ${flight_number}`}</span>
        <span
          tabIndex="0"
          role="button"
          data-confirm="no"
          onKeyDown={closeHandler}
          onClick={closeHandler}
          className="modal-close"
        >
          &times;
        </span>
      </div>
      <ChooseField
        data={data}
        handleClose={handleClose}
        modalUpdateCallBack={modalUpdateCallBack}
      />
    </div>
  );
};

export default UpdateField;

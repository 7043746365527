import React from "react";

const ColorsCode = ({ widgetColors = {} }) => {
  return (
    <div className="colors-boxes">
      {Object.keys(widgetColors).map((status) => {
        return (
          <div key={status}>
            <span
              style={{ backgroundColor: widgetColors[status] }}
              className="color-box"
            />
            <span className="color-status">{status}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ColorsCode;

import React from "react";

const LayoutBlocks = ({ layoutList, layoutId }) => {
  return Object.keys(layoutList[layoutId].blocks).map((blockItem) => {
    const block = layoutList[layoutId].blocks[blockItem];

    return (
      <div
        key={blockItem}
        style={{ height: block.height, width: block.width }}
        className={blockItem}
      >
        {layoutList[layoutId].predefined && block.content && block.content[0]}
      </div>
    );
  });
};

export default LayoutBlocks;

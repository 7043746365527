import React, { memo } from "react";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import "./style.css";

const EmmaCheckBox = ({ checked, onChange, disabled, name, id, label }) => {
  const BpCheckedIcon = styled("span")({
    backgroundColor: "#205CF6",
    borderRadius: "2px",

    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: "#424d5c",
    },
    "&:before": {
      display: "block",
      width: "14px",
      height: "14px",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "4.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0013 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },

    "input:hover ~ &": {
      backgroundColor: "#205CF6",
    },
  });

  const sx = {
    "&:hover": { bgcolor: "transparent" },
    color: "#909FBA",
    "&.Mui-checked": {
      color: "#205CF6",
    },
    "&.Mui-checked svg": {
      fontSize: "14px",
    },
    "& svg > path": {
      stroke: "#909FBA",
      strokeWidth: "2px",
    },
    "&.MuiCheckbox-root.Mui-disabled  svg > path": {
      stroke: "#424d5c",
      strokeWidth: "2px",
    },
    "&.MuiCheckbox-root svg": {
      fontSize: "14px",
    },
    "&.MuiCheckbox-root:hover": {
      color: "#205CF6",
    },
  };
  return (
    <div className="container-checkbox">
      <Checkbox
        checkedIcon={<BpCheckedIcon />}
        sx={sx}
        onChange={onChange}
        checked={!!checked}
        disabled={disabled}
        name={name}
        id={id}
      />
       <label>{label || ""}</label>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.checked === nextProps.checked &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.id === nextProps.id &&
    prevProps.label === nextProps.label &&
    prevProps.name === nextProps.name &&
    prevProps.onChange === nextProps.onChange
  );
};
export default memo(EmmaCheckBox, areEqual);

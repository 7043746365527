import React from "react";
import classNames from "classnames";

const TableBody = ({ belt_id, active, flights, status, terminal }) => {
  const isAnyFlightActive = () => {
    return flights.find((e) => e.status === "active");
  };
  const isActive = () => {
    if (!active || !flights.length || isAnyFlightActive()) {
      return false;
    }
    return true;
  };

  return (
    <tr
      key={belt_id}
      className={`${isActive() ? "active-belt" : ""} info-belt`}
    >
      <td>{belt_id}</td>
      <td>{terminal}</td>
      <td className={classNames({ "under-maint": !active })}>{status}</td>
      <td className="bhs-flights-time">
        {flights.map((d, k) => (
          <div key={k} className="belt-flights-details">
            <div className="belt-flight-number">
              <span>{d.flight_number}</span>
              <span>|</span>
            </div>
            <span>
              {formatDate(d.start_time)} - {formatDate(d.end_time)}
            </span>
          </div>
        ))}
      </td>
    </tr>
  );
};

export default TableBody;

import {
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAIL,
  DELETE_NOTIFICATIONS_START,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_FAIL,
  RESTORE_NOTIFICATIONS_START,
  RESTORE_NOTIFICATIONS_SUCCESS,
  RESTORE_NOTIFICATIONS_FAIL,
  CREATE_NOTIFICATIONS_START,
  CREATE_NOTIFICATIONS_SUCCESS,
  CREATE_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATIONS_START,
  UPDATE_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_FAIL,
  FETCH_NOTIFICATION_START,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAIL,
  SUCCESS_MESSAGE,
} from "../constants";

import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/APIRequest";

const fetchNotificationsStart = () => ({
  type: FETCH_NOTIFICATIONS_START,
});

const fetchNotificationsSuccess = (data, total) => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  data,
  total,
});

const fetchNotificationsFail = () => ({
  type: FETCH_NOTIFICATIONS_FAIL,
});

export const fetchNotifications = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchNotificationsStart());

    const response = await getRequest(`/api/notifications`, data);

    if (!response.error) {
      dispatch(fetchNotificationsSuccess(response.data, response.total));
    } else {
      dispatch(fetchNotificationsFail());
    }
  };
};

const deleteNotificationsStart = () => ({
  type: DELETE_NOTIFICATIONS_START,
});

const deleteNotificationsSuccess = (data) => ({
  type: DELETE_NOTIFICATIONS_SUCCESS,
  data,
});

const deleteNotificationsFail = () => ({
  type: DELETE_NOTIFICATIONS_FAIL,
});

export const deleteNotifications = (data = {}, cb) => {
  return async (dispatch) => {
    dispatch(deleteNotificationsStart());

    const response = await deleteRequest("/api/notifications", { ids: data });

    if (!response.error) {
      dispatch(deleteNotificationsSuccess(data));
      cb && cb();
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "Delete records successfully",
      });
    } else {
      dispatch(deleteNotificationsFail());
    }
  };
};

const restoreNotificationsStart = () => ({
  type: RESTORE_NOTIFICATIONS_START,
});

const restoreNotificationsSuccess = (data) => ({
  type: RESTORE_NOTIFICATIONS_SUCCESS,
  data,
});

const restoreNotificationsFail = () => ({
  type: RESTORE_NOTIFICATIONS_FAIL,
});

export const restoreNotifications = (data = {}, cb) => {
  return async (dispatch) => {
    dispatch(restoreNotificationsStart());

    const response = await putRequest("/api/notifications/restore/multi", {
      ids: data,
    });

    if (!response.error) {
      dispatch(restoreNotificationsSuccess(data));
      cb && cb();
      dispatch({ type: SUCCESS_MESSAGE, message: response.message });
    } else {
      dispatch(restoreNotificationsFail());
    }
  };
};

const createNotificationsStart = () => ({
  type: CREATE_NOTIFICATIONS_START,
});

const createNotificationsSuccess = (data) => ({
  type: CREATE_NOTIFICATIONS_SUCCESS,
  data,
});

const createNotificationsFail = () => ({
  type: CREATE_NOTIFICATIONS_FAIL,
});

export const createNotifications = (data = {}, cb) => {
  return async (dispatch) => {
    dispatch(createNotificationsStart());

    const response = await postRequest("/api/notifications", data);

    if (!response.error) {
      dispatch(createNotificationsSuccess(data));
      cb && cb();
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "Create notification successfully",
        back: true,
      });
    } else {
      dispatch(createNotificationsFail());
    }
  };
};

const updateNotificationsStart = () => ({
  type: UPDATE_NOTIFICATIONS_START,
});

const updateNotificationsSuccess = (data) => ({
  type: UPDATE_NOTIFICATIONS_SUCCESS,
  data,
});

const updateNotificationsFail = () => ({
  type: UPDATE_NOTIFICATIONS_FAIL,
});

export const updateNotifications = (dataId, data = {}, cb) => {
  return async (dispatch) => {
    dispatch(updateNotificationsStart());

    const response = await putRequest(`/api/notifications/${dataId}`, data);

    if (!response.error) {
      dispatch(updateNotificationsSuccess(data));
      cb && cb();
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "Update notification successfully",
        back: data.edit,
      });
    } else {
      dispatch(updateNotificationsFail());
    }
  };
};

const fetchNotificationStart = () => ({
  type: FETCH_NOTIFICATION_START,
});

const fetchNotificationSuccess = (data) => ({
  type: FETCH_NOTIFICATION_SUCCESS,
  data,
});

const fetchNotificationFail = () => ({
  type: FETCH_NOTIFICATION_FAIL,
});

export const fetchNotification = (dataId) => {
  return async (dispatch) => {
    dispatch(fetchNotificationStart());

    const response = await getRequest(`/api/notifications/${dataId}`);

    if (!response.error) {
      dispatch(fetchNotificationSuccess(response));
    } else {
      dispatch(fetchNotificationFail());
    }
  };
};

export const renderFields = [
  {
    name: "level",
    type: "string",
    title: "Level",
    list: ["alerts", "notifications", "general alerts"],
    activeField: true,
    filterField: false,
    canShowOrHide: true,
    export: true,
    render: (object) => object.level,
  },
  {
    name: "alert_code",
    type: "string",
    title: "Code",
    list: ["alerts", "notifications", "general alerts"],
    activeField: true,
    filterField: true,
    canShowOrHide: true,
    export: true,
    render: (object) => object.alert_code,
  },
  {
    name: "flight_number",
    type: "string",
    title: "Flight",
    list: ["alerts", "notifications"],
    activeField: true,
    filterField: true,
    canShowOrHide: true,
    export: true,
    render: (object) => object.flight_number,
  },
  {
    name: "alert_description",
    type: "string",
    title: "Description",
    list: ["alerts", "notifications", "general alerts"],
    activeField: true,
    filterField: true,
    canShowOrHide: true,
    export: true,
    render: (object) => object.alert_description,
  },
  {
    name: "created_at",
    type: "date",
    title: "Date / Time",
    list: ["alerts", "notifications", "general alerts"],
    activeField: true,
    filterField: true,
    canShowOrHide: true,
    export: true,
    render: (object) => object.created_at,
  },
  {
    name: "required_actions",
    type: "action",
    title: "Required Actions",
    list: ["alerts", "general alerts"],
    activeField: true,
    filterField: false,
    canShowOrHide: true,
    export: false,
    render: (object) => object.required_actions,
  },
];

export const types = [
  { name: "alerts", alias: "Alerts", permission_code: "alert_tab" },
  {
    name: "notifications",
    alias: "Notifications",
    permission_code: "notification_tab",
  },
  {
    name: "general alerts",
    alias: "General Alerts",
    permission_code: "general-alerts",
  },
];

import moment from "moment-timezone";

const FORMAT = "YYYY-MM-DD HH:mm:ss[Z]";

const validation = (newVal, fields, errorMsg, timeZone) => {
  let convertednewVal = moment.utc(newVal, FORMAT);
  if (timeZone !== "UTC") {
    convertednewVal = moment.tz(newVal, FORMAT, timeZone);
  }
  const pass = fields.every((field) => {
    const fieldUtc = moment.utc(field, FORMAT);
    return convertednewVal.isAfter(fieldUtc);
  });

  if (pass) return { isValid: true };
  return { isValid: false, errorMsg };
};

const checkValidationUpdate = (newVal, field, flight, timeZone) => {
  const { aobt, aldt, eldt, aibt, eibt, asrt } = flight;
  const ibt = aibt || eibt;
  const ldt = aldt || eldt;
  const isValid = {
    atot: () =>
      validation(newVal, [aobt], "ATOT can not  be less than AOBT", timeZone),
    aibt: () =>
      validation(
        newVal,
        [ldt],
        "AIBT can not  be less than ALDT or ELDT",
        timeZone,
      ),
    eibt: () =>
      ldt
        ? validation(
            newVal,
            [ldt],
            "EIBT can not  be less than ALDT or ELDT",
            timeZone,
          )
        : validation(
            newVal,
            [ldt],
            "Can not edit EIBT before ALDT or ELDT",
            timeZone,
          ),
    eobt: () =>
      validation(newVal, [ibt], "EOBT can not  be less than IBT", timeZone),
    aobt: () =>
      validation(newVal, [ibt], "AOBT can not  be less than IBT", timeZone),
    tobt: () =>
      validation(newVal, [ibt], "TOBT can not  be less than IBT", timeZone),
    tsat: () =>
      validation(newVal, [ibt], "TSAT can not  be less than IBT", timeZone),
    asat: () =>
      validation(newVal, [asrt], "ASAT can not  be less than ASRT", timeZone),
    acgt: () =>
      validation(newVal, [aibt], "ACGT can not  be less than AIBT", timeZone),
  };
  return isValid[field.toLowerCase()]
    ? isValid[field.toLowerCase()]()
    : { isValid: true };
};

export default checkValidationUpdate;

import {
  FETCH_ALERTS_START,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_FAIL,
  REINIT_ALERTS,
  DELETE_ALERTS_START,
  DELETE_ALERTS_SUCCESS,
  DELETE_ALERTS_FAIL,
  RESTORE_ALERTS_START,
  RESTORE_ALERTS_SUCCESS,
  RESTORE_ALERTS_FAIL,
  CREATE_ALERT_START,
  CREATE_ALERT_SUCCESS,
  CREATE_ALERT_FAIL,
  UPDATE_ALERT_START,
  UPDATE_ALERT_SUCCESS,
  UPDATE_ALERT_FAIL,
  FETCH_ALERT_START,
  FETCH_ALERT_SUCCESS,
  FETCH_ALERT_FAIL,
  CHECK_QUERY_START,
  CHECK_QUERY_SUCCESS,
  CHECK_QUERY_FAIL,
} from "../constants";
import { deleteMultiData, updateData, updateObject } from "./utility";

const initialState = {
  alerts: [],
  alert: {},
  loading: false,
  total: 0,
};

const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_ALERTS:
      return updateObject(state, initialState);
    case FETCH_ALERTS_START:
      return updateObject(state, { loading: true });
    case FETCH_ALERTS_SUCCESS:
      return updateObject(state, { alerts: action.data, total: action.total });
    case FETCH_ALERTS_FAIL:
      return updateObject(state, { loading: false });

    case DELETE_ALERTS_START:
      return updateObject(state, { loading: true });
    case DELETE_ALERTS_SUCCESS:
      return deleteMultiData(state, action.data, "alerts");
    case DELETE_ALERTS_FAIL:
      return updateObject(state, { loading: false });

    case RESTORE_ALERTS_START:
      return updateObject(state, { loading: true });
    case RESTORE_ALERTS_SUCCESS:
      return deleteMultiData(state, action.data, "alerts");
    case RESTORE_ALERTS_FAIL:
      return updateObject(state, { loading: false });

    case CREATE_ALERT_START:
      return updateObject(state, { loading: true });
    case CREATE_ALERT_SUCCESS:
      return updateObject(state, {
        loading: false,
        alerts: [action.data, ...state.alerts],
        alert: action.data,
      });
      return;
    case CREATE_ALERT_FAIL:
      return updateObject(state, { loading: false });

    case UPDATE_ALERT_START:
      return updateObject(state, { loading: true });
    case UPDATE_ALERT_SUCCESS:
      return updateData(state, action.data, "alerts", "alert");
    case UPDATE_ALERT_FAIL:
      return updateObject(state, { loading: false });

    case FETCH_ALERT_START:
      return updateObject(state, { loading: true });
    case FETCH_ALERT_SUCCESS:
      return updateObject(state, { loading: false, alert: action.data });
    case FETCH_ALERT_FAIL:
      return updateObject(state, { loading: false });

    case CHECK_QUERY_START:
      return updateObject(state, { loading: true });
    case CHECK_QUERY_SUCCESS:
      return updateObject(state, { loading: false, query: action.data });
    case CHECK_QUERY_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default alertsReducer;

import {
  FETCH_GENERAL_ALERT_LOGS_START,
  FETCH_GENERAL_ALERT_LOGS_SUCCESS,
  FETCH_GENERAL_ALERT_LOGS_FAIL,
  REINIT_GENERAL_ALERT_LOGS,
} from "../constants";
import { updateObject } from "./utility";

const initialState = {
  generalAlertLogs: [],
  totalGeneralAlertLogs: 0,
  loading: false,
};

const generalALertLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_GENERAL_ALERT_LOGS:
      return updateObject(state, initialState);
    case FETCH_GENERAL_ALERT_LOGS_START:
      return updateObject(state, { loading: true });
    case FETCH_GENERAL_ALERT_LOGS_SUCCESS:
      return updateObject(state, {
        generalAlertLogs: action.data,
        totalGeneralAlertLogs: action.total,
      });
    case FETCH_GENERAL_ALERT_LOGS_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default generalALertLogsReducer;

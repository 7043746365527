import React from "react";
import classNames from "classnames";
import LayoutBlocks from "./LayoutBlocks";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "redux/actions";

const LayoutItem = ({
  layoutId,
  layoutList,
  activeLayout,
  selectHandler,
}) => {
  const dispatch = useDispatch()
  const { sidebar } = useSelector((state) => state);
  const onClickHandler = () => {
    selectHandler(layoutId);
    dispatch(toggleSidebar(sidebar))
  };
  return (
    <div
      title={layoutList[layoutId].alias}
      key={layoutId}
      tabIndex="0"
      role="button"
      className="layout-type"
      onClick={onClickHandler}
    >
      <div
        style={{ flexDirection: layoutList[layoutId].direction }}
        role="presentation"
        key={layoutId}
        className={classNames(`layout-grid `, {
          "active-layout": activeLayout === layoutList[layoutId].alias,
        })}
      >
        <LayoutBlocks layoutList={layoutList} layoutId={layoutId} />
      </div>
      <span>{`${layoutList[layoutId].alias}`}</span>
    </div>
  );
};

export default LayoutItem;

export const widgets = [
  {
    id: 1,
    label: "Milestones",
    permission_name: "ACDM",
    style: { icon: "table-columns", color: "#005bff" },
  },
  {
    id: 2,
    label: "Airport layout",
    permission_name: "airport_layout_widget",
    style: { icon: "plane", color: "#f40b75" },
  },
  {
    id: 3,
    label: "APM",
    permission_name: "APM",
    style: { icon: "train", color: "#449a00" },
  },
  {
    id: 4,
    label: "BHS",
    permission_name: "BHS",
    style: { icon: "suitcase", color: "#a635ff" },
  },
  {
    id: 5,
    label: "Weather",
    permission_name: "WEATHER",
    style: { icon: "cloud", color: "#f78600" },
  },
  {
    id: 6,
    label: "PDS",
    permission_name: "PDS",
    style: { icon: "plane-departure", color: "#26a69a" },
  },
  {
    id: 7,
    label: "EMS",
    permission_name: "EMS",
    style: { icon: "cloud-meatball", color: "#6D50A6" },
  },
  {
    id: 8,
    label: "Activity",
    permission_name: "Activity",
    style: { icon: "exclamation-triangle", color: "#F40B75" },
  },
  {
    id: 9,
    label: "KPI",
    permission_name: "KPI",
    style: { icon: "chart-line", color: "#005bff" },
  },
];

import {
  FETCH_MANUAL_UPDATES_LOGS_START,
  FETCH_MANUAL_UPDATES_LOGS_SUCCESS,
  FETCH_MANUAL_UPDATES_LOGS_FAIL,
  REINIT_MANUAL_UPDATES_LOGS,
} from "../constants";
import { updateObject } from "./utility";

const initialState = {
  manualUpdateLogs: [],
  loading: false,
  total: 0,
};

const manualUpdatesLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_MANUAL_UPDATES_LOGS:
      return updateObject(state, initialState);
    case FETCH_MANUAL_UPDATES_LOGS_START:
      return updateObject(state, { loading: true });
    case FETCH_MANUAL_UPDATES_LOGS_SUCCESS:
      return updateObject(state, {
        manualUpdateLogs: action.data,
        total: action.total,
      });
    case FETCH_MANUAL_UPDATES_LOGS_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default manualUpdatesLogsReducer;

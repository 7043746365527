import * as topojson_client from "topojson-client";
import * as d3 from "d3";

const projectionReference = {
  // For now we are going to support ConicEqualArea maybe in the future we
  // will add more projection, its always good to have more options for map
  // projection as it gives us more abstraction as a reusable component.
  ConicEqualArea: d3.geoConicEqualArea,
  Mercator: d3.geoMercator,
  Albers: d3.geoAlbers,
};

export default (config = {}, selector = "svg", projectionName = "Albers") => {
  const width = config.width;
  const height = config.height;
  const topojson = config.topojson || {};

  // retrieve the object name from the topojson object, since we need this to be dynamic and abstract
  // and we need to have it retrieved no mater what the format is.
  const ObjectKeys = Object.keys(topojson.objects);

  const projection = projectionReference[projectionName]()
    .scale(width / 2 / Math.PI)
    .rotate([60.9, 0])
    .parallels([25.3, 51.6])
    .translate([width / 2, height / 2])
    .fitSize(
      [width, height],
      topojson_client.feature(topojson, topojson.objects[ObjectKeys[1]]),
    );
  return projection;
};

import {
  FETCH_CHANNELS_START,
  FETCH_CHANNELS_SUCCESS,
  FETCH_CHANNELS_FAIL,
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAIL,
  CREATE_MESSAGE_START,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAIL,
  CREATE_CHANNEL_START,
  CREATE_CHANNEL_SUCCESS,
  CREATE_CHANNEL_FAIL,
  FETCH_USERS_CHANNEL_START,
  FETCH_USERS_CHANNEL_SUCCESS,
  FETCH_USERS_CHANNEL_FAIL,
  FETCH_UNREAD_MESSAGES_START,
  FETCH_UNREAD_MESSAGES_SUCCESS,
  FETCH_UNREAD_MESSAGES_FAIL,
  READ_MESSAGES_START,
  READ_MESSAGES_SUCCESS,
  READ_MESSAGES_FAIL,
  DELETE_CHANNEL_SUCCESS,
  DELETE_CHANNEL_START,
  DELETE_CHANNEL_FAIL,
  UPDATE_CHANNEL_START,
  UPDATE_CHANNEL_FAIL,
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from "../constants";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from "utils/APIRequest";
const fetchChannelsStart = () => ({
  type: FETCH_CHANNELS_START,
});

const fetchChannelsSuccess = (data, isSearch) => ({
  type: FETCH_CHANNELS_SUCCESS,
  data,
  isSearch,
});

const fetchChannelsFail = () => ({
  type: FETCH_CHANNELS_FAIL,
});

export const fetchChannels = (data = {}, isSearch = false) => {
  return (dispatch) => {
    dispatch(fetchChannelsStart());

    let url = "/api/channels?";
    for (const key in data) {
      data[key] =
        typeof data[key] === "object" ? JSON.stringify(data[key]) : data[key];
      url += key + "=" + data[key] + "&";
    }
    fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchChannelsSuccess(data, isSearch));
      })
      .catch((e) => {
        dispatch(fetchChannelsFail());
      });
  };
};

const fetchMessagesStart = () => ({
  type: FETCH_MESSAGES_START,
});

const fetchMessagesSuccess = (data) => ({
  type: FETCH_MESSAGES_SUCCESS,
  data,
});

const fetchMessagesFail = () => ({
  type: FETCH_MESSAGES_FAIL,
});

export const fetchMessages = (data = {}) => {
  let url = "/api/chats?";

  for (const key in data) {
    data[key] =
      typeof data[key] === "object" ? JSON.stringify(data[key]) : data[key];
    url += key + "=" + data[key] + "&";
  }
  return (dispatch) => {
    dispatch(fetchMessagesStart());
    fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchMessagesSuccess(data));
      })
      .catch((e) => {
        dispatch(fetchMessagesFail());
      });
  };
};

const sendMessageStart = () => ({
  type: CREATE_MESSAGE_START,
});

const sendMessageSuccess = (data) => ({
  type: CREATE_MESSAGE_SUCCESS,
  // data,
});

const sendMessageFail = () => ({
  type: CREATE_MESSAGE_FAIL,
});

export const sendMessage = (data = {}, callback) => {
  return async (dispatch) => {
    dispatch(sendMessageStart());
    const response = await postRequest(
      "/api/chats",
      data,
      {
        Accept: "application/json",
      },
      true,
    );

    if (!response.error) {
      callback && callback();
      dispatch(sendMessageSuccess(response));
    } else {
      dispatch(sendMessageFail());
    }
  };
};

const createChannelStart = () => ({
  type: CREATE_CHANNEL_START,
});

const createChannelFail = () => ({
  type: CREATE_CHANNEL_FAIL,
});

export const createChannel = (data = {}, callback) => {
  return async (dispatch) => {
    dispatch(createChannelStart());

    const response = await postRequest("/api/channels", JSON.stringify(data));

    if (!response.error) {
      dispatch({ type: SUCCESS_MESSAGE, message: "Create Successfully" });
      callback && callback();
    } else {
      dispatch({ type: ERROR_MESSAGE, message: response.message });
      dispatch(createChannelFail());
      callback && callback();
    }
  };
};

const fetchUsersChanelStart = () => ({
  type: FETCH_USERS_CHANNEL_START,
});

const fetchUsersChanelSuccess = (data) => ({
  type: FETCH_USERS_CHANNEL_SUCCESS,
  data,
});

const fetchUsersChanelFail = () => ({
  type: FETCH_USERS_CHANNEL_FAIL,
});

export const fetchUsersChanel = (channelId) => {
  return (dispatch) => {
    dispatch(fetchUsersChanelStart());
    fetch(`/api/channels/${channelId}/users`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchUsersChanelSuccess(data));
      })
      .catch((e) => {
        dispatch(fetchUsersChanelFail());
      });
  };
};

const fetchUnReadMessagesStart = () => ({
  type: FETCH_UNREAD_MESSAGES_START,
});

const fetchUnReadMessagesSuccess = (data) => ({
  type: FETCH_UNREAD_MESSAGES_SUCCESS,
  data,
});

const fetchUnReadMessagesFail = () => ({
  type: FETCH_UNREAD_MESSAGES_FAIL,
});

export const fetchUnReadMessages = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchUnReadMessagesStart());

    const url = "/api/chats/unread";
    const response = await getRequest(url, data);

    if (!response.error) {
      dispatch(fetchUnReadMessagesSuccess(response));
    } else {
      dispatch(fetchUnReadMessagesFail());
    }
  };
};

const readMessagesStart = () => ({
  type: READ_MESSAGES_START,
});

const readMessagesSuccess = (channelId) => ({
  type: READ_MESSAGES_SUCCESS,
  channelId,
});

const readMessagesFail = () => ({
  type: READ_MESSAGES_FAIL,
});

const updateChannelsStart = () => ({
  type: UPDATE_CHANNEL_START,
});

const updateChannelsFail = () => ({
  type: UPDATE_CHANNEL_FAIL,
});
export const updateChannel = (data = {}, callback) => {
  return async (dispatch) => {
    dispatch(updateChannelsStart());
    const response = await putRequest("/api/channels", JSON.stringify(data));

    if (!response.error) {
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "Update records successfully",
      });
      callback && callback();
    } else {
      dispatch({ type: ERROR_MESSAGE, message: response.message });
      dispatch(updateChannelsFail());
      callback && callback();
    }
  };
};

const deleteChannelsStart = () => ({
  type: DELETE_CHANNEL_START,
});

const deleteChannelsSuccess = (data) => ({
  type: DELETE_CHANNEL_SUCCESS,
  data,
});

const deleteChannelsFail = () => ({
  type: DELETE_CHANNEL_FAIL,
});
export const deleteGroupChannel = (channelId) => {
  return async (dispatch) => {
    dispatch(deleteChannelsStart());
    const response = await deleteRequest("/api/channels", { channelId });

    if (!response.error) {
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "Delete records successfully",
      });
      dispatch(deleteChannelsSuccess(channelId));
    } else {
      dispatch(deleteChannelsFail());
    }
  };
};
export const readMessages = (channelId = " ") => {
  return (dispatch) => {
    dispatch(readMessagesStart());
    fetch(`/api/chats/read`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({ channel_id: channelId }),
    })
      .then((data) => {
        dispatch(readMessagesSuccess(channelId));
      })
      .catch((e) => {
        dispatch(readMessagesFail());
      });
  };
};

import * as d3 from "d3";

class DraggableRect {
  constructor(params, projection) {
    const selection = d3.select(".zoomLayer"),
      translate = d3.zoomTransform(selection.node());
    this.view = { x: translate.x, y: translate.y, k: translate.k };
    this.params = params;
    this.projection = projection;
    this.topLeftCoords = params
      ? this.projection.invert([params.x, params.y])
      : [];
    this.bottomRightCoords = params
      ? this.projection.invert([
          params.x + params.width,
          params.y + params.height,
        ])
      : [];

    this.svg = d3.select("svg#airport-pan .zoomLayer .inner-zoom");
    this.dragR = d3.drag().on("drag", this.dragRect.bind(this));
    this.dragC1 = d3.drag().on("drag", this.dragPoint1.bind(this));
    this.dragC2 = d3.drag().on("drag", this.dragPoint2.bind(this));
    this.dragC3 = d3.drag().on("drag", this.dragPoint3.bind(this));
    this.dragC4 = d3.drag().on("drag", this.dragPoint4.bind(this));
    if (params) this.originNode = d3.select(`#main-block${params.id}`);
    this.rectangleElement = params
      ? this.svg
          .append("rect")
          .attr("id", "block-rect")
          .attr("class", "draggable-blocked-area")
          .attr("x", params.x)
          .attr("y", params.y)
          .attr("width", params.width)
          .attr("height", params.height)
          .call(this.dragR)
      : this.svg
          .append("rect")
          .attr("id", "block-rect")
          .attr("class", "draggable-blocked-area")
          .call(this.dragR);
    if (params) d3.select(`#main-block${params.id}`).style("display", "none");
    this.rect = null;
    this.isDown = false;
    this.isDrag = false;
    this.m1 = params ? [params.x, params.y] : [];
    this.m2 = params ? [params.x + params.width, params.y + params.height] : [];
    this.rectData = params
      ? [
          { x: this.m1[0], y: this.m1[1] },
          { x: this.m2[0], y: this.m2[1] },
        ]
      : [];
    this.pointElement1 = this.svg
      .append("circle")
      .attr("class", "blocked-area-corner pointA");
    this.pointElement1.call(this.dragC1);
    this.pointElement2 = this.svg
      .append("circle")
      .attr("class", "blocked-area-corner pointB");
    if (this.params) this.pointElement2.call(this.dragC2);
    this.pointElement3 = this.svg
      .append("circle")
      .attr("class", "blocked-area-corner pointC");
    this.pointElement3.call(this.dragC3);
    this.pointElement4 = this.svg
      .append("circle")
      .attr("class", "blocked-area-corner pointD");
    this.pointElement4.call(this.dragC4);
    this.text = this.svg.append("text");
    this.removeRect = this.svg
      .append("text")
      .attr("class", "remove-draggable-rect")
      .attr("dy", ".1em")
      .attr("text-anchor", "end")
      .style("font-size", ".9em")
      .style("font-weight", "bold")
      .style("fill", "white")
      .style("cursor", "pointer")
      .text("x");
    this.initilizeRect.bind(this)();
    if (params) this.updateRect.bind(this)();
    this.unmountDraggableRect = this.unmountDraggableRect.bind(this);
  }

  resetCoords() {
    if (this.rectData.length) {
      this.topLeftCoords = this.projection.invert([
        this.rectData[0].x,
        this.rectData[0].y,
      ]);
      this.bottomRightCoords = this.projection.invert([
        this.rectData[1].x,
        this.rectData[1].y,
      ]);
    }
  }

  initilizeRect() {
    this.svg
      .on("mousedown", (event) => {
        event.stopPropagation();
        this.svg.style("cursor", "auto");
        this.m1 = [
          (event.offsetX - this.view.x) / this.view.k,
          (event.offsetY - this.view.y) / this.view.k,
        ];
        if (!this.isDown && !this.isDrag) {
          this.text
            .attr("class", "click-text")
            .attr("dy", ".2em")
            .attr("text-anchor", "start")
            .style("font-size", ".9em")
            .style("font-weight", "bold")
            .style("fill", "#4c1120")
            .text("Double click to save");
          if (!this.params)
            this.rectData = [
              { x: this.m1[0], y: this.m1[1] },
              { x: this.m1[0], y: this.m1[1] },
            ];
          if (this.rectData.length) {
            this.updateRect();
          }
          this.isDrag = false;
        } else {
          this.isDrag = true;
        }
        this.isDown = !this.isDown;
      })
      .on("mousemove", (event) => {
        event.stopPropagation();
        const isFirefox =
          navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
        if (isFirefox) {
          if (event.target.classList.contains("airport-image")) {
            this.m2 = [
              (event.offsetX - this.view.x) / this.view.k,
              (event.offsetY - this.view.y) / this.view.k,
            ];
          }
        } else {
          this.m2 = [
            (event.offsetX - this.view.x) / this.view.k,
            (event.offsetY - this.view.y) / this.view.k,
          ];
        }
        if (!this.params && this.isDown && !this.isDrag) {
          this.rectData[1] = { x: this.m2[0], y: this.m2[1] };
          if (this.rectData.length) {
            this.updateRect();
          }
        }
      });
  }

  updateDraggableRectPosition(projection) {
    this.projection = projection;
    if (this.rectData.length) {
      const topLeftPosition = this.projection(this.topLeftCoords);
      this.rectData[0].x = topLeftPosition[0];
      this.rectData[0].y = topLeftPosition[1];
      const bottomRightPosition = this.projection(this.bottomRightCoords);
      this.rectData[1].x = bottomRightPosition[0];
      this.rectData[1].y = bottomRightPosition[1];
    }
    if (this.rectData.length) {
      this.updateRect();
    }
  }

  removeSvgEventsListener() {
    this.svg.on("mousedown", null).on("mousemove", null);
  }

  updateRect() {
    this.resetCoords();
    this.rect = d3.select(".draggable-blocked-area");
    this.rect
      .attr(
        "x",
        this.rectData[1].x - this.rectData[0].x > 0
          ? this.rectData[0].x
          : this.rectData[1].x,
      )
      .attr(
        "y",
        this.rectData[1].y - this.rectData[0].y > 0
          ? this.rectData[0].y
          : this.rectData[1].y,
      )
      .attr("width", Math.abs(this.rectData[1].x - this.rectData[0].x))
      .attr("height", Math.abs(this.rectData[1].y - this.rectData[0].y));
    this.text
      .attr(
        "x",
        this.rectData[1].x - this.rectData[0].x > 0
          ? this.rectData[0].x
          : this.rectData[1].x,
      )
      .attr(
        "y",
        this.rectData[1].y - this.rectData[0].y > 0
          ? this.rectData[1].y + 13
          : this.rectData[0].y + 13,
      )
      .attr("width", Math.abs(this.rectData[1].x - this.rectData[0].x));

    this.removeRect
      .attr(
        "x",
        this.rectData[1].x - this.rectData[0].x > 0
          ? this.rectData[1].x - 10
          : this.rectData[0].x,
      )
      .attr(
        "y",
        this.rectData[1].y - this.rectData[0].y > 0
          ? this.rectData[0].y - 10
          : this.rectData[1].y - 10,
      )
      .attr("width", Math.abs(this.rectData[1].x - this.rectData[0].x));

    const point1 = d3.select(".pointA");
    point1
      .attr("r", 3)
      .attr("cx", this.rectData[0].x)
      .attr("cy", this.rectData[0].y)
      .style("cursor", "nw-resize");
    const point2 = d3.select(".pointB");
    point2
      .attr("r", 3)
      .attr("cx", this.rectData[1].x)
      .attr("cy", this.rectData[1].y)
      .style("cursor", "se-resize");
    const point3 = d3.select(".pointC");
    point3
      .attr("r", 3)
      .attr("cx", this.rectData[1].x)
      .attr("cy", this.rectData[0].y)
      .style("cursor", "ne-resize");
    const point4 = d3.select(".pointD");
    point4
      .attr("r", 3)
      .attr("cx", this.rectData[0].x)
      .attr("cy", this.rectData[1].y)
      .style("cursor", "sw-resize");
  }

  dragRect(event) {
    const e = event;
    for (let i = 0; i < this.rectData.length; i += 1) {
      d3.selectAll(this.rectangleElement)
        .attr("x", (this.rectData[i].x += e.dx))
        .attr("y", (this.rectData[i].y += e.dy));
    }
    this.rect.style("cursor", "move");
    if (this.rectData.length) {
      this.updateRect();
    }
  }

  dragPoint1(event) {
    const e = event;
    d3.selectAll(".pointA")
      .attr("cx", (this.rectData[0].x += e.dx))
      .attr("cy", (this.rectData[0].y += e.dy));
    if (this.rectData.length) {
      this.updateRect();
    }
  }

  dragPoint2(event) {
    const e = event;
    d3.selectAll(".pointB")
      .attr("cx", (this.rectData[1].x += e.dx))
      .attr("cy", (this.rectData[1].y += e.dy));

    if (this.rectData.length) {
      this.updateRect();
    }
  }

  dragPoint3(event) {
    const e = event;
    d3.selectAll(".pointC")
      .attr("cx", (this.rectData[1].x += e.dx))
      .attr("cy", (this.rectData[0].y += e.dy));
    if (this.rectData.length) {
      this.updateRect();
    }
  }

  dragPoint4(event) {
    const e = event;
    d3.selectAll(".pointD")
      .attr("cx", (this.rectData[0].x += e.dx))
      .attr("cy", (this.rectData[1].y += e.dy));
    if (this.rectData.length) {
      this.updateRect();
    }
  }

  unmountDraggableRect() {
    d3.selectAll(".pointA").remove();
    d3.selectAll(".pointB").remove();
    d3.selectAll(".pointC").remove();
    d3.selectAll(".pointD").remove();
    d3.selectAll(".click-text").remove();
    d3.selectAll(".draggable-blocked-area").remove();
    d3.selectAll(".remove-draggable-rect").remove();
    if (this.params)
      d3.select(`#main-block${this.params.id}`).style("display", "block");
  }
} // end Rectangle

export default DraggableRect;

import {
  AUTH_SET_TOKEN,
  AUTH_SET_USER,
  UPDATE_USER_LAYOUT,
} from "../constants";

const token = localStorage.getItem("refresh_token");

export const authReducer = (state = { token }, action) => {
  switch (action.type) {
    case AUTH_SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case AUTH_SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_USER_LAYOUT:
      const user = state.user;
      user.roles[0].users_roles.layout_config = action.data;
      return {
        ...state,
        user,
      };
    default:
      return state;
  }
};

import React from "react";
import Field from "./Field";

const Fields = ({
  flight,
  headers,
  setSelectedFlight,
  onClickFieldHandler,
  clearData,
  setX,
  setY,
}) => {
  return headers.map((field) => {
    return (
      <Field
        clearData={clearData}
        setSelectedFlight={setSelectedFlight}
        key={field.name}
        flight={flight}
        field={field}
        onClickFieldHandler={onClickFieldHandler}
        setY={setY}
        setX={setX}
      />
    );
  });
};

export default Fields;

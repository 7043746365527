import React from "react";
import Compass from "./compass";

export default (props) => (
  <div className="wind" data-layout="row">
    <div className="atcw-section-inner">
      <span className="widget">
        <div className="compass-widget wind-direction">
          <Compass deg={props.wind_dir} />
        </div>
      </span>

      <div className="atcw-winds">
        <div className="wind-value">
          <h1 className="atcw-section-unit atcw-wind total">
            {props.wind_dir}
            <span className="atcw-section-unit-of-mes">°</span>
          </h1>
          <h2 className="atcw-section-title total">Wind Direction</h2>
        </div>
        <div className="wind-value">
          <h1 className="atcw-section-unit atcw-wind-single total">
            {props.gust_speed}
            <span className="atcw-section-unit-of-mes">KT</span>
          </h1>
          <h2 className="atcw-section-title total">Gust</h2>
        </div>
        <div className="wind-value">
          <h1 className="atcw-section-unit atcw-wind-single total">
            {props.gust_dir}
            <span className="atcw-section-unit-of-mes">°</span>
          </h1>
          <h2 className="atcw-section-title total">Gust Direction</h2>
        </div>
        <div className="wind-value">
          <h1 className="atcw-section-unit atcw-section-unit-wrap atcw-wind-single total">
            {props.wind_speed}
            <span className="atcw-section-unit-of-mes">{props.unit}</span>
          </h1>
          <h2 className="atcw-section-title total">Wind Speed</h2>
        </div>
        <div className="wind-value">
          <h1 className="atcw-section-unit atcw-section-unit-wrap atcw-wind-single total">
            {props.min_speed}
            <span className="atcw-section-unit-of-mes">{props.unit}</span>
          </h1>
          <h2 className="atcw-section-title total">Min Wind Speed</h2>
        </div>
        <div className="wind-value">
          <h1 className="atcw-section-unit atcw-section-unit-wrap atcw-wind-single total">
            {props.max_speed}
            <span className="atcw-section-unit-of-mes">{props.unit}</span>
          </h1>
          <h2 className="atcw-section-title total">Max Wind Speed</h2>
        </div>
      </div>
    </div>
  </div>
);

import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useRef,
} from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { getEditableFields, sortTypes } from "../../../utils/helpers";
import ColumnsFilter from "./filters/ColumnsFilter";
import FilterItems from "../../common/filter_manager/filter_items";
import acdmHeaderFields from "../../../utils/configurations/acdm_header_fields";
import "./assets/style/app.css";
import UpdateField from "./update_popup";
import ACDMTabs from "./Table/Tabs";
import TableWrapper from "./Table/TableWarpper";
import { useDispatch, useSelector } from "react-redux";
import { ACDMContext } from "./acdm_context";
import Header from "./header";
import _ from "lodash";
import { FETCH_ACDM_ACTIVE_FIELDS_SUCCESS } from "redux/constants";
import {
  fetchAcdm,
  fetchSystemParams,
  AcdmActiveFields,
  updateAcdmActiveFields,
  showErrorNotification,
  deActivateFlight
} from "redux/actions";
import {
  manualUpdateCases,
  manualUpdateQueueReducer,
} from "./manual_update_reducer";
import ACDMSocket from "./acdm_socket";

const AcdmGrid = (props) => {
  const [filterBy, setFilterBy] = useState({});
  const [sortBy, setSortBy] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [showColumnsFilter, setShowColumnsFilter] = useState(false);
  const [activeHeaders, setActiveHeaders] = useState([]);
  const [listHeaders, setListHeaders] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  const [data, setData] = useState([]);
  const [updateField, setUpdateField] = useState();
  const [searchBy, setSearchBy] = useState();
  const [showUpdateModal, setShowUpdateModal] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState({});
  const [updateData, setUpdateData] = useState(null);

  const [activeList, setActiveList] = useState({
    name: "arrival",
    alias: "ARRIVAL",
    reference: "ldt",
  });

  const ref = useRef();

  const { auth } = useSelector((state) => state);
  const { systemParams, timeZone } = useSelector((state) => state.SystemParams);
  const { ids: selectedFlights = [] } = useSelector(
    (state) => state.UserInfo.selectedFlights
  );
  const { acdmFlights: acdmFlightsObj, activeFields } = useSelector(
    (state) => state.ACDM
  );
  const { activeFlight } = useSelector((state) => state);

  const acdmFlights = Object.values(acdmFlightsObj);
  const { roles } = auth.user;
  const { name: listName } = activeList;

  const dispatch = useDispatch();
  const [manualUpdateQueue, manualDispatch] = useReducer(
    manualUpdateQueueReducer,
    []
  );

  const disableComponent = () => {
    const pointerEvents = "none";
    const disableElement = document.querySelectorAll(
      ".tabs , .block-resize-icon, .acdm-icon, .container-config-header, .header-btns"
    );
    disableElement.forEach((element) => {
      if (manualUpdateQueue.length) {
        Object.assign(element?.style, {
          pointerEvents,
        });
      } else {
        element.style.removeProperty("pointer-events");
      }
    });
  };

  useEffect(() => {
    const listActiveFields = activeFields[listName] || [];
    const activeHeaders = acdmHeaderFields.filter(
      (field) =>
        field.list.includes(listName) && listActiveFields.includes(field.title)
    );

    setActiveHeaders(activeHeaders);
    disableComponent();

    let filteredAcdmFlights = [];
    if (activeList?.name === "departure") {
      filteredAcdmFlights = acdmFlights.filter(
        (ele) => ele.departure_flight_number
      );
    } else {
      filteredAcdmFlights = acdmFlights.filter(
        (ele) => ele.arrival_flight_number
      );
    }

    setData(filteredAcdmFlights);
  }, [activeList, activeFields, manualUpdateQueue, acdmFlightsObj]);

  useEffect(() => {
    const controller = new AbortController();

    dispatch(fetchSystemParams(controller.signal));
    dispatch(AcdmActiveFields());
    dispatch(fetchSystemParams(controller.signal));
    dispatch(
      fetchAcdm(
        { ...(selectedFlights?.length && { flights: selectedFlights }) },
        controller.signal
      )
    );

    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        setShowFilter(false);
        setShowColumnsFilter(false);
      }
    });
    return () => {
      controller.abort();
    };
  }, []);

  const closePopupWhenFullScreen = () => {
    toggleShowModal(true);
    setShowModal(false);
    setUpdateData({});
    dispatch(deActivateFlight(activeFlight?.aircraft_registration));
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", closePopupWhenFullScreen);
    return () => {
      document.removeEventListener(
        "fullscreenchange",
        closePopupWhenFullScreen
      );
    };
  }, [props.fullscreenBlock, showModal]);

  useEffect(() => {
    const filterData = renderACDMFilterFields();
    setFilterObj(filterData);

    setSortBy({ [`${activeList.reference}`]: "asc" });
    const { name: listName } = activeList;
    const listHeaders = acdmHeaderFields.filter((field) =>
      field.list.includes(listName)
    );
    setListHeaders(listHeaders);
  }, [activeList]);

  useEffect(() => {
    if (!systemParams.acdm_manual_update) return;
    const userEditableField = acdmHeaderFields.reduce((acc, field) => {
      const fields = getEditableFields(
        field.editableFields,
        systemParams,
        roles
      );
      if (fields.length) {
        acc[field.title] = true;
        return acc;
      }
      return acc;
    }, {});
    setUpdateField(userEditableField);
  }, [systemParams]);

  const updateManualQueue = (message, action) => {
    manualDispatch({
      type: manualUpdateCases.UPDATE_MANUAL_ITEM,
      data: message,
      socketAction: action,
    });
  };

  const activeIndicator = (left, right) => {
    document.querySelector(`.indicator.acdm-tabs-${props.block}`).style.left =
      left;
    document.querySelector(`.indicator.acdm-tabs-${props.block}`).style.right =
      right;
  };

  const renderACDMFilterFields = () => {
    const listActiveFields = activeFields[listName] || [];
    const filterData = {
      filterBy: [],
    };

    const activeColumns = acdmHeaderFields.filter(
      (field) =>
        field.list.includes(listName) && listActiveFields.includes(field.title)
    );

    activeColumns.map((e) => {
      e.accuracy.map((acc) => {
        filterData.filterBy.push({
          alias: acc.title,
          type: e.type,
          field: acc.name,
          value: filterBy[acc.name],
        });
      });
    });
    return filterData;
  };

  const onSortData = (ev) => {
    const element = ev.target.dataset.name;

    setSortBy((prevState) => ({
      [element]:
        prevState[element] === sortTypes.ASCEND
          ? sortTypes.DESCEND
          : sortTypes.ASCEND,
    }));
  };

  const chooseFlightTab = (ev) => {
    const dataset = ev.target.dataset;
    const { name, alias, reference, indicator } = dataset;

    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    setActiveList({
      name,
      alias,
      reference,
    });
    activeIndicator(`${indicator}%`, `${+indicator + 30}%`);
  };

  const showOrHideColumns = (ev) => {
    const { name: listName } = activeList;
    const listHeaders = acdmHeaderFields.filter((field) =>
      field.list.includes(listName)
    );

    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }

    setListHeaders(listHeaders);
    setShowColumnsFilter(!showColumnsFilter);
  };

  const showFilterColumns = (ev) => {
    let filterObj = {};
    const filterItems = Object.keys(filterBy || {});

    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    if (!showFilter) {
      const filterData = renderACDMFilterFields();
      setFilterObj(filterData);
    }
    filterItems.map((item) => {
      if (filterBy[item].value) {
        filterObj[item] = filterBy[item];
      }
    });
    setFilterBy(filterObj);
    setShowFilter(!showFilter);
  };

  const updateColumns = (fields) => {
    const listName = activeList?.name;
    dispatch({
      type: FETCH_ACDM_ACTIVE_FIELDS_SUCCESS,
      data: { ...activeFields, [listName]: fields },
    });
  };

  const handleFilter = (filter) => {
    setFilterBy({ ...filter });
  };

  const handleSearch = (data) => {
    setSearchBy(data);
  };

  const modalUpdateCallBack = () => {
    manualDispatch({
      type: manualUpdateCases.REMOVE_FIRST_MANUAL_ITEM,
    });
  };

  const toggleShowModal = useCallback(
    (value) => {
      setShowUpdateModal(value);
    },
    [showUpdateModal]
  );

  const closeManualUpdateModal = (message) => {
    dispatch(showErrorNotification(message));
    manualDispatch({
      type: manualUpdateCases.REMOVE_FIRST_MANUAL_ITEM,
    });
  };

  const closeColumnsFilter = () => {
    setShowColumnsFilter(false);
    dispatch(
      updateAcdmActiveFields(JSON.stringify({ acdm_fields: activeFields }))
    );
  };

  const contextAPI = {
    updateField,
    showOrHideColumns,
    firstQueueItem: manualUpdateQueue[0],
    acdmFlights,
    acdmHeaderFields,
    acdmRef: ref,
  };

  return (
    <div className="acdm-container table-font-size">
      <ACDMContext.Provider value={contextAPI}>
        <ACDMSocket alias={props.alias} updateManualQueue={updateManualQueue} />
        <Header
          showFilter={showFilter}
          activeList={activeList}
          showFilterColumns={showFilterColumns}
          showColumnsFilter={showColumnsFilter}
          needResize={props.fullscreenBlock}
          filterBy={filterBy}
          handleSearch={handleSearch}
          searchBy={searchBy}
        />
        <div className="acdm-body">
          {showFilter && (
            <div className="acdm-filter filter-font-size">
              <FilterItems
                instantFilter
                filtersVal={filterBy}
                filter={handleFilter}
                data={filterObj}
                closeFilter={showFilterColumns}
                timeZone={timeZone}
              />
            </div>
          )}
          {showColumnsFilter && (
            <div className="acdm-filter filter-font-size">
              <ColumnsFilter
                listHeaders={listHeaders}
                listActiveFields={activeFields[activeList?.name] || []}
                updateColumns={updateColumns}
                closeFilter={closeColumnsFilter}
              />
            </div>
          )}
          <div className="tables-wrapper" id="section-to-print">
            <div ref={ref} className="acdm-main">
              <ACDMTabs
                name={activeList?.name}
                chooseFlightTab={chooseFlightTab}
                block={props.block}
                fullscreenBlock={props.fullscreenBlock}
              />
              <TableWrapper
                acdmFlights={data}
                sortBy={sortBy}
                sortData={onSortData}
                headers={activeHeaders}
                filterBy={filterBy}
                fullscreenBlock={props.fullscreenBlock}
                searchBy={searchBy}
                toggleShowModal={toggleShowModal}
                setShowModal={setShowModal}
                showModal={showModal}
                setSelectedFlight={setSelectedFlight}
                selectedFlight={selectedFlight}
                setUpdateData={setUpdateData}
                updateData={updateData}
              />
            </div>
          </div>
        </div>

        {manualUpdateQueue.length > 0 && showUpdateModal && (
          <UpdateField
            data={manualUpdateQueue[0]}
            handleClose={closeManualUpdateModal}
            modalUpdateCallBack={modalUpdateCallBack}
          />
        )}
      </ACDMContext.Provider>
    </div>
  );
};

export default AcdmGrid;

import React from "react";
import beltsData from "./belts_data";
import Tooltip from "@mui/material/Tooltip";

const { beltsCases, beltsPosition } = beltsData;

const tooltipStyle = () => {
  return {
    tooltip: {
      sx: {
        bgcolor: "common.white",
        color: "black",
        padding: "8px 8px",
        "& .MuiTooltip-arrow": {
          color: "common.white",
        },
      },
    },
  };
};

const BHS_SVG = (props) => {
  const { status, belt_id, active, tooltipId, flight } = props;
  return (
    <Tooltip
      arrow
      title={
        <>
          <div>{active ? "Not on schedule" : "Under maintenance"}</div>
          {flight?.flight && <div className="bhs-flight">Flight: {flight}</div>}
        </>
      }
      placement="top"
      componentsProps={tooltipStyle()}
    >
      <g fill="none" fillRule="evenodd" transform="translate(0 -1)">
        {beltsPosition[belt_id] ? (
          <g
            name={`belt-${belt_id.replace("belt-", "")}-group`}
            className={`belt-${belt_id.replace("belt-", "")}-group`}
            transform={beltsPosition[belt_id].transform}
          >
            <rect
              className="node"
              width="98"
              height="23.5"
              x={beltsPosition[belt_id].rect.x}
              y={beltsPosition[belt_id].rect.y}
              fill={active ? "transparent" : "url(#pattern)"}
              stroke={beltsCases[status]}
              strokeWidth="2"
              rx="11.5"
            />
            <text
              x={beltsPosition[belt_id].rect.x + 27}
              y={beltsPosition[belt_id].rect.y + 16}
              fill="#a49a9a"
            >
              {belt_id.replace("belt-", "Belt ")}
            </text>
            {status === "active" ? (
              <circle
                className={`${belt_id} animate-belt`}
                fill={beltsCases.active}
                cx={beltsPosition[belt_id].circles.cx}
                cy={beltsPosition[belt_id].circles.cy}
                r="7"
              ></circle>
            ) : null}
            {tooltipId === belt_id ? (
              <g>
                {active ? (
                  <g>
                    <circle
                      fill="#4072ee"
                      cx={beltsPosition[belt_id].circles.cx}
                      cy={beltsPosition[belt_id].circles.cy}
                      r="9"
                    />
                    <circle
                      fill="white"
                      cx={beltsPosition[belt_id].circles.cx}
                      cy={beltsPosition[belt_id].circles.cy}
                      r="5"
                    />
                  </g>
                ) : (
                  <image
                    transform="translate(-10, -10)"
                    x={beltsPosition[belt_id].circles.cx}
                    y={beltsPosition[belt_id].circles.cy}
                    width="19.5"
                    height="19.5"
                    href={`${process.env.REACT_APP_ASSETS_PATH}/images/apm-maint.svg`}
                  />
                )}
              </g>
            ) : null}
          </g>
        ) : null}
      </g>
    </Tooltip>
  );
};

export default BHS_SVG;

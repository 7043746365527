import React, { useState, useEffect, useCallback } from "react";
import WidgetSelector from "./widget_selector";
import RenderLayoutBlock from "./RenderLayoutBlocks";
import classNames from "classnames";
import Button from "components/common/Button";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import ManageLayout from "./MangeLayoutModal";
import styles from "./layout.module.css";
import SaveLayoutModal from "./SaveLayoutModal";
import { showErrorNotification } from "redux/actions";
import { useDispatch } from "react-redux";

const LayoutRouter = (props) => {
  const [selectedWidgets, setSelectedWidgets] = useState({});
  const [visibleLayoutModal, setVisibleLayoutModal] = useState(false);
  const [visibleSaveModal, setVisibleSaveModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const newSelectedWidgets = {};

    for (const key in props.layout?.blocks) {
      const block = props.layout.blocks[key];
      newSelectedWidgets[key] = block.content;
    }
    setSelectedWidgets(newSelectedWidgets);

    return () => {
      setSelectedWidgets({});
    };
  }, []);

  const checkWidget = (widgets, widget) => {
    return Object.values(widgets).includes(widget) && widget !== "ACDM";
  };

  const changeWidget = (block, widget) => {
    const currentSelectedWidgets = { ...selectedWidgets };
    currentSelectedWidgets[block] = widget;

    if (checkWidget(currentSelectedWidgets, widget)) {
      Object.keys(currentSelectedWidgets).forEach((block) => {
        if (currentSelectedWidgets[block] === widget) {
          currentSelectedWidgets[block] = null;
        }
      });
    }
    currentSelectedWidgets[block] = widget;
    setSelectedWidgets(currentSelectedWidgets);
  };

  const saveLayoutConfig = () => {
    const newLayoutConfig = {
      ...props.layout,
      layoutConfig: selectedWidgets,
    };

    props.saveLayoutConfig(newLayoutConfig);
  };

  const toggleLayoutModal = useCallback(() => {
    setVisibleLayoutModal((prev) => !prev);
  }, []);

  const toggleSaveModal = () => {
    const newLayoutConfig = {
      ...props.layout,
      layoutConfig: selectedWidgets,
    };
    const validateWidget = props.checkSelectedLayout(newLayoutConfig);

    if (validateWidget) {
      dispatch(showErrorNotification("Warning! Fill all widgets"));
      return;
    }
    setVisibleSaveModal((prev) => !prev);
  };

  const saveLayout = (content) => {
    Object.keys(content.blocks).map((e) => {
      content.blocks[e].content = selectedWidgets[e];
    });

    props.saveLayoutAsFavorite(content, () => {
      setVisibleSaveModal(false);
    });
  };

  const getSavedLayouts = () => {
    const newLayouts = {};
    for (const id in props.layouts) {
      if (props.layouts[id].user_role_id) {
        newLayouts[id] = props.layouts[id];
      }
    }
    return newLayouts;
  };

  return (
    <div>
      <ManageLayout
        visible={visibleLayoutModal}
        onClose={toggleLayoutModal}
        layouts={getSavedLayouts()}
        saveLayoutConfig={props.saveLayoutConfig}
      />

      <SaveLayoutModal
        action="Save"
        layout={props.layout}
        onSave={saveLayout}
        onClose={toggleSaveModal}
        visible={visibleSaveModal}
      />

      <div
        data-id={props.layout.id}
        className={classNames("layout-wrapper", {
          column: props.layout.direction === "column",
        })}
      >
        <RenderLayoutBlock
          changeWidget={changeWidget}
          selectedWidgets={selectedWidgets}
          layouts={props.layouts}
          layout={props.layout}
          widgetsData={props.widgetsData}
        />
      </div>
      <div
        className={classNames("layout-controller", {
          column: props.layout.direction === "column",
        })}
      >
        <div className="manage-btn">
          <Button
            startIcon={<WindowOutlinedIcon />}
            type="ghost"
            onClick={toggleLayoutModal}
          >
            Manage layouts
          </Button>
        </div>
        <div className="widgets-select">
          <WidgetSelector
            widgets={props.widgetsData}
            changeWidget={changeWidget}
            selectedWidgets={selectedWidgets}
          />
        </div>
        <div className={`${styles.button_container} action-btn`}>
          <div className="width-btn">
            <Button onClick={toggleSaveModal} type="secondary">
              Save layout
            </Button>
          </div>
          <div className="width-btn">
            <Button onClick={saveLayoutConfig} type="primary">
              Done
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutRouter;

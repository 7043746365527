import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Layout from "components/layout_manager";
import UserAccounts from "components/auth/user_accounts";
import MainGrid from "components/main_grid";

const routes = [
  {
    path: "/",
    component: MainGrid,
  },
  {
    path: "/me/account",
    component: () => <UserAccounts />,
  },

  {
    path: "/me",
    component: () => <Navigate to="/me/general" />,
  },
  {
    path: "/admin",
    component: lazy(() =>
      import(
        /* webpackChunkName: "dash" */ "../components/admin_dashboard/dashboard"
      ),
    ),
    name: "dashboard_permission",
    permission: "canView",
  },
  {
    path: "/me/general",
    component: lazy(() => import("../components/user_profile")),
  },
  {
    path: "/admin/messages",
    component: lazy(() => import("../components/Messages")),
    name: "messages",
    permission: "canView",
  },
  {
    path: "/admin/users",
    component: lazy(() => import("../components/admin_dashboard/crud/users")),
    name: "users",
    permission: "canView",
  },
  {
    path: "/admin/users/create",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/users/insert"),
    ),
    name: "users",
    permission: "canCreate",
  },
  {
    path: "/admin/users/update/:user_id",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/users/update"),
    ),
    name: "users",
    permission: "canUpdate",
  },
  {
    path: "/admin/airlines",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/airlines"),
    ),
    name: "org_airline",
    permission: "canView",
  },
  {
    path: "/admin/airlines/create",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/airlines/insert"),
    ),
    name: "org_airline",
    permission: "canCreate",
  },
  {
    path: "/admin/airlines/update/:airline_id",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/airlines/update"),
    ),
    name: "org_airline",
    permission: "canUpdate",
  },
  {
    path: "/admin/organizations",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/airlines"),
    ),
    name: "org_airline",
    permission: "canView",
  },
  {
    path: "/admin/organizations/create",
    component: lazy(() =>
      import(
        "../components/admin_dashboard/crud/airlines/organizations/insert"
      ),
    ),
    name: "org_airline",
    permission: "canCreate",
  },
  {
    path: "/admin/organizations/update/:organization_id",
    component: lazy(() =>
      import(
        "../components/admin_dashboard/crud/airlines/organizations/update"
      ),
    ),
    name: "org_airline",
    permission: "canUpdate",
  },

  {
    path: "/admin/roles",
    component: lazy(() =>
      import(
        /* webpackChunkName: "rolse" */ "../components/admin_dashboard/crud/roles"
      ),
    ),
    name: "roles",
    permission: "canView",
  },
  {
    path: "/admin/roles/create",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/roles/insert"),
    ),
    name: "roles",
    permission: "canCreate",
  },
  {
    path: "/admin/roles/update/:role_id",
    component: lazy(() =>
      import(
        /* webpackChunkName: "update role" */ "../components/admin_dashboard/crud/roles/update"
      ),
    ),
    name: "roles",
    permission: "canUpdate",
  },

  {
    path: "/admin/alerts",
    component: lazy(() => import("../components/admin_dashboard/crud/alerts")),
    name: "alerts",
    permission: "canView",
  },
  {
    path: "/admin/alerts/create",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/alerts/insert"),
    ),
    name: "alerts",
    permission: "canCreate",
  },
  {
    path: "/admin/alerts/update/:alert_id",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/alerts/update"),
    ),
    name: "alerts",
    permission: "canUpdate",
  },

  {
    path: "/admin/notifications",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/notifications"),
    ),
    name: "notifications",
    permission: "canView",
  },
  {
    path: "/admin/notifications/create",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/notifications/insert"),
    ),
    name: "notifications",
    permission: "canCreate",
  },
  {
    path: "/admin/notifications/update/:notification_id",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/notifications/update"),
    ),
    name: "notifications",
    permission: "canUpdate",
  },

  {
    path: "/admin/activitylogs",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/activity_logs"),
    ),
    name: "activity_logs",
    permission: "canView",
  },
  {
    path: "/admin/manualUpdatesLogs",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/manual_updates_logs"),
    ),
    name: "manual_updates_logs",
    permission: "canView",
  },
  {
    path: "/admin/audittrails",
    component: lazy(() => import("../components/admin_dashboard/crud/audit")),
    name: "audittrails",
    permission: "canView",
  },

  {
    path: "/admin/sysparams",
    component: lazy(() =>
      import("../components/admin_dashboard/crud/system-settings/dashboard"),
    ),
    name: "sysparams",
    permission: "canView",
  },
  {
    path: "/admin/sysparams/:category",
    component: lazy(() =>
      import(
        /* webpackChunkName: "params" */ "../components/admin_dashboard/crud/system-settings/sys_params"
      ),
    ),
    name: "sysparams",
    permission: "canView",
  },
  {
    path: "/layout",
    component: Layout,
  },
  {
    path: "/me/select-flights",
    component: lazy(() => import("components/UserFlights")),
  },
];

export default routes;

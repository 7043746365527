import { deleteMultiData, updateObject } from "./utility";
import {
  FETCH_ORGANIZATIONS_FAIL,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_START,
  REINIT_ORGANIZATIONS,
  DELETE_ORGANIZATIONS_START,
  DELETE_ORGANIZATIONS_SUCCESS,
  DELETE_ORGANIZATIONS_FAIL,
  RESTORE_ORGANIZATIONS_START,
  RESTORE_ORGANIZATIONS_SUCCESS,
  RESTORE_ORGANIZATIONS_FAIL,
  RESTORE_USERS_ORGANIZATIONS_START,
  RESTORE_USERS_ORGANIZATIONS_SUCCESS,
  RESTORE_USERS_ORGANIZATIONS_FAIL,
  GET_ROLES_BY_ORG_ID_START,
  GET_ROLES_BY_ORG_ID_SUCCESS,
  GET_ROLES_BY_ORG_ID_FAIL,
  GET_AIRLINES_BY_ORG_ID_START,
  GET_AIRLINES_BY_ORG_ID_SUCCESS,
  GET_AIRLINES_BY_ORG_ID_FAIL,
  CREATE_ORGANIZATION_START,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAIL,
  UPDATE_ORGANIZATION_START,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAIL,
  FETCH_ORGANIZATION_START,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAIL,
} from "../constants";

const initialState = {
  organizations: [],
  roles: [],
  airlines: [],
  loading: false,
  total: 0,
};

const organizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_ORGANIZATIONS:
      return updateObject(state, initialState);

    case FETCH_ORGANIZATIONS_START:
      return updateObject(state, { loading: true });
    case FETCH_ORGANIZATIONS_SUCCESS:
      return updateObject(state, {
        loading: false,
        organizations: action.data,
        total: action.total,
      });
    case FETCH_ORGANIZATIONS_FAIL:
      return updateObject(state, { loading: false });

    case DELETE_ORGANIZATIONS_START:
      return updateObject(state, { loading: true });
    case DELETE_ORGANIZATIONS_SUCCESS:
      return deleteMultiData(state, action.data, "organizations");
    case DELETE_ORGANIZATIONS_FAIL:
      return updateObject(state, { loading: false });

    case RESTORE_ORGANIZATIONS_START:
      return updateObject(state, { loading: true });
    case RESTORE_ORGANIZATIONS_SUCCESS:
      return deleteMultiData(state, action.data, "organizations");
    case RESTORE_ORGANIZATIONS_FAIL:
      return updateObject(state, { loading: false });

    case RESTORE_USERS_ORGANIZATIONS_START:
      return updateObject(state, { loading: true });
    case RESTORE_USERS_ORGANIZATIONS_SUCCESS:
      return updateObject(state, { loading: false });
    case RESTORE_USERS_ORGANIZATIONS_FAIL:
      return updateObject(state, { loading: false });

    case GET_ROLES_BY_ORG_ID_START:
      return updateObject(state, { loading: true });
    case GET_ROLES_BY_ORG_ID_SUCCESS:
      return updateObject(state, { loading: false, roles: action.data });
    case GET_ROLES_BY_ORG_ID_FAIL:
      return updateObject(state, { loading: false });

    case GET_AIRLINES_BY_ORG_ID_START:
      return updateObject(state, { loading: true });
    case GET_AIRLINES_BY_ORG_ID_SUCCESS:
      return updateObject(state, { loading: false, airlines: action.data });
    case GET_AIRLINES_BY_ORG_ID_FAIL:
      return updateObject(state, { loading: false });

    case CREATE_ORGANIZATION_START:
      return updateObject(state, { loading: true });
    case CREATE_ORGANIZATION_SUCCESS:
      return updateObject(state, {
        loading: false,
      });
    case CREATE_ORGANIZATION_FAIL:
      return updateObject(state, { loading: false });

    case UPDATE_ORGANIZATION_START:
      return updateObject(state, { loading: true });
    case UPDATE_ORGANIZATION_SUCCESS:
      return updateObject(state, { loading: false });
    case UPDATE_ORGANIZATION_FAIL:
      return updateObject(state, { loading: false });

    case FETCH_ORGANIZATION_START:
      return updateObject(state, { loading: true });
    case FETCH_ORGANIZATION_SUCCESS:
      const rolesIds = action.data.roles.map((role) => role.role);

      return updateObject(state, {
        organization: { ...action.data, rolesIds },
        loading: false,
      });
    case FETCH_ORGANIZATION_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default organizationsReducer;

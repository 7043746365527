import React from "react";

export default (props) => (
  <div className={`temp ${props.title}`} data-layout="row">
    <div className="atcw-section-unit temp-total total">
      {props.value}
      <span className="atcw-section-unit-of-mes">{props.unit}</span>
    </div>
    <div className="atcw-section-title sec-head total">{props.title}</div>
  </div>
);

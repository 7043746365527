import React from "react";
import LayoutItem from "./LayoutItem";

const LayoutList = ({
  layoutList,
  activeLayout,
  selectHandler,
}) => {
  return Object.keys(layoutList).map((layoutId) => (
    <LayoutItem
      key={layoutId}
      layoutList={layoutList}
      layoutId={layoutId}
      activeLayout={activeLayout}
      selectHandler={selectHandler}
    />
  ));
};

export default LayoutList;

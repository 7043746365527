import React, { Fragment } from "react";
import AirPlaneCard from "./airplane_card";

const renderAirPlanes = (
  airplanes,
  active,
  activateFlight,
  airlines,
  onCloseToolTip,
  utcData,
  timeZone,
) =>
  airplanes.map((airplan) => {
    if (airplan.next_location) {
      return (
        <AirPlaneCard
          key={airplan.aircraft_registration}
          utcData={utcData}
          airlines={airlines}
          cols={airplanes.length}
          activateFlight={activateFlight}
          onCloseToolTip={onCloseToolTip}
          airplane={airplan}
          active={active}
          arrival_time={airplan.arrival_time}
          departure_time={airplan.departure_time}
          status={airplan.status}
          timeZone={timeZone}
        />
      );
    }
    return <Fragment />;
  });

const AirPlanePanelInfo = ({
  airplanes,
  active,
  activateFlight,
  airlines,
  utcData,
  onCloseToolTip,
  timeZone,
}) => (
  <div className="airplanes-panel-container">
    <div className="row">
      <div className="flights">
        <div className="flights-list">
          {renderAirPlanes(
            airplanes,
            active,
            activateFlight,
            airlines,
            onCloseToolTip,
            utcData,
            timeZone,
          )}
        </div>
      </div>
    </div>
  </div>
);

export default AirPlanePanelInfo;

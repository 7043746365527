import {
  SUCCESS_NOTIFICATION_MESSAGE,
  ERROR_NOTIFICATION_MESSAGE,
} from "../constants";

const successNotification = (message) => ({
  type: SUCCESS_NOTIFICATION_MESSAGE,
  message,
});

const errorNotification = (message) => ({
  type: ERROR_NOTIFICATION_MESSAGE,
  message,
});

export const showSuccessNotification = (message) => {
  return (dispatch) => {
    dispatch(successNotification(message));
  };
};

export const showErrorNotification = (message) => {
  return (dispatch) => {
    dispatch(errorNotification(message));
  };
};

import CryptoJS from "crypto-js";
import moment from "moment-timezone";
import _ from "lodash";
import { printf } from "fast-printf";

export const isAuthorized = (userRoles = [], permissionCode) => {
  const userPermissions = userRoles?.map((role) => role.permissions).flat();

  const targetPermission = userPermissions.filter(
    (permission) => permission.permission_code === permissionCode,
  );

  const hasPermission = (index) => {
    return targetPermission.some(({ permission_bin, roles_permissions }) => {
      return (
        +permission_bin[index] === 1 &&
        +roles_permissions.permission_bin[index] === 1
      );
    });
  };
  const permissions = {
    canView: hasPermission(0),
    canCreate: hasPermission(1),
    canUpdate: hasPermission(2),
    canDelete: hasPermission(3),
  };
  return permissions;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const extractValueFromData = (
  values = [],
  dataToExtract = [],
  itemKey,
  returnKey = "id",
) => {
  const ids = [];
  for (let index = 0; index < values?.length; index++) {
    const element = values[index];
    for (let j = 0; j < dataToExtract?.length; j++) {
      const ele = dataToExtract[j];
      if (ele[itemKey] === element) {
        ids.push(ele[returnKey]);
        continue;
      }
    }
  }

  return ids;
};

export const reformatValue = (name, value, needReformat) => {
  if (!needReformat) return value;

  const formatOngt = () => {
    const addType = (digit, type) => {
      if (!digit) return "";

      digit = digit > 9 ? digit : `0${digit}`;
      return type === "m" ? `${digit} ${type}` : `${digit} ${type}:`;
    };

    let min = +value;
    const days = addType(Math.floor(min / 1440), "d");
    const minPerDay = 1440;
    min %= minPerDay;
    const hours = addType(Math.floor(min / 60), "h");
    min %= 60;
    const minutes = addType(min, "m");

    return `${days}${hours}${minutes}`;
  };
  const fieldsNeedsFormat = {
    ongt: formatOngt(value),
  };
  return fieldsNeedsFormat[name] || value;
};

export const convertTimeZoneWithFormat = ({
  time,
  timeZone,
  orginalFormat,
  requiredFormat,
}) => {
  const momentTime = orginalFormat
    ? moment(time, [orginalFormat])
    : moment(time);

  return momentTime.tz(timeZone)?.format(requiredFormat || orginalFormat);
};

export const formatFieldVal = (dateTime, type, timeZone, requiredFormat = "HH:mm") => {
  if (!dateTime) {
    return "N/A";
  }

  if (type === "date") {
    return convertTimeZoneWithFormat({
      time: dateTime,
      timeZone: timeZone,
      requiredFormat
    });
  }
  return dateTime;
};

export const setLocalStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const persistObject = (key, value) => {
  const stringValue = JSON.stringify(value);
  return setLocalStorage(key, stringValue);
};

export const readObject = (key) => {
  const value = getLocalStorage(key);
  return JSON.parse(value);
};

export const removeFromLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

/*  ENUMS    */

export const sortTypes = {
  DESCEND: "desc",
  ASCEND: "asc",
};

export const btnText = {
  SAVE: "Save",
  CONFIRM: "Confirm",
};

export const storageKeys = {
  SELECTED_FLIGHTS: "selectedFlights",
  PAUSED: "paused",
  TOKEN: "token",
  TOKEN_EXPIRES_IN: "token_expires_in",
  REFRESH_TOKEN: "refresh_token",
  REFRESH_EXPIRES_IN: "refresh_expires_in",
  SELECTED_FLIGHTS_NUMBERS: "selectedFlightsNum",
  SELECTED_FLIGHTS_REGISTRATIONS: "selectedFlightsReg",
  TIME_ZONE: "timezone",
};

export const SocketAction = {
  CONFIRM_TOBT_UPDATE: "confirm_tobt_with_update",
  NEW_UNREAD_MESSAGE: "new_unread_message",
  UPDATE_CHANNEL: "update",
  NEW_CHANNEL: "new_channel",
  NEW_MESSAGE: "new_message",
  DELETE: "delete",
  CONTINUE: "continue",
  END: "end",
  CLOSE_POPUP: "close_popup",
  CONFIRM_TOBT: "confirm_tobt",
  UPDATE_SYSTEM_PARAMS: "update_system_params",
};

export const timeZones = {
  UTC: "utc",
};

export const SocketListener = {
  ACDM_NOTIFICATIONS: "ACDM.data.notifications",
  PDS_NOTIFICATIONS: "PDS.data.notifications",
  ACDM_ALERTS: "ACDM:data:alerts",
  ACDM_TOBT: "acdm:tobt",
  VEHICLES: "vehicles",
  VDGS: "vdgs",
  BLOCKED_AREAS: "blocked_areas",
  CONNECT: "connect",
  DISCONNECT: "disconnect",
  MESSAGES: "messages",
  GENERAL: "general",
};

export const SocketEvent = {
  ROOM: "room",
  Leave_ROOM: "leaveRoom",
};

export const ParamsName = {
  AIRPORT_CLOSING: "airport_closing",
};

export const checkEmailIsValid = (email) => {
  var filter =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  return filter.test(email);
};

export const checkPhoneNumber = (phoneNumber) => {
  const filter =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
  return filter.test(phoneNumber);
};

export const encryptMessage = (message) => {
  return CryptoJS.AES.encrypt(
    message,
    process.env.REACT_APP_CRYPTO_SECRET_KEY,
  ).toString();
};

export const decryptMessage = (cipherText) => {
  return CryptoJS.AES.decrypt(
    cipherText,
    process.env.REACT_APP_CRYPTO_SECRET_KEY,
  ).toString(CryptoJS.enc.Utf8);
};

export const formatTime = (time) => {
  if (!time || time === "N/A") {
    return time;
  }
  return moment(time).format("MMMM D / hh:mm A");
};

export const hasPermissions = (field, roles) => {
  const { canUpdate } = isAuthorized(roles, field.toUpperCase());

  return canUpdate;
};

export const formatValue = (value, type, timezone) => {
  if (value === "N/A" || type !== "date") return value;
  return moment.tz(value, "YYYY-MM-DD HH:mm", timezone).format();
};

export const checkEquals = (value, oldValue, type, action, timezone) => {
  const newVal = formatValue(value, type, timezone);
  const oldVal = formatValue(oldValue, type, timezone);

  if (
    action === SocketAction.CONFIRM_TOBT_UPDATE ||
    action === SocketAction.CONFIRM_TOBT
  ) {
    return false;
  }

  if (type !== "date") return oldVal === newVal;

  if (type === "date") {
    if (oldVal !== "N/A" && newVal !== "N/A") {
      return formatTime(oldVal) === formatTime(newVal);
    }
  }
};

export const tobtInEobtWindow = (tobtValue, flight, eobtWindow, timeZone) => {
  const { isEnabled, before, after } = eobtWindow;
  if (!isEnabled) return true;

  const { eobt } = flight;

  const tobtAfterFormat =
    timeZone === "utc"
      ? moment(tobtValue).tz(timeZone).format("YYYY-MM-DD HH:mm")
      : tobtValue;
  const afterEobt = moment(eobt)
    .tz(timeZone)
    .add(after, "minutes")
    .format("YYYY-MM-DD HH:mm");
  const beforeEobt = moment(eobt)
    .tz(timeZone)
    .subtract(before, "minutes")
    .format("YYYY-MM-DD HH:mm");

  return moment(tobtAfterFormat).isBetween(beforeEobt, afterEobt);
};

const addMinutes = (date, minutes) => {
  const dateObj = new Date(date);
  return new Date(dateObj.getTime() + minutes * 60000);
};

export const validateEobtWindow = (
  fieldName,
  value,
  flight,
  eobtWindow,
  timeZone,
) => {
  let tobtValue = value;
  const fields = ["tobt", "ettt"];
  if (!fields.includes(fieldName)) {
    return true;
  }
  if (fieldName === "ettt") {
    tobtValue = addMinutes(flight.aibt, value);
  }
  return tobtInEobtWindow(tobtValue, flight, eobtWindow, timeZone);
};

export const getOldValue = (oldValue, type) => {
  if (!oldValue) {
    return null;
  }
  if (type === "date") {
    return moment(oldValue);
  }
  return oldValue;
};

const formatDefaultVal = (value, type) => {
  if (type !== "date") {
    return value || 10;
  }

  if (value) return value;

  return moment().format("YYYY-MM-DD HH:mm:ss");
};

export const getDefaultData = (name, field, flight) => {
  const { type } = field;
  if (flight[name]) {
    return formatDefaultVal(flight[name], type);
  }
  const accuracy = field.accuracy || [];
  const availableFields = accuracy.find((field) => flight[field.name]);
  const accurateField = availableFields ? availableFields.name : null;

  return formatDefaultVal(flight[accurateField], type);
};

export const findFlight = (acdmFlights, flight_number) => {
  const flight = acdmFlights.find(
    (flight) =>
      flight.flight_number === flight_number ||
      flight.arrival_flight_number === flight_number ||
      flight.departure_flight_number === flight_number,
  );
  return flight;
};

export const canUpdateFields = (editableFields, roles, updateField, title) => {
  const hasPermission = editableFields.some((editableField) => {
    const { canUpdate } = isAuthorized(roles, editableField.toUpperCase());
    return canUpdate;
  });

  const enabled = updateField[title];
  return hasPermission && enabled;
};

export const isEditable = (canEdit, isDisabled) => {
  return canEdit && !isDisabled;
};

export const activeManualFields = (field, params) => {
  const { acdm_manual_update: acdmManualUpdate } = params;
  const { fields } = acdmManualUpdate || {};
  const { enabled } = fields[field.toUpperCase()];
  return enabled;
};

export const getEditableFields = (editableFields = [], params, roles) => {
  return editableFields.filter((field) => {
    return activeManualFields(field, params) && hasPermissions(field, roles);
  });
};

export const validateActualFields = (name, value, timezone) => {
  const isActual = name[0].toUpperCase() === "A";
  if (!isActual) return true;
  const valueTimezone = moment.tz(value, "YYYY-MM-DD HH:mm", timezone).format();
  const diff = moment(valueTimezone).diff(moment(), "minutes");

  if (diff > 0) return false;

  return true;
};

export const valueInTobtLimit = ({ fieldName, flight, minLimit, value }) => {
  if (fieldName !== "tobt") return true;

  const { eobt, tobt } = flight;
  const reference = tobt || eobt;
  const diff = Math.abs(
    moment(value).diff(moment(reference), "minutes", false),
  );

  if (diff > +minLimit) return true;

  return false;
};

export const formatManualLogsMessage = ({
  message,
  new_value,
  old_value,
  timeZone,
}) => {
  const newValue = convertTimeZoneWithFormat({
    time: new_value,
    timeZone,
    requredFormat: "MMMM D / hh:mm A",
  });
  const oldValue = old_value
    ? convertTimeZoneWithFormat({
        time: old_value,
        timeZone,
        requiredFormat: "MMMM D / hh:mm A",
      })
    : "N/A";
  return printf(message, oldValue, newValue);
};

export const getImagePath = (image) => {
  return process.env.REACT_APP_ASSETS_PATH + image;
};

export const statusMap = {
  SCH: "Scheduled",
  INI: "Initiated",
  AIR: "Airborne",
  FIR: "Flight entered local FIR",
  FNL: "Final",
  ARR: "Landed",
  IBK: "In-Block",
  BRD: "Boarding",
  RDY: "Ready",
  OBK: "Off-Block",
  RDI: "Ready for de-icing",
  DEI: "De-icing in progress",
  DEP: "Departed",
};

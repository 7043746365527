import React from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import "./style.css";

const Time = ({ handleTime, value, margin = 0, disabled = false }) => {
  return (
    <div style={{ marginLeft: margin }}>
      <TimePicker
        use12Hours
        showSecond={false}
        onChange={handleTime}
        value={typeof value !== "string" ? value : moment(value, "LT")}
        inputReadOnly={true}
        disabled={disabled}
      />
    </div>
  );
};

export default Time;

import { getRequest } from "utils/APIRequest";
import {
  FETCH_SYSTEM_PARAMS_START,
  FETCH_SYSTEM_PARAMS_SUCCESS,
  FETCH_SYSTEM_PARAMS_FAIL,
  UPDATE_TIME_ZONE_START,
  UPDATE_TIME_ZONE_SUCCESS,
  UPDATE_TIME_ZONE_FAIL,
} from "../constants";

const fetchSystemParamsStart = () => ({
  type: FETCH_SYSTEM_PARAMS_START,
});

const fetchSystemParamsSuccess = (data) => ({
  type: FETCH_SYSTEM_PARAMS_SUCCESS,
  data,
});

const fetchSystemParamsFail = () => ({
  type: FETCH_SYSTEM_PARAMS_FAIL,
});

export const fetchSystemParams = (signal, cb) => {
  return async (dispatch) => {
    dispatch(fetchSystemParamsStart());

    const response = await getRequest(`/api/sysparams`);

    if (!response.error) {
      cb && cb(response);
      dispatch(fetchSystemParamsSuccess(response));
    } else {
      dispatch(fetchSystemParamsFail());
    }
  };
};

const updateTimeZoneStart = () => ({
  type: UPDATE_TIME_ZONE_START,
});

const updateTimeZoneSuccess = (data) => ({
  type: UPDATE_TIME_ZONE_SUCCESS,
  data,
});

const updateTimeZoneFail = () => ({
  type: UPDATE_TIME_ZONE_FAIL,
});

export const getTimeZone = (cb) => {
  return async (dispatch) => {
    dispatch(updateTimeZoneStart());

    const response = await getRequest(`/api/time-zone`);
    if (!response.error) {
      cb && cb(response);
      dispatch(updateTimeZoneSuccess(response));
    } else {
      dispatch(updateTimeZoneFail());
    }
  };
};

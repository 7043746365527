import React from "react";

export default (props) => (
  <div className="ember-view wind-profile" data-layout="row">
    <h2 className="atcw-section-title sec-head total">Wind Profile</h2>
    <div className="atcw-section-inner">
      <div className="atcw-wind-profiles">
        <div className="atcw-wind-profile row first">
          <div className="atcw-stat-dot-wrap">
            <div className="atcw-stat-dot red" />
          </div>
          <h2 className="atcw-wind-profile-title alt">@4,000 ft</h2>
          <h1 className="atcw-section-unit wind-prof total">
            320/25
            <span className="atcw-section-unit-of-mes">KT</span>
          </h1>
          <h1 className="atcw-section-unit wind-prof">-23 R10</h1>
        </div>
        <div className="atcw-wind-profile row second">
          <div className="atcw-stat-dot-wrap">
            <div className="atcw-stat-dot gray" />
          </div>
          <h2 className="atcw-wind-profile-title alt">@3,000 ft</h2>
          <h1 className="atcw-section-unit wind-prof total">
            320/25
            <span className="atcw-section-unit-of-mes">KT</span>
          </h1>
          <h1 className="atcw-section-unit wind-prof">-23 R10</h1>
        </div>
        <div className="atcw-wind-profile row third">
          <div className="atcw-stat-dot-wrap">
            <div className="atcw-stat-dot red" />
          </div>
          <h2 className="atcw-wind-profile-title alt">@2,000 ft</h2>
          <h1 className="atcw-section-unit wind-prof total">
            320/20
            <span className="atcw-section-unit-of-mes">KT</span>
          </h1>
          <h1 className="atcw-section-unit wind-prof">-20 R4</h1>
          <h1 className="atcw-section-unit wind-prof">
            6<span className="atcw-section-unit-of-mes">KT</span>
          </h1>
        </div>

        <div className="atcw-wind-profile row fourth">
          <div className="atcw-stat-dot-wrap">
            <div className="atcw-stat-dot yellow" />
          </div>
          <h2 className="atcw-wind-profile-title alt">@1,000 ft</h2>
          <h1 className="atcw-section-unit wind-prof total">
            330/15
            <span className="atcw-section-unit-of-mes">KT</span>
          </h1>
          <h1 className="atcw-section-unit wind-prof">-15 R2</h1>
          <h1 className="atcw-section-unit wind-prof">—</h1>
        </div>
      </div>
    </div>
  </div>
);

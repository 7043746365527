import React from "react";
import "./style.css";
const SearchInput = (props) => {
  const { changeSearchHandler, style = {}, width, bottom, top } = props;
  return (
    <div
      className="search_container"
      style={{ position: "relative", bottom: bottom || 22, ...style }}
    >
      <span
        style={{
          position: "absolute",
          top: 11,
          left: 20,
          zIndex: 1,
        }}
      >
        <i className="search-icon"></i>
      </span>
      <input
        style={{
          borderBottom: "unset",
          marginBottom: 0,
          boxSizing: "inherit",
          paddingLeft: "3.2rem",
          color: "#fff",
          height: "38px",
          width: width || "",
          boxShadow: "none",
          border:"none"
        }}
        placeholder="Search ..."
        className="input"
        onChange={changeSearchHandler}
      />
    </div>
  );
};

export default SearchInput;

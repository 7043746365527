import React from "react";
import image from "assets/images/errors/404.png";
import ErrorPage from "./ErrorPage";

const NotFound = () => {
  return (
    <ErrorPage
      image={image}
      message="Page not found"
      description="This page doesn’t exist or was removed."
    />
  );
};

export default NotFound;

import { orderBy } from "lodash";
import { sortTypes } from "utils/helpers";

const notAvailable = (value) => {
  return value === null || value === 0;
};

const dataSort = (data, sortBy) => {
  const sortedElements = Object.keys(sortBy);

  if (!sortedElements.length) return data;

  const field = sortedElements[0];
  const order = sortBy[sortedElements];

  const sortedData = orderBy(
    data,
    [(flight) => notAvailable(flight[field]), field],
    [sortTypes.ASCEND, order],
  );

  return sortedData;
};

export default dataSort;

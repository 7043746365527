// Integration Constants
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const SET_ALERTS = "SET_ALERTS";
export const AUTH_SET_TOKEN = "auth/SET_TOKEN";
export const AUTH_SET_USER = "auth/SET_USER";
export const LOG_OUT = "LOG_OUT";
export const ACTIVATE_FLIGHT = "ACTIVATE_FLIGHT";
export const DEACTIVATE_FLIGHT = "DEACTIVATE_FLIGHT";
export const UPDATE_AIRPLANES = "UPDATE_AIRPLANES";

export const UPDATE_USER_LAYOUT = "UPDATE_USER_LAYOUT";

/******************************* USER Account *************************************/

export const FETCH_USER_PROFILE_START = "FETCH_USER_PROFILE_START";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAIL = "FETCH_USER_PROFILE_FAIL";

export const UPDATE_USER_PROFILE_START = "UPDATE_USER_PROFILE_START";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

export const UPLOAD_USER_START = "UPLOAD_USER_START";
export const UPLOAD_USER_SUCCESS = "UPLOAD_USER_SUCCESS";
export const UPLOAD_USER_FAIL = "UPLOAD_USER_FAIL";

export const CLEAR_USER_START = "CLEAR_USER_START";
export const CLEAR_USER_SUCCESS = "CLEAR_USER_SUCCESS";
export const CLEAR_USER_FAIL = "CLEAR_USER_FAIL";

export const FETCH_USER_ROLES_AND_AIRLINES_START =
  "FETCH_USER_ROLES_AND_AIRLINES_START";
export const FETCH_USER_ROLES_AND_AIRLINES_SUCCESS =
  "FETCH_USER_ROLES_AND_AIRLINES_SUCCESS";
export const FETCH_USER_ROLES_AND_AIRLINES_FAIL =
  "FETCH_USER_ROLES_AND_AIRLINES_FAIL";

export const RESTORE_USERS_AIRLINE_START = "RESTORE_USERS_AIRLINE_START";
export const RESTORE_USERS_AIRLINE_SUCCESS = "RESTORE_USERS_AIRLINE_START";
export const RESTORE_USERS_AIRLINE_FAILS = "RESTORE_USERS_AIRLINE_FAILS";
/******************************* MESSAGES *************************************/

export const SET_SEARCHING = "SET_SEARCHING";

export const FETCH_CHANNELS_START = "FETCH_CHANNELS_START";
export const FETCH_CHANNELS_SUCCESS = "FETCH_CHANNELS_SUCCESS";
export const FETCH_CHANNELS_FAIL = "FETCH_CHANNELS_FAIL";

export const FETCH_MESSAGES_START = "FETCH_MESSAGES_START";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAIL = "FETCH_MESSAGES_FAIL";

export const CREATE_MESSAGE_START = "CREATE_MESSAGE_START";
export const CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS";
export const CREATE_MESSAGE_FAIL = "CREATE_MESSAGE_FAIL";

export const REINIT_MESSAGES = "REINIT_MESSAGES";

export const CREATE_CHANNEL_START = "CREATE_CHANNEL_START";
export const CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS";
export const CREATE_CHANNEL_FAIL = "CREATE_CHANNEL_FAIL";

export const FETCH_USERS_CHANNEL_START = "FETCH_USERS_CHANNEL_START";
export const FETCH_USERS_CHANNEL_SUCCESS = "FETCH_USERS_CHANNEL_SUCCESS";
export const FETCH_USERS_CHANNEL_FAIL = "FETCH_USERS_CHANNEL_FAIL";

export const FETCH_UNREAD_MESSAGES_START = "FETCH_UNREAD_MESSAGES_START";
export const FETCH_UNREAD_MESSAGES_SUCCESS = "FETCH_UNREAD_MESSAGES_SUCCESS";
export const FETCH_UNREAD_MESSAGES_FAIL = "FETCH_UNREAD_MESSAGES_FAIL";

export const READ_MESSAGES_START = "READ_MESSAGES_START";
export const READ_MESSAGES_SUCCESS = "READ_MESSAGES_SUCCESS";
export const READ_MESSAGES_FAIL = "READ_MESSAGES_FAIL";

export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_START = "DELETE_CHANNEL_START";
export const DELETE_CHANNEL_FAIL = "DELETE_CHANNEL_FAIL";

export const UPDATE_CHANNEL_START = "UPDATE_CHANNEL_START";
export const UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS";
export const UPDATE_CHANNEL_FAIL = "UPDATE_CHANNEL_FAIL";
export const NEW_UNREAD_MESSAGE = "NEW_UNREAD_MESSAGE";

export const SET_SELECTED_CHANNEL = "SET_SELECTED_CHANNEL";

/******************************* AIRLINES *************************************/
export const REINIT_AIRLINES = "REINIT_AIRLINES";

export const FETCH_AIRLINES_START = "FETCH_AIRLINES_START";
export const FETCH_AIRLINES_SUCCESS = "FETCH_AIRLINES_SUCCESS";
export const FETCH_AIRLINES_FAIL = "FETCH_AIRLINES_FAIL";

export const UPDATE_AIRLINE_IMAGE_START = "UPDATE_AIRLINE_IMAGE_START";
export const UPDATE_AIRLINE_IMAGE_SUCCESS = "UPDATE_AIRLINE_IMAGE_SUCCESS";
export const UPDATE_AIRLINE_IMAGE_FAIL = "UPDATE_AIRLINE_IMAGE_FAIL";

export const DELETE_AIRLINE_START = "DELETE_AIRLINE_START";
export const DELETE_AIRLINE_SUCCESS = "DELETE_AIRLINE_SUCCESS";
export const DELETE_AIRLINE_FAIL = "DELETE_AIRLINE_FAIL";

export const RESTORE_AIRLINE_START = "RESTORE_AIRLINE_START";
export const RESTORE_AIRLINE_SUCCESS = "RESTORE_AIRLINE_SUCCESS";
export const RESTORE_AIRLINE_FAIL = "RESTORE_AIRLINE_FAIL";

export const RESTORE_ORGANIZATION_AIRLINE_START =
  "RESTORE_ORGANIZATION_AIRLINE_START";
export const RESTORE_ORGANIZATION_AIRLINE_SUCCESS =
  "RESTORE_ORGANIZATION_AIRLINE_SUCCESS";
export const RESTORE_ORGANIZATION_AIRLINE_FAILS =
  "RESTORE_ORGANIZATION_AIRLINE_FAILS";

export const CREATE_AIRLINE_START = "CREATE_AIRLINE_START";
export const CREATE_AIRLINE_SUCCESS = "CREATE_AIRLINE_SUCCESS";
export const CREATE_AIRLINE_FAIL = "CREATE_AIRLINE_FAIL";

export const UPDATE_AIRLINE_START = "UPDATE_AIRLINE_START";
export const UPDATE_AIRLINE_FAIL = "UPDATE_AIRLINE_FAIL";

export const FETCH_AIRLINE_START = "FETCH_AIRLINE_START";
export const FETCH_AIRLINE_SUCCESS = "FETCH_AIRLINE_SUCCESS";
export const FETCH_AIRLINE_FAIL = "FETCH_AIRLINE_FAIL";

/******************************* ROLES *************************************/

export const REINIT_ROLES = "REINIT_ROLES";

export const CREATE_ROLES_START = "CREATE_ROLES_START";
export const CREATE_ROLES_SUCCESS = "CREATE_ROLES_SUCCESS";
export const CREATE_ROLES_FAIL = "CREATE_ROLES_FAIL";

export const UPDATE_ROLES_START = "UPDATE_ROLES_START";
export const UPDATE_ROLES_FAIL = "UPDATE_ROLES_FAIL";

export const FETCH_ROLE_START = "FETCH_ROLE_START";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
export const FETCH_ROLE_FAIL = "FETCH_ROLE_FAIL";

export const FETCH_ROLES_START = "FETCH_ROLES_START";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAIL = "FETCH_ROLES_FAIL";

export const FETCH_PERMISSIONS_START = "FETCH_PERMISSIONS_START";
export const FETCH_PERMISSIONS_SUCCESS = "FETCH_PERMISSIONS_SUCCESS";
export const FETCH_PERMISSIONS_FAIL = "FETCH_PERMISSIONS_FAIL";

export const DELETE_ROLES_START = "DELETE_ROLES_START";
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const DELETE_ROLES_FAIL = "DELETE_ROLES_FAIL";

export const RESTORE_ROLES_START = "RESTORE_ROLES_START";
export const RESTORE_ROLES_SUCCESS = "RESTORE_ROLES_SUCCESS";
export const RESTORE_ROLES_FAIL = "RESTORE_ROLES_FAIL";
/******************************* ACDM *************************************/

export const REINIT_TOAST_ALERT = "REINIT_TOAST_ALERT";
export const SUCCESS_MESSAGE = "SUCCESS_MESSAGE";
export const ERROR_MESSAGE = "ERROR_MESSAGE";

export const REINIT_NOTIFICATION = "REINIT_NOTIFICATION";
export const SUCCESS_NOTIFICATION_MESSAGE = "SUCCESS_NOTIFICATION_MESSAGE";
export const ERROR_NOTIFICATION_MESSAGE = "ERROR_NOTIFICATION_MESSAGE";

export const FETCH_ACDM_START = "FETCH_ACDM_START";
export const FETCH_ACDM_SUCCESS = "FETCH_ACDM_SUCCESS";
export const FETCH_ACDM_FAIL = "FETCH_ACDM_FAIL";

export const REINIT_ACDM = "REINIT_ACDM";

export const SELECTED_FLIGHTS = "SELECTED_FLIGHTS";

export const REINIT_USER = "REINIT_USER";

export const FETCH_SYSTEM_PARAMS_START = "FETCH_SYSTEM_PARAMS_START";
export const FETCH_SYSTEM_PARAMS_SUCCESS = "FETCH_SYSTEM_PARAMS_SUCCESS";
export const FETCH_SYSTEM_PARAMS_FAIL = "FETCH_SYSTEM_PARAMS_FAIL";

export const UPDATE_TIME_ZONE_START = "UPDATE_TIME_ZONE_START";
export const UPDATE_TIME_ZONE_SUCCESS = "UPDATE_TIME_ZONE_SUCCESS";
export const UPDATE_TIME_ZONE_FAIL = "UPDATE_TIME_ZONE_FAIL";

export const FETCH_OUT_BOUND_MESSAGES_START = "FETCH_OUT_BOUND_MESSAGES_START";
export const FETCH_OUT_BOUND_MESSAGES_SUCCESS =
  "FETCH_OUT_BOUND_MESSAGES_SUCCESS";
export const FETCH_OUT_BOUND_MESSAGES_FAIL = "FETCH_OUT_BOUND_MESSAGES_FAIL";

export const UPDATE_ACDM_SOCKET = "UPDATE_ACDM_SOCKET";

export const ACDM_START_REQUEST = "ACDM_START_REQUEST";
export const ACDM_FAILD_REQUEST = "ACDM_FAILD_REQUEST";

export const FETCH_GENERAL_ALERTS_START = "FETCH_GENERAL_ALERTS_START";
export const FETCH_GENERAL_ALERTS_SUCCESS = "FETCH_GENERAL_ALERTS_SUCCESS";
export const FETCH_GENERAL_ALERTS_FAIL = "FETCH_GENERAL_ALERTS_FAIL";
export const SET_GENERAL_ALERTS = "SET_GENERAL_ALERTS";

export const UPDATE_ALERTS_SOCKET_DATA = "UPDATE_ALERTS_SOCKET_DATA";

export const REINIT_ALERTS = "REINIT_ACDM";
export const FETCH_ACDM_ACTIVE_FIELDS_START = "FETCH_ACDM_ACTIVE_FIELDS_START";
export const FETCH_ACDM_ACTIVE_FIELDS_SUCCESS =
  "FETCH_ACDM_ACTIVE_FIELDS_SUCCESS";
export const UPDATE_ACDM_ACTIVE_FIELDS_SUCCESS =
  "UPDATE_ACDM_ACTIVE_FIELDS_SUCCESS";

/******************************* ORGANIZATIONS *************************************/

export const REINIT_ORGANIZATIONS = "REINIT_ORGANIZATIONS";

export const FETCH_ORGANIZATIONS_START = "FETCH_ORGANIZATIONS_START";
export const FETCH_ORGANIZATIONS_SUCCESS = "FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_FAIL = "FETCH_ORGANIZATIONS_FAIL";

export const DELETE_ORGANIZATIONS_START = "DELETE_ORGANIZATIONS_START";
export const DELETE_ORGANIZATIONS_SUCCESS = "DELETE_ORGANIZATIONS_SUCCESS";
export const DELETE_ORGANIZATIONS_FAIL = "DELETE_ORGANIZATIONS_FAIL";

export const RESTORE_ORGANIZATIONS_START = "RESTORE_ORGANIZATIONS_START";
export const RESTORE_ORGANIZATIONS_SUCCESS = "RESTORE_ORGANIZATIONS_SUCCESS";
export const RESTORE_ORGANIZATIONS_FAIL = "RESTORE_ORGANIZATIONS_FAIL";

export const RESTORE_USERS_ORGANIZATIONS_START =
  "RESTORE_USERS_ORGANIZATIONS_START";
export const RESTORE_USERS_ORGANIZATIONS_SUCCESS =
  "RESTORE_USERS_ORGANIZATIONS_SUCCESS";
export const RESTORE_USERS_ORGANIZATIONS_FAIL =
  "RESTORE_USERS_ORGANIZATIONS_FAIL";

export const GET_ROLES_BY_ORG_ID_START = "GET_ROLES_BY_ORG_ID_START";
export const GET_ROLES_BY_ORG_ID_SUCCESS = "GET_ROLES_BY_ORG_ID_SUCCESS";
export const GET_ROLES_BY_ORG_ID_FAIL = "GET_ROLES_BY_ORG_ID_FAIL";

export const GET_AIRLINES_BY_ORG_ID_START = "GET_AIRLINES_BY_ORG_ID_START";
export const GET_AIRLINES_BY_ORG_ID_SUCCESS = "GET_AIRLINES_BY_ORG_ID_SUCCESS";
export const GET_AIRLINES_BY_ORG_ID_FAIL = "GET_AIRLINES_BY_ORG_ID_FAIL";

export const CREATE_ORGANIZATION_START = "CREATE_ORGANIZATION_START";
export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";
export const CREATE_ORGANIZATION_FAIL = "CREATE_ORGANIZATION_FAIL";

export const UPDATE_ORGANIZATION_START = "UPDATE_ORGANIZATION_START";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_FAIL = "UPDATE_ORGANIZATION_FAIL";
export const FETCH_ORGANIZATION_START = "FETCH_ORGANIZATION_START";
export const FETCH_ORGANIZATION_SUCCESS = "FETCH_ORGANIZATION_SUCCESS";
export const FETCH_ORGANIZATION_FAIL = "FETCH_ORGANIZATION_FAIL";

/******************************* Alerts *************************************/
export const FETCH_ALERTS_START = "FETCH_ALERTS_START";
export const FETCH_ALERTS_SUCCESS = "FETCH_ALERTS_SUCCESS";
export const FETCH_ALERTS_FAIL = "FETCH_ALERTS_FAIL";

export const RESTORE_ALERTS_START = "RESTORE_ALERTS_START";
export const RESTORE_ALERTS_SUCCESS = "RESTORE_ALERTS_SUCCESS";
export const RESTORE_ALERTS_FAIL = "RESTORE_ALERTS_FAIL";

export const DELETE_ALERTS_START = "DELETE_ALERTS_START";
export const DELETE_ALERTS_SUCCESS = "DELETE_ALERTS_SUCCESS";
export const DELETE_ALERTS_FAIL = "DELETE_ALERTS_FAIL";

export const CREATE_ALERT_START = "CREATE_ALERT_START";
export const CREATE_ALERT_SUCCESS = "CREATE_ALERT_SUCCESS";
export const CREATE_ALERT_FAIL = "CREATE_ALERT_FAIL";

export const UPDATE_ALERT_START = "UPDATE_ALERT_START";
export const UPDATE_ALERT_SUCCESS = "UPDATE_ALERT_SUCCESS";
export const UPDATE_ALERT_FAIL = "UPDATE_ALERT_FAIL";

export const FETCH_ALERT_START = "FETCH_ALERT_START";
export const FETCH_ALERT_SUCCESS = "FETCH_ALERT_SUCCESS";
export const FETCH_ALERT_FAIL = "FETCH_ALERT_FAIL";

export const CHECK_QUERY_START = "CHECK_QUERY_START";
export const CHECK_QUERY_SUCCESS = "CHECK_QUERY_SUCCESS";
export const CHECK_QUERY_FAIL = "CHECK_QUERY_FAIL";

/******************************* USERS *************************************/
export const REINIT_USERS = "REINIT_USERS";

export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";

export const DELETE_USERS_START = "DELETE_USERS_START";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_FAIL = "DELETE_USERS_FAIL";

export const RESTORE_USERS_START = "RESTORE_USERS_START";
export const RESTORE_USERS_SUCCESS = "RESTORE_USERS_SUCCESS";
export const RESTORE_USERS_FAIL = "RESTORE_USERS_FAIL";

export const CREATE_USERS_START = "CREATE_USERS_START";
export const CREATE_USERS_SUCCESS = "CREATE_USERS_SUCCESS";
export const CREATE_USERS_FAIL = "CREATE_USERS_FAIL";

export const UPDATE_USERS_START = "UPDATE_USERS_START";
export const UPDATE_USERS_FAIL = "UPDATE_USERS_FAIL";

export const FETCH_USER_DETAILS_USER_START = "FETCH_USER_DETAILS_USER_START";
export const FETCH_USER_DETAILS_USER_SUCCESS =
  "FETCH_USER_DETAILS_USER_SUCCESS";
export const FETCH_USER_DETAILS_USER_FAIL = "FETCH_USER_DETAILS_USER_FAIL";

/******************************* Alerts *************************************/

export const REINIT_ACTIVE_ALERTS = "REINIT_ACTIVE_ALERTS";

export const FETCH_ACTIVE_ALERTS_START = "FETCH_ACTIVE_ALERTS_START";
export const FETCH_ACTIVE_ALERTS_SUCCESS = "FETCH_ACTIVE_ALERTS_SUCCESS";
export const FETCH_ACTIVE_ALERTS_FAIL = "FETCH_ACTIVE_ALERTS_FAIL";

/******************************* Notifications *************************************/

export const REINIT_NOTIFICATIONS = "REINIT_NOTIFICATIONS";

export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAIL = "FETCH_NOTIFICATIONS_FAIL";

export const DELETE_NOTIFICATIONS_START = "DELETE_NOTIFICATIONS_START";
export const DELETE_NOTIFICATIONS_SUCCESS = "DELETE_NOTIFICATIONS_SUCCESS";
export const DELETE_NOTIFICATIONS_FAIL = "DELETE_NOTIFICATIONS_FAIL";

export const RESTORE_NOTIFICATIONS_START = "RESTORE_NOTIFICATIONS_START";
export const RESTORE_NOTIFICATIONS_SUCCESS = "RESTORE_NOTIFICATIONS_SUCCESS";
export const RESTORE_NOTIFICATIONS_FAIL = "RESTORE_NOTIFICATIONS_FAIL";

export const CREATE_NOTIFICATIONS_START = "CREATE_NOTIFICATIONS_START";
export const CREATE_NOTIFICATIONS_SUCCESS = "CREATE_NOTIFICATIONS_SUCCESS";
export const CREATE_NOTIFICATIONS_FAIL = "CREATE_NOTIFICATIONS_FAIL";

export const UPDATE_NOTIFICATIONS_START = "UPDATE_NOTIFICATIONS_START";
export const UPDATE_NOTIFICATIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SUCCESS";
export const UPDATE_NOTIFICATIONS_FAIL = "UPDATE_NOTIFICATIONS_FAIL";

export const FETCH_NOTIFICATION_START = "FETCH_NOTIFICATION_START";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAIL = "FETCH_NOTIFICATION_FAIL";

/******************************* alert logs *************************************/
export const REINIT_LOGS = "REINIT_LOGS";

export const FETCH_LOGS_START = "FETCH_LOGS_START";
export const FETCH_LOGS_SUCCESS = "FETCH_LOGS_SUCCESS";
export const FETCH_LOGS_FAIL = "FETCH_LOGS_FAIL";

/******************************* general alert logs *************************************/
export const REINIT_GENERAL_ALERT_LOGS = "REINIT_GENERAL_ALERT_LOGS";

export const FETCH_GENERAL_ALERT_LOGS_START = "FETCH_GENERAL_ALERT_LOGS_START";
export const FETCH_GENERAL_ALERT_LOGS_SUCCESS =
  "FETCH_GENERAL_ALERT_LOGS_SUCCESS";
export const FETCH_GENERAL_ALERT_LOGS_FAIL = "FETCH_GENERAL_ALERT_LOGS_FAIL";

/******************************* notification logs *************************************/

export const REINIT_MANUAL_UPDATES_LOGS = "REINIT_MANUAL_UPDATES_LOGS";

export const FETCH_MANUAL_UPDATES_LOGS_START =
  "FETCH_MANUAL_UPDATES_LOGS_START";
export const FETCH_MANUAL_UPDATES_LOGS_SUCCESS =
  "FETCH_MANUAL_UPDATES_LOGS_SUCCESS";
export const FETCH_MANUAL_UPDATES_LOGS_FAIL = "FETCH_MANUAL_UPDATES_LOGS_FAIL";

/******************************* Audit trails  *************************************/

export const REINIT_AUDIT_TRAILS = "REINIT_AUDIT_TRAILS";

export const FETCH_AUDIT_TRAILS_START = "FETCH_AUDIT_TRAILS_START";
export const FETCH_AUDIT_TRAILS_SUCCESS = "FETCH_AUDIT_TRAILS_SUCCESS";
export const FETCH_AUDIT_TRAILS_FAIL = "FETCH_AUDIT_TRAILS_FAIL";

/******************************* Layouts  *************************************/

export const REINIT_LAYOUTS = "REINIT_LAYOUTS";

export const FETCH_LAYOUTS_START = "FETCH_LAYOUTS_START";
export const FETCH_LAYOUTS_SUCCESS = "FETCH_LAYOUTS_SUCCESS";
export const FETCH_LAYOUTS_FAIL = "FETCH_LAYOUTS_FAIL";

export const SAVE_USER_LAYOUT_START = "SAVE_USER_LAYOUT_START";
export const SAVE_USER_LAYOUT_SUCCESS = "SAVE_USER_LAYOUT_SUCCESS";
export const SAVE_USER_LAYOUT_FAIL = "SAVE_USER_LAYOUT_FAIL";

export const UPDATE_USER_LAYOUT_START = "UPDATE_USER_LAYOUT_START";
export const UPDATE_USER_LAYOUT_SUCCESS = "UPDATE_USER_LAYOUT_SUCCESS";
export const UPDATE_USER_LAYOUT_FAIL = "UPDATE_USER_LAYOUT_FAIL";

export const UPDATE_USER_LAYOUT_CONFIG_START =
  "UPDATE_USER_LAYOUT_CONFIG_START";
export const UPDATE_USER_LAYOUT_CONFIG_SUCCESS =
  "UPDATE_USER_LAYOUT_CONFIG_SUCCESS";
export const UPDATE_USER_LAYOUT_CONFIG_FAIL = "UPDATE_USER_LAYOUT_CONFIG_FAIL";

export const UPDATE_FAVORITE_LAYOUT_START = "UPDATE_FAVORITE_LAYOUT_START";
export const UPDATE_FAVORITE_LAYOUT_SUCCESS = "UPDATE_FAVORITE_LAYOUT_SUCCESS";
export const UPDATE_FAVORITE_LAYOUT_FAIL = "UPDATE_FAVORITE_LAYOUT_FAIL";

export const CHANGE_LAYOUT = "CHANGE_LAYOUT";

/******************************* Layouts  *************************************/
export const REINIT_APM = "REINIT_APM";

export const FETCH_APM_START = "FETCH_APM_START";
export const FETCH_APM_SUCCESS = "FETCH_APM_SUCCESS";
export const FETCH_APM_FAIL = "FETCH_APM_FAIL";
export const REINIT_SELECTED_LAYOUT = "REINIT_SELECTED_LAYOUT";

/******************************* bhs  *************************************/

export const REINIT_BHS = "REINIT_BHS";

export const FETCH_BHS_START = "FETCH_BHS_START";
export const FETCH_BHS_SUCCESS = "FETCH_BHS_SUCCESS";
export const FETCH_BHS_FAIL = "FETCH_BHS_FAIL";

export const FETCH_BHS_BELTS_START = "FETCH_BHS_BELTS_START";
export const FETCH_BHS_BELTS_SUCCESS = "FETCH_BHS_BELTS_SUCCESS";
export const FETCH_BHS_BELTS_FAIL = "FETCH_BHS_BELTS_FAIL";

export const NEW_BELT_SOCKET = "NEW_BELT_SOCKET";

import React, { useEffect, useState } from "react";
import InputForm from "components/common/UiLibrary/input_form";

const IntegerInput = ({
  errorMessage,
  value,
  handleSave,
  fieldName,
  data,
  errorMessageUpdate,
}) => {
  const [valueInput, setValueInput] = useState("");
  const [errorValueMsg, setErrorValueMsg] = useState("");

  const handleValue = (e) => {
    setValueInput(e.target.value);
  };

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  const handleClick = () => {
    if (valueInput <= 0) setErrorValueMsg("Positive numbers are only allowed");
    else if (valueInput > 999) setErrorValueMsg("maximum value is 999 minutes");
    else return handleSave(+valueInput, fieldName);
  };

  return (
    <div>
      <div className="container-date">
        <div className="acdm-manual-update-number">
          <InputForm
            label="Number of minutes"
            id="Number"
            value={valueInput}
            onChange={handleValue}
            type="number"
            key="Number"
            error={errorValueMsg}
          />
        </div>
      </div>
      <div className="error-text">
        {errorMessage ||
          (errorMessageUpdate && (
            <span>{errorMessage || errorMessageUpdate}</span>
          ))}
      </div>
      <div className="container-btn">
        <button
          onClick={handleClick}
          className="btn-submit"
          data-disabled-btn={errorMessage}
        >
          <span className="text-btn">{`${data.okBtnVal || "Save"}`}</span>
        </button>
      </div>
    </div>
  );
};

export default IntegerInput;

import React, { useEffect, useState } from "react";
import InfoModal from "../common/modal_manager/info";
import {
  extractValueFromData,
  storageKeys,
  removeFromLocalStorage,
} from "utils/helpers";
import { terminateWorker } from "utils/workers";
import { useNavigate } from "react-router-dom";
import { logOut, setUser } from "redux/actions/session";
import { REINIT_USER, SELECTED_FLIGHTS } from "redux/constants";
import { useDispatch } from "react-redux";

const UserAccounts = (props) => {
  const [userInfo, setUserInfo] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [applicableRoles, setApplicableRoles] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const abortController = new AbortController();

  useEffect(() => {
    terminateWorker();
    setTimeout(() => {
      getUserRoles();
    }, 1000);

    return () => {
      abortController.abort();
    };
  }, []);

  const logout = () => {
    dispatch({ type: REINIT_USER });
    dispatch(logOut());
  };

  const setUserAuth = (data) => {
    dispatch(setUser(data.user));
  };

  const handleApplicableRoles = (rolesIds, action) => {
    if (!rolesIds) return;
    if (action === "select") {
      applicableRoles.push(...rolesIds);
    } else {
      rolesIds.forEach((id) => {
        const index = applicableRoles.indexOf(+id);
        applicableRoles.splice(index, 1);
      });
    }
    setApplicableRoles(applicableRoles);
  };

  const validApplicable = (roleId) => {
    if (selectedRoles.length && !applicableRoles.includes(roleId)) {
      return false;
    }
    return true;
  };

  const selectRole = (role) => {
    const roleId = role.id;
    const newSelectedRoles = [...selectedRoles];
    const index = newSelectedRoles.indexOf(+roleId);

    if (index > -1) {
      newSelectedRoles.splice(index, 1);
      handleApplicableRoles(role.applicable_roles);
    } else {
      if (!validApplicable(roleId)) return;
      newSelectedRoles.push(+roleId);
      handleApplicableRoles(role.applicable_roles, "select");
    }
    setSelectedRoles(newSelectedRoles);
  };

  const logInByRoles = async () => {
    if (!selectedRoles.length) {
      setConfirmBox(true);
      setErrorMessage("Please choose at least one role");
    }

    return await fetch("/api/users/select-roles", {
      method: "post",
      signal: abortController.signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        rolesIds: selectedRoles,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setUserAuth(data);

        const checkResponsibleTobt = extractValueFromData(
          selectedRoles,
          userInfo.roles,
          "id",
          "responsible_for_tobt",
        );
        const responsibleForTobt = checkResponsibleTobt.some((ele) => ele);

        if (!responsibleForTobt) {
          removeFromLocalStorage(storageKeys.SELECTED_FLIGHTS);
          removeFromLocalStorage(storageKeys.SELECTED_FLIGHTS_NUMBERS);
          removeFromLocalStorage(storageKeys.SELECTED_FLIGHTS_REGISTRATIONS);
          dispatch({ type: SELECTED_FLIGHTS, data: {} });
          navigate("/");
        } else navigate("/me/select-flights");
      });
  };

  const getUserRoles = async () => {
    return await fetch("/api/users/roles", {
      method: "get",
      signal: abortController.signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        setUserInfo(data);
        setErrorMessage("");

        if (!data.roles || !data.roles.length) {
          setErrorMessage(
            "No roles found for this user, please try to logout and login again",
          );
        }
      })
      .catch((e) => {
        setErrorMessage(
          "No roles found for this user, please try to logout and login again",
        );
      });
  };

  const closeModal = () => {
    setConfirmBox(false);
    setErrorMessage("");
  };

  const getClass = (roleId) => {
    const defaultClass = "user-role";

    if (selectedRoles.includes(roleId)) {
      return `${defaultClass} selected`;
    }

    if (!applicableRoles.includes(roleId) && selectedRoles.length) {
      return `${defaultClass} disabled`;
    }
    return defaultClass;
  };

  return (
    <div className="user-role-wrapper">
      {confirmBox ? (
        <InfoModal
          message={errorMessage}
          cb={closeModal}
          overlay
          icon="warning"
          color="red"
        />
      ) : null}
      <div>
        {userInfo.roles && userInfo.roles.length ? (
          <div>
            <div className="profile-title">Welcome Back</div>
            <div className="user-name-container">
              <div className="user-image">
                <img
                  alt=""
                  src={
                    userInfo.image
                      ? `${process.env.REACT_APP_ASSETS_PATH}${userInfo.image}`
                      : `${process.env.REACT_APP_USER_DEFAULT_PROFILE_PICTURE}`
                  }
                />
              </div>
              <div className="user-name">{userInfo.name}</div>
            </div>
            <div className="roles-header">Select a Role</div>
            <div className="user-roles-wrapper">
              {userInfo.roles.map((role, i) => (
                <div
                  key={i}
                  className={getClass(role.id)}
                  role="button"
                  tabIndex="0"
                  onKeyDown={(ev) => {
                    if (ev.keyCode === 13) ev.target.click();
                  }}
                  onClick={() => selectRole(role)}
                >
                  <div>
                    <div className="role">{role.role}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : errorMessage ? (
          <div className="error-message">{errorMessage}</div>
        ) : null}
        <div className="select-role-buttons">
          <div
            className={`button-role button-contained ${selectedRoles.length ? "button-active" :"button-disable"
              }`}
            role="button"
            tabIndex="0"
            onKeyDown={(ev) => {
              if (ev.keyCode === 13) ev.target.click();
            }}
            onClick={logInByRoles}
          >
            Start
          </div>
          <div
            className="button-role button-sign-out"
            role="button"
            tabIndex="0"
            onKeyDown={(ev) => {
              if (ev.keyCode === 13) ev.target.click();
            }}
            onClick={logout}
          >
            Sign out
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccounts;

import React, { useEffect } from "react";
import { Tooltip } from "react-redux-tooltip";
import ColumnHeader from "./column_header";

const columns = {
  arrival: [
    { name: "aldt", type: "date" },
    { name: "eibt", type: "date" },
    { name: "exit", type: "number" },
  ],
  departure: [
    { name: "aobt", type: "date" },
    { name: "ttot", type: "date" },
    { name: "exot", type: "number" },
  ],
};

const AirPlaneToolTip = ({
  aircraft_registration,
  flight_number,
  airlineLogo,
  showToolTips,
  onCloseToolTip,
  flight,
  timeZone,
}) => {
  const isArrival =
    flight?.ades === process.env.REACT_APP_AIRPORT_ICAO ||
    flight?.ades === process.env.REACT_APP_AIRPORT_IATA;

  const closeToolTip = () => {
    onCloseToolTip(aircraft_registration);
  };

  const show = () => {
    const airplane = document.querySelector(
      `g[data-tail-number="${aircraft_registration}"]`,
    );

    if (!airplane) return;

    const rect = airplane.getBoundingClientRect();
    const origin = { y: rect.top, x: rect.left + rect.width / 2 };

    showToolTips({
      name: aircraft_registration,
      origin,
    });
  };

  addEventListener("resize", _.debounce(show, 500));

  useEffect(() => {
    show();
    const airportLayout = document.querySelector("#airport-pan")
    airportLayout?.addEventListener("click", closeToolTip);
    return () => {
      airportLayout?.removeEventListener("click", closeToolTip);
    };
  }, [aircraft_registration]);

  return (
    <div>
      <Tooltip
        id={aircraft_registration}
        name={aircraft_registration}
        className="tooltip airplane-tooltip"
      >
        <div className="header-tooltip">
          <div className="flight-tooltip-logo">
            <img
              src={`${process.env.REACT_APP_ASSETS_PATH}${airlineLogo}`}
              alt={flight_number}
              width="50px"
              height="10px"
            />
          </div>
          <div className="header-cell">
            <div className="header-content">Flight num</div>
            <div className="header-flight">{flight_number}</div>
          </div>
          <div className="header-cell">
            <div className="header-content">Aircraft reg</div>
            <div className="header-flight">{aircraft_registration}</div>
          </div>
          <div className="header-cell">
            <div className="header-content">ARR / DEP</div>
            <div className="header-flight">{isArrival ? "ARR" : "DEP"}</div>
          </div>
        </div>
        <div className="header-tooltip mt-field">
          <ColumnHeader
            colums={isArrival ? columns["arrival"] : columns["departure"]}
            data={flight}
            timeZone={timeZone}
          />
        </div>
        <div className="header-tooltip">
          <div className="header-cell">
            <div className="header-content">Est fuel cons</div>
            <div className="header-flight">12 liters</div>
          </div>
          <div className="header-cell">
            <div className="header-content">Est noise level</div>
            <div className="header-flight">140 dB</div>
          </div>
          <div className="header-cell">
            <div className="header-content">Est emissions</div>
            <div className="header-flight">2.5 PM10</div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default AirPlaneToolTip;

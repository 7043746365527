import {
  FETCH_AUDIT_TRAILS_START,
  FETCH_AUDIT_TRAILS_SUCCESS,
  FETCH_AUDIT_TRAILS_FAIL,
} from "../constants";

import { getRequest } from "utils/APIRequest";

const fetchAuditTrailsStart = () => ({
  type: FETCH_AUDIT_TRAILS_START,
});

const fetchAuditTrailsSuccess = (data, total) => ({
  type: FETCH_AUDIT_TRAILS_SUCCESS,
  data,
  total,
});

const fetchAuditTrailsFail = () => ({
  type: FETCH_AUDIT_TRAILS_FAIL,
});

export const fetchAuditTrails = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchAuditTrailsStart());

    const response = await getRequest("/api/audittrails", data);

    if (!response.error) {
      dispatch(fetchAuditTrailsSuccess(response.data, response.total));
    } else {
      dispatch(fetchAuditTrailsFail());
    }
  };
};

import React from "react";

export default ({ deg }) => (
  <div className="compass">
    <div className="direction">
      <p>
        NE<span>10 mph</span>
      </p>
    </div>
    <div
      className="arrow ne"
      style={{ transform: `rotate(${deg || "0"}deg)` }}
    />
  </div>
);

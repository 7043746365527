import {
  FETCH_ACTIVE_ALERTS_START,
  FETCH_ACTIVE_ALERTS_SUCCESS,
  FETCH_ACTIVE_ALERTS_FAIL,
  FETCH_GENERAL_ALERTS_START,
  FETCH_GENERAL_ALERTS_SUCCESS,
  FETCH_GENERAL_ALERTS_FAIL,
} from "../constants";

import { getRequest } from "utils/APIRequest";

const fetchActiveAlertsStart = () => ({
  type: FETCH_ACTIVE_ALERTS_START,
});

const fetchActiveAlertsSuccess = (data) => ({
  type: FETCH_ACTIVE_ALERTS_SUCCESS,
  data,
});

const fetchActiveAlertsFail = () => ({
  type: FETCH_ACTIVE_ALERTS_FAIL,
});

export const fetchActiveAlerts = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchActiveAlertsStart());
    const url = `/api/activealerts/index`;
    const response = await getRequest(url, data);

    if (!response.error) {
      dispatch(fetchActiveAlertsSuccess(response));
    } else {
      dispatch(fetchActiveAlertsFail());
    }
  };
};

const fetchGeneralAlertsStart = () => ({
  type: FETCH_GENERAL_ALERTS_START,
});

const fetchGeneralAlertsSuccess = (data) => ({
  type: FETCH_GENERAL_ALERTS_SUCCESS,
  data,
});

const fetchGeneralAlertsFail = () => ({
  type: FETCH_GENERAL_ALERTS_FAIL,
});

export const fetchGeneralAlerts = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchGeneralAlertsStart());

    const response = await getRequest(`/api/generalalerts`, data);
    if (!response.error) {
      dispatch(fetchGeneralAlertsSuccess(response));
    } else {
      dispatch(fetchGeneralAlertsFail());
    }
  };
};

export default {
  beltsCases: {
    maint: "#fefefe",
    idle: "#4375ef",
    schedule: "#fefefe",
    active: "#72e94d",
  },

  beltsPosition: {
    "belt-1": {
      circles: {
        cx: 164.194,
        cy: 114.694,
      },
      rect: {
        x: 151.194,
        y: 102.694,
      },
      transform: "rotate(45 200.194 114.194)",
    },
    "belt-2": {
      circles: {
        cx: 164.194,
        cy: 225.694,
      },
      rect: {
        x: 151.194,
        y: 213.694,
      },
      transform: "rotate(45 200.194 225.194)",
    },
    "belt-3": {
      circles: {
        cx: 272.194,
        cy: 114.694,
      },
      rect: {
        x: 259.194,
        y: 102.694,
      },
      transform: "rotate(45 308.194 114.194)",
    },
    "belt-4": {
      circles: {
        cx: 272.194,
        cy: 225.694,
      },
      rect: {
        x: 259.194,
        y: 213.694,
      },
      transform: "rotate(45 308.194 225.194)",
    },
    "belt-5": {
      circles: {
        cx: 371.194,
        cy: 114.694,
      },
      rect: {
        x: 358.194,
        y: 102.694,
      },
      transform: "rotate(45 407.194 114.194)",
    },
    "belt-6": {
      circles: {
        cx: 373.194,
        cy: 225.694,
      },
      rect: {
        x: 360.194,
        y: 213.694,
      },
      transform: "rotate(45 409.194 225.194)",
    },
  },
};

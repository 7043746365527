import React from "react";
import classNames from "classnames";

export default (props) => {
  const widget = props.widgets[props.widget] || {};

  const drag = (ev) => {
    ev.dataTransfer.setData("widget", widget.permission_name);
  };

  return (
    <span
      role="presentation"
      onDrop={props.drop}
      className={classNames("widget-icon", { "dragged-item": !props.enabled })}
      draggable={!props.isSelected}
      onDragStart={drag}
    >
      <div className="widget-icon-container">
        {!props.isSelected && (
          <i
            style={{ background: widget.style.color }}
            role="presentation"
            className={`fa fa-${widget.style.icon}`}
          />
        )}
      </div>

      {!props.isSelected && (
        <span role="presentation" className="widget-alias">
          {widget.label}
        </span>
      )}
    </span>
  );
};

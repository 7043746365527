import React from "react";
import { Tooltip, actions as reduxToolTipActions } from "react-redux-tooltip";
import moment from "moment-timezone";
import { convertTimeZoneWithFormat } from "utils/helpers";
export default class BlockedAreaInfo extends React.PureComponent {
  constructor() {
    super();
    this.editBlockedArea = this.editBlockedArea.bind(this);
    this.removeBlockedArea = this.removeBlockedArea.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  handleHover(name) {
    reduxToolTipActions.keep(name);
  }

  handleLeave(name) {
    reduxToolTipActions.hide(name);
  }

  removeBlockedArea() {
    this.props.deleteBlockedArea({ id: this.props.dataId });
  }

  editBlockedArea() {
    const blockInfo = {};
    blockInfo.id = this.props.dataId;
    blockInfo.note = this.props.note;
    blockInfo.color = this.props.color;
    blockInfo.x = this.props.x;
    blockInfo.y = this.props.y;
    blockInfo.width = this.props.width;
    blockInfo.height = this.props.height;
    blockInfo.bottomRight = this.props.bottomRight;
    blockInfo.topLeft = this.props.topLeft;
    this.props.editBlockedArea(blockInfo);
  }

  formatDate(time) {
    const momentObj = moment.utc(time);
    const dateString = momentObj.format("YYYY-MM-DD hh:mm A");
    return dateString;
  }

  render() {
    const smallScreen = window.matchMedia("(max-width: 992px)").matches;
    return (
      <Tooltip
        id={`block${this.props.dataId}-tooltip`}
        className="tooltip blocked-area-tooltip"
        name={`main-block${this.props.dataId}`}
        onHover={this.handleHover(`block${this.props.dataId}`)}
        onLeave={this.handleLeave(`block${this.props.dataId}`)}
        onClick={this.handleLeave(`block${this.props.dataId}`)}
      >
        <div>
          <div>{this.props.status}</div>
          <hr />
          <div className="blocked-area-note text">{this.props.note}</div>
          {this.props.note ? <hr /> : null}
          <div className="blocked-area-note time">
            <div>
              From:{" "}
              {this.props.startDate && this.props.timeZone !== "utc"
                ? convertTimeZoneWithFormat({
                    time: this.props.startDate,
                    timeZone: this.props.timeZone,
                    requiredFormat: "MM/DD/YYYY hh:mm",
                  })
                : this.formatDate(this.props.startDate)}
            </div>
            <div>
              To:{" "}
              {this.props.endDate && this.props.timeZone !== "utc"
                ? convertTimeZoneWithFormat({
                    time: this.props.endDate,
                    timeZone: this.props.timeZone,
                    requiredFormat: "MM/DD/YYYY hh:mm",
                  })
                : this.formatDate(this.props.endDate)}
            </div>
          </div>
          {!smallScreen ? (
            <div>
              <br />
              <hr className="edit-strash-splitter" />
              <span className="trash-edit-container">
                {this.props.canDelete ? (
                  <i
                    aria-hidden="true"
                    role="presentation"
                    className="fa fa-trash"
                    onClick={this.removeBlockedArea}
                  />
                ) : null}
                {this.props.canEdit ? (
                  <i
                    aria-hidden="true"
                    role="presentation"
                    className="fa fa-edit"
                    onClick={this.editBlockedArea}
                  />
                ) : null}
              </span>
            </div>
          ) : null}
        </div>
      </Tooltip>
    );
  }
}

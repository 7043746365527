import { createContext, useEffect, useState } from "react";
import { widgets } from "../components/common/widget";
import { isAuthorized } from "utils/helpers";
import { useSelector } from "react-redux";

export const AuthContext = createContext();

export const AuthContexProvider = ({ children }) => {
  const [widgetToView, setWidgetToView] = useState([]);
  const { auth } = useSelector((state) => state);

  const checkCanView = (name) => {
    const { canView } = isAuthorized(auth?.user?.roles, name);
    return canView;
  };

  useEffect(() => {
    const widgetToView = widgets.filter(({ permission_name }) =>
      checkCanView(permission_name),
    );

    setWidgetToView(widgetToView);
  }, [auth]);

  return (
    <AuthContext.Provider value={{ widgetToView }}>
      {children}
    </AuthContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Time from "components/common/TimePicker";
import { btnText, timeZones } from "utils/helpers";
import moment from "moment-timezone";
import UiDatePicker from "components/common/date/date";

const DateTimePicker = ({
  value,
  handleConfirm,
  handleSave,
  fieldName,
  data,
  errorMessageUpdate,
  errorMessage,
}) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [btnAction, setBtnAction] = useState("");
  const { timeZone } = useSelector((state) => state.SystemParams);

  const MILLISECONDS_PER_DAY = 60 * 60 * 24 * 1000;

  useEffect(() => {
    setTime(moment(value).tz(timeZone).format("HH:mm"));
    setDate(moment(value).tz(timeZone).format("MM/DD/YYYY"));
  }, [value]);

  const handleTime = (e) => {
    setTime(e.format("HH:mm"));
  };

  const handleDate = (date) => {
    setDate(moment(date).format("MM/DD/YYYY"));
  };

  const formatValue = (dateToFormat, timeToFormat) => {
    const dateAfterFormat = moment(dateToFormat || date, ["MM/DD/YYYY"]).format(
      "YYYY-MM-DD",
    );
    const timeAfterFormat =
      timeToFormat || moment(value).tz(timeZone).format("HH:mm");
    const fullTime = `${dateAfterFormat} ${timeAfterFormat}`;
    const timeWithTimeZone =
      timeZone === timeZones.UTC ? `${fullTime}Z` : fullTime;
    return timeWithTimeZone;
  };

  const save = () => {
    const value = formatValue(date, time);
    return handleSave(value, fieldName);
  };

  const confirm = () => {
    const value = formatValue(date, time);
    return handleConfirm(value, fieldName);
  };

  const handleBtnAction = () => {
    const oldValue = formatValue();
    const newValue = formatValue(date, time);
    const changed = oldValue !== newValue;
    if (!changed && fieldName === "tobt") {
      return setBtnAction(btnText.CONFIRM);
    }
    return setBtnAction(data.okBtnVal || btnText.SAVE);
  };

  useEffect(() => {
    handleBtnAction();
  }, [time, fieldName, date]);

  return (
    <>
      <div className="container-date">
        <div className="container-time" data-disabled={!!errorMessage}>
          <i
            className="fa-regular fa-clock date-input-icon"
            data-disabled={!!errorMessage}
          ></i>
          <label className="time-label" data-disabled={!!errorMessage}>
            Time
          </label>
          <Time
            handleTime={handleTime}
            value={time}
            disabled={!!errorMessage}
          />
        </div>
        <div className="date-filter-wrapper" data-disabled={!!errorMessage}>
          <UiDatePicker
            label="Date"
            value={date || ""}
            handleChange={handleDate}
            disableDays={true}
            minDate={new Date().getTime() - MILLISECONDS_PER_DAY}
            maxDate={new Date().getTime() + MILLISECONDS_PER_DAY}
            disabled={!!errorMessage}
          />
        </div>
      </div>
      <div className="error-text">
        {(errorMessage || errorMessageUpdate) && (
          <span>{errorMessage || errorMessageUpdate}</span>
        )}
      </div>
      <div className="container-btn">
        <button
          onClick={btnAction === btnText.CONFIRM ? confirm : save}
          className="btn-submit"
          data-disabled-btn={!!errorMessage}
        >
          {btnAction}
        </button>
      </div>
    </>
  );
};

export default DateTimePicker;

import { updateObject } from "./utility";
import {
  CHANGE_LAYOUT,
  REINIT_LAYOUTS,
  FETCH_LAYOUTS_START,
  FETCH_LAYOUTS_SUCCESS,
  FETCH_LAYOUTS_FAIL,
  SAVE_USER_LAYOUT_START,
  SAVE_USER_LAYOUT_SUCCESS,
  SAVE_USER_LAYOUT_FAIL,
  UPDATE_FAVORITE_LAYOUT_START,
  UPDATE_FAVORITE_LAYOUT_SUCCESS,
  UPDATE_FAVORITE_LAYOUT_FAIL,
  UPDATE_USER_LAYOUT_CONFIG_START,
  UPDATE_USER_LAYOUT_CONFIG_SUCCESS,
  UPDATE_USER_LAYOUT_CONFIG_FAIL,
  REINIT_SELECTED_LAYOUT,
} from "../constants";

const initialState = {
  layouts: [],
  layout: { alias: "Single layout", id: 1, direction: "row" },
  loading: false,
  total: 0,
  activeFields: {},
};
const clearLayouts = (layouts) => {
  return layouts.map((ele) => {
    if (ele.user_role_id) {
      return ele;
    }
    for (const key in ele.layout_config) {
      ele.layout_config[key].content = "";
    }

    return ele;
  });
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_LAYOUTS:
      return updateObject(state, initialState);

    case FETCH_LAYOUTS_START:
      return updateObject(state, { loading: true });
    case FETCH_LAYOUTS_SUCCESS:
      return updateObject(state, { loading: false, layouts: action.data });
    case FETCH_LAYOUTS_FAIL:
      return updateObject(state, { loading: false });

    case SAVE_USER_LAYOUT_START:
      return updateObject(state, { loading: true });
    case SAVE_USER_LAYOUT_SUCCESS:
      return updateObject(state, {
        loading: false,
        layouts: [...state.layouts, action.data],
      });
    case SAVE_USER_LAYOUT_FAIL:
      return updateObject(state, { loading: false });

    case UPDATE_USER_LAYOUT_CONFIG_START:
      return updateObject(state, { loading: true });
    case UPDATE_USER_LAYOUT_CONFIG_SUCCESS:
      return updateObject(state, { loading: false });
    case UPDATE_USER_LAYOUT_CONFIG_FAIL:
      return updateObject(state, { loading: false });

    case UPDATE_FAVORITE_LAYOUT_START:
      return updateObject(state, { loading: true });
    case UPDATE_FAVORITE_LAYOUT_SUCCESS:
      return updateObject(state, {
        loading: false,
        layouts: [
          ...state.layouts.filter((ele) => !ele.user_role_id),
          ...action.data.filter((ele) => !ele.deleted),
        ],
      });
    case UPDATE_FAVORITE_LAYOUT_FAIL:
      return updateObject(state, { loading: false });

    case CHANGE_LAYOUT:
      return updateObject(state, {
        layouts: clearLayouts(state.layouts),
        layout: action.data,
      });

    case REINIT_SELECTED_LAYOUT:
      return updateObject(state, { layout: initialState.layout });

    default:
      return state;
  }
};

export default layoutReducer;

import React, { Component } from "react";

class Marker extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const sensor = e.target.dataset.name;
    this.props.onClick(this.props.name, sensor);
  }
  render() {
    switch (this.props.mode) {
      case "green": {
        if (this.props.type === "air") {
          if (this.props.currenFilter === "noise") {
            return null;
          }
          return (
            <div className="marker" data-name="air1" onClick={this.handleClick}>
              <img
                data-name="air1"
                src={`${process.env.REACT_APP_ASSETS_PATH}/images/ems/green-air.png`}
              />
            </div>
          );
        } else if (
          !this.props.currenFilter ||
          this.props.currenFilter == "noise"
        ) {
          return (
            <div className="marker" onClick={this.handleClick}>
              <img
                src={`${process.env.REACT_APP_ASSETS_PATH}/images/ems/green-noise.png`}
              />
            </div>
          );
        }
      }
      case "red": {
        if (this.props.type === "air") {
          if (this.props.currenFilter === "noise") {
            return null;
          }
          return (
            <div className="marker" data-name="air1" onClick={this.handleClick}>
              <img
                data-name="air1"
                src={`${process.env.REACT_APP_ASSETS_PATH}/images/ems/red-air.png`}
              />
            </div>
          );
        } else if (
          !this.props.currenFilter ||
          this.props.currenFilter == "noise"
        ) {
          return (
            <div
              className="marker"
              data-name="noise2"
              onClick={this.handleClick}
            >
              <img
                data-name="noise2"
                src={`${process.env.REACT_APP_ASSETS_PATH}/images/ems/red-noise.png`}
              />
            </div>
          );
        }
        return null;
      }
      case "yellow": {
        if (this.props.type === "air") {
          if (this.props.currenFilter === "noise") {
            return null;
          }
          return (
            <div className="marker" data-name="air2" onClick={this.handleClick}>
              <img
                data-name="air2"
                src={`${process.env.REACT_APP_ASSETS_PATH}/images/ems/yellow-air.png`}
              />
            </div>
          );
        } else if (
          !this.props.currenFilter ||
          this.props.currenFilter == "noise"
        ) {
          return (
            <div
              className="marker"
              data-name="noise1"
              onClick={this.handleClick}
            >
              <img
                data-name="noise1"
                src={`${process.env.REACT_APP_ASSETS_PATH}/images/ems/yellow-noise.png`}
              />
            </div>
          );
        }
        return null;
      }
    }
  }
}

export default Marker;

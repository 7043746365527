import {
  FETCH_ALERTS_START,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_FAIL,
  RESTORE_ALERTS_START,
  RESTORE_ALERTS_SUCCESS,
  RESTORE_ALERTS_FAIL,
  SUCCESS_MESSAGE,
  DELETE_ALERTS_START,
  DELETE_ALERTS_SUCCESS,
  DELETE_ALERTS_FAIL,
  CREATE_ALERT_START,
  CREATE_ALERT_SUCCESS,
  CREATE_ALERT_FAIL,
  UPDATE_ALERT_START,
  UPDATE_ALERT_SUCCESS,
  UPDATE_ALERT_FAIL,
  ERROR_MESSAGE,
  FETCH_ALERT_START,
  FETCH_ALERT_SUCCESS,
  FETCH_ALERT_FAIL,
  CHECK_QUERY_START,
  CHECK_QUERY_SUCCESS,
  CHECK_QUERY_FAIL,
} from "../constants";

import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/APIRequest";

const fetchAlertsStart = () => ({
  type: FETCH_ALERTS_START,
});

const fetchAlertsSuccess = (data, total) => ({
  type: FETCH_ALERTS_SUCCESS,
  data,
  total,
});

const fetchAlertsFail = () => ({
  type: FETCH_ALERTS_FAIL,
});

export const fetchAlerts = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchAlertsStart());

    const response = await getRequest(`/api/alerts`, data);

    if (!response.error) {
      dispatch(fetchAlertsSuccess(response.data, response.total));
    } else {
      dispatch(fetchAlertsFail());
    }
  };
};

const restoreAlertsStart = () => ({
  type: RESTORE_ALERTS_START,
});

const restoreAlertsSuccess = (data) => ({
  type: RESTORE_ALERTS_SUCCESS,
  data,
});

const restoreAlertsFail = () => ({
  type: RESTORE_ALERTS_FAIL,
});

export const restoreAlerts = (dataIds = [], cb) => {
  return async (dispatch) => {
    dispatch(restoreAlertsStart());

    const response = await putRequest("/api/alerts/restore/multi", {
      ids: dataIds,
    });

    if (!response.error) {
      dispatch({ type: SUCCESS_MESSAGE, message: response.message });
      dispatch(restoreAlertsSuccess(dataIds));
      cb && cb();
    } else {
      dispatch(restoreAlertsFail());
    }
  };
};

const deleteAlertsStart = () => ({
  type: DELETE_ALERTS_START,
});

const deleteAlertsSuccess = (data) => ({
  type: DELETE_ALERTS_SUCCESS,
  data,
});

const deleteAlertsFail = () => ({
  type: DELETE_ALERTS_FAIL,
});

export const deleteAlerts = (dataIds = [], cb) => {
  return async (dispatch) => {
    dispatch(deleteAlertsStart());

    const response = await deleteRequest("/api/alerts", {
      ids: dataIds,
    });

    if (!response.error) {
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "Delete records successfully",
      });
      dispatch(deleteAlertsSuccess(dataIds));
      cb && cb();
    } else {
      dispatch(deleteAlertsFail());
    }
  };
};

const createAlertStart = () => ({
  type: CREATE_ALERT_START,
});

const createAlertSuccess = (data) => ({
  type: CREATE_ALERT_SUCCESS,
  data,
});

const createAlertFail = () => ({
  type: CREATE_ALERT_FAIL,
});

export const createAlert = (data, cb) => {
  return async (dispatch) => {
    dispatch(createAlertStart());

    const response = await postRequest("/api/alerts", data);

    if (!response.error) {
      dispatch({
        type: SUCCESS_MESSAGE,
        message: response.message || "Create alert successfully",
        back: true,
      });
      dispatch(createAlertSuccess(response));
      cb && cb();
    } else {
      dispatch({
        type: ERROR_MESSAGE,
        message: response.message || "Create alert failed",
      });
      dispatch(createAlertFail());
    }
  };
};

const updateAlertStart = () => ({
  type: UPDATE_ALERT_START,
});

const updateAlertSuccess = (data) => ({
  type: UPDATE_ALERT_SUCCESS,
  data,
});

const updateAlertFail = () => ({
  type: UPDATE_ALERT_FAIL,
});

export const updateAlert = (dataId, data, cb) => {
  return async (dispatch) => {
    dispatch(updateAlertStart());

    const response = await putRequest(`/api/alerts/${dataId}`, data);

    if (!response.error) {
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "Update alert successfully",
        back: data.edit,
      });
      dispatch(updateAlertSuccess(response.data));
      cb && cb();
    } else {
      dispatch({
        type: ERROR_MESSAGE,
        message: "Update alert failed",
      });
      dispatch(updateAlertFail());
    }
  };
};

const fetchAlertStart = () => ({
  type: FETCH_ALERT_START,
});

const fetchAlertSuccess = (data) => ({
  type: FETCH_ALERT_SUCCESS,
  data,
});

const fetchAlertFail = () => ({
  type: FETCH_ALERT_FAIL,
});

export const fetchAlert = (dataId) => {
  return async (dispatch) => {
    dispatch(fetchAlertStart());

    const response = await getRequest(`/api/alerts/${dataId}`);

    if (!response.error) {
      dispatch(fetchAlertSuccess(response));
    } else {
      dispatch(fetchAlertFail());
    }
  };
};

const checkQueryStart = () => ({
  type: CHECK_QUERY_START,
});

const checkQuerySuccess = (data) => ({
  type: CHECK_QUERY_SUCCESS,
  data,
});

const checkQueryFail = () => ({
  type: CHECK_QUERY_FAIL,
});

export const checkAlertQuery = (data, cb) => {
  return async (dispatch) => {
    dispatch(checkQueryStart());

    const response = await postRequest(`/api/alerts/checkquery`, data);

    if (!response.error) {
      dispatch(checkQuerySuccess(response.query));
      cb && cb(response.query);
    } else {
      dispatch(checkQueryFail());
    }
  };
};

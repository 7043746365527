import React from "react";
import WeatherEntry from "./weather_entry";
import WindEntry from "./wind_entry";
import WindProfile from "./wind_profile";
import RunwayVisualRange from "./runway_visual_range";
import { pressuredata, winddata, rvrdata } from "./assets/data/constant";

const WeatherTap = ({ data }) => {
  const { general, pressure, wind, runway_visual_range } = data;
  const { temperature, relative_humidity, dew_point } = general || {};
  const { qnh, qfe } = pressure || {};
  const { wind_dir, gust_speed, gust_dir, wind_speed, min_speed, max_speed } =
    wind || {};

  return (
    <div className="atcw-body">
      <div className="pressure-container" data-layout="col">
        <WeatherEntry
          title="Temperature"
          icon="thermometer-full"
          value={temperature}
          unit="°C"
        />
        <WeatherEntry
          display={pressuredata.rh || {}}
          title="Relative Humidity"
          icon="tint"
          value={relative_humidity}
          unit="%"
        />
        <WeatherEntry
          display={pressuredata.dew_point || {}}
          title="Dew Point"
          icon="tint"
          value={dew_point}
          unit="°C"
        />
        <WeatherEntry
          display={pressuredata.pressure_qnh || {}}
          title="Barometric Pressure qnh"
          icon="forumbee"
          value={qnh}
          unit="hPa"
        />
        <WeatherEntry
          display={pressuredata.pressure_qfe || {}}
          title="Barometric Pressure qfe"
          icon="forumbee"
          value={qfe}
          unit="hPa"
        />
      </div>

      <div className="wind-container" data-layout="col">
        <WindEntry
          display={winddata || {}}
          title="Wind"
          wind_dir={wind_dir}
          gust_dir={gust_dir}
          gust_speed={gust_speed}
          min_speed={min_speed}
          max_speed={max_speed}
          wind_speed={wind_speed}
          unit="KT"
        />
        <WindProfile
          display={winddata || {}}
          title="Wind Profile"
          icon="flag-o"
          value={{
            4000: "320/25",
            3000: "///",
            2000: "320/20",
            1000: "320/15",
          }}
          unit="°TRUE"
        />
      </div>
      <div className="cloud-container" data-layout="col">
        <RunwayVisualRange
          display={rvrdata || {}}
          title="Runway Visual Range"
          icon="remove_red_eye"
          data={runway_visual_range}
          unit="M"
        />
      </div>
    </div>
  );
};

export default WeatherTap;

import React, { useState } from "react";
import Map from "./map";
import ChartWrapper from "./chart_wrapper";
import "./assets/style/app.css";
import { useSelector } from "react-redux";

const EMS = () => {
  const [name, setName] = useState("");
  const [sensor, setSensor] = useState(null);
  const [showAirFilter, setShowAirFilter] = useState(false);
  const [currenFilter, setCurrenFilter] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [variable, setVariable] = useState("");

  const { timeZone } = useSelector((state) => state);

  const handleName = (name, sensor) => {
    const chart = document.querySelector(".chart");
    chart.style.visibility = "hidden";
    setName(name);
    setSensor(sensor);
  };

  const handleFilter = (e) => {
    const filterby = e.target.dataset.name;
    if (filterby === "noise") {
      setShowAirFilter(!showAirFilter);
    }
    const updateFilter = currenFilter === filterby ? null : filterby;
    setCurrenFilter(updateFilter);
    setSelectedFilter(filterby);
  };

  const renderFilter = () => {
    return (
      <>
        <div className={`air-filter-wrapper ${showAirFilter ? "active" : ""}`}>
          <div
            data-name="air-quality"
            className="filter"
            onClick={(e) => {
              handleFilter(e);
            }}
          >
            <span>
              <i
                aria-hidden="true"
                className="air-filter-icon fa fa-wind"
                data-name="air-quality"
              ></i>
            </span>
            <span data-name="air-quality">Air quality</span>
            <span className="date-value" data-name="air-quality">
              <i
                aria-hidden="true"
                className="fa fa-sort-down"
                data-name="air-quality"
              ></i>
            </span>
          </div>

          {showAirFilter && (
            <div className="air-filter-drop">
              <div className="air-filter-child" data-name="NO2">
                <input
                  id="NO2"
                  type="radio"
                  name="roles"
                  value="NO2"
                  data-name="NO2"
                  onChange={handleFilter}
                />
                <label htmlFor="NO2">NO2</label>
              </div>
              <div className="air-filter-child" data-name="CO2">
                <input
                  data-name="CO"
                  id="CO"
                  type="radio"
                  name="roles"
                  value="CO"
                  onChange={handleFilter}
                />
                <label htmlFor="CO">CO</label>
              </div>
              <div className="air-filter-child" data-name="O3">
                <input
                  id="O3"
                  type="radio"
                  name="roles"
                  value="O3"
                  data-name="O3"
                  onChange={handleFilter}
                />
                <label htmlFor="O3">O3</label>
              </div>
              <div className="air-filter-child" data-name="PM10">
                <input
                  id="PM10"
                  type="radio"
                  name="roles"
                  value="PM10"
                  data-name="PM10"
                  onChange={handleFilter}
                />
                <label htmlFor="PM10">PM10</label>
              </div>
              <div className="air-filter-child" data-name="PM2_5">
                <input
                  id="PM2_5"
                  type="radio"
                  name="roles"
                  value="PM2.5"
                  data-name="PM2_5"
                  onChange={handleFilter}
                />
                <label htmlFor="PM2_5">PM2.5</label>
              </div>
            </div>
          )}
        </div>

        <div
          data-name="noise"
          className={`filter ${currenFilter === "noise" ? "noise-filter" : ""}`}
          onClick={handleFilter}
        >
          <span>
            <i
              aria-hidden="true"
              className="air-filter-icon fa fa-volume-up"
              data-name="noise"
              onClick={handleFilter}
            ></i>
          </span>
          <span data-name="noise" onClick={handleFilter}>
            Noise
          </span>
          <span
            className="date-value hidden"
            data-name="noise"
            onClick={handleFilter}
          >
            <i
              aria-hidden="true"
              className="fa fa-sort-down"
              data-name="noise"
              onClick={handleFilter}
            ></i>
          </span>
        </div>
      </>
    );
  };

  return (
    <div className="ems-container">
      <Map
        handleName={handleName}
        currenFilter={currenFilter}
        selectedFilter={selectedFilter}
      />
      <ChartWrapper
        name={name}
        sensor={sensor}
        timeZone={timeZone}
        variable={variable}
      />
      <div className="ems-filter">{renderFilter()}</div>
    </div>
  );
};

export default EMS;

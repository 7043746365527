import {
  FETCH_MANUAL_UPDATES_LOGS_START,
  FETCH_MANUAL_UPDATES_LOGS_SUCCESS,
  FETCH_MANUAL_UPDATES_LOGS_FAIL,
} from "../constants";

import { getRequest } from "utils/APIRequest";

const fetchManualUpdatesLogsStart = () => ({
  type: FETCH_MANUAL_UPDATES_LOGS_START,
});

const fetchManualUpdatesLogsSuccess = (data, total) => ({
  type: FETCH_MANUAL_UPDATES_LOGS_SUCCESS,
  data,
  total,
});

const fetchManualUpdatesLogsFail = () => ({
  type: FETCH_MANUAL_UPDATES_LOGS_FAIL,
});

export const fetchManualUpdatesLogs = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchManualUpdatesLogsStart());

    const response = await getRequest("/api/manualUpdatesLogs", data);
    if (!response.error) {
      dispatch(fetchManualUpdatesLogsSuccess(response.data, response.total));
    } else {
      dispatch(fetchManualUpdatesLogsFail());
    }
  };
};

import {
  FETCH_ACDM_START,
  FETCH_ACDM_SUCCESS,
  FETCH_ACDM_FAIL,
  FETCH_OUT_BOUND_MESSAGES_START,
  FETCH_OUT_BOUND_MESSAGES_SUCCESS,
  FETCH_OUT_BOUND_MESSAGES_FAIL,
  ACDM_START_REQUEST,
  FETCH_ACDM_ACTIVE_FIELDS_SUCCESS,
  ACDM_FAILD_REQUEST,
  UPDATE_ACDM_ACTIVE_FIELDS_SUCCESS,
} from "../constants";

import { getRequest, postRequest, patchRequest } from "utils/APIRequest";

const fetchAcdmStart = () => ({
  type: FETCH_ACDM_START,
});

const fetchAcdmSuccess = (data) => ({
  type: FETCH_ACDM_SUCCESS,
  data,
});

const fetchAcdmFail = () => ({
  type: FETCH_ACDM_FAIL,
});

export const fetchAcdm = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchAcdmStart());

    const response = await getRequest("/api/acdm/index", data);

    if (!response.error) {
      dispatch(fetchAcdmSuccess(response));
    } else {
      dispatch(fetchAcdmFail());
    }
  };
};

const fetchOutBoundMessagesStart = () => ({
  type: FETCH_OUT_BOUND_MESSAGES_START,
});

const fetchOutBoundMessagesSuccess = (data) => ({
  type: FETCH_OUT_BOUND_MESSAGES_SUCCESS,
  data,
});

const fetchOutBoundMessagesFail = () => ({
  type: FETCH_OUT_BOUND_MESSAGES_FAIL,
});

export const outBoundMessages = (data = {}, cb) => {
  return async (dispatch) => {
    dispatch(fetchOutBoundMessagesStart());

    const response = await postRequest(`/api/acdm/outboundmessages?`, data);

    if (!response.error) {
      cb && cb();
      dispatch(fetchOutBoundMessagesSuccess(response));
    } else {
      dispatch(fetchOutBoundMessagesFail());
    }
  };
};

const acdmStartRequest = () => ({
  type: ACDM_START_REQUEST,
});

const fetchAcdmActiveFieldsSuccess = (data) => ({
  type: FETCH_ACDM_ACTIVE_FIELDS_SUCCESS,
  data,
});

const acdmFaildRequest = () => ({
  type: ACDM_FAILD_REQUEST,
});

export const AcdmActiveFields = (signal) => {
  return async (dispatch) => {
    dispatch(acdmStartRequest());

    const response = await getRequest("/api/users/acdmfields");

    if (!response.error) {
      dispatch(fetchAcdmActiveFieldsSuccess(response));
    } else {
      dispatch(acdmFaildRequest());
    }
  };
};

export const updateAcdmActiveFieldsSuccess = (data) => ({
  type: UPDATE_ACDM_ACTIVE_FIELDS_SUCCESS,
  data,
});

export const updateAcdmActiveFields = (data) => {
  return async (dispatch) => {
    if (!data) {
      return;
    }
    dispatch(acdmStartRequest());

    const response = await patchRequest("/api/users/acdmfields", data);

    if (!response.error) {
      dispatch(updateAcdmActiveFieldsSuccess(response));
    } else {
      dispatch(acdmFaildRequest());
    }
  };
};

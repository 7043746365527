import React from "react";
import chartOne from "./imgs/chart1.png";
import chartTow from "./imgs/chart2.png";
import chartThree from "./imgs/chart3.png";
import chartFour from "./imgs/chart4.png";
import "./style.css";

const Kpi = (props) => {
  return (
    <>
      <div className="acdm-container background">
        <div className="widget-acdm-header">
          <span className="acdm-logo" title="Alerts and Notifications">
            Key performance indicators
          </span>
        </div>
        <div className="overview-header">
          <div className="overview-heading">
            <span className="text-heading">Report</span>
          </div>
          <div className="add-icon">
            <span className="add-text">+</span>
          </div>
          <div className="layer-icon layer-icon header-icons">
            <i className="fa-solid fa-layer-group"></i>
          </div>
          <div className="overview-share">
            <div className="export">
              <span className="export-text">Export</span>
              <i className="fa-solid fa-chevron-down"></i>
            </div>
            <div className="crop">
              <i className="fa-solid fa-crop"></i>
            </div>
            <div className="share">Share</div>
            <div className="add-report">+ Add report</div>
          </div>
        </div>
        <div className="container-days">
          <div className="days">
            <div className="day-text">Today</div>
            <div className="day-text">7D</div>
            <div className="day-text">30D</div>
            <div className="day-text">3M</div>
            <div className="day-text">6M</div>
            <div className="day-text">12M</div>
          </div>
          <div className="airport-text">
            <span className="export-text">All airports</span>
            <i className="fa-solid fa-chevron-down"></i>
          </div>
        </div>
        <div className="container-kpi">
          <div className="content-view">
            <div className="view-item">
              <img src={chartOne} alt="kpi-chart" />
            </div>
            <div className="view-item">
              <img src={chartTow} alt="kpi-chart" />
            </div>
          </div>
          <div className="content-view">
            <div className="view-item">
              <img src={chartThree} alt="kpi-chart" />
            </div>
            <div className="view-item">
              <img src={chartFour} alt="kpi-chart" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Kpi;

import { statusColors } from "components/common/constant";
import React, { useEffect, useState } from "react";

const Vdgs = ({ showToolTips, toggleStandInfo, size, projection, vdgs }) => {
  const [showedTooltip, setShowedTooltip] = useState(false);

  useEffect(() => {
    const status = vdgs.status ? vdgs.status.toLowerCase() : null;
    const color = statusColors[status] || "#607099";
    toggleStandInfo(
      vdgs.stand,
      projection([vdgs.stand_location[0], vdgs.stand_location[1]]),
      color,
      true,
    );
  }, [vdgs.status]);

  const animateTooltip = (id) => {
    const block = document.querySelector(`#stand-${id}`);
    if (block) {
      const origin = offset(block);
      showToolTips({ name: `stand-${id}`, origin });
    }
  };

  const offset = (el) => {
    const rect = el.getBoundingClientRect();
    return { y: rect.top, x: rect.left + rect.width / 2 };
  };

  const showToolTip = (ev) => {
    setShowedTooltip(true);

    const { id, name, color } = ev.target.dataset;
    animateTooltip(id);
    const tooltipPosition = ev.target.getBoundingClientRect();
    toggleStandInfo(name, tooltipPosition, color, true);
  };

  const preventMouseEvents = (ev) => {
    return ev.preventDefault();
  };

  const renderVdgs = () => {
    const width = size.width < 1000 ? 4 : 8;

    if (!vdgs.stand_location) return null;
    const status = vdgs.status ? vdgs.status.toLowerCase() : null;
    const color = statusColors[status] || "#607099";
    const XYLocation = projection([
      vdgs.stand_location[0],
      vdgs.stand_location[1],
    ]);
    return (
      <g>
        <g
          name={`stand-${vdgs.id}`}
          onMouseEnter={preventMouseEvents}
          onMouseLeave={preventMouseEvents}
        >
          <rect
            id={`stand-${vdgs.id}`}
            className="stand"
            data-id={vdgs.id}
            data-name={vdgs.stand}
            data-aircraft_registration={vdgs.aircraft_registration}
            data-iata={vdgs.iata}
            data-status={vdgs.status}
            data-block_time={vdgs.block_time}
            data-estimate_block_time={vdgs.estimate_block_time}
            data-arr_dep={vdgs.arr_dep}
            data-aircraft_type={vdgs.aircraft_type}
            data-error={vdgs.error_text}
            data-color={color}
            x={XYLocation[0]}
            y={XYLocation[1]}
            rx={width}
            ry={width}
            width={width}
            height={width}
            style={{ fill: color }}
            onClick={showToolTip}
          />
        </g>
      </g>
    );
  };

  return <g className="vdgs-group">{renderVdgs()}</g>;
};

export default Vdgs;

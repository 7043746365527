import React from "react";
import pkceChallenge from "pkce-challenge";
import queryString from "query-string";
import { encryptMessage } from "utils/helpers";
import "./style.css";
import { useLocation } from "react-router-dom";

const Login = () => {
  const location = useLocation();
  const goToLogin = () => {
    const challenge = pkceChallenge();
    const { code_verifier, code_challenge } = challenge;
    const login_hint = new URLSearchParams(location.search).get("login_hint");
    const state = encryptMessage(JSON.stringify({ code_verifier }));

    const query = queryString.stringify({
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
      code_challenge: code_challenge,
      code_challenge_method: "S256",
      redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URL,
      response_type: "code",
      scope: "openid profile",
      state,
      ...(login_hint && { login_hint }),
    });

    window.location.replace(
      `${process.env.REACT_APP_KEYCLOAK_URL}auth?${query}`,
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-1-of-2 header-login">
          <div className="header-box">
            <img src="/assets/images/emma_logo.png" className="emma-logo" />
            <span className="login-label">Log In to Emma</span>
            <div className="submit-login">
              <span className="submit-login-text" onClick={goToLogin}>
                Log In to Emma
              </span>
            </div>
          </div>
        </div>

        <div className="col-1-of-2 image">
          <div className="logo">
            <img src="/assets/images/seo_logo.png" />
          </div>

          <div className="header-text-box">
            <span className="heading-primary-main">
              How does EMMA support airport?
            </span>
            <ul className="list-header">
              <li>Efficient & transparent information sharing</li>
              <li>Effective decision-making</li>
              <li>Enhanced predictability of airport operations</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAccessToken,
  setToken,
  updateAuthDetails,
} from "redux/actions/session";
import { decryptMessage } from "utils/helpers";

const ExchangeToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const changeToken = async () => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    const state = decryptMessage(queryParams.get("state"));
    const parseState = JSON.parse(state);

    let response = null;
    let redirectTo = "/";

    if (code) {
      try {
        response = await getAccessToken(code, parseState.code_verifier);
        updateAuthDetails(response);
        dispatch(setToken(response.access_token));
        redirectTo = "/me/account";
      } catch (error) {
        redirectTo = "/login";
      }
    }
    navigate(redirectTo);

    return () => {};
  };

  useEffect(changeToken, []);

  return <div>loading</div>;
};

export default ExchangeToken;

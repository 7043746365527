import React, { useState } from "react";
import styles from "./manage_layout.module.css";
import Modal from "components/common/Modal";
import LayoutListItem from "./LayoutListItem";
import SaveLayoutModal from "../SaveLayoutModal";
import { useDispatch, useSelector } from "react-redux";
import { updateFavoriteLayout } from "redux/actions";
import DeleteLayoutModal from "../DeleteLayoutModal";

const ManageLayout = ({ visible, onClose, saveLayoutConfig }) => {
  const [selectedLayout, setSelectedLayout] = useState({});
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleUpdateModal, setVisibleUpdateModal] = useState(false);
  const { layouts } = useSelector((state) => state.Layouts);
  const layoutList = layouts.filter((ele) => ele.user_role_id);
  const dispatch = useDispatch();

  const updateUserLayouts = (newLayouts, action = "Update") => {
    dispatch(
      updateFavoriteLayout(newLayouts, action, () => {
        setVisibleUpdateModal(false);
        setVisibleDeleteModal(false);
      }),
    );
  };

  const updateLayoutList = (layout) => {
    const newLayouts = [...layoutList];
    for (let index = 0; index < newLayouts.length; index++) {
      const layoutItem = newLayouts[index];
      if (layoutItem.id === layout.id) {
        newLayouts[index] = layout;
      }
    }
    return newLayouts;
  };

  const onUpdateLayout = (layout) => {
    const newList = updateLayoutList(layout);
    updateUserLayouts(newList);
  };

  const onDeleteLayout = (layout) => {
    layout.deleted = true;
    const newList = updateLayoutList(layout);
    updateUserLayouts(newList, "Delete");
  };

  const onCloseUpdateModal = () => {
    setSelectedLayout({});
    setVisibleUpdateModal(false);
  };

  const openUpdateModal = (layout) => {
    setSelectedLayout(layout);
    setVisibleUpdateModal(true);
  };

  const onCloseDeleteModal = () => {
    setSelectedLayout({});
    setVisibleDeleteModal(false);
  };

  const onOpenDeleteModal = (layout) => {
    setSelectedLayout(layout);
    setVisibleDeleteModal(true);
  };

  return (
    <>
      <SaveLayoutModal
        action="Update"
        layout={selectedLayout}
        onSave={onUpdateLayout}
        onClose={onCloseUpdateModal}
        visible={visibleUpdateModal}
      />
      <DeleteLayoutModal
        onClose={onCloseDeleteModal}
        visible={visibleDeleteModal}
        layout={selectedLayout}
        onSubmit={onDeleteLayout}
      />

      <Modal
        visible={visible}
        onClose={onClose}
        title="Manage layout"
        subTitle="You are creating a new layout for managing widgets"
      >
        <div className={styles.list_container}>
          {!layoutList.length && <div>No layouts available</div>}
          {layoutList?.map((layout, index) => (
            <LayoutListItem
              key={layout.id}
              layoutId={layout.id}
              layout={layout}
              showDivider={index !== layoutList.length - 1}
              onUpdate={openUpdateModal}
              onDelete={onOpenDeleteModal}
              saveLayoutConfig={saveLayoutConfig}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};

export default ManageLayout;

import { deleteMultiData, updateObject } from "./utility";
import {
  FETCH_AIRLINES_FAIL,
  FETCH_AIRLINES_SUCCESS,
  FETCH_AIRLINES_START,
  REINIT_AIRLINES,
  DELETE_AIRLINE_START,
  DELETE_AIRLINE_SUCCESS,
  DELETE_AIRLINE_FAIL,
  RESTORE_AIRLINE_START,
  RESTORE_AIRLINE_SUCCESS,
  RESTORE_AIRLINE_FAIL,
  RESTORE_USERS_AIRLINE_START,
  RESTORE_USERS_AIRLINE_SUCCESS,
  RESTORE_USERS_AIRLINE_FAILS,
  RESTORE_ORGANIZATION_AIRLINE_START,
  RESTORE_ORGANIZATION_AIRLINE_SUCCESS,
  RESTORE_ORGANIZATION_AIRLINE_FAILS,
  CREATE_AIRLINE_START,
  CREATE_AIRLINE_SUCCESS,
  CREATE_AIRLINE_FAIL,
  UPDATE_AIRLINE_START,
  UPDATE_AIRLINE_FAIL,
  FETCH_AIRLINE_START,
  FETCH_AIRLINE_SUCCESS,
  FETCH_AIRLINE_FAIL,
} from "../constants";

const initialState = {
  airlines: [],
  airline: {},
  total: 0,
  loading: false,
};

const airlinesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_AIRLINES:
      return updateObject(state, initialState);

    case FETCH_AIRLINES_START:
      return updateObject(state, { loading: true });
    case FETCH_AIRLINES_SUCCESS:
      return updateObject(state, {
        loading: false,
        airlines: action.data,
        total: action.total,
      });
    case FETCH_AIRLINES_FAIL:
      return updateObject(state, { loading: false });

    case DELETE_AIRLINE_START:
      return updateObject(state, { loading: true });
    case DELETE_AIRLINE_SUCCESS:
      return deleteMultiData(state, action.data, "airlines");
    case DELETE_AIRLINE_FAIL:
      return updateObject(state, { loading: false });

    case RESTORE_AIRLINE_START:
      return updateObject(state, { loading: true });
    case RESTORE_AIRLINE_SUCCESS:
      return deleteMultiData(state, action.data, "airlines");
    case RESTORE_AIRLINE_FAIL:
      return updateObject(state, { loading: false });

    case RESTORE_ORGANIZATION_AIRLINE_START:
      return updateObject(state, { loading: true });

    case RESTORE_ORGANIZATION_AIRLINE_SUCCESS:
      return deleteMultiData(state, action.data, "airlines");

    case RESTORE_ORGANIZATION_AIRLINE_FAILS:
      return updateObject((state, { loading: false }));

    case CREATE_AIRLINE_START:
      return updateObject(state, { loading: true });
    case CREATE_AIRLINE_SUCCESS:
      return updateObject(state, {
        loading: false,
        airlines: [action.data, ...state.airlines],
      });

    case RESTORE_USERS_AIRLINE_START:
      return updateObject(state, { loading: true });
    case RESTORE_USERS_AIRLINE_SUCCESS:
      return updateObject(state, { loading: false });
    case RESTORE_USERS_AIRLINE_FAILS:
      return updateObject(state, { loading: false });

    case CREATE_AIRLINE_FAIL:
      return updateObject(state, { loading: false });

    case UPDATE_AIRLINE_START:
      return updateObject(state, { loading: true });
    case UPDATE_AIRLINE_FAIL:
      return updateObject(state, { loading: false });

    case FETCH_AIRLINE_START:
      return updateObject(state, { loading: true });
    case FETCH_AIRLINE_SUCCESS:
      return updateObject(state, { loading: false, airline: action.data });
    case FETCH_AIRLINE_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default airlinesReducer;

import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CheckSelectedRoles = ({ path, children }) => {
  const { user } = useSelector((state) => state.auth);
  const selectRolesPath = "/me/account";

  if (user?.id && !user?.selected_roles?.length && path !== selectRolesPath)
    return <Navigate to={selectRolesPath} />;

  return children;
};

export default CheckSelectedRoles;

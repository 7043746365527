import React from "react";
import image from "assets/images/errors/403.png";
import ErrorPage from "./ErrorPage";

const Unauthorized = () => {
  return (
    <ErrorPage
      image={image}
      message="Restricted access"
      description="You lack permission to access this page"
    />
  );
};

export default Unauthorized;

import React from "react";
import { TextField } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Time from ".";
import moment from "moment-timezone";
import styles from "./Time.module.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& label": {
      color: "#909fba !important",
    },
    "& .MuiInputLabel-shrink":{
      top: "10px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-root > input": {
      borderBottom: "none",
    },
  },
}));

const TimePickerUi = ({
  startTime = null,
  endTime = null,
  handleStartTimeChange,
  handleEndTimeChange,
  timeError = "",
  disabled = false,
}) => {
  const classes = useStyles();

  const startTimeChange = (value) => {
    const startTime = moment(value, ["hh:mm A"]).format("hh:mm A");
    return handleStartTimeChange(startTime);
  };

  const endTimeChange = (value) => {
    const endTime = moment(value, ["hh:mm A"]).format("hh:mm A");

    return handleEndTimeChange(endTime);
  };

  const svgIcon = () => (
    <AccessTimeIcon className={disabled ? styles.darkColorSvg : ""} />
  );

  return (
    <div className={styles.wrapperTime}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div
          className={`${styles.timePickerFlex} ${
            !disabled ? styles.darkColor : ""
          }`}
        >
          <div className={styles.timeBoxFlex}>
            <div className={styles.timeHidenSelectedFlights}>
              <Time
                value={startTime ? startTime : "0"}
                handleTime={startTimeChange}
                disabled={disabled}
              />
            </div>
            {svgIcon()}
            <TimePicker
              renderInput={(props) => (
                <TextField {...props} variant="standard" />
              )}
              className={classes.root}
              label="Start time"
              readOnly
              disableOpenPicker
              value={startTime ? moment(startTime, "LT").toDate() : null}
              onChange={() => {}}
              disabled={disabled}
            />
          </div>
          <div className={styles.timeBoxFlex}>
            <div className={styles.timeHidenSelectedFlights}>
              <Time
                value={endTime ? endTime : "0"}
                handleTime={endTimeChange}
                disabled={disabled}
              />
            </div>
            {svgIcon()}
            <TimePicker
              renderInput={(props) => (
                <TextField {...props} variant="standard" />
              )}
              label="End time"
              readOnly
              disableOpenPicker
              value={endTime ? moment(endTime, "LT").toDate() : null}
              onChange={() => {}}
              disabled={disabled}
              className={classes.root}
            />
          </div>
        </div>
      </LocalizationProvider>
      {timeError && <span className={styles.timeErrorMsg}>{timeError}</span>}
    </div>
  );
};

export default TimePickerUi;

import { ACTIVATE_FLIGHT, DEACTIVATE_FLIGHT } from "../constants";

const activeFlightsReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVATE_FLIGHT:
      return action.payload;
    case DEACTIVATE_FLIGHT:
      return action.payload;
    default:
      return state;
  }
};

export default activeFlightsReducer;

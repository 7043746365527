import React, { useContext, useRef } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import copyTable from "components/common/copy_manager/table_copy";
import dataSort from "components/common/sort_manager/data_sort";
import { sortTypes } from "utils/helpers";
import dateFormat from "dateformat";
import dataExport from "components/common/export_manager/data_export";
import dataPrint from "components/common/print_manager/data_print";
import { ACDMContext } from "components/widgets/acdm/acdm_context";

const DropDown = ({ open, anchorEl, handleClose, activeList, printTable }) => {
  const ref = useRef();
  const APIContext = useContext(ACDMContext);

  const { showOrHideColumns, acdmFlights, acdmHeaderFields } = APIContext;

  const copyData = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    const ACDMTable = document.querySelector(".acdm-body");
    copyTable(ACDMTable);
    handleClose();
  };

  const exportData = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    const data = [
      [
        {
          value: activeList.alias,
          type: "string",
        },
      ],
    ];
    const grouped = [];
    const refContainer = {};
    const sorted = dataSort(acdmFlights, {
      [activeList.reference]: {
        sort_type: sortTypes.DESCEND,
      },
    });
    sorted.forEach((a) => {
      let ref;
      if (a[activeList.reference]) {
        ref = dateFormat(a[activeList.reference], "mediumDate");
      } else {
        const dates = Object.keys(refContainer).map((e) =>
          new Date(e).getTime(),
        );
        if (dates.length) {
          const maxDate = Math.max(dates);
          ref = dateFormat(maxDate, "mediumDate");
        } else {
          ref = "N/A";
        }
      }
      refContainer[ref] || grouped.push((refContainer[ref] = []));
      refContainer[ref].push(a);
    }, Object.create(null));

    grouped.map((g) => {
      const records = [];
      let headers = [];
      data.push([
        {
          value: "",
          type: "string",
        },
      ]);
      data.push([
        {
          value: "Date",
          type: "string",
        },
        {
          value: g[0][activeList.reference]
            ? dateFormat(g[0][activeList.reference], "mediumDate")
            : "N/A",
          type: "string",
        },
      ]);

      g.map((flight) => {
        const record = [];
        headers = [];
        acdmHeaderFields
          .filter((e) => e.list.includes(activeList.name))
          .map((header) => {
            header.accuracy.map((acc) => {
              headers.push({
                value: acc.title,
                type: "string",
              });
              let value = "";
              if (header.type === "date") {
                if (flight[acc.name]) {
                  value = dateFormat(flight[acc.name], "HH:MM", false);
                } else {
                  value = "";
                }
              } else {
                value = flight[acc.name];
              }

              record.push({
                value: value != null ? value.toString() : "N/A",
                type: "string",
              });
            });
          });
        records.push(record);
      });
      data.push(headers);
      records.map((r) => data.push(r));
    });

    dataExport(`EMMA_${dateFormat(new Date(), "mediumDate")}`, data);
    handleClose();
  };

  const printData = (ev) => {
    dataPrint(printTable.nameTable, printTable.tabel);
    handleClose();
  };

  return (
    <div ref={ref}>
      <Menu
        container={ref.current}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiPaper-root": {
            background: "#070B0F",
            color: "white",
            width: "248px",
            borderRadius: "20px",
            marginTop: "4px",
            marginLeft: "33px",
          },
        }}
      >
        <MenuItem onClick={showOrHideColumns}>
          <div className="list" onClick={handleClose}>
            <i className="show-hide-column-icon width-icon"></i>
            <span className="icon-text"> Show / hide columns</span>
          </div>
        </MenuItem>
        <MenuItem onClick={exportData} onClose={handleClose}>
          <div className="list">
            <i className="export-icon width-icon"></i>
            <span className="icon-text"> Export as excel</span>
          </div>
        </MenuItem>
        <MenuItem onClick={printData}>
          <div className="list">
            <i className="print-icon width-icon"></i>
            <span className="icon-text"> Print</span>
          </div>
        </MenuItem>
        <MenuItem onClick={copyData}>
          <div className="list">
            <i className="fa-solid fa-copy width-icon"></i>
            <span className="icon-text"> Copy</span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DropDown;

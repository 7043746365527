import React, { useRef } from "react";

const BlockedArea = ({ block, projection, toggleRectInfo, showToolTips }) => {
  const ref = useRef();

  const animateTooltip = (blockId) => {
    if (ref.current) {
      const origin = offset(ref.current);
      showToolTips({ name: `main-block${blockId}`, origin });
    }
  };

  const offset = (el) => {
    const rect = el.getBoundingClientRect();
    return { y: rect.top, x: rect.left + rect.width / 2 };
  };

  const handelMouseEnter = (ev) => {
    animateTooltip(ev.target.dataset.id);
    toggleRectInfo(ev, true);
  };

  const getAllBlockedArea = () => {
    if (block.dimension) {
      const XYLocation = projection([block.dimension.x1, block.dimension.y1]);
      const XYLocation2 = projection([block.dimension.x2, block.dimension.y2]);
      return (
        <g>
          <svg name={`main-block${block.id}`} delay={2000}>
            <rect
              ref={ref}
              id={`main-block${block.id}`}
              className="airport_blocked_area"
              data-id={block.id}
              data-note={block.note}
              x={
                XYLocation[0] < XYLocation2[0] ? XYLocation[0] : XYLocation2[0]
              }
              y={
                XYLocation[1] < XYLocation2[1] ? XYLocation[1] : XYLocation2[1]
              }
              width={
                XYLocation2[0] - XYLocation[0] > 0
                  ? XYLocation2[0] - XYLocation[0]
                  : XYLocation[0] - XYLocation2[0]
              }
              height={
                XYLocation2[1] - XYLocation[1] > 0
                  ? XYLocation2[1] - XYLocation[1]
                  : XYLocation[1] - XYLocation2[1]
              }
              style={{ fill: block.color }}
              data-startdate={block.start_date}
              data-enddate={block.end_date}
              data-status={block.status}
              onMouseEnter={handelMouseEnter}
            />
          </svg>
        </g>
      );
    }
    return [];
  };

  return <g className="blocked-areas-group">{getAllBlockedArea()}</g>;
};

export default BlockedArea;

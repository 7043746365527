import React from "react";
import { convertTimeZoneWithFormat, isAuthorized } from "../../utils/helpers";
import { useSelector } from "react-redux";
import GeneralButton from "./Button";

const AlertLog = (props) => {
  const { canCreate } = isAuthorized(props.roles, "alerts");
  const handleClose = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    return props.hideAlerts();
  };
  const { timeZone } = useSelector((state) => state.SystemParams);
  return (
    <div className="alerts-log-overlay">
      <div className="alerts-log">
        <div className="container-alerts-log">
          <div className="madal-alert-header">
            <span className="alerts-log-header">Alerts</span>
            <span
              tabIndex="0"
              role="button"
              data-confirm="no"
              onKeyDown={handleClose}
              onClick={handleClose}
              className="modal-close"
            >
              &times;
            </span>
          </div>
          <div className="alert-action">
            <span className="header-action-text">All</span>
            {canCreate ? (
              <div className="add-new-alert">
                <GeneralButton
                  onClick={() => props.addNewAlert()}
                  type="primary"
                >
                  Add new alert +
                </GeneralButton>
              </div>
            ) : null}
          </div>
          <div className="alerts-log-body">
            {Object.values(props.alerts)
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((alert, i) => (
                <div className="main-alert" key={i}>
                  <div className="border"></div>
                  <div className="alert-time">
                    {convertTimeZoneWithFormat({
                      time: alert.created_at,
                      timeZone,
                      requiredFormat: "DD MMM, HH:mm",
                    })}
                  </div>
                  <div className="alert-header">
                    <div>{`${alert.alert_code} / ${alert.flight_number}`}</div>
                    <div className={`alert-level ${alert.level.toLowerCase()}`}>
                      {alert.level || "Low"}
                    </div>
                  </div>
                  <div className="alert-body">
                    <span className="alert-time">Aircraft Reg:</span>
                    <div className="aircraft-reg">
                      {alert.aircraft_registration}
                    </div>
                    <span className="alert-time">Description:</span>
                    <div className="alert-message">
                      {alert.alert_description.length >= 40
                        ? `${alert.alert_description.substring(0, 40)}..`
                        : alert.alert_description}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AlertLog;

import React from "react";
import {
  FormControl,
  FormControlLabel,
  Switch as MuiSwitch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  iosSwitch: {
    width: 38,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#1976d2",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16,
      height: 16,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
    },
  },
}));

export default function Switch(props) {
  const { name, label, value, onChange } = props;

  const classes = useStyles();
  return (
    <FormControl>
      <FormControlLabel
        className={classes.lable}
        control={
          <MuiSwitch
            className={classes.iosSwitch}
            sx={{ m: 2 }}
            name={name}
            checked={value}
            onChange={onChange}
          />
        }
        label={label}
        value="start"
        labelPlacement="end"
      />
    </FormControl>
  );
}

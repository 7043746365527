import _ from "lodash";
import { updateObject } from "./utility";
import {
  FETCH_APM_FAIL,
  FETCH_APM_SUCCESS,
  FETCH_APM_START,
  REINIT_APM,
} from "../constants";

const initialState = {
  apmList: [],
  loading: false,
  total: 0,
};

const apmReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_APM:
      return updateObject(state, initialState);

    case FETCH_APM_START:
      return updateObject(state, { loading: true });
    case FETCH_APM_SUCCESS:
      return updateObject(state, { loading: false, apmList: action.data });
    case FETCH_APM_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default apmReducer;

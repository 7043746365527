import React, { useEffect, useState } from "react";
import Chart from "./chart";
import getData from "./data";
import UiDatePicker from "components/common/date/date";

const ChartWrapper = ({ variable, dataChart, sensor, name }) => {
  const [variables, setVariables] = useState("");
  const [chartData, setChartData] = useState([]);
  const [date, setDate] = useState({});

  useEffect(() => {
    setVariables(variable);
    setChartData(dataChart);
    setDate({
      startDate: new Date().getTime(),
      endDate: new Date().getTime() + 3600 * 24 * 1000,
    });
  }, []);

  const selectVariable = (e) => {
    const variableName = e.target.dataset.name;
    const chartData = getData(variableName, sensor);
    const chart = document.querySelector(".chart");
    chart.style.visibility = "visible";
    setVariables(variableName);
    setChartData(chartData);

    const old = document.querySelector(".active-variable5");
    if (old) {
      old.classList.remove("active-variable5");
    }
    const active = document.querySelector(`.${variableName}`);
    active.classList.add("active-variable5");
  };

  const renderVariables = () => {
    if (sensor === "air1" || sensor === "air2") {
      return (
        <>
          <div
            data-name="NO2"
            className={`NO2 ${variables === "NO2" ? "active-variable5" : ""}`}
            onClick={selectVariable}
          >
            <span data-name="NO2">NO2</span>
          </div>
          <div
            data-name="CO"
            className={`CO ${variables === "CO" ? "active-variable5" : ""}`}
            onClick={selectVariable}
          >
            <span data-name="CO">CO</span>
          </div>
          <div
            data-name="O3"
            className={`O3 ${variables === "O3" ? "active-variable5" : ""}`}
            onClick={selectVariable}
          >
            <span data-name="O3">O3</span>
          </div>
          <div
            data-name="PM10"
            className={`PM10 ${variables === "PM10" ? "active-variable5" : ""}`}
            onClick={selectVariable}
          >
            <span data-name="PM10">PM10</span>
          </div>
          <div
            data-name="PM2_5"
            className={`PM2_5 ${
              variables === "PM2_5" ? "active-variable5" : ""
            }`}
            onClick={selectVariable}
          >
            <span data-name="PM2_5">PM2.5</span>
          </div>
        </>
      );
    }
    return (
      <div
        data-name="noise"
        className={`noise ${variables === "noise" ? "active-variable5" : ""}`}
        onClick={selectVariable}
      >
        <span data-name="noise">Noise</span>
      </div>
    );
  };

  const hideChart = () => {
    const chart = document.querySelector(".chart-wrapper");
    const filter = document.querySelector(".ems-filter");
    filter.style.display = "block";
    chart.style.display = "none";
  };

  const handleStartValue = (value) => {
    setDate({ ...date, startDate: value });
  };

  const handleEndValue = (value) => {
    setDate({ ...date, endDate: value });
  };

  const container = document.querySelector(".ems-container");
  const smallWindow = container && container.offsetWidth < 992;
  return (
    <div className={`chart-wrapper ${smallWindow ? "small" : ""}`}>
      <div className="ems-close-btn" role="button" onClick={hideChart}>
        ×
      </div>
      <div className="name-date">
        <div className="ems-name">{name}</div>

        <div className="date-filter-wrapper mr-top">
          <UiDatePicker
            label="Start"
            value={date?.startDate || ""}
            handleChange={handleStartValue}
          />
          <UiDatePicker
            label="End"
            value={date?.endDate || ""}
            handleChange={handleEndValue}
          />
        </div>
      </div>

      <div className={`chart-variables ${smallWindow ? "small" : ""}`}>
        <div className={`variables ${smallWindow ? "small" : ""}`}>
          {renderVariables()}
        </div>
        <div className="chart">
          <Chart chartData={chartData} variable={variables} />
        </div>
      </div>
    </div>
  );
};
export default ChartWrapper;

import React from "react";

const BHSSvg = ({ children }) => {
  return (
    <svg
      className="bhs-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="34 35 483 301"
    >
      <defs>
        <pattern
          id="pattern"
          width="15"
          height="10"
          patternUnits="userSpaceOnUse"
          fill="black"
        >
          <line stroke="#7c1f12" strokeWidth="13px" y2="10" />
        </pattern>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 -1)">
        <path
          fill="#1A1A1C"
          d="M26.455.959L13.487 23.194l36.829 29.991L0 52.668v76.012h5.187v26.89H582V57.321L562.548 37.93h-14.783L513.01 10.266 501.599 25.78l31.123 23.786h-33.717l-7.262-7.239H118.786l-11.93 6.205H78.845z"
        />
        <path
          fill="#2D2C30"
          d="M2.4 162.76v-40.816l4.154-7.421V67.876h71.655l10.385 3.18H200.75V42.907h10.904v26.559h178.62V42.907h11.942v26.559h117.867l16.616-7.422 8.827-.53v56.72l12.981 13.251H577.2v24.384H20.573z"
        />
        <path
          fill="#1A1A1C"
          d="M555.545 340.041l12.968-22.235-36.829-29.991 50.316.517V212.32h-5.187v-26.89H0v98.248l19.452 19.391h14.783l34.754 27.665 11.412-15.513-31.123-23.786h33.717l7.262 7.239h372.957l11.93-6.205h28.011z"
        />
        <path
          fill="#2D2C30"
          d="M579.6 178.24v40.816l-4.154 7.421v46.647h-71.655l-10.385-3.18H381.25v28.149h-10.904v-26.559h-178.62v26.559h-11.942v-26.559H61.917L45.3 278.956l-8.827.53v-56.72l-12.981-13.251H4.8V185.13h556.627z"
        />
        <path fill="#33393F" d="M16 168h550v1H16z" />
      </g>
      {children}
    </svg>
  );
};

export default BHSSvg;

import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import PublicRouter from "./routes/PublicRoute";
import AuthContainer from "./components/auth/login";
import ExchangeToken from "components/auth/exchange_token";
import Sidebar from "components/common/Sidebar";
import AppRoutes from "./routes/AppRoutes";
import Navigation from "components/common/navigation";
import "./main.css";

class App extends Component {
  render() {
    return (
      <>
        <Routes>
          <Route
            path="/me/accounts"
            element={
              <PublicRouter>
                <ExchangeToken />
              </PublicRouter>
            }
          />
          <Route
            path="/login"
            element={
              <div className="login-page-container">
                <PublicRouter>
                  <AuthContainer />
                </PublicRouter>
              </div>
            }
          />
          <Route
            path="/*"
            element={
              <div className={`app active`}>
                <Navigation mode={"normal"} />
                <div className={`emma-body`}>
                  <Sidebar />
                  <AppRoutes />
                </div>
              </div>
            }
          />
        </Routes>
      </>
    );
  }
}

export default App;

import { updateObject } from "./utility";
import {
  REINIT_TOAST_ALERT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
} from "../constants";

const initialState = {
  message: "",
  type: "",
  back: false,
};

const TostAlert = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_TOAST_ALERT:
      return updateObject(state, initialState);
    case SUCCESS_MESSAGE:
      return updateObject(state, {
        type: "success",
        message: action.message,
        back: action.back || false,
      });
    case ERROR_MESSAGE:
      return updateObject(state, { type: "error", message: action.message });

    default:
      return state;
  }
};

export default TostAlert;

import {
  FETCH_LOGS_START,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_FAIL,
} from "../constants";

import { getRequest } from "utils/APIRequest";

const fetchLogsStart = () => ({
  type: FETCH_LOGS_START,
});

const fetchLogsSuccess = (data, total) => ({
  type: FETCH_LOGS_SUCCESS,
  data,
  total,
});

const fetchLogsFail = () => ({
  type: FETCH_LOGS_FAIL,
});

export const fetchLogs = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchLogsStart());

    const response = await getRequest(`/api/activitylogs`, data);
    if (!response.error) {
      dispatch(fetchLogsSuccess(response.data, response.total));
    } else {
      dispatch(fetchLogsFail());
    }
  };
};

import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { middleware as tooltip } from "redux-tooltip";
import emmaClientReducers from "./reducers";

// Applying Middlewares
const createStoreWithMiddleware = applyMiddleware(
  thunkMiddleware,
  tooltip,
)(createStore);

export default createStoreWithMiddleware(
  emmaClientReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

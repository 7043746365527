import { React, useState, useEffect } from "react";
import Logo from "./logo";
import AlertLog from "./alerts";
import { isAuthorized } from "../../utils/helpers";
import MessagesNumber from "./MessagesNumber";
import NavProfileMenu from "./nav_profile_menu";
import NavLayoutMenu from "./nav_layout_menu";
import _ from "lodash";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Badge, IconButton, Stack, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/material/styles";
import Time from "./time_header";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLayout, toggleSidebar } from "redux/actions";
import Notification from "./Notification";
import { logOut } from "redux/actions/session";
import { REINIT_USER } from "redux/constants";

const useStyles = makeStyles((theme) => ({
  badge: {
    fontSize: 10,
    height: 16,
    width: 16,
    color: "white",
    backgroundColor: "#205CF6",
  },
  iconButton: {
    paddingLeft: 6,
    height: 35,
    width: 35,
    "&:hover": {
      backgroundColor: "#2A3D52",
    },
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#1E2D3F",
    padding: "5px 8px",
  },
}));

const tooltip = {
  "& .MuiTooltip-tooltip": {
    color: "white",
    fontSize: "12px !important",
    borderRadius: 1,
    marginTop: 0.7,
  },
};

const Navigation = (props) => {
  const [displayLayoutGrid, setDisplayLayoutGrid] = useState(false);
  const [displayUserGrid, setDisplayUserGrid] = useState(false);
  const [alerts_popup, setAlerts_popup] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  let layout = null;

  const {
    auth,
    UserInfo,
    sidebar,
    ActiveAlerts,
    Messages,
    Notification: NotificationMessage,
  } = useSelector((state) => state);

  useEffect(() => {
    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        setAlerts_popup(false);
        setDisplayLayoutGrid(false);
        setDisplayUserGrid(false);
      }
    });
  }, []);

  const getMessagesCount = () => {
    let count = 0;
    const unreadMessage = Messages?.unreadMessage;
    Object.keys(unreadMessage).map(
      (ele) => (count += unreadMessage[ele].chats?.length),
    );

    return count;
  };

  const logout = () => {
    setDisplayLayoutGrid(false);
    dispatch({ type: REINIT_USER });
    dispatch(logOut());
  };

  const handleLayoutChange = ({ alias, id, direction }) => {
    dispatch(changeLayout({ alias, id, direction }));
    setDisplayLayoutGrid(false);
  };

  const handleUserSelection = () => {
    setDisplayUserGrid(false);
  };

  const goToHomePage = (ev) => {
    if (
      (ev.keyCode && ev.keyCode !== 13) ||
      (checkHasTobt() && !UserInfo?.selectedFlights.ids?.length) ||
      location.pathname === "/me/account"
    ) {
      return null;
    }

    return navigate("/");
  };

  const toggleSidebarNav = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    if (sidebar) {
      document.querySelector(".main-container").classList.remove("normal");
    } else {
      document.querySelector(".main-container").classList.add("normal");
    }
    dispatch(toggleSidebar(!sidebar));
  };

  const goToInsertAlertPage = () => {
    setAlerts_popup(false);
    navigate("/admin/alerts/create");
  };

  const toggleAlerts = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    setAlerts_popup(!alerts_popup);
  };

  const toggleLayout = (ev) => {
    if (
      ev.keyCode &&
      ev.keyCode !== 13 &&
      ev.keyCode !== 38 &&
      ev.keyCode !== 40
    ) {
      return null;
    }
    setDisplayLayoutGrid(!displayLayoutGrid);
    setDisplayUserGrid(false);
  };

  const showUserGrid = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    setDisplayUserGrid(!displayUserGrid);
    setDisplayLayoutGrid(false);
  };

  const hideAlerts = () => {
    setAlerts_popup(false);
  };

  const checkHasTobt = () => {
    const userRoles = auth?.user?.roles;
    const userHasResponsibleForTOBT = userRoles?.some(
      (ele) => ele.responsible_for_tobt,
    );

    return userHasResponsibleForTOBT && !UserInfo?.selectedFlights.ids?.length;
  };
  let canViewAlert;
  let canViewAlertTab;

  let canViewAcdm,
    channelPermissions = {};
  const roles =
    auth.user && auth.user.roles && auth.user.roles.length
      ? auth.user.roles
      : null;
  const isSmall = matchMedia("only screen and (max-width: 992px)").matches;
  if (roles) {
    layout = roles[0].users_roles ? roles[0].users_roles.layout_config : null;

    const { canView } = isAuthorized(roles, "ACDM");
    canViewAlert = isAuthorized(roles, "alerts");
    canViewAlertTab = isAuthorized(roles, "alert_tab");

    channelPermissions = isAuthorized(roles, "channels");

    canViewAcdm = canView;
  }

  useEffect(() => {
    if (
      checkHasTobt() &&
      location.pathname !== "/me/account" &&
      location.pathname !== "/me/select-flights"
    ) {
      navigate("/me/select-flights");
    }
  }, [props.selectedFlights]);

  const disableLayoutGrid = () => {
    setDisplayLayoutGrid(false);
  };

  return (
    <div>
      {NotificationMessage?.message && (
        <Notification
          message={NotificationMessage?.message}
          type={NotificationMessage?.type}
        />
      )}
      {location.pathname !== "/login" && location.pathname !== "me/account" ? (
        <div className="main-header">
          <div className="nav normal-mode">
            <div className="nav-wrapper container">
              <span className="header-left-btns">
                {location.pathname !== "/me/account" &&
                location.pathname !== "/me/select-flights" &&
                auth?.token &&
                !checkHasTobt() &&
                roles ? (
                  <div
                    className={`list-btn main-list ${sidebar ? "active" : ""}`}
                    tabIndex="0"
                    role="button"
                    onKeyDown={toggleSidebarNav}
                    onClick={toggleSidebarNav}
                  >
                    <i aria-hidden="true" className="fa fa-bars" />
                  </div>
                ) : null}
                <div
                  className="logo-wrapper"
                  tabIndex="0"
                  role="button"
                  onKeyDown={goToHomePage}
                  onClick={goToHomePage}
                >
                  <Logo />
                </div>
              </span>

              {auth?.token && roles && location.pathname !== "/login" ? (
                <span className="header-btns">
                  <div
                    title="My profile"
                    tabIndex="0"
                    role="button"
                    className={`${
                      location.pathname === "/me" ? "active" : ""
                    } nav-btn`}
                  >
                    <NavProfileMenu
                      showUserGrid={showUserGrid}
                      display={displayUserGrid}
                      logOut={logout}
                      handleUserSelection={handleUserSelection}
                      displayUserGrid={displayUserGrid}
                      auth={auth}
                    />
                  </div>
                  <div className="container-role">
                    <div className="user-name">{auth.user.name}</div>
                    <div className="role-name">
                      {auth.user?.roles[0]?.role}
                      {auth.user?.roles?.length > 1 && (
                        <StyledTooltip
                          title={
                            <ul>
                              {auth.user?.roles.map((role) => {
                                return <li key={role.id}>{role.role}</li>;
                              })}
                            </ul>
                          }
                          components={{ Tooltip: Stack }}
                          sx={tooltip}
                        >
                          <span> +{auth.user?.roles?.length - 1}</span>
                        </StyledTooltip>
                      )}
                    </div>
                  </div>
                  {location.pathname === "/" &&
                  canViewAcdm &&
                  canViewAlertTab.canView ? (
                    <div>
                      {!isSmall ? (
                        <StyledTooltip
                          title="Alerts Log"
                          components={{ Tooltip: Stack }}
                          sx={tooltip}
                        >
                          <IconButton
                            className={classes.iconButton}
                            onClick={toggleAlerts}
                            style={{ size: "large" }}
                          >
                            <Badge
                              badgeContent={
                                Object.keys(ActiveAlerts?.alerts).length
                              }
                              style={{ width: 22 }}
                              color="primary"
                              classes={{ badge: classes.badge }}
                            >
                              <NotificationsIcon style={{ color: "white" }} />
                            </Badge>
                          </IconButton>
                        </StyledTooltip>
                      ) : null}
                    </div>
                  ) : null}
                  {location.pathname !== "/me/account" &&
                    location.pathname !== "/me/select-flights" && (
                      <MessagesNumber
                        channelPermissions={channelPermissions}
                        numberOfMessages={getMessagesCount()}
                      />
                    )}
                  {(!isSmall && location.pathname === "/") ||
                  location.pathname === "/layout" ? (
                    <div>
                      <StyledTooltip
                        title="Change Layout"
                        components={{ Tooltip: Stack }}
                        sx={tooltip}
                      >
                        <IconButton
                          className={classes.iconButton}
                          onClick={toggleLayout}
                        >
                          <DashboardIcon style={{ color: "white" }} />
                        </IconButton>
                      </StyledTooltip>
                      <NavLayoutMenu
                        layout={layout}
                        displayLayoutGrid={displayLayoutGrid}
                        roles={roles}
                        isSmall={isSmall}
                        display={displayLayoutGrid}
                        handleLayoutChange={handleLayoutChange}
                        toggleLayout={toggleLayout}
                        disableLayoutGrid={disableLayoutGrid}
                        auth={auth}
                        setDisplayLayoutGrid={setDisplayLayoutGrid}
                      />
                    </div>
                  ) : null}
                  {<Time />}
                </span>
              ) : null}
            </div>
          </div>
          {location.pathname === "/" && alerts_popup ? (
            <AlertLog
              hideAlerts={hideAlerts}
              addNewAlert={goToInsertAlertPage}
              alerts={ActiveAlerts?.alerts}
              roles={roles}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Navigation;

import React, { memo } from "react";
import { sortTypes } from "utils/helpers";

const GridHeader = memo((props) => {
  const { title, name, sortData, desc_sort, sortBy } = props;

  return (
    <th className="acdm-fixed-header">
      {title}
      <div
        className={`acdm-icon ${sortBy && sortBy[name] ? "active" : ""}`}
        data-name={name}
        role="button"
        onKeyDown={sortData}
        onClick={sortData}
      >
        <i
          aria-hidden="true"
          data-name={name}
          className={`fa fa-sort-${
            desc_sort === sortTypes.ASCEND ? "up " : "down "
          } fa-1g`}
        />
      </div>
    </th>
  );
});

export default GridHeader;

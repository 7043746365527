import React, { Component } from "react";
import { Line } from "react-chartjs-2";

export default class Chart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let unit = "ugm -3";
    if (this.props.variable === "O3") {
      unit = "ppb";
    }
    if (this.props.variable === "noise") {
      unit = "db";
    }
    const data = {
      labels: this.props.lables,
      datasets: [
        {
          fill: false,
          lineTension: 0.1,
          borderColor: "#FFBB0B",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#FFBB0B",
          pointBackgroundColor: "#FFBB0B",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#FFBB0B",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.props.chartData,
        },
      ],
    };
    const options = {
      responsive: true,
      title: {
        display: true,
        text: this.props.variable.replace("_", "."),
        position: "top",
        align: "start",
        fontColor: "#FFFFFF",
        fontSize: 15,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: `${this.props.variable}  (${unit})`,
            },
            stacked: true,
            gridLines: {
              display: true,
              color: "#3B3B57",
            },
            beginAtZero: false,
          },
        ],
        xAxes: [
          {
            ticks: {
              callback: function (value, index, values) {
                const t = value.split(",");
                if (t.length > 1) {
                  return value;
                }
                return index % 12 == 0 ? value : null;
              },
            },
          },
        ],
      },
    };
    return <Line data={data} options={options} height={120} />;
  }
}

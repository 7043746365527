import {
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAIL,
  REINIT_NOTIFICATIONS,
  DELETE_NOTIFICATIONS_START,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_FAIL,
  RESTORE_NOTIFICATIONS_START,
  RESTORE_NOTIFICATIONS_SUCCESS,
  RESTORE_NOTIFICATIONS_FAIL,
  CREATE_NOTIFICATIONS_START,
  CREATE_NOTIFICATIONS_SUCCESS,
  CREATE_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATIONS_START,
  UPDATE_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_FAIL,
  FETCH_NOTIFICATION_START,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAIL,
} from "../constants";
import { deleteMultiData, updateData, updateObject } from "./utility";

const initialState = {
  notifications: [],
  notification: {},
  loading: false,
  total: 0,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_NOTIFICATIONS:
      return updateObject(state, initialState);
    case FETCH_NOTIFICATIONS_START:
      return updateObject(state, { loading: true });
    case FETCH_NOTIFICATIONS_SUCCESS:
      return updateObject(state, {
        notifications: action.data,
        total: action.total,
      });
    case FETCH_NOTIFICATIONS_FAIL:
      return updateObject(state, { loading: false });

    case DELETE_NOTIFICATIONS_START:
      return updateObject(state, { loading: true });
    case DELETE_NOTIFICATIONS_SUCCESS:
      return deleteMultiData(state, action.data, "notifications");
    case DELETE_NOTIFICATIONS_FAIL:
      return updateObject(state, { loading: false });

    case RESTORE_NOTIFICATIONS_START:
      return updateObject(state, { loading: true });
    case RESTORE_NOTIFICATIONS_SUCCESS:
      return deleteMultiData(state, action.data, "notifications");
    case RESTORE_NOTIFICATIONS_FAIL:
      return updateObject(state, { loading: false });

    case CREATE_NOTIFICATIONS_START:
      return updateObject(state, { loading: true });
    case CREATE_NOTIFICATIONS_SUCCESS:
      return updateObject(state, {
        loading: false,
        notifications: [action.data, ...state.notifications],
      });
    case CREATE_NOTIFICATIONS_FAIL:
      return updateObject(state, { loading: false });

    case UPDATE_NOTIFICATIONS_START:
      return updateObject(state, { loading: true });
    case UPDATE_NOTIFICATIONS_SUCCESS:
      return updateData(state, action.data, "notifications", "notification");
    case UPDATE_NOTIFICATIONS_FAIL:
      return updateObject(state, { loading: false });

    case FETCH_NOTIFICATION_START:
      return updateObject(state, { loading: true });
    case FETCH_NOTIFICATION_SUCCESS:
      return updateObject(state, { loading: false, notification: action.data });
    case FETCH_NOTIFICATION_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default notificationsReducer;

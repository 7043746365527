import React from "react";
import { convertTimeZoneWithFormat } from "utils/helpers";

const ColumnHeader = ({ colums = [], data = {}, timeZone }) => {
  return (
    <>
      {colums.map(({ name, type }, index) => (
        <div className="header-field-cell" key={index}>
          <div className="header-content upper-case">{name}</div>
          <div className="header-flight">
            {data[name] && type === "date"
              ? convertTimeZoneWithFormat({
                  time: data[name],
                  timeZone: timeZone,
                  requiredFormat: "HH:mm",
                })
              : data[name]}
          </div>
        </div>
      ))}
    </>
  );
};

export default ColumnHeader;

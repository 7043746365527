import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import dataPrint from "./print_manager/data_print";
import copyTable from "./copy_manager/table_copy";
import dataExport from "./export_manager/data_export";
import { convertTimeZoneWithFormat } from "utils/helpers";
import { useSelector } from "react-redux";

const ControlBtn = ({
  anchorEl,
  open,
  handleClose,
  title,
  classSelect,
  showColmnsFilter,
  data,
  columns,
}) => {
  const { timeZone } = useSelector((state) => state.SystemParams);

  const printData = () => {
    dataPrint(`${title} table`, `.${classSelect}`);
    handleClose();
  };

  const copyData = () => {
    const dashboardTable = document.querySelector(`.${classSelect}`);
    copyTable(dashboardTable);
    handleClose();
  };

  const getHeaderList = (columns) => {
    const headers = [];
    columns.forEach((element) => {
      headers.push({
        value: element.label || element.title,
        type: "string",
      });
    });
    return headers;
  };

  const getData = (data) => {
    const records = [];
    data.forEach((element) => {
      let record = [];
      columns.forEach((col) => {
        let value = col.render(element);
        record.push({
          value,
          type: "string",
        });
      });

      records.push(record);
    });
    return records;
  };

  const exportData = () => {
    const dataToExport = [];
    const headers = getHeaderList(columns);
    const records = getData(data);

    dataToExport.push(headers);

    records.map((r) => dataToExport.push(r));
    dataExport(
      `EMMA ${title} ${convertTimeZoneWithFormat({
        time: new Date(),
        timeZone,
        requiredFormat: "YYYY DD MMM",
      })}`,
      dataToExport,
    );
    handleClose();
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiPaper-root": {
            background: "#070B0F",
            color: "white",
            width: "248px",
            borderRadius: "20px",
            marginTop: "4px",
            marginLeft: "33px",
          },
        }}
      >
        <MenuItem onClick={showColmnsFilter} onClose={handleClose}>
          <div className="list">
            <i className="show-hide-column-icon width-icon"></i>
            <span className="icon-text">Show / hide columns</span>
          </div>
        </MenuItem>
        <MenuItem onClick={exportData} onClose={handleClose}>
          <div className="list">
            <i className="export-icon width-icon"></i>
            <span className="icon-text">Export as excel</span>
          </div>
        </MenuItem>
        <MenuItem>
          <div onClick={printData} className="list">
            <i className="print-icon width-icon"></i>
            <span className="icon-text">Print</span>
          </div>
        </MenuItem>
        <MenuItem onClick={copyData}>
          <div className="list">
            <i className="fa-solid fa-copy width-icon"></i>
            <span className="icon-text">Copy</span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ControlBtn;

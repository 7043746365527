import React from "react";
import TableBody from "./table_body";

const BHSTable = ({ belts }) => {
  return (
    <table className="bhs-table">
      <thead>
        <tr className="belt-info bhs-cell">
          <th>Belt id</th>
          <th>Terminal</th>
          <th>Status</th>
          <th>Flights</th>
          <th>First bag</th>
          <th>Last bag</th>
        </tr>
      </thead>
      <tbody>
        {belts.map((belt) => {
          return <TableBody key={belt.id} {...belt} />;
        })}
      </tbody>
    </table>
  );
};

export default BHSTable;

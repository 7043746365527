import React, { useEffect, useState } from "react";
import { Box, Popover } from "@mui/material";
import "./style.css";
import UiDatePicker from "components/common/date/date";
import moment from "moment-timezone";
import styles from "./modal.module.css";
import TimePickerUi from "../TimePicker/TimePicker";
import GeneralButton from "../Button";

const UiFilterModal = ({
  anchorEl,
  handleCancel,
  field,
  handleDateChange,
  filtersVal,
}) => {
  const [timeRange, setTimeRange] = useState({});
  const [timeError, setTimeError] = useState("");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setTimeRange({});
    if (filtersVal) {
      setTimeRange({
        start: moment(filtersVal?.value?.start).format("HH:mm"),
        end: moment(filtersVal?.value?.end).format("HH:mm"),
        date: moment(filtersVal?.value?.start).format("MM-DD-YYYY"),
      });
    }
  }, [filtersVal]);

  const handleStartTimeChange = (value) => {
    setTimeRange({
      ...timeRange,
      start: value,
    });
  };

  const handleEndTimeChange = (value) => {
    setTimeRange({
      ...timeRange,
      end: value,
    });
  };

  const handleFilterStartDate = (date) => {
    setTimeRange({
      ...timeRange,
      date: moment(date).format("MM-DD-YYYY"),
    });
  };

  const applyFilter = () => {
    const date = timeRange.date || moment().format("MM-DD-YYYY");
    if (!timeRange.start || !timeRange.end) {
      return setTimeError("Select start & end times");
    }
    handleCancel();
    setTimeError("");
    return handleDateChange(timeRange, date, field);
  };

  return (
    <div className={styles.headerFilter}>
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          disableEnforceFocus={false}
        >
          <Box className={styles.boxModal}>
            <div className={styles.headerWrapper}>
              <div className={styles.headerText}>
                {field === "created_at" ? "Time" : field.toUpperCase()}
              </div>
              <div
                onClick={handleCancel}
                role="button"
                tabIndex="0"
                onKeyDown={handleCancel}
                className={styles.filterCancelButton}
              >
                X
              </div>
            </div>
            <TimePickerUi
              startTime={timeRange.start}
              endTime={timeRange.end}
              handleStartTimeChange={handleStartTimeChange}
              handleEndTimeChange={handleEndTimeChange}
              timeError={timeError}
            />
            <div className={styles.dateFilterWrapper}>
              <UiDatePicker
                label="Date"
                value={timeRange.date || moment().format("MM/DD/YYYY")}
                handleChange={handleFilterStartDate}
              />
            </div>
            <GeneralButton type="primary" onClick={applyFilter}>
              Apply
            </GeneralButton>
          </Box>
        </Popover>
      </div>
    </div>
  );
};

export default UiFilterModal;

import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    color: "white",
    width: "150px",
    left: "35px",
    "& svg": {
      display: "none",
    },
    "& button": {
      width: "160px",
      height: "48px",
      position: "absolute",
      left: "-46px",
      padding: "0px",
    },
    "& MuiInputBase-root": {
      marginTop: "0px !important",
    },
    "& .Mui-disabled": {
      WebkitTextFillColor: "#212831 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-root > input": {
      borderBottom: "none",
    },
    "& label": {
      color: "#909fba !important",
      top: "10px",
    },
  },
  inputLabelNoShrink: {
    color: "white",
    top: "-5px",
  },
  inputLabelShrink: {
    color: "#909FBA !important",
    top: "5px",
  },
}));

const styleDate = () => {
  return {
    sx: {
      "& .MuiIconButton-root": {
        color: "white !important",
        fontFamily: "Proxima Nova !important",
      },
      "& .MuiPaper-root": {
        backgroundColor: "#303B46",
        borderRadius: "12px",
        fontFamily: "Proxima Nova !important",
      },
      "& .MuiButtonBase-root:hover": {
        color: "white",
      },
      "& .MuiTypography-root": {
        color: "#A8B6C5",
        fontSize: "12px",
        fontFamily: "Proxima Nova !important",
      },
      "& .MuiButtonBase-root-MuiPickersDay-root, & .Mui-selected": {
        backgroundColor: "#1142BC",
      },
      "& .MuiButtonBase-root:hover": {
        color: "black",
        background: "white",
      },
      "& .PrivatePickersFadeTransitionGroup-root": {
        color: "white",
        fontFamily: "Proxima Nova !important",
      },
      "& .MuiPickersFadeTransitionGroup-root": {
        color: "white",
      },
      "& .MuiButtonBase-root:hover": {
        color: "black !important",
        backgroundColor: "white !important",
      },
      "& .Mui-disabled": {
        WebkitTextFillColor: "#ffffff5e",
      },
    },
  };
};

function UiDatePicker({
  label,
  value = "",
  handleChange,
  minDate = "",
  maxDate = "",
  disabled = false,
}) {
  const classes = useStyles();
  const shrink = value !== null;
  return (
    <div className="date-input-wrapper">
      <i
        className="fa-regular fa-calendar-minus date-input-icon"
        style={{ color: disabled ? "#ffffff26" : "" }}
      ></i>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={moment(value).toDate()}
          onChange={handleChange}
          className={classes.root}
          renderInput={(params) => (
            <TextField
              variant="standard"
              InputLabelProps={{
                shrink: shrink,
                className: shrink
                  ? classes.inputLabelShrink
                  : classes.inputLabelNoShrink,
              }}
              {...params}
            />
          )}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          slotProps={{
            textField: {
              InputProps: {
                disableUnderline: true,
              },
            },

            popper: styleDate(),
          }}
        />
      </LocalizationProvider>
    </div>
  );
}

export default UiDatePicker;

import React from "react";
import { defaultRVR } from "./assets/data/constant";

const renderRanges = (data = {}, unit) => {
  return defaultRVR.map((rvr, i) => (
    <div
      key={i}
      className={`atcw-rnwv-range total ${i % 2 === 0 ? "even" : "odd"}`}
    >
      <div className="rnw-range-label">{rvr}</div>
      <div className="atcw-section-unit sub total">
        {data[rvr]}
        <span className="atcw-section-unit-of-mes">{unit}</span>
      </div>
    </div>
  ));
};

export default (props) => (
  <div className="rnwv-range" data-layout="row">
    <h2 className="atcw-section-title sec-head total">Runway Visual Range</h2>
    <div className="atcw-section-inner">
      <div className="atcw-rnwv-ranges">
        {renderRanges(props.data, props.unit, props.display)}
      </div>
    </div>
  </div>
);

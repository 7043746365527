import React from "react";

const Rating = ({ value, className }) => {
  return (
    <div
      className={className}
      style={{ width: "18px", textAlign: "center", marginLeft: "25px" }}
    >
      <div className="rating">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="3"
          viewBox="0 0 17 3"
          fill="none"
        >
          <rect
            x="0.166687"
            width="16.6667"
            height="2.66667"
            rx="1.33333"
            fill={value === "HIGH" ? "#D12C00" : "#434A58"}
          />
        </svg>
      </div>
      <div className="rating">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="4"
          viewBox="0 0 11 4"
          fill="none"
        >
          <rect
            x="0.5"
            y="0.666626"
            width="10"
            height="2.66667"
            rx="1.33333"
            fill={
              value === "HIGH"
                ? "#D12C00"
                : value === "MED"
                ? "#D17100"
                : "#434A58"
            }
          />
        </svg>
      </div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="3"
          viewBox="0 0 6 3"
          fill="none"
        >
          <rect
            x="0.5"
            y="0.333374"
            width="5.33333"
            height="2.66667"
            rx="1.33333"
            fill={
              value === "HIGH"
                ? "#D12C00"
                : value === "MED"
                ? "#D17100"
                : "#FFCB00"
            }
          />
        </svg>
      </div>
    </div>
  );
};

export default Rating;

import React from "react";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export const headersColumns = [
  {
    label: "APM",
    key: "APM",
    width: "2px",
    export: true,
  },
  {
    label: "Location",
    key: "Location",
    width: "2px",
    export: true,
  },
  {
    label: "Status",
    key: "Status",
    width: "2px",
    export: true,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root": {
      color: "#909FBA",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "23px",
      borderBottom: "1px solid #313C48",
      background: "transparent",
    },
    "& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root": {
      color: "white",
      borderBottom: "1px solid #313C48",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "31px",
    },
    "& .MuiTableHead-root .MuiTableSortLabel-root,.MuiTableHead-root .MuiTableSortLabel-icon":
      {
        color: " #909FBA",
      },
  },
}));

const Details = (props) => {
  const { apm } = props;
  const classes = useStyles();

  return (
    <TableContainer
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
      }}
      component={Paper}
    >
      <Table
        sx={{
          minWidth: 650,
          color: "white",
          backgroundColor: "transparent",
          marginLeft: "22px",
        }}
        aria-label="simple table"
        className={`dashboard-table-wrapper ${classes.root}`}
      >
        <TableHead>
          <TableRow>
            {headersColumns.map((ele, index) => (
              <TableCell
                style={{ ...(ele.width && { width: ele.width }) }}
                align={
                  ele.align || index > headersColumns.length - 1
                    ? "right"
                    : "left"
                }
                key={ele.key}
              >
                {ele.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(apm).map(({ name, location, status }, index) => (
            <TableRow key={index}>
              <TableCell>{name}</TableCell>
              <TableCell>{location}</TableCell>
              <TableCell>{status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Details;

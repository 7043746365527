import React, { useEffect, useState } from "react";

const Tabs = (props) => {
  const { name, chooseFlightTab, block } = props;
  const [fontSize, setFontSize] = useState(12);

  useEffect(() => {
    setFontSize(props.fullscreenBlock ? 14 : 12);
  }, [props.fullscreenBlock, fontSize]);

  return (
    <ul
      className="tabs column s12 tabs-fixed-width"
      style={{ fontSize: fontSize, width: "61%" }}
    >
      <li
        role="button"
        data-name="arrival"
        data-alias="ARRIVAL"
        data-reference="ldt"
        data-indicator="0"
        tabIndex="0"
        onKeyDown={chooseFlightTab}
        className={`tab ${name === "arrival" ? "active" : ""}`}
        onClick={chooseFlightTab}
      >
        ARRIVAL
      </li>
      <li
        role="button"
        tabIndex="0"
        data-name="turnaround"
        data-alias="TURN AROUND"
        data-reference="ibt"
        data-indicator="33"
        onKeyDown={chooseFlightTab}
        onClick={chooseFlightTab}
        className={`tab ${name === "turnaround" ? "active" : ""}`}
      >
        TURNAROUND
      </li>
      <li
        role="button"
        tabIndex="0"
        data-name="departure"
        data-alias="DEPARTURE"
        data-reference="obt"
        data-indicator="70"
        onKeyDown={chooseFlightTab}
        onClick={chooseFlightTab}
        className={`tab ${name === "departure" ? "active" : ""}`}
      >
        DEPARTURE
      </li>
      <div className={`indicator acdm-tabs acdm-tabs-${block}`} />
    </ul>
  );
};

export default Tabs;

import { SocketAction } from "utils/helpers";

export const manualUpdateCases = {
  REMOVE_FIRST_MANUAL_ITEM: "REMOVE_FIRST_MANUAL_ITEM",
  CLEAR_MANUAL_ITEMS: "CLEAR_MANUAL_ITEMS",
  UPDATE_MANUAL_ITEM: "UPDATE_MANUAL_ITEM",
};

const getModalDescription = (action, flightNumber) => {
  if (action === SocketAction.CONFIRM_TOBT_UPDATE)
    return `Please update or confirm TOBT calculation for flight ${flightNumber}`;

  return `Please confirm TOBT calculation for flight ${flightNumber}`;
};

const prepareTOBTSocketData = (action, message) => {
  const { flight_number, tobt, aircraft_registration } = message;

  return {
    value: tobt,
    editableFields: ["tobt"],
    field: { name: "tobt", type: "date" },
    type: "date",
    flight: message,
    aircraft_registration,
    inputModalDesc: getModalDescription(action, flight_number),
    okBtnVal:
      action === SocketAction.CONFIRM_TOBT_UPDATE ? "Update" : "Confirm",
    action,
    confirmBtnVal:
      action === SocketAction.CONFIRM_TOBT_UPDATE ? "Confirm" : null,
  };
};

const updateManual = (state, action, data) => {
  let queue = [...state];
  const tobtData = prepareTOBTSocketData(action, data);

  let index = queue.findIndex(
    ({ flight }) =>
      flight?.aircraft_registration === data.aircraft_registration &&
      flight?.flight_number === data.flight_number,
  );

  if (index === -1) {
    queue = [...state, tobtData];
  }

  if (action !== SocketAction.CLOSE_POPUP) {
    return [...queue];
  }

  const fieldToEdit = queue[index] || {};

  if (
    action === SocketAction.CLOSE_POPUP &&
    fieldToEdit?.field?.name === "tobt" &&
    fieldToEdit.action
  ) {
    return queue.splice(index);
  }

  return [...state];
};

export const manualUpdateQueueReducer = (state = [], action) => {
  switch (action.type) {
    case manualUpdateCases.REMOVE_FIRST_MANUAL_ITEM:
      const [_, ...rest] = state;
      return [...rest];
    case manualUpdateCases.CLEAR_MANUAL_ITEMS:
      return [];
    case manualUpdateCases.UPDATE_MANUAL_ITEM:
      const { socketAction, data } = action;
      return updateManual(state, socketAction, data);
    default:
      return state;
  }
};

import React from "react";
import { Typography } from "@mui/material";
import styles from "./delete_layout_modal.module.css";
import Modal from "components/common/Modal";
import Button from "components/common/Button";

const DeleteLayoutModal = ({ visible, onClose, layout, onSubmit }) => {
  const submitHandler = () => {
    onSubmit(layout);
  };

  return (
    <Modal zIndex={3002} visible={visible} onClose={onClose}>
      <div className={styles.container}>
        <Typography className={styles.header}>
          Are you sure you want to delete “{layout.alias}”
        </Typography>
        <Typography className={styles.description}>
          This action can’t be undone
        </Typography>
        <div className={styles.actions}>
          <Button onClick={onClose} type="text">
            cancel
          </Button>
          <Button onClick={submitHandler} type="danger">
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteLayoutModal;

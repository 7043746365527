import queryString from "query-string";
import { storageKeys } from "utils/helpers";

const _requestOptions = (
  url,
  method = "GET",
  contentType = "application/json",
) => {
  const parsedUrl = new URL(url);
  let realHeaders = {};
  realHeaders["Host"] = parsedUrl.host;
  realHeaders["Authorization"] = `Bearer ${localStorage.getItem(
    storageKeys.TOKEN,
  )}`;
  realHeaders["Content-Type"] = contentType;

  return {
    host: parsedUrl.hostname,
    port: parsedUrl.port,
    path: parsedUrl.pathname,
    method,
    headers: realHeaders,
  };
};

export const updateKeycloakAccount = async (data) => {
  const url = process.env.REACT_APP_KEYCLOAK_ACCOUNT_URL;
  const options = _requestOptions(url, "POST");
  const payload = Object.assign(
    {
      body: JSON.stringify(data),
    },
    options,
  );

  const response = await keycloakFetch(url, payload);

  return response.status === 204;
};

export const getKeycloakAccount = async () => {
  const url = process.env.REACT_APP_KEYCLOAK_ACCOUNT_URL;
  const options = _requestOptions(url, "GET");
  const payload = Object.assign(options);
  const response = await keycloakFetch(url, payload);
  const account = await response.json();

  return account;
};

export const logoutKeycloak = async () => {
  const url = `${process.env.REACT_APP_KEYCLOAK_URL}logout`;

  const post_data = queryString.stringify({
    refresh_token: localStorage.getItem(storageKeys.REFRESH_TOKEN),
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  });

  const options = _requestOptions(
    url,
    "POST",
    "application/x-www-form-urlencoded",
  );
  const payload = Object.assign(
    {
      body: post_data,
    },
    options,
  );

  return await keycloakFetch(url, payload);
};

export const keyCloakToken = async (params) => {
  const url = `${process.env.REACT_APP_KEYCLOAK_URL}token`;

  const post_data = queryString.stringify(params);

  const options = _requestOptions(
    url,
    "POST",
    "application/x-www-form-urlencoded",
  );

  const payload = Object.assign(
    {
      body: post_data,
    },
    options,
  );

  let response = await keycloakFetch(url, payload);

  if (response.status === 200) {
    return await response.json();
  } else throw new Error("get token failed");
};

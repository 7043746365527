import _ from "lodash";
import { updateObject } from "./utility";
import {
  FETCH_ACDM_FAIL,
  FETCH_ACDM_SUCCESS,
  FETCH_ACDM_START,
  REINIT_ACDM,
  FETCH_OUT_BOUND_MESSAGES_START,
  FETCH_OUT_BOUND_MESSAGES_SUCCESS,
  FETCH_OUT_BOUND_MESSAGES_FAIL,
  UPDATE_ACDM_SOCKET,
  ACDM_START_REQUEST,
  FETCH_ACDM_ACTIVE_FIELDS_SUCCESS,
  ACDM_FAILD_REQUEST,
} from "../constants";

const initialState = {
  acdmFlights: [],
  systemParams: {},
  loading: false,
  total: 0,
  activeFields: {},
};

// this part of enhancement acdm table
const updateSocketData = (acdmFlights, data = {}) => {
  const { deleted, updated } = data;
  const newData = { ...acdmFlights, ...updated };

  const result = _.omit(newData, deleted);

  return {
    acdmFlights: result,
    loading: false,
  };
};

const acdmReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_ACDM:
      return updateObject(state, initialState);

    case FETCH_ACDM_START:
      return updateObject(state, { loading: true });
    case FETCH_ACDM_SUCCESS:
      return updateObject(state, { loading: false, acdmFlights: action.data });
    case FETCH_ACDM_FAIL:
      return updateObject(state, { loading: false });

    case FETCH_OUT_BOUND_MESSAGES_START:
      return updateObject(state, { loading: true });
    case FETCH_OUT_BOUND_MESSAGES_SUCCESS:
      return updateObject(state, { loading: false });
    case FETCH_OUT_BOUND_MESSAGES_FAIL:
      return updateObject(state, { loading: false });

    case UPDATE_ACDM_SOCKET:
      return updateObject(
        state,
        updateSocketData(state.acdmFlights, action.data),
      );

    case ACDM_START_REQUEST:
      return updateObject(state, { loading: true });
    case FETCH_ACDM_ACTIVE_FIELDS_SUCCESS:
    case FETCH_ACDM_ACTIVE_FIELDS_SUCCESS:
      return updateObject(state, { loading: false, activeFields: action.data });
    case ACDM_FAILD_REQUEST:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default acdmReducer;

import React from "react";

const InfoModal = ({ cb, overlay, color, icon, message }) => {
  const handleClick = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    cb(false);
  };

  return (
    <div className={`emma-modal ${overlay ? "overlay" : ""}`}>
      <div style={{ zIndex: 1301 }} className="modal-content">
        <div
          tabIndex="0"
          role="button"
          onKeyDown={handleClick}
          onClick={handleClick}
          className="close"
        >
          &times;
        </div>
        <div style={{ color: color || "blue" }} className="icon info-icon">
          <i aria-hidden="true" className={`fa fa-${icon || "info-circle"}`} />
        </div>
        <div className="modal-desc">{message}</div>
      </div>
    </div>
  );
};

export default InfoModal;

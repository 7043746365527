import React, { Component } from "react";
import * as d3 from "d3";
import ColorsCode from "../../common/widgets_color_codes";
import diff from "deep-diff";
import BlockedAreaRectTooltip from "./components/blocked_area_components/blocked_area_rect_tooltip";
import BlockedAreaInfo from "./components/blocked_area_components/note_tooltip";
import Defs from "./components/defs";
import AirPlane from "./components/airplanes/airplane";
import AirPlanePanelInfo from "./components/airplanes/airplane_panel_info";
import BlockedArea from "./components/blocked_area_components/blocked_area";
import DraggableRect from "./components/blocked_area_components/blocked_area_rect";
import "./assets/style/app.css";
import Vdgs from "./components/vdgs/vdgs";
import StandInfo from "./components/vdgs/stand_tooltip";
import AsmgcsSvg from "./components/asmgcs_svg";
import AirPlaneToolTip from "./components/airplanes/airplane_tooltip";
import { isAuthorized, SocketEvent, SocketListener } from "utils/helpers";
import setupSocket from "utils/socket_setup";
import BlockNoise from "./components/noise/block_noise";
import NoiseInfo from "./components/noise/noise_Info";
import Loading from "components/common/loading";
import { vdgsCodes } from "components/common/constant";

export default class ASMGCS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flightsDetails: false,
      flightsNoiseDetails: false,
      userHasAccessTo: {},
      preloader: false,
      blockMode: "normal",
      blockedAreas: [],
      renderBlockedAreaRectTooltip: false,
      note: "",
      currentColor: null,
      currentNote: null,
      currentBlockId: null,
      startDate: null,
      endDate: null,
      blockedAreaStatus: null,
      blockedAreaX: 0,
      blockedAreaY: 0,
      tooltipPosition: [],
      blockedAreaWidth: 0,
      blockedAreaHeight: 0,
      planesPaths: [],
      vdgsData: [],
    };
    this.controller = new AbortController();
    this.signal = this.controller.signal;
    this.blockedAreaTimer = null;
    this.preloaderTimer = null;
    this.resetSVGTimer = null;
    this.draggableRect = null;
    this.projection = this.props.projection;

    this.onCloseToolTip = this.onCloseToolTip.bind(this);
    this.buildAirPortPan = this.buildAirPortPan.bind(this);
    this.handleChildUnmount = this.handleChildUnmount.bind(this);
    this.skipDraggableRect = this.skipDraggableRect.bind(this);
    this.addBlockedArea = this.addBlockedArea.bind(this);
    this.updateBlockedArea = this.updateBlockedArea.bind(this);
    this.deleteBlockedArea = this.deleteBlockedArea.bind(this);
    this.editBlockedArea = this.editBlockedArea.bind(this);
    this.toggleRectInfo = this.toggleRectInfo.bind(this);
    this.toggleStandInfo = this.toggleStandInfo.bind(this);
    this.getAirlineLogo = this.getAirlineLogo.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.zoomReset = this.zoomReset.bind(this);
    this.svgZoomed = this.svgZoomed.bind(this);
    this.zoomRangeChange = this.zoomRangeChange.bind(this);
    this.drawBlockedArea = this.drawBlockedArea.bind(this);
    this.zoomAirPortPan = this.zoomAirPortPan.bind(this);
    this.scale = this.scale.bind(this);
    this.resize = this.resize.bind(this);
    this.showFlightsDetails = this.showFlightsDetails.bind(this);
    this.showNoiseDetails = this.showNoiseDetails.bind(this);
    this.noiseInfo = this.noiseInfo.bind(this);

    this.ASMGCS_socket = setupSocket(
      process.env.REACT_APP_ASMGCS_SOCKET_PATH,
      this.props.auth.token,
    );

    this.blockedAreas_socket = setupSocket(
      process.env.REACT_APP_BLOCKED_AREAS_SOCKET_PATH,
      this.props.auth.token,
    );

    this.blockedAreasSocket();

    this.vdgs_socket = setupSocket(
      process.env.REACT_APP_VDGS_SOCKET_PATH,
      this.props.auth.token,
    );

    this.airlines = [];

    this.blockedAreaAccess = isAuthorized(
      this.props.auth.user.roles,
      "blocked_area",
    );

    this.tooltipAndFlightAccess = isAuthorized(
      this.props.auth.user.roles,
      "ASMGCS",
    );
  }
  handleChildUnmount() {
    this.setState({ renderBlockedAreaRectTooltip: false });
  }

  static getDerievedStateFromProps(nextProps) {
    if (
      nextProps.auth.user &&
      nextProps.auth.user.roles &&
      diff(
        nextProps.auth.user.roles[0].permissions,
        this.props.auth.user.roles[0].permissions,
      )
    ) {
      this.setNewUserAuth();
    }
  }

  blockedAreasSocket() {
    this.blockedAreas_socket.on(SocketListener.BLOCKED_AREAS, (data) => {
      const { message } = data;

      this.setState({ blockedAreas: message });
    });
  }

  getAllBlockedAreas() {
    if (this.blockedAreaAccess.canView) {
      return fetch("/api/blockedareas", {
        method: "get",
        signal: this.signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        credentials: "include",
      })
        .then((resp) => resp.json())
        .then((blockedAreas) => {
          this.setState({ blockedAreas });
        })
        .catch((e) => {});
    }
  }

  fetchVDGSSData() {
    return fetch(`/api/vdgs/index`, {
      method: "get",
      signal: this.signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((vdgsData) => {
        this.setState({ vdgsData });
      })
      .catch((e) => {});
  }

  /**
   * Build Airport projection using D3js, this function handle the default projection
   * based on the current svg size, we also use this function to rebuild the airport projection
   * upon screen size change/responsive test.
   * @param bool resize, used to indecate the action to perform on the current airport pan, its either build the location if its false, reproject otherwise.
   */
  buildAirPortPan(width, height) {
    const svg = d3.select("svg#airport-pan"),
      zoom = d3
        .zoom()
        .scaleExtent([1, 8])
        .on("zoom", () => {
          if (this.state.blockMode === "normal") {
            return this.svgZoomed();
          }
          return null;
        });

    this.zoom = zoom;
    svg.select(".zoomLayer").call(zoom).select(".inner-zoom");

    const airport = d3.select("g.airport");

    airport.selectAll("circle").remove();
  }

  /**
   * Envent handler for d3js zoom functionality and its attached to the input type range
   * in the Zooming controller.
   */
  zoomRangeChange(value) {
    this.zoomAirPortPan(value, true);
  }

  /**
   * Envent handler for d3js zoom-in functionality and its attached to the zoom-in button
   * in the Zooming controller.
   */
  zoomIn(ev) {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    this.zoomAirPortPan(1);
  }

  /**
   * Envent handler for d3js zoom-out functionality and its attached to the zoom-out button
   * in the Zooming controller.
   */
  zoomOut(ev) {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    this.zoomAirPortPan(-1);
  }

  /**
   * zoomAirPortPan A method to handle all of the zooming functionality of the airport Pan.
   */
  zoomAirPortPan(direction, isRanged = false) {
    const svgContainer = document.querySelector(".svgContainer");
    const newWidth = svgContainer.offsetWidth || this.props.size.width;
    const newHeight = svgContainer.offsetHeight || this.props.size.height;
    let selection = d3.select(".zoomLayer"),
      center = [newWidth / 2, newHeight / 2],
      extent = this.zoom.scaleExtent(),
      translate = d3.zoomTransform(selection.node()),
      view = { x: translate.x, y: translate.y, k: translate.k };

    let target_zoom = 1,
      translate0 = [],
      l = [];

    switch (direction) {
      case 1:
        if (!isRanged) {
          target_zoom = Math.ceil(view.k + 1);
        } else {
          target_zoom = direction;
        }
        break;
      case -1:
        target_zoom = Math.floor(view.k - 1);
        break;
      default:
        target_zoom = direction;
        break;
    }

    if (target_zoom < extent[0] || target_zoom > extent[1]) {
      return false;
    }

    translate0 = [(center[0] - view.x) / view.k, (center[1] - view.y) / view.k];
    view.k = target_zoom;
    l = [translate0[0] * view.k + view.x, translate0[1] * view.k + view.y];

    view.x += center[0] - l[0];
    view.y += center[1] - l[1];

    selection
      .transition()
      .duration(350)
      .call(
        this.zoom.transform,
        d3.zoomIdentity.translate(view.x, view.y).scale(view.k),
      );
    this.setState({ airportPanZoom: view.k });
  }

  /**
   * Envent handler for d3js zoom-reset functionality and its attached to the zoom-reset button
   * in the Zooming controller.
   */
  zoomReset(ev) {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    const selection = d3.select(".zoomLayer");
    selection
      .transition()
      .duration(350)
      .call(this.zoom.transform, d3.zoomIdentity.translate(0, 0).scale(1));
  }

  /**
   * svgZoomed is a D3js event handler implemented to allow zooming smoothly into the airport pan.
   */
  svgZoomed() {
    const selection = d3.select(".zoomLayer"),
      svg = d3.select("svg#airport-pan .inner-zoom");

    const t = d3.zoomTransform(selection.node());
    svg.attr("transform", t);
  }

  /**
   * Event handler for ToolTip close event.
   */
  onCloseToolTip(flight) {
    this.props.deActivateFlight(flight);
  }

  setNewUserAuth(nextProps) {
    const props = nextProps || this.props;
    const access = {};
    if (props.auth.user) {
      props.auth.user.roles[0].permissions.map((e) => {
        access[e.permission_code] = e.roles_permissions.permission_bin;
      });
    }
    this.setState({ userHasAccessTo: access });
  }

  fetchASMGCSData() {
    const { registrations = [], numbers = [] } = this.props.selectedFlights;

    let url = `/api/asmgcs/index`;
    if (registrations.length || numbers.length) {
      const selectedFlights = JSON.stringify([...registrations, ...numbers]);
      url = `/api/asmgcs?flights=${selectedFlights}`;
    }
    return fetch(url, {
      method: "get",
      signal: this.signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.handleASMGCSData(data);
      });
  }
  componentDidMount() {
    this.fetchASMGCSData();
    this.fetchVDGSSData();

    this.setNewUserAuth();
    this.blockedAreaTimer = setTimeout(() => this.getAllBlockedAreas(), 1000);

    this.handleVdgsData();
    this.vdgs_socket.on(SocketListener.CONNECT, () => {
      this.vdgs_socket.emit(SocketEvent.ROOM, ["broadcast"]);
    });

    this.ASMGCS_socket.on(SocketListener.CONNECT, () => {
      const { registrations = [], numbers = [] } = this.props.selectedFlights;

      if (registrations.length || numbers.length) {
        const selectedFlights = [...registrations, ...numbers];
        const rooms = selectedFlights.map(
          (flight) => `selectedFlight_${flight}`,
        );
        return this.ASMGCS_socket.emit(SocketEvent.ROOM, rooms);
      }

      this.airlines = this.props.auth.user
        ? this.props.auth.user.airlines.map((e) => e.iata)
        : [];

      return this.ASMGCS_socket.emit(SocketEvent.ROOM, this.airlines);
    });

    this.ASMGCS_socket.on(this.props.alias, (data) => {
      const { message, action } = data;
      const airplanes = this.props.airplanes;
      const { planesPaths } = this.state;
      const tail_number = message.aircraft_registration;
      const path = message.path;
      switch (action) {
        case "create":
        case "update":
          // add path
          planesPaths[tail_number] = path;
          this.setState({ planesPaths });
          let airplaneIndex;

          this.props.airplanes.map((e, i) => {
            if (e.id === message.id) {
              airplaneIndex = i;
            }
          });

          if (airplaneIndex > -1) {
            airplanes[airplaneIndex] = message;
          } else {
            airplanes.push(message);
          }
          break;

        case "delete":
          // delet path
          delete planesPaths[tail_number];
          this.setState({ planesPaths });
          this.props.airplanes.map((e, i) => {
            if (e.aircraft_registration === message.aircraft_registration) {
              airplanes.splice(i, 1);
            }
          });
          break;
        default:
          planesPaths[tail_number] = path;
          this.setState({ planesPaths });
          if (message.next_location !== null) {
            let airplaneIndex;

            this.props.airplanes.map((e, i) => {
              if (e.id === message.id) {
                airplaneIndex = i;
              }
            });

            if (airplaneIndex > -1) {
              airplanes[airplaneIndex] = message;
            } else {
              airplanes.push(message);
            }
          } else {
            this.props.airplanes.map((e, i) => {
              if (e.aircraft_registration === message.aircraft_registration) {
                airplanes.splice(i, 1);
              }
            });
          }
          break;
      }
      this.props.updateAirplanes(airplanes);
    });

    this.buildAirPortPan();
  }

  handleVdgsData() {
    this.vdgs_socket.on(SocketListener.VDGS, (data) => {
      const { message, action } = data;
      const { vdgsData } = this.state;
      let dataIndex;
      this.state.vdgsData.map((e, i) => {
        if (e.id === message.id) {
          dataIndex = i;
        }
      });
      if (dataIndex > -1) {
        vdgsData[dataIndex] = message;
      } else {
        vdgsData.push(message);
      }

      this.setState({ vdgsData });
    });
  }

  handleASMGCSData(asmgcsData) {
    const { planesPaths } = this.state;

    const flights = asmgcsData.filter(
      (flight) => flight.next_location && flight.current_location,
    );
    this.props.updateAirplanes(flights);
  }

  /**
   * Re-scale A-SMGCS icons based on porjected airport surface widget with.
   */
  scale() {
    let scale = 1;
    const { width, height } = this.props.size;
    if (width >= 650 && width <= 900) {
      scale = 1.1;
    } else if (width >= 1000 && width <= 1200) {
      scale = 1.3; // 1.3;
    } else if (width >= 1200 && width < 1400) {
      scale = 1.5;
    } else if (width >= 1400 && width < 1600) {
      scale = 1.7;
      if (height < 400) {
        scale = 1.1;
      }
    } else {
      scale = (width / 1000) * 1; // 1.5;
    }
    return scale;
  }

  resize() {
    const svgContainer = document.getElementById("airport-pan");
    const width = svgContainer
      ? svgContainer.getBoundingClientRect().width
      : null;
    const height = svgContainer
      ? svgContainer.getBoundingClientRect().height
      : null;
    this.setState({ width, height });
  }

  /**
   * Render The main svg element for the airport pan.
   */

  showFlightsDetails(ev) {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    this.props.changeHeight(null, !this.state.flightsDetails);
    this.setState(
      (prevState) => ({ flightsDetails: !prevState.flightsDetails }),
      () => this.resize(),
    );
  }

  showNoiseDetails(ev) {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    this.setState(
      (prevState) => ({ flightsNoiseDetails: !prevState.flightsNoiseDetails }),
      () => this.resize(),
    );
  }

  renderAirPlanes() {
    const smallScreen = window.matchMedia("(max-width: 992px)").matches;
    return (
      <div className="svgContainer">
        <div className="flights-details-toggle-btn">
          {this.props.airplanes.length ? (
            <div className="switch-box">
              <label className="switch flight-details">
                <input
                  type="checkbox"
                  onChange={this.showFlightsDetails}
                  checked={this.state.flightsDetails}
                />
                <span
                  onKeyDown={this.showFlightsDetails}
                  role="button"
                  tabIndex="0"
                  className="slider round"
                />
              </label>
              <div className="flight-details">Show flights details</div>
            </div>
          ) : null}
        </div>
        <div
          className="btn-group-vertical"
          role="group"
          aria-label="Zoom controller"
        >
          <div className="zoom-controller-btns">
            <div
              role="button"
              tabIndex="0"
              onKeyDown={this.zoomReset}
              aria-label="reset"
              type="button"
              id="reset"
              onClick={this.zoomReset}
            >
              <i aria-hidden="true" className="fa  fa-crosshairs fa-2" />
            </div>
            <div
              role="button"
              tabIndex="0"
              onKeyDown={this.zoomIn}
              aria-label="zoom in"
              type="button"
              id="zoom-in"
              onClick={this.zoomIn}
            >
              <i aria-hidden="true" className="fa fa-plus fa-1" />
            </div>
            <div
              role="button"
              tabIndex="0"
              onKeyDown={this.zoomOut}
              aria-label="zoom out"
              type="button"
              id="zoom-out"
              onClick={this.zoomOut}
            >
              <i aria-hidden="true" className="fa fa-minus fa-1" />
            </div>
          </div>
        </div>
        {this.blockedAreaAccess.canCreate && !smallScreen ? (
          <div
            role="presentation"
            className={
              this.state.blockMode !== "normal"
                ? "block-btn deactive"
                : "block-btn"
            }
            onClick={this.drawBlockedArea}
          >
            <i aria-hidden="true" className="fa fa-pencil" />
          </div>
        ) : null}

        <svg id="airport-pan" width="100%" height="100%">
          <g className="zoomLayer">
            <g className="inner-zoom">
              <Defs />
              <g className="airport">
                <AsmgcsSvg />
                <path />
                {this.tooltipAndFlightAccess.canView
                  ? this.props.airplanes.map((d, i) => {
                      const {
                        on_block,
                        current_location,
                        next_location,
                        path,
                        off_block,
                        aircraft_registration,
                        icao_callsign,
                        speed,
                        flight_number,
                      } = d;
                      return (
                        <AirPlane
                          scale={this.scale()}
                          resize={this.props.size.height}
                          projection={this.props.projection}
                          activateFlight={this.props.activateFlight}
                          active={this.props.selectedAirplane}
                          showToolTips={this.props.showToolTips}
                          hideToolTips={this.props.hideToolTips}
                          on_block={on_block}
                          off_block={off_block}
                          key={aircraft_registration}
                          flight_number={flight_number}
                          speed={+speed}
                          aircraft_registration={aircraft_registration}
                          icao={icao_callsign}
                          coordinates={current_location}
                          current_location={current_location}
                          next_location={next_location}
                          path={path}
                          size={this.props.size}
                          flight={d}
                        />
                      );
                    })
                  : null}
              </g>
              {this.state.vdgsData.map((vdgs) => (
                <Vdgs
                  vdgs={vdgs}
                  resize={this.props.size.height}
                  key={vdgs.stand}
                  projection={this.props.projection}
                  showToolTips={this.props.showToolTips}
                  hideToolTips={this.props.hideToolTips}
                  toggleStandInfo={this.toggleStandInfo}
                  size={this.props.size}
                  scale={this.scale()}
                  timeZone={this.props.timeZone}
                />
              ))}
              <BlockNoise
                resize={this.props.size.height}
                projection={this.props.projection}
                showToolTips={this.props.showToolTips}
                hideToolTips={this.props.hideToolTips}
                toggleNoiseInfo={this.noiseInfo}
                size={this.props.size}
                scale={this.scale()}
              />
              {this.blockedAreaAccess.canView
                ? this.state.blockedAreas.map((block) => (
                    <BlockedArea
                      block={block}
                      key={block.id}
                      projection={this.props.projection}
                      showToolTips={this.props.showToolTips}
                      hideToolTips={this.props.hideToolTips}
                      blockMode={this.state.blockMode}
                      blockedAreas={this.state.blockedAreas}
                      toggleRectInfo={this.toggleRectInfo}
                      deleteBlockedArea={this.deleteBlockedArea}
                      editBlockedArea={this.editBlockedArea}
                    />
                  ))
                : null}
            </g>
          </g>
        </svg>

        {this.state.renderBlockedAreaRectTooltip ? (
          <div className="blocked-area-text-overlay">
            <BlockedAreaRectTooltip
              currentBlockId={this.state.currentBlockId}
              currentNote={this.state.currentNote}
              currentColor={this.state.currentColor}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              addBlockedArea={this.addBlockedArea}
              updateBlockedArea={this.updateBlockedArea}
              unmountMe={this.handleChildUnmount}
              skip={this.skipDraggableRect}
              timeZone={this.props.timeZone}
            />
          </div>
        ) : null}
        <BlockedAreaInfo
          projection={this.props.projection}
          active={this.state.blockMode === "normal"}
          toggleRectInfo={this.toggleRectInfo}
          blockMode={this.state.blockMode}
          dataId={this.state.id}
          note={this.state.note}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          status={this.state.blockedAreaStatus}
          x={this.state.blockedAreaX}
          y={this.state.blockedAreaY}
          width={this.state.blockedAreaWidth}
          height={this.state.blockedAreaHeight}
          tooltipPosition={this.state.tooltipPosition}
          deleteBlockedArea={this.deleteBlockedArea}
          editBlockedArea={this.editBlockedArea}
          canEdit={this.blockedAreaAccess.canUpdate}
          canDelete={this.blockedAreaAccess.canDelete}
          timeZone={this.props.timeZone}
        />
        <StandInfo
          timeZone={this.props.timeZone}
          dataId={this.state.standId}
          name={this.state.standName}
          status={this.state.standStatus}
          aircraft_registration={this.state.aircraft_registration}
          iata={this.state.iata}
          error={this.state.error}
          block_time={this.state.block_time}
          estimate_block_time={this.state.estimate_block_time}
          aircraft_type={this.state.aircraft_type}
          color={this.state.status_color}
          hideToolTips={this.props.hideToolTips}
        />
        {this.state.flightsNoiseDetails && (
          <NoiseInfo
            tooltipPosition={this.state.tooltipPosition}
            hideToolTips={this.showNoiseDetails}
            activeFlights={this.props.airplanes}
            size={this.props.size}
          />
        )}

        {this.props.selectedAirplane.aircraft_registration && (
          <AirPlaneToolTip
            flight_number={this.props.selectedAirplane.flight_number}
            airlineLogo={this.getAirlineLogo(
              this.props.selectedAirplane.flight_number,
              this.props.selectedAirplane.iata,
            )}
            aircraft_registration={
              this.props.selectedAirplane.aircraft_registration
            }
            flight={this.props.selectedAirplane.flight}
            timeZone={this.props.timeZone}
            onCloseToolTip={this.onCloseToolTip}
            showToolTips={this.props.showToolTips}
          />
        )}
      </div>
    );
  }

  // Display blocked area note / comment
  toggleRectInfo(ev, show) {
    if (show) {
      if (ev) {
        const target = d3.select(`#main-block${ev.target.dataset.id}`);
        this.setState(
          {
            id: ev.target.dataset.id,
            note: ev.target.dataset.note,
            startDate: ev.target.dataset.startdate,
            endDate: ev.target.dataset.enddate,
            blockedAreaStatus: ev.target.dataset.status,
            blockedAreaX: target.attr("x"),
            blockedAreaY: target.attr("y"),
            blockedAreaWidth: target.attr("width"),
            blockedAreaHeight: target.attr("height"),
            tooltipPosition: ev.target.getBoundingClientRect(),
          },
          () => {},
        );
      }
    }
  }

  // Display VDGS tooltip
  toggleStandInfo(stand, tooltipPosition, color, show) {
    const vdgs = this.state.vdgsData.filter((data) => data.stand === stand)[0];
    if (show) {
      if (stand) {
        this.setState(
          {
            standId: vdgs.id,
            standName: vdgs.stand,
            standStatus: vdgs.status,
            aircraft_registration: vdgs.aircraft_registration,
            iata: vdgs.iata,
            error: vdgs.error,
            block_time: vdgs.block_time,
            estimate_block_time: vdgs.estimate_block_time,
            aircraft_type: vdgs.aircraft_type,
            status_color: color,
            tooltipPosition,
          },
          () => {},
        );
      }
    }
  }

  noiseInfo(tooltipPosition, show) {
    if (show) {
      this.setState({
        tooltipPosition,
        flightsNoiseDetails: true,
      });
    }
  }

  // Display Airplane tooltip
  getAirlineLogo(flight_number, asmgcsIata) {
    if (!flight_number && !asmgcsIata) return null;
    const airlines = this.props.auth.user.airlines || [];
    const iata = asmgcsIata
      ? asmgcsIata.slice(0, 2)
      : flight_number.slice(0, 2);
    const _airline = airlines.filter(
      (e) => e.iata.toLowerCase() === iata.toLowerCase(),
    );
    return _airline[0].logo;
  }

  // Remove/Delete blocked area from db
  deleteBlockedArea(data) {
    if (this.blockedAreaAccess.canDelete) {
      return fetch(`/api/blockedareas/${data.id}`, {
        method: "delete",
        signal: this.signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        credentials: "include",
      })
        .then((resp) => resp.json())
        .catch((e) => {});
    }
  }

  // Skip Creating/Editing blocked area
  skipDraggableRect() {
    this.draggableRect.unmountDraggableRect();
    this.draggableRect.removeSvgEventsListener();
    this.setState({ blockMode: "normal" });
  }

  // Display blocked area as draggable rect to enable editing
  editBlockedArea(data) {
    if (this.blockedAreaAccess.canUpdate) {
      this.setState({ blockMode: "edit" });
      const blockedAreaData = {
        x: +data.x,
        y: +data.y,
        width: +data.width,
        height: +data.height,
        note: data.note,
        color: data.color,
        id: data.id,
      };
      this.draggableRect = new DraggableRect(
        blockedAreaData,
        this.props.projection,
      );
      this.draggableRect.removeRect.on("click", () => {
        this.skipDraggableRect();
      });
      this.draggableRect.rectangleElement.on("dblclick", () =>
        this.setState({
          renderBlockedAreaRectTooltip: true,
          currentBlockId: data.id,
          currentNote: blockedAreaData.note,
          currentColor: blockedAreaData.color,
        }),
      );
    }
  }

  // Set/Update blocked area with new dimension || note || color
  updateBlockedArea(data) {
    const topLeftCorner = this.draggableRect.topLeftCoords;
    const bottomRightCorner = this.draggableRect.bottomRightCoords;
    data.dimension = {
      x1: topLeftCorner[0],
      y1: topLeftCorner[1],
      x2: bottomRightCorner[0],
      y2: bottomRightCorner[1],
    };
    if (this.blockedAreaAccess.canUpdate) {
      return fetch(`/api/blockedareas/${data.id}`, {
        method: "put",
        signal: this.signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        credentials: "include",
        body: JSON.stringify({ ...data }),
      })
        .then((resp) => resp.json())
        .then(() => {
          this.skipDraggableRect();
        })
        .catch((e) => {});
    }
  }

  // Add new blocked area to db
  addBlockedArea(data) {
    const topLeftCorner = this.draggableRect.topLeftCoords;
    const bottomRightCorner = this.draggableRect.bottomRightCoords;

    data.dimension = {
      x1: topLeftCorner[0],
      y1: topLeftCorner[1],
      x2: bottomRightCorner[0],
      y2: bottomRightCorner[1],
    };
    return fetch("/api/blockedareas", {
      method: "post",
      signal: this.signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({ ...data }),
    })
      .then((resp) => resp.json())
      .then(() => {
        d3.select("svg#airport-pan .zoomLayer .inner-layer")
          .on("mousdown", null)
          .on("mousemove", null);
        this.skipDraggableRect();
      })
      .catch((e) => {});
  }

  // Display draggable rect to add new blocked area
  drawBlockedArea() {
    if (this.blockedAreaAccess.canCreate) {
      this.draggableRect = new DraggableRect(null, this.props.projection);
      this.setState({ blockMode: "add" });
      d3.select("svg#airport-pan .zoomLayer .inner-zoom").style(
        "cursor",
        "crosshair",
      );
      this.draggableRect.removeRect.on("click", () => this.skipDraggableRect());
      this.draggableRect.rectangleElement.on("dblclick", () =>
        this.setState({
          renderBlockedAreaRectTooltip: true,
          currentNote: null,
          currentColor: null,
        }),
      );
    }
  }

  componentWillUnmount() {
    this.ASMGCS_socket.disconnect();
    this.vdgs_socket.disconnect();
    this.blockedAreas_socket.disconnect();

    this.controller.abort();
    clearTimeout(this.blockedAreaTimer);
    clearTimeout(this.preloaderTimer);
    clearTimeout(this.resetSVGTimer);
  }

  render() {
    return (
      <div className="asmgcs-container">
        {this.state.preloader ? <Loading /> : null}
        <div className="airport-pan-contianer">
          <div className="airport-pan-row airport-pan-row-1">
            {this.renderAirPlanes()}
          </div>
          {this.state.flightsDetails ? (
            <div className="airport-pan-row airport-pan-row-2">
              <AirPlanePanelInfo
                activateFlight={this.props.activateFlight}
                onCloseToolTip={this.onCloseToolTip}
                airplanes={this.props.airplanes}
                active={this.props.selectedAirplane.icao_callsign}
                airlines={this.props.auth.user.airlines || []}
                timeZone={this.props.timeZone}
              />
            </div>
          ) : null}
        </div>
        {!this.state.flightsDetails ? (
          <ColorsCode widgetColors={vdgsCodes} />
        ) : null}
      </div>
    );
  }
}

import { actions as reduxToolTipActions } from "react-redux-tooltip";
import {
  ACTIVATE_FLIGHT,
  UPDATE_AIRPLANES,
  DEACTIVATE_FLIGHT,
  SET_ALERTS,
  TOGGLE_SIDEBAR,
  SET_GENERAL_ALERTS,
} from "../constants";

export const setAlerts = (payload) => ({
  type: SET_ALERTS,
  payload,
});

export const setGeneralAlerts = (payload) => ({
  type: SET_GENERAL_ALERTS,
  payload,
});

export const toggleSidebar = (payload) => ({
  type: TOGGLE_SIDEBAR,
  payload,
});

export const activateFlight = (flight) => (dispatch) => {
  const activeAirplane = document.querySelector("g.active.aircraft");
  if (activeAirplane !== null) {
    const activeTailNumber = activeAirplane.getAttribute("data-tail-number");
    dispatch({ type: DEACTIVATE_FLIGHT, payload: {} });
    dispatch(reduxToolTipActions.hide({ name: activeTailNumber }));
  }
  const airplane = document.querySelector(
    `g[data-tail-number="${flight.flight.aircraft_registration}"]`,
  );
  // This is a work around to get the current active flight number, since this will get the next filght number if done in the convinetaional way.
  // if (document.querySelectorAll('.plane .tool-tip-title')) {
  //   const toolTips = document.querySelectorAll('.plane .tool-tip-title');
  //   for (let i = 0; i < toolTips.length; i += 1) {
  //     const aircraft_registration = toolTips[i].textContent;
  //     dispatch(toolTipActions.hide({ name: aircraft_registration }));
  //   }
  // }
  if (airplane) {
    const airplaneBoundingClientRect = airplane.getBoundingClientRect();
    const origin = {
      x: airplaneBoundingClientRect.left,
      y: airplaneBoundingClientRect.top,
    };
    dispatch(
      reduxToolTipActions.show({
        name: flight.flight.aircraft_registration,
        origin,
      }),
    );
  }

  dispatch({
    type: ACTIVATE_FLIGHT,
    payload: flight,
  });
};

export const deActivateFlight = (aircraft_registration) => (dispatch) => {
  dispatch(
    reduxToolTipActions.hide({
      name: `${aircraft_registration}`,
    }),
  );
  dispatch({ type: DEACTIVATE_FLIGHT, payload: {} });
};

export const updateAirplanes = (airplanes) => ({
  type: UPDATE_AIRPLANES,
  payload: airplanes,
});

export const updateObject = (oldObject = {}, updatedValues = {}) => {
  return {
    ...oldObject,
    ...updatedValues,
  };
};

export const updateData = (state = {}, newData = {}, key1 = "", key2 = "") => {
  const updatedData = [];

  state[key1].forEach((element) => {
    updatedData.push(element.id !== newData.id ? element : newData);
  });

  return {
    [key1]: updatedData,
    [key2]: newData,
    loading: false,
    total: state.total,
  };
};

export const updateState = (state, newData, key1 = "", key2 = "") => {
  const updatedChannels = state[key1].map((channel) => {
    return channel.id === newData[key1]?.id ? newData[key1] : channel;
  });
  return {
    ...state,
    channels: updatedChannels,
    selectedChannel: newData[key1],
  };
};

export const deleteMultiDataChannels = (
  state = {},
  dataIds = [],
  key1 = "",
) => {
  const updatedData = state[key1].filter((ele) => !dataIds.includes(ele.id));
  return {
    ...state,
    channels: updatedData,
    loading: false,
    total: state.total - updatedData.length,
  };
};

export const deleteData = (state = {}, dataId = "", key1 = "") => {
  const updatedData = state[key1].filter((ele) => ele.id !== dataId);
  return {
    [key1]: updatedData,
    loading: false,
    total: state.total - 1,
  };
};

export const deleteMultiData = (state = {}, dataIds = [], key1 = "") => {
  const updatedData = state[key1].filter((ele) => !dataIds.includes(ele.id));
  return {
    [key1]: updatedData,
    loading: false,
    total: state.total - 1,
  };
};

export const convertArrayToObject = (data = [], key = "") => {
  const obj = {};

  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    obj[element[key]] = element;
  }

  return obj;
};

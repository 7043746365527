import React from "react";
import { useSelector } from "react-redux";
import LayoutRouter from "./layout_router";

const Layout = ({
  widgets,
  layout,
  saveLayoutConfig,
  saveLayoutAsFavorite,
  checkSelectedLayout
}) => {
  const { layouts } = useSelector((state) => state.Layouts);
  const getLayoutsData = () => {
    const newLayouts = {};

    layouts.map((e) => {
      newLayouts[e.id] = {};
      newLayouts[e.id].alias = e.alias;
      newLayouts[e.id].direction = e.direction;
      newLayouts[e.id].blocks_number = e.blocks_number;
      newLayouts[e.id].blocks = e.layout_config;
      newLayouts[e.id].user_role_id = e.user_role_id;

      return null;
    });

    return newLayouts;
  };

  const getLayoutData = () => {
    let layoutData = {};
    const newLayouts = getLayoutsData();
    if (Object.keys(newLayouts)) {
      const blocks = { ...newLayouts[layout.id]?.blocks };

      layoutData = {
        ...layout,
        blocks,
      };
    }

    return { layoutData, newLayouts };
  };

  const data = [];
  const { newLayouts, layoutData } = getLayoutData();

  widgets.forEach((element) => {
    const widgetData = { ...element };
    widgetData.alias = `widget:data:${element.id}:`;
    data[element.permission_name] = widgetData;
  });

  return (
    <div className="editable-widgets-wrapper">
      {widgets.length && (
        <LayoutRouter
          layouts={newLayouts}
          widgetsData={data}
          layout={layoutData}
          saveLayoutConfig={saveLayoutConfig}
          saveLayoutAsFavorite={saveLayoutAsFavorite}
          checkSelectedLayout={checkSelectedLayout}
        />
      )}
    </div>
  );
};

export default Layout;

import _ from "lodash";
import { updateObject } from "./utility";
import {
  FETCH_BHS_FAIL,
  FETCH_BHS_SUCCESS,
  FETCH_BHS_START,
  FETCH_BHS_BELTS_START,
  FETCH_BHS_BELTS_SUCCESS,
  FETCH_BHS_BELTS_FAIL,
  REINIT_BHS,
  NEW_BELT_SOCKET,
} from "../constants";

const initialState = {
  belts: [],
  loading: false,
  total: 0,
};

const setBelts = (state, data) => {
  const belts = data.map((el) => {
    const o = Object.assign({}, el);
    o.flights = [];
    return o;
  });

  return updateObject(state, { loading: false, belts });
};

const setBHSData = (state, data) => {
  const belts = state.belts;
  const newBelts = data.map((d) => {
    const belt = belts.filter((b) => b.belt_id === d.bhs_belt_id);
    if (belt.length) {
      if (!belt[0].flights) {
        belt[0].flights = [];
      }
      const flightIndex = belt[0].flights
        .map((e) => e.flight_number)
        .indexOf(d.flight_number);
      if (flightIndex > -1) {
        belt[0].flights[flightIndex] = d;
      } else {
        belt[0].flights.push(d);
      }
    }

    return belt;
  });
  return updateObject(state, { loading: false, newBelts });
};

const handleDelete = (flights) => {
  const flightIndex = flights
    .map((e) => e.flight_number)
    .indexOf(message.flight_number);

  flights.splice(flightIndex, 1);

  return flights;
};

const handleOtherActions = (flights) => {
  const flightIndex = flights
    .map((e) => e.flight_number)
    .indexOf(message.flight_number);

  if (flightIndex > -1) {
    flights[flightIndex] = message;
  } else {
    flights.push(message);
  }

  return flights;
};

const handleNewBelt = (state, { message, action }) => {
  const belts = state.belts;

  const newBelts = belts.map((belt) => {
    if (message.bhs_belt_id === belt.belt_id) {
      if (action === "delete") {
        belt.flights = handleDelete(belt.flights);
      } else {
        belt.flights = handleOtherActions(belt.flights);
      }
    }
    return belt;
  });

  return updateObject(state, { loading: false, belts: newBelts });
};

const acdmReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_BHS:
      return updateObject(state, initialState);

    case FETCH_BHS_START:
      return updateObject(state, { loading: true });
    case FETCH_BHS_SUCCESS:
      return setBHSData(state, action.data);
    case FETCH_BHS_FAIL:
      return updateObject(state, { loading: false });

    case FETCH_BHS_BELTS_START:
      return updateObject(state, { loading: true });
    case FETCH_BHS_BELTS_SUCCESS:
      return setBelts(state, action.data);
    case FETCH_BHS_BELTS_FAIL:
      return updateObject(state, { loading: false });

    case NEW_BELT_SOCKET:
      return handleNewBelt(state, action.data);

    default:
      return state;
  }
};

export default acdmReducer;

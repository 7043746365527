import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

import Marker from "./marker";

const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
];

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapOptions: {},
    };

    this.apiIsLoaded = this.apiIsLoaded.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  apiIsLoaded(map, maps) {
    map.fullscreenControl = false;
    const mapOptions = {
      styles: mapStyle,
      fullscreenControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    };

    this.setState({ mapOptions });
  }

  handleClick(name, sensor) {
    const chart = document.querySelector(".chart-wrapper");
    const filter = document.querySelector(".ems-filter");
    this.props.handleName(name, sensor);
    chart.style.display = "flex";
    filter.style.display = "none";

    const old = document.querySelector(".active-variable5");
    if (old) {
      old.classList.remove("active-variable5");
    }
  }

  render() {
    const doha = {
      lat: 25.2854,
      lng: 51.531,
    };
    return (
      <div className="map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
          defaultCenter={this.props.doha || doha}
          defaultZoom={this.props.zoom || 12}
          options={this.state.mapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
        >
          <Marker
            name="Qatar University" //air2
            onClick={this.handleClick}
            lat={25.37711}
            lng={51.491136}
            mode={
              this.props.selectedFilter === "NO2" ||
              this.props.selectedFilter === "O3"
                ? "green"
                : "yellow"
            }
            type="air"
            currenFilter={this.props.currenFilter}
          />
          <Marker
            name="Hamad Airport" //air1
            onClick={this.handleClick}
            lat={25.277593}
            lng={51.607941}
            mode={
              this.props.selectedFilter === "NO2" ||
              this.props.selectedFilter === "PM10"
                ? "red"
                : "green"
            }
            type="air"
            currenFilter={this.props.currenFilter}
          />
          <Marker
            name="Ar-Rayyan" //noise1
            onClick={this.handleClick}
            lat={25.281734}
            lng={51.413849}
            mode="yellow"
            type="noise"
            currenFilter={this.props.currenFilter}
          />
          <Marker
            name="Doha Port" //noise2
            onClick={this.handleClick}
            lat={25.294606}
            lng={51.543779}
            mode="red"
            type="noise"
            currenFilter={this.props.currenFilter}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;

import { AUTH_SET_TOKEN, AUTH_SET_USER } from "../constants";
import { storageKeys } from "utils/helpers";
import { logoutKeycloak, keyCloakToken } from "./keycloak-actions";
import { getRequest } from "utils/APIRequest";

export const setToken = (token) => ({ type: AUTH_SET_TOKEN, token });
export const setUser = (payload) => ({ type: AUTH_SET_USER, payload });

export const getUserFromToken = (callback) => {
  return async (dispatch) => {
    const response = await getRequest(`/api/users/info`);

    if (!response.error) {
      if (!response.roles || !response.roles.length) {
        callback && callback();
      }

      dispatch(setUser(response));
    } else {
      dispatch(setUser({}));
    }
  };
};

export const updateAuthDetails = (object = {}) => {
  const { access_token, expires_in, refresh_token, refresh_expires_in } =
    object;

  localStorage.setItem(storageKeys.TOKEN, access_token);
  localStorage.setItem(
    storageKeys.TOKEN_EXPIRES_IN,
    new Date(new Date().getTime() + expires_in * 1000),
  );
  localStorage.setItem(storageKeys.REFRESH_TOKEN, refresh_token);
  localStorage.setItem(
    storageKeys.REFRESH_EXPIRES_IN,
    new Date(new Date().getTime() + refresh_expires_in * 1000),
  );
};

const isTokenExpired = () => {
  const tokenExpires = localStorage.getItem(storageKeys.TOKEN_EXPIRES_IN);
  const expiredAt = new Date(tokenExpires).getTime();
  const currentDate = new Date().getTime();
  let expire = expiredAt - currentDate;

  return expire < 1;
};

const isRefreshTokenExpired = () => {
  const refreshExpires = localStorage.getItem(storageKeys.REFRESH_EXPIRES_IN);
  const expiredRefreshToken = new Date(refreshExpires).getTime();
  const currentDate = new Date().getTime();

  return expiredRefreshToken <= currentDate;
};

export const getRefreshToken = async () => {
  const params = {
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    grant_type: "refresh_token",
    redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URL,
    state: "KeyCloak",
    refresh_token: localStorage.getItem(storageKeys.REFRESH_TOKEN),
  };

  return await keyCloakToken(params);
};

export const getAccessToken = async (code, codeVerifier) => {
  let params = {
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    code: code,
    grant_type: "authorization_code",
    redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URL,
    code_verifier: codeVerifier,
    state: "KeyCloak",
  };

  return await keyCloakToken(params);
};

export const logOut = async () => {
  try {
    const response = await logoutKeycloak();
    if (response) {
      localStorage.removeItem(storageKeys.TOKEN);
      localStorage.removeItem(storageKeys.REFRESH_TOKEN);
      localStorage.removeItem(storageKeys.TOKEN_EXPIRES_IN);
      localStorage.removeItem(storageKeys.REFRESH_EXPIRES_IN);

      window.location.href = `/login`;

      return response;
    }
  } catch (error) {
    return false;
  }
};

export const checkIfTokensValid = () =>
  !isTokenExpired() && !isRefreshTokenExpired();

export const getKeyCloakToken = async () => {
  if (!checkIfTokensValid() && localStorage.getItem(storageKeys.TOKEN)) {
    const response = await getRefreshToken();
    updateAuthDetails(response);

    return response.access_token;
  }

  return localStorage.getItem(storageKeys.TOKEN);
};

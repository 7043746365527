import { getKeycloakAccount, updateKeycloakAccount } from "./keycloak-actions";
import {
  FETCH_USER_PROFILE_START,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAIL,
  UPLOAD_USER_START,
  UPLOAD_USER_SUCCESS,
  UPLOAD_USER_FAIL,
  CLEAR_USER_START,
  CLEAR_USER_SUCCESS,
  CLEAR_USER_FAIL,
  ERROR_MESSAGE,
  FETCH_USER_ROLES_AND_AIRLINES_START,
  FETCH_USER_ROLES_AND_AIRLINES_SUCCESS,
  FETCH_USER_ROLES_AND_AIRLINES_FAIL,
  UPDATE_USER_PROFILE_START,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
} from "../constants";
import { getRequest, mergePatchRequest, postRequest } from "utils/APIRequest";
import { storageKeys } from "utils/helpers";

const fetchUserStart = () => ({
  type: FETCH_USER_PROFILE_START,
});

const fetchUserSuccess = (data) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  data,
});

const fetchUserFail = () => ({
  type: FETCH_USER_PROFILE_FAIL,
});

export const fetchUserProfile = () => {
  return async (dispatch) => {
    dispatch(fetchUserStart());

    const response = await getKeycloakAccount();
    if (response) {
      dispatch(
        fetchUserSuccess({
          ...response.attributes,
          email: [response.email],
          name: [response.username],
        }),
      );
    } else {
      dispatch(fetchUserFail());
    }
  };
};

const updateUserStart = () => ({
  type: UPDATE_USER_PROFILE_START,
});

const updateUserSuccess = (data) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  data,
});

const updateUserFail = () => ({
  type: UPDATE_USER_PROFILE_FAIL,
});

export const updateUserProfile = (data) => {
  return async (dispatch) => {
    dispatch(updateUserStart());

    const response = await updateKeycloakAccount(data);

    if (!response.error) {
      dispatch(updateUserSuccess(data));
    } else {
      dispatch(updateUserFail());
    }
  };
};

const uploadUserImageStart = () => ({
  type: UPLOAD_USER_START,
});

const uploadUserImageSuccess = (url) => ({
  type: UPLOAD_USER_SUCCESS,
  url,
});

const uploadUserImageFail = () => ({
  type: UPLOAD_USER_FAIL,
});

export const uploadUserImage = (userId, data, cb) => {
  return async (dispatch) => {
    dispatch(uploadUserImageStart());

    const response = await postRequest(
      `/api/users/upload-image/${userId}`,
      data,
      {
        Cache: "no-cache",
        Authorization: `Bearer ${localStorage.getItem(storageKeys.TOKEN)}`,
      },
      true,
    );

    if (!response.error) {
      dispatch(uploadUserImageSuccess(response.path));
      cb && cb(response.path);
    } else {
      dispatch(uploadUserImageFail());
    }
  };
};

const removeUserImageStart = () => ({
  type: CLEAR_USER_START,
});

const removeUserImageSuccess = () => ({
  type: CLEAR_USER_SUCCESS,
  url: "",
});

const removeUserImageFail = () => ({
  type: CLEAR_USER_FAIL,
});

export const removeUserImage = (userId, data, cb) => {
  return async (dispatch) => {
    dispatch(removeUserImageStart());

    const response = await mergePatchRequest(
      `/api/users/clear-image/${userId}`,
      data,
    );

    if (!response.error) {
      dispatch(removeUserImageSuccess());
      cb && cb("");
    } else {
      dispatch({ type: ERROR_MESSAGE, message: response.message });
      dispatch(removeUserImageFail());
    }
  };
};

const fetchUserRolesAndAirlinesStart = () => ({
  type: FETCH_USER_ROLES_AND_AIRLINES_START,
});

const fetchUserRolesAndAirlinesSuccess = ({ airlines, roles }) => ({
  type: FETCH_USER_ROLES_AND_AIRLINES_SUCCESS,
  airlines,
  roles,
});

const fetchUserRolesAndAirlinesFail = () => ({
  type: FETCH_USER_ROLES_AND_AIRLINES_FAIL,
});

export const fetchUserRolesAndAirlines = () => {
  return async (dispatch) => {
    dispatch(fetchUserRolesAndAirlinesStart());

    const response = await getRequest("/api/users/roles");

    if (!response.error) {
      dispatch(fetchUserRolesAndAirlinesSuccess(response));
    } else {
      dispatch(fetchUserRolesAndAirlinesFail());
    }
  };
};

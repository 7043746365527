import dataFilter from "components/common/filter_manager/data_filter";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/APIRequest";
import {
  FETCH_AIRLINES_START,
  FETCH_AIRLINES_SUCCESS,
  FETCH_AIRLINES_FAIL,
  UPDATE_AIRLINE_IMAGE_START,
  UPDATE_AIRLINE_IMAGE_SUCCESS,
  UPDATE_AIRLINE_IMAGE_FAIL,
  DELETE_AIRLINE_START,
  DELETE_AIRLINE_SUCCESS,
  DELETE_AIRLINE_FAIL,
  RESTORE_AIRLINE_START,
  RESTORE_AIRLINE_SUCCESS,
  RESTORE_AIRLINE_FAIL,
  RESTORE_USERS_AIRLINE_START,
  RESTORE_USERS_AIRLINE_SUCCESS,
  RESTORE_USERS_AIRLINE_FAILS,
  RESTORE_ORGANIZATION_AIRLINE_START,
  RESTORE_ORGANIZATION_AIRLINE_SUCCESS,
  RESTORE_ORGANIZATION_AIRLINE_FAILS,
  CREATE_AIRLINE_START,
  CREATE_AIRLINE_SUCCESS,
  CREATE_AIRLINE_FAIL,
  UPDATE_AIRLINE_START,
  UPDATE_AIRLINE_FAIL,
  FETCH_AIRLINE_START,
  FETCH_AIRLINE_SUCCESS,
  FETCH_AIRLINE_FAIL,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
} from "../constants";

const fetchAirlinesStart = () => ({
  type: FETCH_AIRLINES_START,
});

const fetchAirlinesSuccess = (data, total) => ({
  type: FETCH_AIRLINES_SUCCESS,
  data,
  total,
});

const fetchAirlinesFail = () => ({
  type: FETCH_AIRLINES_FAIL,
});

export const fetchAirlines = (data) => {
  return async (dispatch) => {
    dispatch(fetchAirlinesStart());

    const response = await getRequest(`/api/airlines`, data);

    if (!response.error) {
      dispatch(fetchAirlinesSuccess(response.data, response.total));
    } else {
      dispatch(fetchAirlinesFail());
    }
  };
};

const uploadAirlineImageStart = () => ({
  type: UPDATE_AIRLINE_IMAGE_START,
});

const uploadAirlineImageSuccess = (data) => ({
  type: UPDATE_AIRLINE_IMAGE_SUCCESS,
  data,
});

const uploadAirlineImageFail = () => ({
  type: UPDATE_AIRLINE_IMAGE_FAIL,
});

export const uploadAirlineImage = (airlineId, data) => {
  return async (dispatch) => {
    dispatch(uploadAirlineImageStart());

    const url = `/api/airlines/uploadimage/${airlineId}`;

    const response = await postRequest(url, data);
    if (!response.error) {
      dispatch(uploadAirlineImageSuccess(response));
    } else {
      dispatch(uploadAirlineImageFail());
    }
  };
};

const deleteAirlineStart = () => ({
  type: DELETE_AIRLINE_START,
});

const deleteAirlineSuccess = (data) => ({
  type: DELETE_AIRLINE_SUCCESS,
  data,
});

const deleteAirlineFail = () => ({
  type: DELETE_AIRLINE_FAIL,
});

export const deleteAirline = (ids, cb) => {
  return async (dispatch) => {
    dispatch(deleteAirlineStart());

    const url = `/api/airlines`;

    const response = await deleteRequest(url, { ids });
    if (!response.error) {
      dispatch(deleteAirlineSuccess(ids));
      cb && cb();
    } else {
      dispatch(deleteAirlineFail());
    }
  };
};

const restoreAirlineStart = () => ({
  type: RESTORE_AIRLINE_START,
});

const restoreAirlineSuccess = (data) => ({
  type: RESTORE_AIRLINE_SUCCESS,
  data,
});

const restoreAirlineFail = () => ({
  type: RESTORE_AIRLINE_FAIL,
});

const restoreOrganizationAirlineStart = () => ({
  type: RESTORE_ORGANIZATION_AIRLINE_START,
});

const restoreOrganizationAirlineSuccess = () => ({
  type: RESTORE_ORGANIZATION_AIRLINE_SUCCESS,
});

const restoreOrganizationAirlineFails = () => ({
  type: RESTORE_ORGANIZATION_AIRLINE_FAILS,
});

const restoreUserAirlineStart = () => ({
  type: RESTORE_USERS_AIRLINE_START,
});

const restoreUserAirlineSuccess = () => ({
  type: RESTORE_USERS_AIRLINE_SUCCESS,
});

const restoreUserAirlineFails = () => ({
  type: RESTORE_USERS_AIRLINE_FAILS,
});

export const restoreAirline = (ids, cb) => {
  return async (dispatch) => {
    dispatch(restoreAirlineStart());

    const url = `/api/airlines/restore/multi`;

    const response = await putRequest(url, { ids });
    if (!response.error) {
      dispatch(restoreAirlineSuccess(ids));
      cb && cb(response.users);
    } else {
      dispatch(restoreAirlineFail());
    }
  };
};

export const restoreOrganizationAirline = (ids, cb) => {
  return async (dispatch) => {
    dispatch(restoreOrganizationAirlineStart());
    const url = "/api/airlines/restore/organizationAirline";
    const respone = await putRequest(url, { ids });
    if (!respone.error) {
      dispatch(restoreOrganizationAirlineSuccess(ids));
      cb && cb();
    } else {
      dispatch(restoreOrganizationAirlineFails());
    }
  };
};
export const restoreUsersAirline = (ids, cb) => {
  return async (dispatch) => {
    dispatch(restoreUserAirlineStart());
    const url = "/api/airlines/restore/usersAirline";
    const response = await putRequest(url, { ids });
    if (!response.error) {
      dispatch(restoreUserAirlineSuccess(ids));
      cb && cb();
    } else {
      dispatch(restoreUserAirlineFails());
    }
  };
};

const createAirlineStart = () => ({
  type: CREATE_AIRLINE_START,
});

const createAirlineSuccess = (data) => ({
  type: CREATE_AIRLINE_SUCCESS,
  data,
});

const createAirlineFail = () => ({
  type: CREATE_AIRLINE_FAIL,
});

export const createAirline = (data, cb) => {
  return async (dispatch) => {
    dispatch(createAirlineStart());

    const url = `/api/airlines`;

    const response = await postRequest(url, data, {}, true);
    if (!response.error) {
      dispatch(createAirlineSuccess(response.data));
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "Create airline successfully",
        back: true,
      });
    } else {
      dispatch({
        type: ERROR_MESSAGE,
        message: response.message,
      });

      dispatch(createAirlineFail());
    }
  };
};

const updateAirlineStart = () => ({
  type: UPDATE_AIRLINE_START,
});

const updateAirlineFail = () => ({
  type: UPDATE_AIRLINE_FAIL,
});

export const updateAirline = (dataId, data, cb) => {
  return async (dispatch) => {
    dispatch(updateAirlineStart());

    const url = `/api/airlines/${dataId}`;

    const response = await putRequest(
      url,
      data,
      { Authorization: `Bearer ${localStorage.getItem("token")}` },
      true,
    );
    if (!response.error) {
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "Update airline successfully",
        back: true,
      });
    } else {
      dispatch({ type: ERROR_MESSAGE, message: response.message });

      dispatch(updateAirlineFail());
    }
  };
};

const fetchAirlineStart = () => ({
  type: FETCH_AIRLINE_START,
});

const fetchAirlineSuccess = (data) => ({
  type: FETCH_AIRLINE_SUCCESS,
  data,
});

const fetchAirlineFail = () => ({
  type: FETCH_AIRLINE_FAIL,
});

export const fetchAirline = (dataId) => {
  return async (dispatch) => {
    dispatch(fetchAirlineStart());

    const url = `/api/airlines/${dataId}`;
    const response = await getRequest(url);

    if (!response.error) {
      dispatch(fetchAirlineSuccess(response));
    } else {
      dispatch(fetchAirlineFail());
    }
  };
};

import React from "react";

const air1 = {};
const air2 = {};
const noise1 = {};
const noise2 = {};

air1.NO2 = [
  74.56644, 84.34996, 90.8792, 93.15588, 88.0592, 94.79336, 82.8704, 94.0658,
  75.74896, 89.44664, 86.4424, 88.12688, 86.96692, 85.23732, 94.08836, 88.80932,
  97.34076, 69.60512, 77.20596, 84.4872, 83.37048, 71.10724, 78.93556, 62.80328,
  59.52644, 61.05112, 64.53288, 75.7264, 66.10268, 63.85044, 54.72492, 53.3826,
  52.29032, 52.8374, 61.14136, 59.73136, 58.95868, 63.121, 58.36648, 58.98124,
  57.20652, 56.06724, 67.33032, 63.41804, 93.201, 86.82968, 79.1386, 89.01424,
  145.23564, 90.8792, 91.22136, 95.18064, 100.73228, 96.9328, 87.76216,
  105.03184, 86.28448, 85.96488, 92.42644, 90.83408, 68.12744, 73.884, 74.45176,
  63.34848, 74.22428, 91.92636, 69.37764, 89.17404, 63.3936, 60.5736, 46.76312,
  56.1368, 43.3716, 38.16212, 43.84912, 36.56976, 33.99792, 45.87576, 28.17368,
  27.92364, 26.62644, 29.9484, 39.59468, 26.85392, 34.8176, 36.8198, 52.47268,
  63.37104, 75.81664, 97.3652, 91.60864, 96.3406, 90.37912, 96.75044, 102.86984,
  93.97368, 86.057, 94.20116, 96.20336, 91.08412, 94.83848, 91.72144, 91.10668,
  94.81592, 76.65888, 73.97424, 71.2896, 75.11164, 85.80508, 88.03664, 89.87904,
  89.1966, 88.26412, 87.78472, 85.05496, 79.4582, 72.65448, 75.43124, 75.31656,
  74.36152, 68.33236, 70.265, 69.4472, 69.21972, 64.6908, 61.91592, 66.62532,
  68.49028, 66.21548, 59.52644, 58.5714, 61.75612, 58.95868, 60.82364, 58.22924,
  58.54884, 61.84824, 62.64348, 64.89572, 72.06416, 74.90672, 79.32096,
  68.83244, 85.28244, 96.021, 88.83188, 115.385, 94.74824, 93.0412, 92.56368,
  88.37692, 89.12704, 72.8594, 92.42644, 90.78896, 111.17568, 79.23072,
  85.66972, 84.0548, 95.52092, 78.3208, 87.14928, 84.14504, 67.2852, 74.8616,
  90.78896, 72.76916, 85.23732, 76.65888, 91.9038, 79.34352, 86.057, 84.1676,
  65.73796, 86.01, 70.37968, 72.8594, 81.09568, 79.27584, 89.3094, 84.25784,
  89.44664, 87.23952, 91.72144, 78.20612, 101.11768, 89.65156, 101.98248,
  87.64936, 85.4648, 75.20376, 89.65156, 91.9038, 79.07092, 81.00544, 78.07076,
  90.24188, 81.75556, 73.04176, 73.24668,
];

air1.CO = [
  259.813095, 268.22083, 278.087295, 265.49115, 276.45567, 276.378955,
  301.10294, 297.959915, 299.92817, 306.650465, 276.291935, 291.955535,
  320.269095, 283.732145, 278.348355, 287.53927, 275.378225, 265.53466,
  277.478155, 289.040365, 286.32099, 277.173585, 281.220015, 315.428035,
  303.355155, 350.105505, 358.55675, 338.3246, 326.93643, 334.38809, 312.013645,
  324.521625, 306.618405, 308.93474, 308.477885, 291.596005, 299.92817,
  336.93228, 293.06504, 316.67952, 294.67491, 303.311645, 307.0203, 378.407615,
  287.37668, 293.74975, 309.957225, 290.476195, 312.164785, 298.46028,
  307.575625, 360.77576, 351.29058, 297.37253, 304.07307, 315.06965, 291.09564,
  272.419545, 278.0976, 268.384565, 269.820395, 270.505105, 277.945315,
  262.826735, 266.92698, 278.22813, 300.88539, 262.92406, 262.989325, 248.35966,
  244.30178, 252.13358, 248.947045, 257.289515, 237.515365, 243.28044, 244.1827,
  248.14211, 242.475505, 253.982755, 284.02641, 289.812095, 308.303845,
  282.02495, 284.341285, 326.175005, 317.092865, 314.992935, 321.661415,
  324.402545, 298.28624, 347.50521, 328.12265, 336.605955, 291.520435,
  291.36815, 304.10513, 287.07211, 301.603305, 308.63017, 300.602575,
  301.962835, 302.28916, 311.86136, 340.54361, 301.646815, 292.814285,
  295.566865, 289.14914, 280.741405, 379.451855, 282.525315, 277.988825,
  285.66834, 292.651695, 282.687905, 268.428075, 282.351275, 289.07357,
  286.07138, 295.35962, 293.70624, 291.117395, 325.870435, 344.611795,
  365.46339, 341.218015, 372.533765, 318.995855, 314.514325, 364.408845,
  318.01688, 300.67929, 308.41262, 324.76093, 314.221205, 305.54096, 315.97191,
  287.50721, 296.643165, 286.560295, 287.10417, 257.953615, 280.381875,
  286.919825, 309.174045, 267.035755, 250.545465, 242.48581, 241.5721, 244.3888,
  229.31373, 244.041865, 223.35286, 233.36016, 221.1453, 231.96784, 256.04948,
  226.594355, 238.678685, 225.419585, 231.641515, 224.25512, 253.025535,
  243.96515, 227.05121, 234.034565, 226.985945, 236.514635, 235.2746,
  236.427615, 228.204225, 226.028725, 227.6489, 224.984485, 232.555225,
  249.4039, 260.216135, 247.195195, 278.40217, 316.385255, 282.70966,
  300.254495, 299.656805, 310.577815, 292.54292, 331.146595, 272.65885,
  260.117665, 275.79157, 303.20287, 297.76412, 268.26434, 284.63555, 267.024305,
  291.476925, 262.423695, 384.29177, 287.833535, 257.10517,
];

air1.O3 = [
  22.97, 14.635, 25.523, 26.862, 26.228, 27.294, 25.627, 32.098, 37.286, 31.147,
  41.784, 42.894, 36.586, 38.876, 40.56, 40.002, 47.48, 48.617, 43.708, 42.134,
  43.003, 44.812, 41.462, 34.514, 38.089, 36.056, 33.798, 31.716, 31.027,
  28.048, 33.312, 31.41, 30.267, 32.098, 29.617, 30.634, 31.962, 29.245, 28.524,
  28.294, 31.388, 28.321, 32, 27.534, 35.225, 30.645, 32.596, 29.475, 28.813,
  30.109, 27.48, 20.948, 20.806, 27.666, 25.446, 23.03, 25.129, 27.649, 25.851,
  30.366, 25.769, 29.644, 28.327, 27.775, 28.742, 27.764, 27.403, 27.195,
  28.682, 33.257, 31.486, 31.29, 33.066, 29.54, 33.946, 29.535, 31.366, 31.382,
  29.59, 27.748, 22.927, 20.418, 22.276, 22.817, 18.116, 19.237, 18.745, 19.078,
  13.744, 12.995, 15.558, 11.77, 14.853, 15.187, 19.248, 16.187, 19.499, 16.723,
  18.499, 18.663, 17.133, 20.39, 23.73, 23.347, 24.462, 26.141, 29.535, 32.421,
  32.202, 35.908, 32.35, 37.663, 37.554, 37.237, 42.708, 45.966, 44.998, 46.163,
  44.031, 48.748, 42.659, 41.664, 36.674, 35.378, 31.136, 26.179, 25.173,
  24.145, 31.169, 25.752, 22.861, 27.66, 30.606, 27.272, 25.025, 28.332, 28.584,
  23.61, 26.786, 22.194, 26.786, 29.447, 28.485, 27.704, 28.699, 30.016, 24.528,
  28.387, 26.201, 26.392, 27.66, 32.94, 32.979, 30.951, 28.491, 27.983, 30.885,
  28.573, 29.48, 29.409, 29.568, 32.109, 31.082, 29.655, 28.617, 31.295, 30.295,
  30.251, 30.88, 32.355, 29.994, 31.492, 30.366, 29.251, 31.585, 31.049, 28.321,
  26.988, 28.835, 22.325, 23.506, 24.905, 23.872, 22.927, 20.757, 20.713,
  21.046, 25.933, 30.584, 23.572, 24.73, 19.822, 25.67, 25.561, 25.282, 21.128,
  23.818, 20.051, 23.173, 15.187,
];

air1.PM2_5 = [
  5.294, 5.873, 4.98, 5.525, 5.74, 5.889, 5.393, 5.277, 5.029, 4.599, 5.244,
  4.831, 4.93, 5.046, 5.575, 5.625, 5.773, 6.302, 5.856, 5.195, 5.591, 5.36,
  5.939, 5.36, 5.773, 5.658, 5.162, 5.873, 5.41, 5.244, 5.244, 5.095, 5.095,
  5.773, 5.079, 5.658, 5.889, 5.426, 5.426, 5.575, 5.509, 5.492, 5.062, 5.195,
  4.814, 5.277, 5.393, 5.658, 5.261, 5.426, 5.773, 5.757, 5.376, 5.575, 5.492,
  5.74, 5.674, 5.128, 5.062, 5.591, 5.476, 6.054, 5.525, 5.261, 5.988, 5.625,
  5.178, 5.707, 5.228, 5.062, 5.376, 5.509, 5.31, 5.327, 5.393, 5.542, 5.691,
  5.658, 5.393, 5.625, 5.162, 5.228, 5.079, 5.591, 5.343, 5.426, 5.691, 5.509,
  5.145, 5.542, 4.98, 5.525, 5.591, 5.492, 5.575, 5.228, 5.261, 5.443, 5.261,
  5.972, 5.74, 5.707, 5.36, 5.476, 6.418, 5.079, 5.674, 5.79, 6.22, 5.839,
  5.228, 5.658, 6.253, 4.963, 6.038, 5.724, 6.071, 5.988, 5.691, 5.459, 6.154,
  6.319, 5.492, 6.98, 5.509, 6.154, 6.121, 5.542, 6.253, 5.939, 5.591, 5.906,
  6.137, 5.939, 5.31, 5.939, 5.195, 5.674, 5.674, 5.162, 5.459, 5.773, 6.137,
  5.443, 6.137, 5.757, 6.203, 5.707, 6.104, 6.054, 5.939, 5.625, 6.088, 5.41,
  5.906, 6.104, 6.402, 6.319, 6.17, 6.038, 5.575, 6.302, 5.476, 5.658, 5.641,
  6.104, 5.757, 6.22, 6.236, 5.856, 5.922, 5.79, 6.319, 5.74, 5.327, 6.865,
  5.724, 5.625, 5.294, 5.641, 5.773, 5.707, 6.154, 5.575, 5.608, 5.591, 5.906,
  6.021, 6.468, 5.41, 5.542, 6.137, 6.054, 5.806, 5.757, 5.608, 6.236, 6.154,
  6.881, 6.286,
];

air1.PM10 = [
  14.27, 14.786, 9.885, 11.435, 12.101, 12.709, 10.276, 12.032, 11.022, 7.934,
  7.636, 13.111, 9.725, 13.604, 13.007, 17.243, 13.202, 21.111, 13.696, 9.323,
  9.862, 17.3, 12.72, 14.362, 10.126, 9.656, 16.864, 12.973, 14.867, 12.101,
  11.768, 13.168, 12.835, 13.157, 10.31, 11.538, 13.753, 12.778, 10.092, 11.619,
  8.29, 8.944, 11.515, 12.824, 8.703, 11.619, 11.527, 15.888, 9.403, 13.593,
  11.263, 10.712, 10.895, 10.264, 10.367, 9.553, 9.943, 13.03, 9.885, 12.916,
  10.551, 11.355, 9.105, 10.081, 11.814, 11.091, 9.621, 8.531, 13.593, 9.725,
  16.485, 10.379, 9.598, 9.312, 9.185, 12.112, 13.914, 10.528, 10.253, 8.106,
  10.609, 11.274, 11.309, 11.091, 10.631, 11.24, 14.522, 10.769, 8.233, 9.059,
  9.633, 14.591, 11.458, 14.04, 10.7, 12.801, 12.835, 12.743, 11.642, 12.032,
  11.481, 11.814, 7.911, 12.181, 13.65, 6.867, 13.019, 16.554, 10.276, 10.161,
  9.484, 7.751, 10.505, 12.675, 11.492, 10.907, 11.55, 19.148, 8.336, 9.059,
  16.956, 9.862, 10.413, 12.376, 12.238, 8.474, 12.686, 8.324, 12.468, 10.367,
  11.24, 8.68, 11.963, 12.192, 8.462, 9.174, 7.877, 7.383, 10.264, 13.065,
  10.494, 10.494, 9.851, 10.367, 12.055, 8.852, 12.124, 11.515, 9.093, 7.854,
  21.271, 7.819, 12.411, 12.078, 8.187, 13.673, 9.162, 11.205, 10.895, 9.771,
  7.464, 10.586, 10.058, 8.68, 7.246, 11.573, 13.03, 9.277, 10.677, 10.689,
  9.139, 8.531, 9.805, 10.367, 10.413, 20.227, 12.342, 8.152, 13.294, 10.689,
  10.987, 11.779, 10.861, 12.032, 9.553, 11.974, 12.445, 14.293, 12.606, 10.046,
  9.885, 10.54, 10.264, 9.082, 11.068, 10.517, 12.353, 10.666, 13.937, 12.342,
];

noise1.noise = [
  64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64,
  63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63,
  64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64, 63, 64,
  63, 64, 63, 64, 63, 63, 56, 61, 60, 61, 57, 60, 60, 61, 66, 59, 64, 59, 63,
  59, 61, 59, 62, 63, 60, 60, 62, 63, 61, 59, 61, 65, 64, 62, 64, 61, 62, 59,
  60, 67, 61, 62, 59, 68, 55, 56, 65, 66, 60, 62, 62, 57, 62, 58, 60, 59, 62,
  56, 60, 61, 57, 59, 62, 61, 59, 55, 64, 58, 56, 61, 58, 59, 66, 55, 58, 58,
  58, 60, 59, 62, 59, 59, 62, 63, 63, 64, 58, 58, 55, 57, 55, 58, 57, 58, 57,
  58, 57, 55, 59, 58, 57, 56, 60, 57, 63, 56, 57, 56, 59, 65, 59, 60, 55, 63,
  62, 58, 60, 58, 57, 58, 55, 55, 57, 57, 57, 59, 57, 60, 55, 60, 55, 55, 58,
  55, 57, 57, 55, 57, 58, 58, 57, 55, 58, 60, 62, 62, 57, 62, 58, 60, 59, 62,
  56, 60, 61, 57, 59, 62, 61, 59, 55, 64, 58, 56, 61, 58, 59, 66, 55, 58, 58,
  58, 60, 59, 62, 59, 59, 62, 63, 63, 64, 58, 58, 55, 57, 55, 58, 57, 58, 57,
  58, 57, 55, 59, 58, 57, 56, 60, 57, 63, 56, 57, 56, 59, 65, 59, 60, 55, 63,
  62, 58, 60, 58, 57, 58, 55, 55, 57, 57, 57, 59, 57, 60, 55, 60, 55, 55, 58,
  55, 57, 57, 55, 57, 58, 58, 57, 55, 58,
];

air2.NO2 = air1.NO2.map((e) => {
  if (e < 50) {
    return e;
  }
  return Math.random() * (49 - 30) + 30;
});

air2.CO = air1.CO.map((e) => {
  if (e > 230) {
    return e;
  }
  return Math.random() * (230 - 200) + 200;
});

air2.O3 = air1.O3.map((e) => {
  return e + Math.random() * (9 - 5) + 5;
});

air2.PM10 = air1.PM10;
air2.PM2_5 = air1.PM2_5;
noise2.noise = noise1.noise.map((e) => {
  if (e < 60) {
    return e;
  }
  return Math.random() * (80 - 70) + 70;
});
const sensors = { air1, air2, noise1, noise2 };

const shuffle = (array) => {
  array.sort(() => Math.random() - 0.5);
  return array;
};

const getData = (variable, sensor) => {
  // if (variable !== 'noise') {
  // }
  const sensorData = sensors[sensor];
  if (sensorData) {
    const variableData = sensorData[variable];
    // return variableData
    return variableData.concat(variableData, variableData);
  }
  const shuffledArr = shuffle(air1.CO);
  const data = shuffledArr.concat(shuffledArr, shuffledArr);
  return data;
};

export default getData;

import React from "react";
import { Button } from "@mui/material";
import styles from "./button.module.css";
import classNames from "classnames";

const GeneralButton = ({
  type,
  children,
  size = "large",
  onClick,
  ...rest
}) => {
  return (
    <Button
      onClick={onClick}
      {...rest}
      className={classNames(styles.container, styles[type], styles[size])}
    >
      {children}
    </Button>
  );
};

export default GeneralButton;

import classNames from "classnames";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateUserLayoutConfig } from "redux/actions";
import SideBarWidgetItem from "./SidebarWidgetItem";
import { isAuthorized } from "../../../utils/helpers";
import { AuthContext } from "context/authWidget";

const Sidebar = () => {
  const [showMore, setShowMore] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { sidebar } = useSelector((state) => state);
  const { canView } = isAuthorized(user?.roles, "dashboard_permission");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { widgetToView } = useContext(AuthContext);

  const saveLayoutConfig = (widget) => {
    const newWidget = {
      layout_config: {
        direction: "row",
        blocks: {
          block1: {
            content: widget,
            height: "100%",
            width: "100%",
          },
        },
      },
    };
    dispatch(
      updateUserLayoutConfig(user.roles[0].id, newWidget, () => {
        navigate("/");
      }),
    );
  };

  const routeTo = (path) => {
    navigate(path);
  };

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const currentLocation = location.pathname;
  if (currentLocation === "/login" || currentLocation === "/me/account") {
    return null;
  }

  const isActiveSideBar = () => {
    return (
      currentLocation.indexOf("/admin") > -1 &&
      currentLocation.indexOf("/admin/") < 0
    );
  };

  return (
    <div
      role="button"
      className={classNames("sidebar-container", {
        hide: !sidebar,
      })}
    >
      <div
        className={classNames("sidebar-btn", {
          active: currentLocation === "/",
        })}
        tabIndex="0"
        role="button"
        onKeyDown={(ev) => {
          if (ev.keyCode === 13) {
            ev.target.click();
          }
        }}
        onClick={routeTo.bind(this, "/")}
      >
        <i aria-hidden="true" className="sidebar-icon fa fa-home" />
        <span>My Workspace</span>
      </div>
      {canView && (
        <div
          className={classNames("sidebar-btn", {
            active: isActiveSideBar(),
          })}
          tabIndex="0"
          role="button"
          onClick={routeTo.bind(this, "/admin")}
        >
          <i aria-hidden="true" className="sidebar-icon fa fa-cogs" />
          <span>Admin Panel</span>
        </div>
      )}
      <hr />
      <div
        className={classNames("sidebar-btn", { active: showMore })}
        tabIndex="0"
        role="button"
        onClick={toggleShowMore}
      >
        <span className="sidebar-icon">...</span>
      </div>
      {showMore &&
        widgetToView.map((widget) => (
          <SideBarWidgetItem
            key={widget.id}
            saveLayoutConfig={saveLayoutConfig}
            widget={widget}
          />
        ))}
    </div>
  );
};

export default Sidebar;

import React from "react";
import { useSelector } from "react-redux";
import { convertTimeZoneWithFormat } from "utils/helpers";
const AirPlaneCard = (props) => {
  const {
    airline,
    airlines_name,
    flight_number,
    aircraft_registration,
    ades: destination_airport,
    adep: origin_airport,
  } = props.airplane;

  const { timeZone } = useSelector((state) => state.SystemParams);
  const { arrival_time, status, departure_time } = props;
  let airlineLogo;
  const _airline = props.airlines.filter(
    (e) => e.icao.toLowerCase() === airline.toLowerCase(),
  );
  if (_airline.length) {
    airlineLogo = _airline[0].logo;
  }

  const activateFlight = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    props.activateFlight(props.airplane);
  };

  const closeToolTip = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    ev.stopPropagation();
    props.onCloseToolTip({ aircraft_registration });
  };
  return (
    <div
      role="button"
      tabIndex="0"
      onKeyDown={activateFlight}
      className={`airplane ${
        props.active &&
        props.active.slice(3).toString() === flight_number.slice(2).toString()
          ? "active"
          : ""
      }`}
      onClick={activateFlight}
    >
      {props.active ? (
        <div
          role="button"
          tabIndex="0"
          onKeyDown={closeToolTip}
          onClick={closeToolTip}
          className="close-active-flight-btn"
          title="close"
        >
          &times;
        </div>
      ) : null}
      <div className="flight">
        <div className="flight-carrier">
          <img
            id={flight_number}
            className="ember-view"
            src={`${process.env.REACT_APP_ASSETS_PATH}${airlineLogo}`}
            alt={airlines_name}
          />
        </div>
        <div className="flight-info">
          <div className="flight-info-section flight-number">
            <h3 className="flight-info-label">Flight No.</h3>
            <h2 className="flight-info-unit flight_num">{flight_number}</h2>
          </div>
          <div className="plane-info-splitter" />
          <div className="flight-info-section flight-terminal">
            <h3 className="flight-info-label">STATUS</h3>
            <h2 className="flight-info-unit">{status}</h2>
          </div>
          <div className="plane-info-splitter" />
          <div className="flight-info-section flight-tail">
            <h3 className="flight-info-label">Tail</h3>
            <h2 className="flight-info-unit">{aircraft_registration}</h2>
          </div>
        </div>
        <div className="flight-info">
          <div className="flight-info-section flight-origin">
            <h3 className="flight-info-label">Origin</h3>
            <h2 className="flight-info-unit">{origin_airport}</h2>
          </div>
          <div className="plane-info-splitter" />
          <div className="flight-info-section flight-dest">
            <h3 className="flight-info-label">Destination</h3>
            <h2 className="flight-info-unit">{destination_airport}</h2>
          </div>
          <div className="plane-info-splitter" />
          <div className="flight-info-section flight-arrival">
            <h3 className="flight-info-label">Arrival time</h3>
            <h2 className="flight-info-unit">
              {convertTimeZoneWithFormat({
                time: arrival_time,
                timeZone,
                requiredFormat: "HH:mm",
              })}
            </h2>
          </div>
          <div className="plane-info-splitter" />
          <div className="flight-info-section flight-departure">
            <h3 className="flight-info-label">Departure time</h3>
            <h2 className="flight-info-unit">
              {convertTimeZoneWithFormat({
                time: departure_time,
                timeZone,
                requiredFormat: "HH:mm",
              })}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirPlaneCard;

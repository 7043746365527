export const filterUpdateCases = {
  INITIAL_FILTER_ITEM: "INITIAL_FILTER_ITEM",
  UPDATE_FILTER_ITEM: "UPDATE_FILTER_ITEM",
  ADD_DATE_TO_FILTER: "ADD_DATE_TO_FILTER",
  FILTER_POPUP_DATE_OPEN: "FILTER_POPUP_DATE_OPEN",
};

export const filterReducer = (state = {}, action) => {
  switch (action.type) {
    case filterUpdateCases.INITIAL_FILTER_ITEM:
      return {
        filterBy: Object.keys(action.data.filtersVal || {}),
        filtersVal: Object.assign({}, action.data.filtersVal) || {},
      };
    case filterUpdateCases.UPDATE_FILTER_ITEM:
      return updateFilter(state, action);
    case filterUpdateCases.ADD_DATE_TO_FILTER:
      return addDateToFilter(state, action);
    case filterUpdateCases.FILTER_POPUP_DATE_OPEN:
      return { ...state, anchorEl: action.data };
    default:
      return state;
  }
};

const updateFilter = (queue, action) => {
  const filterItems = queue.filterBy;
  const itemIndex = filterItems.indexOf(action.name);
  const newFiltersVals = queue.filtersVal;
  if (itemIndex > -1) {
    filterItems.splice(itemIndex, 1);
    delete newFiltersVals[action.name];
  } else {
    filterItems.push(action.name);
  }
  return {
    filterBy: filterItems,
    filtersVal: newFiltersVals,
    isUpdating: action.isUpdating,
  };
};

export const addDateToFilter = (queue, action) => {
  const obj = { ...queue.filtersVal, ...action.data };
  return {
    filterBy: queue.filterBy,
    filtersVal: obj,
    isUpdating: action.isUpdating,
  };
};

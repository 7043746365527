import {
  convertArrayToObject,
  updateObject,
  deleteMultiDataChannels,
  updateState,
} from "./utility";
import {
  FETCH_CHANNELS_FAIL,
  FETCH_CHANNELS_SUCCESS,
  FETCH_CHANNELS_START,
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAIL,
  CREATE_MESSAGE_START,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAIL,
  REINIT_MESSAGES,
  CREATE_CHANNEL_START,
  CREATE_CHANNEL_SUCCESS,
  CREATE_CHANNEL_FAIL,
  FETCH_USERS_CHANNEL_START,
  FETCH_USERS_CHANNEL_SUCCESS,
  FETCH_USERS_CHANNEL_FAIL,
  FETCH_UNREAD_MESSAGES_START,
  FETCH_UNREAD_MESSAGES_SUCCESS,
  FETCH_UNREAD_MESSAGES_FAIL,
  READ_MESSAGES_START,
  READ_MESSAGES_SUCCESS,
  READ_MESSAGES_FAIL,
  DELETE_CHANNEL_SUCCESS,
  DELETE_CHANNEL_START,
  DELETE_CHANNEL_FAIL,
  UPDATE_CHANNEL_START,
  UPDATE_CHANNEL_SUCCESS,
  UPDATE_CHANNEL_FAIL,
  SET_SEARCHING,
  NEW_UNREAD_MESSAGE,
  SET_SELECTED_CHANNEL,
} from "../constants";

const initialState = {
  messages: [],
  channels: [],
  filterChannels: [],
  selectedChannel: {},
  searching: false,
  unreadMessage: {},
  users: [],
  loading: false,
  total: 0,
};

const createChannel = (state, data, airlines) => {
  if (!data) return state;
  let airlineExists = false,
    channelExists = false;
  const isDuplicate = state.channels.some((obj) => obj?.id === data.channel.id);

  if (isDuplicate) {
    channelExists = true;
  }
  const airlineIds = airlines.map((airline) =>
    airline.airline_channels ? airline.airline_channels.airline_id : null,
  );
  for (let index = 0; index < airlines.length; index++) {
    const airline = airlines[index];
    if (!airlineIds.includes(airline)) {
      airlineExists = true;
      break;
    }
  }

  return updateObject(state, {
    loading: false,
    ...(data &&
      !channelExists &&
      airlineExists && { channels: [data.channels, ...state.channels] }),
  });
};

const setReadMessages = (state, channelId) => {
  const currentUnRead = state.unreadMessage;

  delete currentUnRead[channelId];

  return updateObject(state, {
    loading: false,
    currentUnRead: currentUnRead,
  });
};

const addNewUnreadMessage = (state, newData) => {
  const currentUnreadMessages = { ...state.unreadMessage };
  const channelId = newData.channel_id;
  if (currentUnreadMessages[channelId]) {
    currentUnreadMessages[channelId].chats.push(newData);
  } else {
    currentUnreadMessages[channelId] = {
      id: newData.channel_id,
      chats: [newData],
    };
  }

  return updateObject(state, {
    unreadMessage: currentUnreadMessages,
  });
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_MESSAGES:
      return updateObject(state, initialState);

    case FETCH_CHANNELS_START:
      return updateObject(state, { loading: true });
    case FETCH_CHANNELS_SUCCESS:
      return updateObject(state, {
        loading: false,
        searching: action.isSearch,
        ...(action.isSearch && { filterChannels: action.data }),
        ...(!action.isSearch && { channels: action.data, filterChannels: [] }),
      });
    case FETCH_CHANNELS_FAIL:
      return updateObject(state, { loading: false });

    case SET_SEARCHING:
      return updateObject(state, { searching: action.searching });

    case FETCH_MESSAGES_START:
      return updateObject(state, { loading: true });
    case FETCH_MESSAGES_SUCCESS:
      return updateObject(state, { loading: false, messages: action.data });
    case FETCH_MESSAGES_FAIL:
      return updateObject(state, { loading: false });

    case FETCH_UNREAD_MESSAGES_START:
      return updateObject(state, { loading: true });
    case FETCH_UNREAD_MESSAGES_SUCCESS:
      return updateObject(state, {
        loading: false,
        unreadMessage: convertArrayToObject(action.data, "id"),
      });
    case FETCH_UNREAD_MESSAGES_FAIL:
      return updateObject(state, { loading: false });

    case CREATE_MESSAGE_START:
      return updateObject(state, { loading: true });
    case CREATE_MESSAGE_SUCCESS:
      return updateObject(state, {
        loading: false,
        ...(action.data && { messages: [...state.messages, action.data] }),
      });
    case CREATE_MESSAGE_FAIL:
      return updateObject(state, { loading: false });

    case CREATE_CHANNEL_START:
      return updateObject(state, {
        loading: true,
      });
    case CREATE_CHANNEL_SUCCESS:
      return createChannel(state, action.data, action.airlines);

    case CREATE_CHANNEL_FAIL:
      return updateObject(state, {
        loading: false,
      });

    case FETCH_USERS_CHANNEL_START:
      return updateObject(state, {
        loading: true,
      });
    case FETCH_USERS_CHANNEL_SUCCESS:
      return updateObject(state, {
        loading: false,
        users: action.data,
      });

    case FETCH_USERS_CHANNEL_FAIL:
      return updateObject(state, {
        loading: false,
      });

    case READ_MESSAGES_START:
      return updateObject(state, { loading: true });
    case READ_MESSAGES_SUCCESS:
      return setReadMessages(state, action.channelId);
    case READ_MESSAGES_FAIL:
      return updateObject(state, { loading: false });
    case DELETE_CHANNEL_START:
      return updateObject(state, { loading: true });
    case DELETE_CHANNEL_SUCCESS:
      return deleteMultiDataChannels(state, action.data, "channels");
    case DELETE_CHANNEL_FAIL:
      return updateObject(state, { loading: false });
    case UPDATE_CHANNEL_START:
      return updateObject(state, { loading: true });
    case UPDATE_CHANNEL_SUCCESS:
      return updateState(state, action.data, "channels", "selectedChannel");
    case UPDATE_CHANNEL_FAIL:
      return updateObject(state, { loading: false });
    case NEW_UNREAD_MESSAGE:
      return addNewUnreadMessage(state, action.data);

    case SET_SELECTED_CHANNEL:
      return updateObject(state, { selectedChannel: action.data });

    default:
      return state;
  }
};

export default messagesReducer;

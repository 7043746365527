const dataPrint = (title, selector) => {
  const wrapper = document.createElement("div");
  wrapper.classList.add("printable-wrapper");

  const titleDiv = document.createElement("div");
  titleDiv.classList.add("title");
  titleDiv.innerHTML = title;

  const content = document.querySelector(selector);

  if (content) {
    content.classList.add("printable-wrapper");
    wrapper.appendChild(titleDiv);
    wrapper.appendChild(content.cloneNode(true));

    const html = wrapper.outerHTML;

    const style = `
    @media print {
      body, html {
        background: none !important;
        width: 100%;
        height: 100%;
      }

      table {
        width: 100%;
      }

      table thead tr th {
        color: black !important;
        text-align: center;
        font-family: Proxima Nova;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.58;
        letter-spacing: normal;
        text-align: center;
        color: #333333;
      }

      table tbody tr td {
        text-align: center;
        padding: .6em 0;

        font-family: Proxima Nova;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
      }

      table tr:nth-child(even) {
        background-color: #a6a6a6 !important;
      }

      table tbody tr:nth-child(even) {
        background-color: #a09c9c !important;
      }

      table tbody tr:nth-child(odd) {
        background-color: #706d6d !important;
      }

      .printable-wrapper .title {
        font-size: 1.5em !important;
        font-weight: bold !important;
        color: black !important;
        padding: 1em 0;
      }

      @page {
          margin-top: 2cm;
          margin-bottom: 2cm;
          marginP-left: 2cm;
          margin-right: 2cm;
      }

      p {
        page-break-inside: avoid;
      }

      .editable-field-cbcbcb {
        color: #black;
      }
      .editable-field-1fba1f {
        color: #1fba1f;
      }

      .editable-field-ea554e {
        color: #ea554e;
      }

      .editable-field-7d7de6 {
        color: #7d7de6;
      }

      .MuiSvgIcon-root {
        display:none;
      }
      }
  `;
    const newWindow = window.open("", "", "width=900,height=650");
    newWindow.document.write(
      `<head><title>EMMA</title><style>${style}</style></head><body>${html}</body>`
    );

    newWindow.document.close();
    newWindow.focus();
    newWindow.print();
    newWindow.close();
  }
};

export default dataPrint;

import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import WidgetRouter from "./common/widget_router";
import {
  isAuthorized,
  SocketEvent,
  SocketListener,
  formatManualLogsMessage,
} from "../utils/helpers";
import _ from "lodash";
import setupSocket from "utils/socket_setup";
import { createWebWorker } from "utils/workers";
import { useDispatch, useSelector } from "react-redux";
import { fetchActiveAlerts, fetchGeneralAlerts } from "redux/actions";
import { UPDATE_ALERTS_SOCKET_DATA } from "redux/constants";
import { AuthContext } from "context/authWidget";

const WORKER_URL = "./web-worker.js";

const MainGrid = () => {
  const [fullscreenBlock, setFullscreenBlock] = useState(null);
  const [socketData, setSocketData] = useState({
    alerts: {
      updated: {},
      deleted: [],
    },
    notifications: {
      updated: {},
      deleted: [],
    },
    "general alerts": {
      updated: {},
      deleted: [],
    },
  });

  const dispatch = useDispatch();
  const { auth, SystemParams, UserInfo } = useSelector((state) => state);

  const { widgetToView } = useContext(AuthContext);

  let airlines = [];
  let userLayout = {};

  useEffect(() => {
    let _ismounted = true;
    document.addEventListener(
      "fullscreenchange",
      () => {
        if (!document.fullscreen && _ismounted) {
          setFullscreenBlock(null);
        }
      },
      false
    );

    document.addEventListener(
      "mozfullscreenchange",
      () => {
        if (!document.mozFullScreen && _ismounted) {
          setFullscreenBlock(null);
        }
      },
      false
    );

    document.addEventListener(
      "webkitfullscreenchange",
      () => {
        if (!document.webkitIsFullScreen && _ismounted) {
          setFullscreenBlock(null);
        }
      },
      false
    );

    document.addEventListener(
      "msfullscreenchange",
      () => {
        if (!document.msFullscreenElement && this._ismounted) {
          setFullscreenBlock(null);
        }
      },
      false
    );

    return () => {
      _ismounted = false;
    };
  }, []);

  useEffect(() => {
    getLayoutConfig();
    _resetSocketData();
    const user = auth?.user;

    fetchAlertsInfo();
    const alarm_socket = setupSocket(
      process.env.REACT_APP_ALERTS_SOCKET_PATH,
      auth?.token,
    );
  
    const notifications_socket = setupSocket(
      process.env.REACT_APP_NOTIFICATIONS_SOCKET_PATH,
      auth?.token,
    );

    alarm_socket.on(SocketListener.CONNECT, () => {
      let rooms = [];
      const { ids: selectedFlights } = UserInfo?.selectedFlights;
      if (!selectedFlights?.length) {
        rooms = user ? user.airlines.map((e) => e.iata) : [];
      } else {
        rooms = selectedFlights?.map((ele) => `selectedFlight_${ele}`);
      }
      const { canView } = isAuthorized(user?.roles, "general-alerts");
      if (canView) {
        rooms.push("general-alerts");
      }

      alarm_socket.emit(SocketEvent.ROOM, rooms);

      fetchAlertsInfo();
    });
    acdmAlarmSocket(alarm_socket);

    notifications_socket.on(SocketListener.CONNECT, () => {
      airlines = user ? user.airlines.map((e) => e.iata) : [];

      airlines.push("broadcast");
      notifications_socket.emit(SocketEvent.ROOM, airlines);
    });
    acdmNotificationsSocket(notifications_socket);
    createWebWorker(user, WORKER_URL);
    return () => {
      alarm_socket.disconnect();
      notifications_socket.disconnect();
    };
  }, []);

  function fetchAlertsInfo() {
    const { roles } = auth?.user || {};
    const { canView } = isAuthorized(roles, "ACDM");
    if (!canView) return;

    const { ids: selectedFlights = [] } = UserInfo?.selectedFlights;
    dispatch(
      fetchActiveAlerts({
        ...(selectedFlights.length && {
          flights: selectedFlights,
        }),
      })
    );
    dispatch(fetchGeneralAlerts({}));
  }

  function acdmNotificationsSocket(notifications_socket) {
    notifications_socket.on(SocketListener.ACDM_NOTIFICATIONS, (data) => {
      const { message: socketMessage } = data;
      const message = formatManualLogsMessage({
        ...socketMessage,
        timeZone: SystemParams?.timeZone,
      });
      createNotification({ ...socketMessage, message });
    });
  }

  function createNotification(data) {
    let { message } = data;
    if (data.type === "message") {
      message = `Message For Flight number ${data.flight_number} : ${message}`;
    }
    toast.info(message, {
      position: toast.POSITION.TOP_RIGHT,
      bodyClassName: "grow-font-size acdm-data-changed",
      style: { fontFamily: "Proxima Nova" },
    });
  }

  function _resetSocketData() {
    setSocketData({
      alerts: {
        updated: {},
        deleted: [],
      },
      notifications: {
        updated: {},
        deleted: [],
      },
      generalAlerts: {
        updated: {},
        deleted: [],
      },
    });
  }

  const debounceData = _.debounce(() => {
    dispatch({ type: UPDATE_ALERTS_SOCKET_DATA, socketData });
    _resetSocketData();
  }, 1000);

  const acdmAlarmSocket = (alarm_socket) => {
    alarm_socket.on(SocketListener.ACDM_ALERTS, ({ action, message }) => {
      if (!message) return;

      const { id, show_type } = message;
      const currentAlertData = socketData[show_type];

      let { deleted, updated } = { ...currentAlertData };

      if (action === "delete") {
        deleted.push(id);
      } else {
        updated = { ...updated, [id]: message };
      }
      socketData[show_type] = { updated, deleted };

      debounceData();
    });
  };

  function renderBlockAsFullscreen(ev) {
    const isInFullScreen =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);
    const block = document.querySelector(`body`);
    if (!isInFullScreen) {
      setFullscreenBlock(ev.target.dataset.blockid);
      if (block.requestFullscreen) {
        block.requestFullscreen();
      } else if (block.mozRequestFullScreen) {
        block.mozRequestFullScreen();
      } else if (block.webkitRequestFullScreen) {
        block.webkitRequestFullScreen();
      } else if (block.msRequestFullscreen) {
        block.msRequestFullscreen();
      }
    } else {
      setFullscreenBlock(null);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }

  function getWidgetData() {
    const data = {};
    (widgetToView || []).forEach((element) => {
      const widgetData = { ...element };
      widgetData.alias = `widget:data:${element.id}`;
      data[element.permission_name] = widgetData;
    });

    return (
      <div className="widgets-wrapper">
        {!fullscreenBlock ? (
          <ToastContainer
            position="top-right"
            type="default"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnHover
          />
        ) : null}
        {layoutsRouter(data)}
      </div>
    );
  }

  function getLayoutConfig() {
    if (auth?.user) {
      userLayout = auth?.user?.roles[0]?.users_roles?.layout_config;
    }
    return userLayout || {};
  }

  function layoutsRouter(data) {
    const smallScreen = window.matchMedia("(max-width: 992px)").matches;
    const layoutConfig = getLayoutConfig();
    let { blocks } = layoutConfig;
    if (blocks) {
      if (smallScreen) {
        blocks = { block1: blocks.block1 };
      }
      return (
        <div
          className={`layout-wrapper ${
            layoutConfig.direction === "column" ? "column" : ""
          }`}
        >
          {Object.keys(blocks).map((key) => {
            const resizeIcon =
              fullscreenBlock === key ? "compress" : "arrows-alt";
            const height = smallScreen ? "100%" : blocks[key].height;
            const width = smallScreen ? "100%" : blocks[key].width;
            const maxWidth = smallScreen ? "100%" : blocks[key].width;
            return (
              <div
                key={key}
                id={key}
                className={`${key} ${
                  fullscreenBlock === key ? "fullscreen-container" : ""
                }`}
                style={{ height, width, maxWidth:width }}
              >
                {fullscreenBlock ? (
                  <ToastContainer
                    position="top-right"
                    type="default"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnHover
                  />
                ) : null}
                {!smallScreen ? (
                  <a
                    role="button"
                    tabIndex="0"
                    onKeyDown={(ev) => {
                      if (ev.keyCode === 13) {
                        renderBlockAsFullscreen(ev);
                      }
                    }}
                    data-blockid={key}
                    className="block-resize-icon"
                    onClick={(ev) => renderBlockAsFullscreen(ev, blocks[key])}
                  >
                    <i data-blockid={key} className={`fa fa-${resizeIcon}`} />
                  </a>
                ) : null}
                <WidgetRouter
                  layoutConfig={layoutConfig}
                  auth={auth}
                  fullScreenWidget={fullscreenBlock === key}
                  widgetsData={data}
                  component={blocks[key].content}
                  block={key}
                  systemParams={SystemParams.systemParams}
                  fullscreenBlock={fullscreenBlock}
                />
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }

  return <div className="sub-main-container">{getWidgetData()}</div>;
};

export default MainGrid;

import React, { memo } from "react";
import _ from "lodash";

const ColumnsFilter = memo(
  ({ listActiveFields, updateColumns, closeFilter, listHeaders }) => {
    const toggleColumn = (ev) => {
      if (ev.keyCode && ev.keyCode !== 13) {
        return null;
      }

      const checkbox = ev.target;
      const { title } = ev.target.dataset;

      if (checkbox.checked) {
        listActiveFields.push(title);
      } else {
        const index = listActiveFields.indexOf(title);
        listActiveFields.splice(index, 1);
      }

      updateColumns(listActiveFields);
    };

    const handleClick = (ev) => {
      if (ev.keyCode && ev.keyCode !== 13) {
        return null;
      }
      closeFilter("showColmnsFilter");
    };

    return (
      <div className="filter-by">
        <div className="header-section">
          <div className="filter-header">Show/Hide columns</div>
          <div
            tabIndex="0"
            role="button"
            data-confirm="no"
            onKeyDown={handleClick}
            onClick={handleClick}
            className="filter-close"
          >
            &times;
          </div>
        </div>

        <div className="filter-items-wrapper">
          {_.orderBy(listHeaders, ["title"], ["asc"]).map((e, i) => (
            <div className="filter-item" key={e.title}>
              <label className="record filter-record acdm-record">
                <input
                  type="checkbox"
                  data-title={e.title}
                  name={e.name}
                  onChange={toggleColumn}
                  checked={listActiveFields.includes(e.title)}
                />
                <span className="checkrecord" />
              </label>
              <div className="filter-input">
                <div className="item-name" role="presentation">
                  {e.title}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  },
);

export default ColumnsFilter;

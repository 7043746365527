import React, { useState } from "react";
import Rating from "../modal_rating/rating";
import Switch from "../switch/switch";

const InfoAlert = (props) => {
  const [silentAlert, setSilentAlert] = useState(false);

  const handleClick = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }

    if (props.message?.show_type !== "general alerts") {
      setSilentAlert(!silentAlert);
    }
    props.cb();
  };

  const closeAlerts = (ev) => {
    return fetch(`/api/activity/${props.message.id}`, {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        props.cb();
      });
  };

  const staticAlert = () => {
    return (
      <div className="container-btns">
        <button className="close-alert-btn" onClick={closeAlerts}>
          Close alert
        </button>
        <button className="activity-btn" onClick={handleClick}>
          Done
        </button>
      </div>
    );
  };

  const isGeneralAlert = () => {
    return props.message.show_type === "general alerts";
  };

  const doneHandler = (e) => {
    if (silentAlert) {
      closeAlerts(e);
    } else {
      handleClick(e);
    }
  };

  const intervalAlert = () => {
    return (
      <div>
        <div className="container-btn">
          <button className="activity-btn" onClick={doneHandler}>
            Done
          </button>
        </div>
        {!isGeneralAlert() && (
          <Switch
            name="silent"
            label="Don't notify me again"
            value={silentAlert}
            onChange={(ev) => setSilentAlert(!silentAlert)}
          />
        )}
      </div>
    );
  };

  const messages = {
    alerts: `${props.message.alert_code} alert - ${props.message.alert_description} for flight ${props.message.flight_number}`,
    "general alerts": props.message.alert_description,
  };

  const alertActions = {
    interval: intervalAlert(),
    static: staticAlert(),
  };

  return (
    <div className={`emma-modal-alert ${props.overlay ? "overlay" : ""}`}>
      <div style={{ zIndex: 1301 }} className="modal-content-alert">
        <div>
          <div
            tabIndex="0"
            role="button"
            onKeyDown={handleClick}
            onClick={handleClick}
            className="close"
          >
            &times;
          </div>
          <div className="activity-info">
            <div className="container-rate">
              <Rating
                value={props.message.level}
                className="container-rating"
              />
            </div>
            <div className="activity-action">
              {props.message?.show_type === "general alerts" && (
                <div className="font-action">Action: </div>
              )}
              <span className="font-action">
                {messages[props.message?.show_type]}
              </span>
            </div>
            <div className="modal-desc-alert activity-message">
            Action: {props.message.required_actions}
            </div>
            <div className="card-activity-btn">
              {props.canClose &&
                alertActions[props.message?.alert_type || "interval"]}

              {!props.canClose && (
                <div className="container-btn">
                  <button className="activity-btn" onClick={handleClick}>
                    Done
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoAlert;

import React from "react";
import styles from "./manage_layout.module.css";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import classNames from "classnames";
import Button from "components/common/Button";

const LayoutListItem = ({
  layout,
  showDivider,
  onDelete,
  layoutId,
  saveLayoutConfig,
  onUpdate,
}) => {
  const onLoadLayout = () => {
    const layoutConfig = {};
    const blocks = layout.layout_config;

    for (const key in blocks) {
      const block = blocks[key];
      layoutConfig[key] = block.content;
    }

    const newLayoutConfig = {
      ...layout,
      layoutConfig,
      id: layoutId,
    };

    saveLayoutConfig(newLayoutConfig);
  };

  const updateHandler = () => {
    onUpdate(layout);
  };
  const deleteHandler = () => {
    onDelete(layout);
  };
  return (
    <>
      <div className={styles.item_container}>
        <span className={styles.list_item_name}>{layout.alias}</span>
        <div className={styles.item_actions}>
          <CreateIcon onClick={updateHandler} className={styles.item_action} />
          <DeleteIcon
            onClick={deleteHandler}
            className={classNames(styles.item_action, styles.action_delete)}
          />
          <Button size="small" type="secondary" onClick={onLoadLayout}>
            Load layout
          </Button>
        </div>
      </div>
      {showDivider && <Divider className={styles.item_divider} />}
    </>
  );
};

export default LayoutListItem;

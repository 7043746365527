import { updateObject } from "./utility";

import {
  FETCH_SYSTEM_PARAMS_START,
  FETCH_SYSTEM_PARAMS_SUCCESS,
  FETCH_SYSTEM_PARAMS_FAIL,
  UPDATE_TIME_ZONE_START,
  UPDATE_TIME_ZONE_SUCCESS,
  UPDATE_TIME_ZONE_FAIL,
} from "../constants";

const initialState = {
  systemParams: {},
  timeZone: "",
};

const sysParamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SYSTEM_PARAMS_START:
      return updateObject(state, { loading: true });
    case FETCH_SYSTEM_PARAMS_SUCCESS:
      return updateObject(state, { loading: false, systemParams: action.data });
    case FETCH_SYSTEM_PARAMS_FAIL:
      return updateObject(state, { loading: false });
    case UPDATE_TIME_ZONE_START:
      return updateObject(state, { loading: true });
    case UPDATE_TIME_ZONE_SUCCESS:
      return updateObject(state, {
        loading: false,
        timeZone: action.data?.timeZone,
      });
    case UPDATE_TIME_ZONE_FAIL:
      return updateObject(state, { loading: false });
    default:
      return state;
  }
};

export default sysParamsReducer;

import React, { useState, useEffect, useRef, useCallback } from "react";
import Base from "./base";
import ColumnsFilter from "../../common/columns_manager/columns_filter";
import FilterItems from "../../common/filter_manager/filter_items";
import dataFilter from "../../common/filter_manager/data_filter";
import { renderFields, types } from "./header_field";
import { isAuthorized } from "utils/helpers";
import { useSelector } from "react-redux";
import "./style/app.css";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SearchInput from "components/common/SearchInput";
import Badge from "@mui/material/Badge";
import ControlBtn from "components/common/ControlBtn";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: "#fecc36",
  },
  tabs: {
    fontSize: "20px",
    "& .MuiTab-root.Mui-selected": {
      color: "white",
    },
  },
  badge: {
    fontSize: 10,
    height: 16,
    width: 16,
    color: "black",
    backgroundColor: "#F5F5F5",
    right: 27,
    minWidth: 15,
  },
}));

const Activity = (props) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const [data, setData] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openSearch, setOpenSearch] = useState(false);

  const click = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const _handleClose = () => {
    setAnchorEl(null);
  };

  const _handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = () => {
    setOpenSearch(!openSearch);
  };

  const [showColmnsFilter, setShowColmnsFilter] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterBy, setFilterBy] = useState({});
  const [headerFields, setHeaderFields] = useState([]);
  const [numberFilters, setNumberFilters] = useState([]);
  const [searchBy, setSearchBy] = useState();
  const [fontSize, setFontSize] = useState(10);

  const showColmnsFilterHandler = useCallback(() => {
    setShowColmnsFilter(!showColmnsFilter), setAnchorEl(null);
  }, [showColmnsFilter]);

  const showFilterHandler = () => setShowFilter(!showFilter);

  const Alerts = useSelector((state) => state.ActiveAlerts);

  const { auth } = useSelector((state) => state);

  const canAccess = (type) => {
    const { canView } = isAuthorized(auth?.user?.roles, type);
    return canView;
  };

  const type = types.filter(({ permission_code }) =>
    canAccess(permission_code),
  );

  const [active, setActive] = useState(
    type[0] ? Object.values(type)[0].name : "alerts",
  );

  const handleClickAway = () => {
    if (!searchBy) {
      setSearchBy();
      setOpenSearch(false);
    }
  };

  const closeFilter = (filter) => {
    if (filter === "showFilter") setShowFilter(!showFilter);
    if (filter === "showColmnsFilter") setShowColmnsFilter(!showColmnsFilter);
  };

  useEffect(() => {
    _renderActivityFilterFields();
    _activeColumn();
    setHeaderFields(renderFields.filter(({ list }) => list.includes(active)));
    setData(Object.values(Alerts[active]));
  }, [Alerts[active]]);

  useEffect(() => {
    setFontSize(props.fullscreenBlock);
  }, [props.fullscreenBlock, fontSize]);

  useEffect(() => {
    const filteredData = dataFilter(Object.values(Alerts[active]), filterBy);
    let filter = [];
    Object.values(filterBy || []).map((item) => {
      if (item.value) {
        filter.push(item);
      }
    });
    setNumberFilters(filter.length);
    setData(filteredData);
  }, [filterBy]);

  const _renderActivityFilterFields = () => {
    const filterData = {
      filterBy: [],
    };
    headerFields.map((e) => {
      if (e.filterField) {
        filterData.filterBy.push({
          alias: e.title,
          type: e.type,
          field: e.name,
          value: filterBy[e.name],
        });
      }
    });
    return filterData;
  };

  const _activeColumn = () => {
    const currentActiveList = {};

    headerFields.forEach((element) => {
      currentActiveList[element.title] = true;
    });
    return currentActiveList;
  };

  const handleClick = (event) => {
    const type = event.target.id;
    setSearchBy()
    setActive(type);
    setHeaderFields(renderFields.filter(({ list }) => list.includes(type)));
  };

  const renderTabs = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ color: "white" }}>
          <Tabs
            value={value}
            onChange={_handleChange}
            className={classes.tabs}
            TabIndicatorProps={{ className: classes.indicator }}
          >
            {type.map((a) => (
              <Tab
                key={a.name}
                label={a.alias}
                onClick={handleClick}
                id={a.name}
                sx={{
                  color: "#939191",
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: 700,
                  width: "20%",
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    );
  };

  const debounce = _.debounce((data) => {
    const value = data.toLowerCase() || "";
    const result = Object.values(Alerts[active] || {}).filter(
      (ele) =>
        (ele.flight_number || "").toLowerCase().includes(value) ||
        (ele.alert_code || "").toLowerCase().includes(value) ||
        (ele.alert_description || "").toLowerCase().includes(value) ||
        (ele.level || "").toLowerCase().includes(value),
    );
    value ? setSearchBy(result) : setSearchBy();
  }, 500);

  const onSearch = ({ target: { value } }) => {
    debounce(value);
  };

  const _updateColumns = (activeCols) => {
    const headers = renderFields.filter((col) => {
      if (activeCols[col.title]) {
        return col;
      }
    });
    const newShowColumns = [];
    for (let index = 0; index < headers.length; index++) {
      const element = headers[index];
      if (activeCols[element.title]) {
        newShowColumns.push(element);
      }
    }
    setHeaderFields(newShowColumns);
  };

  return (
    <div style={{ fontSize: fontSize }} className="acdm-container">
      <div
        style={{ height: "9px", display: "flex", justifyContent: "flex-end" }}
      ></div>
      <div
        className={
          props.fullscreenBlock
            ? "resize-widget-config-acdm"
            : "widget-config-settings"
        }
      >
        <div
          className="ellipsis-vertical-config widget-config"
          style={{
            backgroundColor: open ? "#2A3D52" : "",
            right: props.fullscreenBlock ? "52px" : "",
            top: !props.fullscreenBlock ? "3px" : "",
            marginRight: open ? "4px" : "",
            position: "absolute",
          }}
          id="basic-button"
          onClick={click}
        >
          <i className="fa-solid fa-ellipsis-vertical"></i>
        </div>
        <div
          className="widget-config-filter"
          style={{
            right: props.fullscreenBlock ? "86px" : "",
            top: !props.fullscreenBlock ? "3px" : "",
            background: numberFilters ? "#205CF6" : "",
            marginRight: open ? "13px" : "",
          }}
          onClick={showFilterHandler}
          role="button"
          tabIndex="0"
          onKeyDown={showFilterHandler}
        >
          <Badge
            badgeContent={numberFilters}
            style={{ width: 22 }}
            classes={{ badge: classes.badge }}
          >
            <i className="filter-icon" />
          </Badge>
        </div>
        <ClickAwayListener onClickAway={handleClickAway}>
            {!openSearch ? (
              <div
                className="widget-config-search"
                role="button"
                tabIndex="0"
                onClick={handleSearch}
                style={{
                  right: props.fullscreenBlock ? "121px" : "",
                  top: !props.fullscreenBlock ? "3px" : "",
                  marginRight: open ? "12px" : "",
                }}
              >
                <i className="search-icon"></i>
              </div>
            ) : (
              <div
                style={{
                  height: "0",
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "144px",
                  position: "relative",
                  bottom: "3px",
                }}
              >
                <SearchInput
                  style={{
                    maxWidth: "290px",
                    flex: 1,
                    right: props.fullscreenBlock ? "120px" : "142px",
                    boxSizing: "revert",
                  }}
                  changeSearchHandler={onSearch}
                  top="36px"
                  bottom={props.fullscreenBlock ? "16px" : "-3px"}
                  width={props.fullscreenBlock ? "160px" : "260px"}
                />
              </div>
            )}
        </ClickAwayListener>
      </div>
      <div className="widget-acdm-header">
        <span
          className="acdm-logo"
          title="Alerts and Notifications"
          style={{ fontSize: fontSize }}
        >
          Activity
        </span>
        {open && (
          <ControlBtn
            columns={renderFields}
            data={Object.values(Alerts.alerts)}
            showColmnsFilter={showColmnsFilterHandler}
            classSelect="activity-table-wrapper"
            title="Activity"
            handleClose={_handleClose}
            open={open}
            anchorEl={anchorEl}
          />
        )}
      </div>
      <div className="acdm-body">
        {showFilter ? (
          <div className="acdm-filter" style={{ fontSize: fontSize }}>
            <FilterItems
              instantFilter
              filtersVal={filterBy}
              filter={(filterBy) => setFilterBy({ ...filterBy })}
              data={_renderActivityFilterFields()}
              closeFilter={closeFilter}
            />
          </div>
        ) : null}
        {showColmnsFilter ? (
          <div className="acdm-filter" style={{ fontSize: fontSize }}>
            <ColumnsFilter
              data={renderFields.filter(({ list }) => list.includes(active))}
              updateColumns={_updateColumns}
              closeFilter={closeFilter}
              activeColumns={_activeColumn()}
            />
          </div>
        ) : null}
        <div className="activity-table-wrapper" id="section-to-print">
          <div className="acdm-main">
            {renderTabs()}
            {type.length ? (
              <Base
                data={data}
                searchBy={searchBy}
                active={active}
                activeColumn={headerFields.map((header) => header.name)}
                headers={headerFields.filter((header) => header.activeField)}
                fullscreenBlock={props.fullscreenBlock}
                auth={auth}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;

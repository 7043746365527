import _ from "lodash";
import { updateObject } from "./utility";
import {
  SELECTED_FLIGHTS,
  REINIT_USER,
  FETCH_USER_PROFILE_START,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_START,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  UPLOAD_USER_START,
  UPLOAD_USER_SUCCESS,
  UPLOAD_USER_FAIL,
  CLEAR_USER_START,
  CLEAR_USER_SUCCESS,
  CLEAR_USER_FAIL,
  FETCH_USER_ROLES_AND_AIRLINES_START,
  FETCH_USER_ROLES_AND_AIRLINES_SUCCESS,
  FETCH_USER_ROLES_AND_AIRLINES_FAIL,
} from "../constants";
import { storageKeys } from "utils/helpers";

const initialState = {
  selectedFlights: { ids: [], numbers: [], registrations: [] },
  airlines: [],
  roles: [],
  info: {},
  loading: false,
};

const prepareDate = (data) => {
  const obj = {};

  for (const key in data) {
    obj[key] = data[key][0];
  }

  return obj;
};

const acdmReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_USER:
      localStorage.removeItem(storageKeys.SELECTED_FLIGHTS);
      return updateObject(state, initialState);

    case SELECTED_FLIGHTS:
      return updateObject(state, { selectedFlights: action.data });

    case FETCH_USER_PROFILE_START:
      return updateObject(state, { loading: true });
    case FETCH_USER_PROFILE_SUCCESS:
      return updateObject(state, {
        info: prepareDate(action.data),
        loading: false,
      });
    case FETCH_USER_PROFILE_FAIL:
      return updateObject(state, { loading: false });

    case UPDATE_USER_PROFILE_START:
      return updateObject(state, { loading: true });
    case UPDATE_USER_PROFILE_SUCCESS:
      return updateObject(state, {
        info: { ...action.data.attributes, email: action.data.email },
        loading: false,
      });
    case UPDATE_USER_PROFILE_FAIL:
      return updateObject(state, { loading: false });

    case UPLOAD_USER_START:
      return updateObject(state, { loading: true });
    case UPLOAD_USER_SUCCESS:
      return updateObject(state, {
        info: { ...state.info, thumbnail: action.url },
        loading: false,
      });
    case UPLOAD_USER_FAIL:
      return updateObject(state, { loading: false });

    case CLEAR_USER_START:
      return updateObject(state, { loading: true });
    case CLEAR_USER_SUCCESS:
      return updateObject(state, {
        info: { ...state.info, thumbnail: "" },
        loading: false,
      });
    case CLEAR_USER_FAIL:
      return updateObject(state, { loading: false });

    case FETCH_USER_ROLES_AND_AIRLINES_START:
      return updateObject(state, { loading: true });
    case FETCH_USER_ROLES_AND_AIRLINES_SUCCESS:
      return updateObject(state, {
        loading: false,
        airlines: action.airlines,
        roles: action.roles,
      });
    case FETCH_USER_ROLES_AND_AIRLINES_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default acdmReducer;

import React from "react";
import "./style.css";

const ErrorPage = ({ image, message, description }) => {
  return (
    <div className="error-container">
      <img src={image} alt="Error image" />
      <div className="restriction">
        <p className="restricte-msg">{message}</p>
        <p className="error-description">{description}</p>
      </div>
    </div>
  );
};

export default ErrorPage;

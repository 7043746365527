import {
  FETCH_LOGS_START,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_FAIL,
  REINIT_LOGS,
} from "../constants";
import { updateObject } from "./utility";

const initialState = {
  logs: [],
  loading: false,
  total: 0,
};

const logsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_LOGS:
      return updateObject(state, initialState);
    case FETCH_LOGS_START:
      return updateObject(state, { loading: true });
    case FETCH_LOGS_SUCCESS:
      return updateObject(state, {
        logs: action.data,
        total: action.total,
      });
    case FETCH_LOGS_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default logsReducer;

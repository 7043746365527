import React, { useState, useEffect } from "react";
import "./assets/style/app.css";
import { SocketEvent, SocketListener } from "utils/helpers";
import setupSocket from "utils/socket_setup";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPM } from "redux/actions";
import { reinitAPM } from "redux/actions/apm-actions";
import Apm from "./Apm";
import Details from "./Details";

import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  btn: {
    width: "55px",
    height: "28px",
    borderRadius: "12px",
    fontSize: "14px",
    fontWeight: "800",
    textTransform: "none",
    marginRight: "12px",

    "&:focus": {
      backgroundColor: "#1142BC",
    },
    "&:hover": {
      color: "white",
    },
  },
}));

const APM = (props) => {
  const [apm, setApm] = useState({
    1: {
      status: "NORTH",
      prevStatus: "NORTH",
      icon: "shuttle",
      location: "NORTH",
    },
    2: {
      status: "NORTH",
      prevStatus: "NORTH",
      icon: "shuttle",
      location: "NORTH",
    },
  });
  const [activeTab, setActiveTab] = useState("APM");
  const classes = useStyles();

  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch({});

  useEffect(() => {
    const newSocket = setupSocket(
      process.env.REACT_APP_APM_SOCKET_PATH,
      auth.token,
    );

    dispatch(fetchAPM(handleAPMData));

    newSocket
      .on(SocketListener.CONNECT, () => {
        newSocket.emit(SocketEvent.ROOM, ["broadcast"]);

        dispatch(fetchAPM(handleAPMData));
      })
      .on(props.alias, (data) => {
        const { message } = data;
        try {
          newValueHandler(message);
        } catch (e) {}
      });

    return () => {
      newSocket.disconnect();
      dispatch(reinitAPM());
    };
  }, []);

  const handleAPMData = (values) => {
    if (values && values.length) {
      values.map((e) => newValueHandler(e));
    }
  };

  const appendDataToApmTable = (values) => {
    const new_row = document.createElement("tr");
    const new_td_1 = document.createElement("td");
    const new_td_2 = document.createElement("td");
    new_row.appendChild(new_td_1);
    new_row.appendChild(new_td_2);
    new_td_1.innerText = values.apm;
    new_td_2.innerText = values.status;
    const table = document.querySelector(".apm-table tbody");
    if (table) {
      table.appendChild(new_row);
      new_row.classList.add("blink");
      const nodes = table.querySelectorAll("tr");
      if (nodes.length > 5) {
        table.deleteRow(0);
      }
    }
  };

  const newValueHandler = (e) => {
    const apmNum = e.apm.split("APM")[1];
    const newValues = {};
    newValues[`APM${apmNum}_location`] = e.status;
    appendDataToApmTable(e);
    let icon = "shuttle";
    if (e.status === "MAINT") {
      icon = "maint";
    }

    setApm((prev) => ({
      ...prev,
      [apmNum]: {
        ...prev[apmNum],
        status: e.status,
        icon,
        location: e.location,
        name: e.apm,
      },
    }));
  };

  return (
    <div className="col s12 apm-container">
      <div className="col s12" id="apm">
        <div className="widget-header apm-header">
          <span title="Automated People Mover">APM</span>
        </div>
        <div className="container-apm-btn mb-small">
          <Button
            className={classes.btn}
            sx={
              activeTab === "APM"
                ? { backgroundColor: "#1142BC" }
                : { backgroundColor: "#112449", color: "#205CF6" }
            }
            variant="contained"
            disableElevation
            disableRipple
            disableFocusRipple
            onClick={() => {
              setActiveTab("APM");
            }}
          >
            APM
          </Button>
          <Button
            className={classes.btn}
            sx={
              activeTab !== "APM"
                ? { backgroundColor: "#1142BC" }
                : { backgroundColor: "#112449", color: "#205CF6" }
            }
            variant="contained"
            disableElevation
            onClick={() => {
              setActiveTab("Details");
            }}
          >
            Details
          </Button>
        </div>
        <div className="apm-body">
          {activeTab === "APM" ? <Apm apm={apm} /> : <Details apm={apm} />}
        </div>
      </div>
    </div>
  );
};

export default APM;

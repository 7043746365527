import {
  FETCH_APM_START,
  FETCH_APM_SUCCESS,
  FETCH_APM_FAIL,
  REINIT_APM,
} from "../constants";

import { getRequest } from "utils/APIRequest";

export const reinitAPM = (data = {}) => {
  return async (dispatch) => {
    dispatch({ type: REINIT_APM });
  };
};

const fetchAPMStart = () => ({
  type: FETCH_APM_START,
});

const fetchAPMSuccess = (data) => ({
  type: FETCH_APM_SUCCESS,
  data,
});

const fetchAPMFail = () => ({
  type: FETCH_APM_FAIL,
});

export const fetchAPM = (cb) => {
  return async (dispatch) => {
    dispatch(fetchAPMStart());

    const response = await getRequest("/api/apm/index");

    if (!response.error) {
      dispatch(fetchAPMSuccess(response));
      cb && cb(response);
    } else {
      dispatch(fetchAPMFail());
    }
  };
};

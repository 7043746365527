import { deleteMultiData, updateObject, updateData } from "./utility";
import {
  REINIT_USERS,
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  DELETE_USERS_START,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
  RESTORE_USERS_START,
  RESTORE_USERS_SUCCESS,
  RESTORE_USERS_FAIL,
  CREATE_USERS_START,
  CREATE_USERS_SUCCESS,
  CREATE_USERS_FAIL,
  UPDATE_USERS_START,
  UPDATE_USERS_FAIL,
  FETCH_USER_DETAILS_USER_START,
  FETCH_USER_DETAILS_USER_SUCCESS,
  FETCH_USER_DETAILS_USER_FAIL,
} from "../constants";

const initialState = {
  users: [],
  user: {},
  loading: false,
  total: 0,
};

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_USERS:
      return updateObject(state, initialState);
    case FETCH_USERS_START:
      return updateObject(state, { loading: true });
    case FETCH_USERS_SUCCESS:
      return updateObject(state, {
        loading: false,
        users: action.data,
        total: action.total,
      });
    case FETCH_USERS_FAIL:
      return updateObject(state, { loading: false });

    case DELETE_USERS_START:
      return updateObject(state, { loading: true });
    case DELETE_USERS_SUCCESS:
      return deleteMultiData(state, action.data, "users");
    case DELETE_USERS_FAIL:
      return updateObject(state, { loading: false });

    case RESTORE_USERS_START:
      return updateObject(state, { loading: true });
    case RESTORE_USERS_SUCCESS:
      return deleteMultiData(state, action.data, "users");
    case RESTORE_USERS_FAIL:
      return updateObject(state, { loading: false });

    case CREATE_USERS_START:
      return updateObject(state, { loading: true });
    case CREATE_USERS_SUCCESS:
      return updateObject(state, {
        loading: false,
        users: [action.data, ...state.users],
      });
    case CREATE_USERS_FAIL:
      return updateObject(state, { loading: false });

    case UPDATE_USERS_START:
      return updateObject(state, { loading: true });
    case UPDATE_USERS_FAIL:
      return updateObject(state, { loading: false });

    case FETCH_USER_DETAILS_USER_START:
      return updateObject(state, { loading: true });
    case FETCH_USER_DETAILS_USER_SUCCESS:
      const data = action.data;

      return updateObject(state, {
        loading: false,
        user: {
          ...data,
          organization: data.organizations[0],
          airlines: data.airlines.map((e) => e.iata) || [],
          roles: data.roles.map((e) => e.role),
        },
      });
    case FETCH_USER_DETAILS_USER_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default UsersReducer;

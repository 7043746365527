import React from "react";

export default () => (
  <defs>
    <filter id="f1" x="0" y="0" height="100%" width="100%">
      <feOffset result="offOut" in="SourceAlpha" dx="1" dy="1" />
      <feGaussianBlur result="blurOut" in="off" stdDeviation="5" />
      <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
    </filter>
    <svg width="30" height="30" id="airplan">
      <g dx="100" dy="100" width="50" height="50" transform="scale(0.5)">
        <path
          d="M22.654 22.552c0.102 0 0.203 0.102 0.406 0.102 0.305 0 0.508 0.102 1.321 0.102s0.813-0.102 1.117-0.102c0.508 0 0.711-0.102 0.711-0.406v-1.016c0-0.305-0.102-0.406-0.711-0.406-0.305 0-0.305-0.102-1.117-0.102-0.305 0-0.508 0-0.711 0l1.625-2.844c4.064 0 8.025 0 10.26 0 1.93 0 4.978-0.508 4.978-1.524s-3.048-1.625-4.978-1.625c-2.337 0-6.197 0-10.26 0l-1.625-2.844c0.203 0 0.406 0 0.711 0 0.813 0 0.813-0.102 1.117-0.102 0.508 0 0.711-0.102 0.711-0.406v-1.016c0-0.305-0.102-0.406-0.711-0.406-0.305 0-0.305-0.102-1.117-0.102s-1.016 0-1.321 0.102c-0.203 0-0.305 0-0.406 0.102l-5.079-9.244c-0.305-0.305-1.625-0.203-1.625-0.203 0 0.102 0.61 2.743 1.219 5.283-0.305 0-0.508 0-0.508 0.102s0.203 0.102 0.61 0.102c0.203 0.914 0.508 1.829 0.61 2.54-0.406 0-0.508 0-0.508 0.102s0.203 0.102 0.61 0.102c0.203 0.711 0.305 1.117 0.305 1.117v1.321c-0.406 0-0.508 0-0.508 0.102s0.203 0.102 0.508 0.102v2.946c-5.283 0-9.448 0-9.448 0-0.813 0-2.032 0.102-2.844 0.203-0.508-0.711-3.149-4.368-3.454-4.775 0 0.102-1.422 0.102-1.422 0.203 0 0.305 1.219 4.673 1.422 5.384-0.406 0.102-1.625 0.305-1.93 0.61-0.102 0-0.203 0-0.203 0.102 0.102 0.102 0.203 0.102 0.305 0.102 0.203 0.305 1.524 0.508 1.829 0.61-0.203 0.711-1.422 5.079-1.422 5.384 0 0.102 1.422 0.203 1.524 0.102 0.305-0.406 2.946-4.064 3.454-4.775 0.813 0.102 1.93 0.305 2.743 0.305 0 0 4.267 0 9.448 0v2.946c-0.406 0-0.508 0-0.508 0.102s0.203 0.102 0.508 0.102v1.321c0 0-0.102 0.406-0.305 1.117-0.406 0-0.61 0-0.61 0.102s0.203 0.102 0.508 0.102c-0.203 0.711-0.406 1.625-0.61 2.54-0.406 0-0.61 0-0.61 0.102s0.102 0.102 0.508 0.102c-0.61 2.54-1.321 5.181-1.321 5.283 0 0 1.321 0.203 1.625-0.102l5.181-9.041z"
          filter="url(#f1)"
        />
      </g>
    </svg>
    <svg width="30" height="30" id="vehicle">
      <g
        dx="100"
        dy="100"
        width="50"
        height="50"
        transform="scale(0.4)"
        fill="#333333"
      >
        <defs>
          <filter
            id="a"
            width="137.5%"
            height="137.5%"
            x="-18.8%"
            y="-11.2%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation="2"
            />
            <feColorMatrix
              in="shadowBlurOuter1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            />
          </filter>
          <path id="c" d="M0 28h13V0H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(18 7)">
            <path
              stroke="#000"
              strokeLinecap="round"
              strokeWidth="1.2"
              d="M10.932 9.74H2.068V1.662c0-.583.458-1.054 1.023-1.054h6.818c.565 0 1.023.471 1.023 1.054v8.076z"
            />
            <mask id="d" fill="#fff">
              <use xlinkHref="#c" />
            </mask>
            <path
              stroke="#000"
              strokeLinecap="round"
              strokeWidth="1.2"
              d="M3.84 4.26h5.32V2.436H3.84zM3.017 27.391H1.119a.536.536 0 0 1-.528-.544V10.283c0-.3.237-.544.528-.544h1.898c.292 0 .529.244.529.544v16.564c0 .3-.237.544-.53.544zM11.88 27.391H9.984a.536.536 0 0 1-.528-.544V10.283c0-.3.236-.544.528-.544h1.898c.291 0 .528.244.528.544v16.564c0 .3-.237.544-.528.544zM4.136 19.478h4.728M3.545 26.174h5.91"
              mask="url(#d)"
            />
          </g>
        </g>
      </g>
    </svg>
  </defs>
);

import React, { useEffect, useState } from "react";
import DropDown from "./dropDown_menu";
import SearchInput from "components/common/SearchInput";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Badge from "@mui/material/Badge";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  badge: {
    fontSize: 10,
    height: 16,
    width: 16,
    color: "black",
    backgroundColor: "#F5F5F5",
    right: 27,
    minWidth: 15,
  },
}));

const ACDMHeader = ({
  showFilterColumns,
  activeList,
  needResize,
  filterBy,
  handleSearch,
  fullscreenBlock,
  searchBy
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openSearch, setOpenSearch] = useState(false);
  const [filterItem, setFilterItem] = useState([]);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggelSearch = () => {
    setOpenSearch(!openSearch);
  };

  const handleClickAway = () => {
    if(!searchBy){
    setOpenSearch(false);
    handleSearch("");
    }
  };

  const onSearch = ({ target: { value } }) => {
    handleSearch(value);
  };

  useEffect(() => {
    let filter = [];
    Object.values(filterBy || []).map((item) => {
      if (item.value) {
        filter.push(item);
      }
    });
    setFilterItem(filter);
  }, [filterBy]);

  return (
    <div className="container-config-header">
      <div
        className={
          needResize ? "resize-widget-config-acdm" : "widget-config-settings"
        }
      >
        <div
          className="ellipsis-vertical-config widget-config"
          style={{
            backgroundColor: open ? "#2A3D52" : "",
            right: "52px",
            top: !needResize ? "3px" : "",
            marginRight: open ? "4px" : "",
            position: "absolute",
          }}
          id="basic-button"
          onClick={handleClick}
        >
          <i className="fa-solid fa-ellipsis-vertical"></i>
        </div>
        <div
          className="widget-config-filter"
          onClick={showFilterColumns}
          role="button"
          tabIndex="0"
          onKeyDown={showFilterColumns}
          style={{
            right: "86px",
            top: !needResize ? "3px" : "",
            background: Object.values(filterItem).length ? "#205CF6" : "",
            marginRight: open ? "13px" : "",
          }}
        >
          <Badge
            badgeContent={Object.values(filterItem).length}
            style={{ width: 22 }}
            classes={{ badge: classes.badge }}
          >
            <i className="filter-icon" />
          </Badge>
        </div>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div style={{ display: "contents" }}>
            {!openSearch ? (
              <div
                className="widget-config-search"
                role="button"
                tabIndex="0"
                onClick={toggelSearch}
                style={{
                  right: "121px",
                  top: !needResize ? "3px" : "",
                  marginRight: open ? "12px" : "",
                }}
              >
                <i className="search-icon"></i>
              </div>
            ) : null}
            {openSearch ? (
              <div
                style={{
                  height: "0",
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "144px",
                  position: "relative",
                  bottom: "3px",
                  marginRight: "10px",
                }}
              >
                <SearchInput
                  style={{
                    maxWidth: "290px",
                    flex: 1,
                    right: needResize ? "120px" : "142px",
                    boxSizing: "revert",
                  }}
                  changeSearchHandler={onSearch}
                  top="36px"
                  bottom={fullscreenBlock ? "16px" : "-3px"}
                  width={fullscreenBlock ? "160px" : "260px"}
                />
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>

      <div className="widget-acdm-header">
        <span
          className="acdm-logo filter-font-size"
          title="Airport Collaborative Decision Making"
          id="#section-to-print"
        >
          Milestones
        </span>
        {open ? (
          <DropDown
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            activeList={activeList}
            printTable={{
              nameTable: "ACDM table",
              tabel: ".acdm-body",
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ACDMHeader;

import React, { useEffect, useReducer, useState } from "react";
import { filterUpdateCases, filterReducer } from "./filter_reducer";
import UiFilterModal from "./filter_modal";
import InputForm from "../UiLibrary/input_form";
import CheckBox from "../CheckBox";
import { useSelector } from "react-redux";

const initialState = {
  filterBy: [],
  filtersVal: [],
  isUpdating: false,
  anchorEl: null,
};

const FilterItems = (props) => {
  const [state, dispatch] = useReducer(filterReducer, initialState);
  const [fieldName, setFieldName] = useState("");
  const { timeZone } = useSelector((state) => state.SystemParams);

  const displayContent =
    props.data && props.data.filterBy ? props.data.filterBy : [];

  useEffect(() => {
    dispatch({ type: filterUpdateCases.INITIAL_FILTER_ITEM, data: props });
  }, []);

  const handleClick = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    props.closeFilter("showFilter");
  };

  const handleOpenPopup = (event) => {
    dispatch({
      type: filterUpdateCases.FILTER_POPUP_DATE_OPEN,
      data: event.currentTarget,
    });
    setFieldName(event.target.id);
  };

  const handleCancel = () => {
    dispatch({ type: filterUpdateCases.FILTER_POPUP_DATE_OPEN, data: null });
  };

  const formatDate = (time, date) => {
    if (!date && !time) {
      return "";
    }
    return `${date || ""} ${time}`;
  };

  const handleDateChange = (time, date, field) => {
    const start = formatDate(time.start, date);
    const end = formatDate(time.end, date);
    if (!state.isUpdating) {
      const filtersVal = {
        [field]: {
          type: "date",
          value: {
            start,
            end,
            timeZone: timeZone,
          },
        },
      };
      if (props.instantFilter) {
        dispatch({
          type: filterUpdateCases.ADD_DATE_TO_FILTER,
          data: filtersVal,
          isUpdating: true,
        });
      }
    }
  };

  useEffect(() => {
    if (state.isUpdating) {
      props.filter(state.filtersVal);
      dispatch({
        type: filterUpdateCases.ADD_DATE_TO_FILTER,
        isUpdating: false,
      });
    }
  }, [state.isUpdating, state.filtersVal]);

  const renderDatePicker = (ev) => {
    const { alias, type, field } = ev;

    return (
      <div className="filter-input">
        <div className="datepicker-input-wrapper" tabIndex={0} role="button">
          <div className="container-input-filter">
            <InputForm
              label={alias}
              name={field}
              id={field}
              value={
                state.filtersVal[field]?.value
                  ? `${formatDate(
                      state.filtersVal[field]?.value?.start,
                    )} - ${formatDate(state.filtersVal[field]?.value?.end)}`
                  : null
              }
              onClick={handleOpenPopup}
              index={field}
              type={field}
              readOnly
            />
          </div>
          <UiFilterModal
            anchorEl={state.anchorEl}
            handleCancel={handleCancel}
            field={fieldName}
            handleDateChange={handleDateChange}
            filtersVal={state.filtersVal[fieldName]}
          />
        </div>
      </div>
    );
  };

  const filterByName = (ev) => {
    const name = ev.target.name;
    setFieldName(name);
    dispatch({
      type: filterUpdateCases.UPDATE_FILTER_ITEM,
      name,
      isUpdating: true,
    });
    return props.filter(state.filtersVal);
  };

  const filter = (ev, type) => {
    const obj = {};
    obj[ev.target.name] = {
      value: ev.target.value.toString(),
      type,
    };
    const newVal = Object.assign(state.filtersVal, obj);
    if (props.instantFilter) {
      props.filter(newVal);
    }
  };

  const renderInputField = (e) => {
    const { alias, type, field } = e;
    return (
      <div className="container-input-filter">
        <InputForm
          label={alias}
          name={field}
          id={field}
          value={state.filtersVal[field] ? state.filtersVal[field].value : ""}
          onChange={(ev) => filter(ev, type)}
          index={field}
          type={type}
        />
      </div>
    );
  };

  const renderInput = (e) => {
    if (e.type === "date") {
      return renderDatePicker(e);
    }
    return renderInputField(e);
  };

  return (
    <div className="filter-by">
      <div className="header-section">
        <div className="filter-header">Filter by</div>
        <div
          tabIndex="0"
          role="button"
          data-confirm="no"
          onKeyDown={handleClick}
          onClick={handleClick}
          className="filter-close"
        >
          &times;
        </div>
      </div>
      <div className="filter-items-wrapper">
        {displayContent.map((e, i) => (
          <div key={i} className="filter-items-checkbox">
            <CheckBox
              onChange={filterByName}
              id={e.field}
              checked={state.filterBy.indexOf(e.field) > -1 || false}
              name={e.field}
              label={e.alias}
            />
            <div>
              {state.filterBy.indexOf(e.field) > -1 ? renderInput(e) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterItems;

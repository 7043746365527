import React from "react";
import WidgetRouter from "../common/widget_router";
import WidgetIcon from "./widget_icon";

const RenderLayoutBlock = ({
  changeWidget,
  selectedWidgets,
  widgetsData,
  layouts,
  layout,
}) => {
  const allowDrop = (ev) => {
    ev.preventDefault();
  };

  const drag = (ev) => {
    ev.dataTransfer.setData("widget", ev.target.dataset.id);
  };

  const drop = (ev) => {
    ev.preventDefault();
    const data = ev.dataTransfer.getData("widget");
    const blockNumber =
      ev.target.dataset.block || ev.target.parentNode.dataset.block;
    changeWidget(blockNumber, data);
  };

  const blocks = { ...layouts[layout.id]?.blocks };

  return Object.keys(blocks).map((block) => (
    <div
      role="presentation"
      key={block}
      data-block={block}
      className={`layout-block edit ${block}`}
      style={{
        height: `calc(${blocks[block].height} - .8em)`,
        width: `calc(${blocks[block].width} - .8em)`,
      }}
      onDrop={drop}
      onDragOver={allowDrop}
      onFocus={() => {}}
    >
      {selectedWidgets[block] && (
        <i
          role="presentation"
          onClick={() => changeWidget(block, null)}
          className="remove-widget-btn fa fa-times"
        />
      )}
      {selectedWidgets[block] && (
        <WidgetRouter
          layoutConfig={layout}
          widgetsData={widgetsData}
          component={selectedWidgets[block]}
        />
      )}
      {selectedWidgets[block] && (
        <WidgetIcon
          drop={drop}
          isSelected={false}
          widget={selectedWidgets[block]}
          enabled={false}
          drag={drag}
          widgets={widgetsData}
        />
      )}
    </div>
  ));
};

export default RenderLayoutBlock;

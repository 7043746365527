import { storageKeys } from "./helpers";
import queryString from "query-string";
import { getKeyCloakToken, logOut } from "redux/actions/session";
const _ = require("lodash");
const { fetch: windowFetch } = window;

window.keycloakFetch = fetch;
let requestQueue = [];
let refreshTokenInProgress = false;

const processQueue = (error, token = null) => {
  requestQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
};

window.fetch = async (...args) => {
  let [resource, config] = args;
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    storageKeys.TOKEN,
  )}`;
  return windowFetch(resource, config).then(async (response) => {
    if (
      (response.status === 401 || response.status === 403) &&
      !config._retry
    ) {
      if (refreshTokenInProgress) {
        return new Promise((resolve, reject) => {
          requestQueue.push({ resolve, reject });
        })
          .then(async (token) => {
            config.headers.Authorization = `Bearer ${token}`;
            return await windowFetch(resource, config);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      config._retry = true;
      refreshTokenInProgress = true;

      return new Promise((resolve, reject) => {
        getKeyCloakToken()
          .then((token) => {
            processQueue(null, token);
            config.headers.Authorization = `Bearer ${token}`;
            requestQueue = [];
            resolve(windowFetch(resource, config));
          })
          .catch((err) => {
            processQueue(err, null);
            logOut();
            return reject(err);
          })
          .then(() => {
            refreshTokenInProgress = false;
          });
      });
    } else {
      return Promise.resolve(response);
    }
  });
};

const sendRequest = async (url, body, header, method, isForm) => {
  if (!isForm && typeof body !== "string") {
    body = JSON.stringify(body);
  }
  const response = await fetch(url, {
    method,
    headers: header || {
      Accept: "application/json",
      "Content-Type": "application/json",
      Cache: "no-cache",
    },
    body,
    credentials: "include",
    window: null,
  });

  if (response.status === 200 || response.status === 201) {
    return await response.json();
  } else if (response.status === 204) {
    return true;
  } else {
    const data = await response?.json();
    return { error: true, message: data?.message };
  }
};

export const postRequest = async (url, data, header, isForm = false) =>
  sendRequest(url, data, header, "post", isForm);

export const putRequest = async (url, data, header, isForm = false) =>
  sendRequest(url, data, header, "put", isForm);

export const patchRequest = async (url, data, header) =>
  sendRequest(url, data, header, "PATCH");

export const deleteRequest = async (url, data, header) =>
  sendRequest(url, data, header, "DELETE");

export const mergePatchRequest = async (url, data, header) => {
  header = {
    "Content-Type": "application/json+merge-patch",
    Authorization: `Bearer ${localStorage.getItem(storageKeys.TOKEN)}`,
  };
  return sendRequest(url, data, header, "PATCH");
};
export const getRequest = async (url, data, signal, header) => {
  if (!_.isEmpty(data)) {
    url = `${url}?${queryString.stringify(data)}`;
  }

  const response = await fetch(url, {
    method: "get",
    ...(signal && { signal }),
    headers: {
      ...header,
    } || {
      Accept: "application/json",
      "Content-Type": "application/json",
      Cache: "no-cache",
    },
    credentials: "include",
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    const data = await response.json();

    return { error: true, message: data.message };
  }
};

export const requestValidator = async (apiBuilder) => {
  const response = await fetch(apiBuilder.urlBuilder(), {
    method: "get",
    signal: apiBuilder.signal,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Cache: "no-cache",
    },
    credentials: "include",
  });

  return response.status === 200;
};

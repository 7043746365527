import {
  FETCH_GENERAL_ALERT_LOGS_START,
  FETCH_GENERAL_ALERT_LOGS_SUCCESS,
  FETCH_GENERAL_ALERT_LOGS_FAIL,
} from "../constants";

import { getRequest } from "utils/APIRequest";

const fetchGeneralAlertLogsStart = () => ({
  type: FETCH_GENERAL_ALERT_LOGS_START,
});

const fetchGeneralAlertLogsSuccess = (data, total) => ({
  type: FETCH_GENERAL_ALERT_LOGS_SUCCESS,
  data,
  total,
});

const fetchGeneralAlertLogsFail = () => ({
  type: FETCH_GENERAL_ALERT_LOGS_FAIL,
});

export const fetchGeneralAlertLogs = (data = {}) => {
  return async (dispatch) => {
    dispatch(fetchGeneralAlertLogsStart());

    const response = await getRequest(`/api/generalalertlogs`, data);

    if (!response.error) {
      dispatch(fetchGeneralAlertLogsSuccess(response.data, response.total));
    } else {
      dispatch(fetchGeneralAlertLogsFail());
    }
  };
};

import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/APIRequest";
import {
  FETCH_ROLES_START,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAIL,
  SUCCESS_MESSAGE,
  DELETE_ROLES_START,
  DELETE_ROLES_SUCCESS,
  DELETE_ROLES_FAIL,
  FETCH_PERMISSIONS_START,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAIL,
  RESTORE_ROLES_START,
  RESTORE_ROLES_SUCCESS,
  RESTORE_ROLES_FAIL,
  CREATE_ROLES_START,
  CREATE_ROLES_SUCCESS,
  CREATE_ROLES_FAIL,
  UPDATE_ROLES_START,
  UPDATE_ROLES_FAIL,
  FETCH_ROLE_START,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_FAIL,
  ERROR_MESSAGE,
} from "../constants";

const fetchRolesStart = () => ({
  type: FETCH_ROLES_START,
});

const fetchRolesSuccess = (data, total) => ({
  type: FETCH_ROLES_SUCCESS,
  data,
  total,
});

const fetchRolesFail = () => ({
  type: FETCH_ROLES_FAIL,
});

export const fetchRoles = (data) => {
  return async (dispatch) => {
    dispatch(fetchRolesStart());

    const response = await getRequest(`/api/roles`, data);

    if (!response.error) {
      dispatch({ type: SUCCESS_MESSAGE, message: response.message });
      dispatch(fetchRolesSuccess(response.data, response.total));
    } else {
      dispatch(fetchRolesFail());
    }
  };
};

const fetchPermissionsStart = () => ({
  type: FETCH_PERMISSIONS_START,
});

const fetchPermissionsSuccess = (data) => ({
  type: FETCH_PERMISSIONS_SUCCESS,
  data,
});

const fetchPermissionsFail = () => ({
  type: FETCH_PERMISSIONS_FAIL,
});

export const fetchPermissions = (data) => {
  return async (dispatch) => {
    dispatch(fetchPermissionsStart());

    const response = await getRequest(`/api/permissions/index`, data);

    if (!response.error) {
      dispatch({ type: SUCCESS_MESSAGE, message: response.message });
      dispatch(fetchPermissionsSuccess(response.data));
    } else {
      dispatch(fetchPermissionsFail());
    }
  };
};

const deleteRolesStart = () => ({
  type: DELETE_ROLES_START,
});

const deleteRolesSuccess = (data) => ({
  type: DELETE_ROLES_SUCCESS,
  data,
});

const deleteRolesFail = () => ({
  type: DELETE_ROLES_FAIL,
});

export const deleteRoles = (data, cb) => {
  return async (dispatch) => {
    dispatch(deleteRolesStart());

    const response = await deleteRequest(`/api/roles`, { ids: data });

    if (!response.error) {
      dispatch({ type: SUCCESS_MESSAGE, message: response.message });
      dispatch(deleteRolesSuccess(data));
      cb && cb();
    } else {
      dispatch(deleteRolesFail());
    }
  };
};

const restoreRolesStart = () => ({
  type: RESTORE_ROLES_START,
});

const restoreRolesSuccess = (data, total) => ({
  type: RESTORE_ROLES_SUCCESS,
  data,
  total,
});

const restoreRolesFail = () => ({
  type: RESTORE_ROLES_FAIL,
});

export const restoreRoles = (dataIds, cb) => {
  return async (dispatch) => {
    dispatch(restoreRolesStart());

    const response = await putRequest(`/api/roles/restore/multi`, {
      ids: dataIds,
    });

    if (!response.error) {
      dispatch({ type: SUCCESS_MESSAGE, message: response.message });
      dispatch(restoreRolesSuccess(dataIds));
      cb && cb();
    } else {
      dispatch(restoreRolesFail());
    }
  };
};

const createRoleStart = () => ({
  type: CREATE_ROLES_START,
});

const createRoleSuccess = (data) => ({
  type: CREATE_ROLES_SUCCESS,
  data,
});

const createRoleFail = () => ({
  type: CREATE_ROLES_FAIL,
});

export const createRole = (data, cb) => {
  return async (dispatch) => {
    dispatch(createRoleStart());

    const response = await postRequest(`/api/roles`, data);

    if (!response.error) {
      dispatch({
        type: SUCCESS_MESSAGE,
        message: response.message,
        back: true,
      });
      dispatch(createRoleSuccess(response));
      cb && cb();
    } else {
      dispatch({
        type: ERROR_MESSAGE,
        message: response.message,
      });
      dispatch(createRoleFail());
    }
  };
};

const fetchRoleStart = () => ({
  type: FETCH_ROLE_START,
});

const fetchRoleSuccess = (data) => ({
  type: FETCH_ROLE_SUCCESS,
  data,
});

const fetchRoleFail = () => ({
  type: FETCH_ROLE_FAIL,
});

export const fetchRole = (dataId) => {
  return async (dispatch) => {
    dispatch(fetchRoleStart());

    const response = await getRequest(`/api/roles/${dataId}`);

    if (!response.error) {
      dispatch(fetchRoleSuccess(response));
    } else {
      dispatch(fetchRoleFail());
    }
  };
};

const updateRoleStart = () => ({
  type: UPDATE_ROLES_START,
});

const updateRoleFail = () => ({
  type: UPDATE_ROLES_FAIL,
});

export const updateRole = (dataId, data, cb) => {
  return async (dispatch) => {
    dispatch(updateRoleStart());

    const response = await putRequest(`/api/roles/${dataId}`, data);

    if (!response.error) {
      dispatch({
        type: SUCCESS_MESSAGE,
        message: response.message,
        back: true,
      });
      cb && cb();
    } else {
      dispatch({
        type: ERROR_MESSAGE,
        message: response.message,
      });
      dispatch(updateRoleFail());
    }
  };
};

import React, { useEffect, useRef, useState } from "react";
import WeatherTap from "./weather_tap";
import "./assets/style/app.css";
import { useSelector } from "react-redux";
import moment from "moment";
import { runways, data } from "./assets/data/constant";

const Weather = (props) => {
  const [fontSize, setFontSize] = useState(14);
  const [tab, setTab] = useState("");
  const tabSize = 100 / runways.length;

  const { auth } = useSelector((state) => state);

  useEffect(() => {
    setTab("tab1");
  }, []);

  useEffect(() => {
    setFontSize(props.fullscreenBlock ? 20 : 10);
  }, [props.fullscreenBlock, fontSize]);

  const activeIndicator = (left, right) => {
    document.querySelector(".weather-indicator").style.left = left;
    document.querySelector(".weather-indicator").style.right = right;
  };

  const chooseRunway = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    const tabSize = 100 / runways.length;
    const { index } = ev.target.dataset;
    setTab(`tab${+index + 1}`);
    activeIndicator(
      `${tabSize * +index}%`,
      `${100 - tabSize - tabSize * +index}%`
    );
  };

  return (
    <div
      style={{
        fontSize: fontSize,
      }}
      className="weather-container weahter-tabs atcw-body"
    >
      <div className="widget-header">
        <div>
          <div>Weather</div>
          <div className="date">{moment().format("dddd MMM-DD , YYYY")}</div>
        </div>
        <ul className="tabs tabs-fixed-width">
          {runways.map((runway, i) => (
            <li
              key={i}
              className={`tab ${tab === `tab${i + 1}` ? "active" : ""}`}
            >
              <div
                role="button"
                className={tab === `tab${i + 1}` ? "active" : ""}
                data-index={i}
                onClick={chooseRunway}
                tabIndex="0"
                onKeyDown={chooseRunway}
              >
                {runway}
              </div>
            </li>
          ))}
          <div
            style={{
              left: `${tabSize * 0}%`,
              right: `${100 - tabSize - tabSize * 0}%`,
            }}
            className="indicator weather-indicator"
          />
        </ul>
      </div>
      {runways.map((e, i) =>
        tab === `tab${i + 1}` ? (
          <WeatherTap
            key={i}
            location={e.substring(0, 3)}
            data={data[e]}
          />
        ) : null
      )}
    </div>
  );
};

export default Weather;

import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./modal.module.css";

const GeneralModal = ({
  zIndex = 1300,
  visible,
  onClose,
  title,
  subTitle,
  children,
}) => {
  return (
    <Modal style={{ zIndex }} keepMounted open={visible} onClose={onClose}>
      <Box style={{ zIndex }} className={styles.container}>
        <div className={styles.header_container}>
          <Typography className={styles.header_title}>
            {title && title}
          </Typography>
          <CloseIcon onClick={onClose} />
        </div>
        {subTitle && (
          <Typography className={styles.header_sub_title}>
            {subTitle}
          </Typography>
        )}
        {children}
      </Box>
    </Modal>
  );
};

export default GeneralModal;

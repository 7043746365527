export const data = {
  "16R": {
    general: {
      dew_point: 15.85,
      relative_humidity: 57,
      temperature: 24,
    },
    pressure: { qnh: 1022.67, qfe: 1022.21 },
    runway_visual_range: {
      RVR1A: 3000,
      RVR1M: 3000,
      RVR1X: 3000,
      RVR10A: 3000,
      RVR10M: 3000,
      RVR10X: 3000,
      VIS1A: 10000,
      VIS10A: 10000,
    },
    wind: {
      wind_dir: 100,
      wind_speed: 11.51,
      min_speed: 9.14,
      max_speed: 13.02,
      gust_speed: 13.61,
      gust_dir: 347,
    },
    cloud: { cloud_cover: null, cloud_height: null },
  },
  "16L": {
    general: {
      dew_point: 15.4,
      relative_humidity: 57,
      temperature: 24.8,
    },
    pressure: { qnh: 1022.67, qfe: 1022.21 },
    runway_visual_range: {
      RVR1A: 3000,
      RVR1M: 3000,
      RVR1X: 3000,
      RVR10A: 3000,
      RVR10M: 3000,
      RVR10X: 3000,
      VIS1A: 10000,
      VIS10A: 10000,
    },
    wind: {
      wind_dir: 340,
      wind_speed: 11.51,
      min_speed: 9.14,
      max_speed: 13.02,
      gust_speed: 13.61,
      gust_dir: 347,
    },
    cloud: { cloud_cover: null, cloud_height: null },
  },
  "34L": {
    general: {
      dew_point: 27.3,
      relative_humidity: 57,
      temperature: 27.1,
    },
    pressure: { qnh: 1022.67, qfe: 1022.21 },
    runway_visual_range: {
      RVR1A: 3000,
      RVR1M: 3000,
      RVR1X: 3000,
      RVR10A: 3000,
      RVR10M: 3000,
      RVR10X: 3000,
      VIS1A: 10000,
      VIS10A: 10000,
    },
    wind: {
      wind_dir: 320,
      wind_speed: 11.51,
      min_speed: 9.14,
      max_speed: 13.02,
      gust_speed: 13.61,
      gust_dir: 347,
    },
    cloud: { cloud_cover: null, cloud_height: null },
  },
  "34R": {
    general: {
      dew_point: 15.85,
      relative_humidity: 57,
      temperature: 27.8,
    },
    pressure: { qnh: 1022.67, qfe: 1022.21 },
    runway_visual_range: {
      RVR1A: 3000,
      RVR1M: 3000,
      RVR1X: 3000,
      RVR10A: 3000,
      RVR10M: 3000,
      RVR10X: 3000,
      VIS1A: 10000,
      VIS10A: 10000,
    },
    wind: {
      wind_dir: 280,
      wind_speed: 11.51,
      min_speed: 9.14,
      max_speed: 13.02,
      gust_speed: 13.61,
      gust_dir: 347,
    },
    cloud: { cloud_cover: null, cloud_height: null },
  },
};

export const runways = ["16L", "16R", "34L", "34R"];

export const defaultRVR = [
  "RVR1A",
  "RVR1M",
  "RVR1X",
  "RVR10A",
  "RVR10M",
  "RVR10X",
  "VIS1A",
  "VIS10A",
];

export const pressuredata = {
  wrapper: { alias: "MAIN WEATHER" },
  temp: { alias: "TEMPERATURE" },
  rh: { alias: "RELATIVE HUMIDITY" },
  dew_point: { alias: "DEW POINT" },
  pressure_qnh: { alias: "BAROMETRIC PRESSURE QNH" },
  pressure_qfe: { alias: "BAROMETRIC PRESSURE QFE" },
};

export const winddata = {
  wrapper: { alias: "WIND" },
  wind_speed: { alias: "WIND SPEED" },
  wind_dir: { alias: "WIND DIRECTION" },
  gust_speed: { alias: "GUST SPEED" },
  gust_dir: { alias: "GUST DIRECTION" },
  max_wind_speed: { alias: "MAX WIND SPEED" },
  min_wind_speed: { alias: "MIN WIND SPEED" },
  wind_4000_ft: { alias: "WIND 4000 FT" },
  wind_3000_ft: { alias: "WIND 3000 FT" },
  wind_2000_ft: { alias: "WIND 2000 FT" },
  wind_1000_ft: { alias: "WIND 1000 FT" },
};

export const rvrdata = {
  wrapper: { alias: "RUNWAY VISUAL RANGE" },
  rvr1a: { alias: "RVR1A" },
  rvr1m: { alias: "RVR1M" },
  rvr1x: { alias: "RVR1X" },
  vis1a: { alias: "VIS1A" },
  rvr10a: { alias: "RVR10A" },
  rvr10m: { alias: "RVR10M" },
  rvr10x: { alias: "RVR10X" },
  vis10a: { alias: "VIS10A" },
  cloud_cover: { alias: "CLOUD COVER" },
  cloud_height: { alias: "CLOUD HEIGHT" },
};
import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import BhsSvg from "./belt_svg";
import BHSSvg from "./bhs_svg";
import "./assets/style/app.css";
import BHSTable from "./bhs_table";
import setupSocket from "utils/socket_setup";
import {
  SocketEvent,
  SocketListener,
} from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { fetchBHS, fetchBHSBelts, newBeltSocket } from "redux/actions";

import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  btn: {
    width: "55px",
    height: "28px",
    borderRadius: "12px",
    fontSize: "14px",
    fontWeight: "800",
    textTransform: "none",
    marginRight: "12px",

    "&:focus": {
      backgroundColor: "#1142BC",
    },
  },
}));

const BHS = (props) => {
  const [bhsTable, setBhsTable] = useState(false);
  const [bhsBeltId, setBhsBeltId] = useState(null);
  const dispatch = useDispatch();
  const { belts } = useSelector((state) => state.BHS);
  const auth = useSelector((state) => state);
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchBHSBelts());
    dispatch(fetchBHS());

    const BHS_socket = setupSocket(
      process.env.REACT_APP_BHS_SOCKET_PATH,
      auth.token,
    );

    BHS_socket.on(SocketListener.CONNECT, () => {
      BHS_socket.emit(SocketEvent.ROOM, ["broadcast"]);
    }).on(props.alias, (data) => {
      const { message, action } = data;
      handleNewBeltValue(action, message);
    });

    return () => {
      BHS_socket.disconnect();
    };
  }, []);

  useEffect(() => {
    renderBHSBelts(belts);
  }, [belts,bhsTable]);

  const handleNewBeltValue = (action, message) => {
    dispatch(newBeltSocket({ action, message }));
  };

  const displayBeltTooltip = (ev, belt) => {
    setBhsBeltId(belt.belt_id);
  };

  const renderBHSBelts = (belts) => {
    const div = d3
      .select("body")
      .append("div")
      .attr("class", "bhs-tooltip")
      .style("opacity", 0)
      .on("click", () => {
        div.style("opacity", 0);
      });
    belts.map((e) => {
      const group = d3.selectAll(`.bhs-svg .${e.belt_id}-group`);
      group.on("mouseover", (event) => {
        displayBeltTooltip(event, e);
      });
      group.on("mouseleave", () => {
        setBhsBeltId(null);
      });
      return null;
    });
  };

  return (
    <div className="col s12 bhs-container">
      <div className="widget-header bhs-header">
        <span title="Baggage Handling System">BHS</span>
      </div>
      <div className="container-apm-btn">
        <Button
          className={classes.btn}
          sx={
            !bhsTable
              ? { backgroundColor: "#1142BC" }
              : { backgroundColor: "#112449", color: "#205CF6" }
          }
          variant="contained"
          disableElevation
          disableRipple
          disableFocusRipple
          onClick={() => {
            setBhsTable(false);
          }}
        >
          BHS
        </Button>
        <Button
          className={classes.btn}
          sx={
            bhsTable
              ? { backgroundColor: "#1142BC" }
              : { backgroundColor: "#112449", color: "#205CF6" }
          }
          variant="contained"
          disableElevation
          onClick={() => {
            setBhsTable(true);
          }}
        >
          Details
        </Button>
      </div>
      <div className="bhs-body">
        {bhsTable ? (
          <div className="bhs-table-wrapper">
            <BHSTable belts={belts} bhsTable={bhsTable} />
          </div>
        ) : (
          <div className="svg-container bhs-svg-container">
            
            <BHSSvg>
              {belts.map((belt) => {
                let status = "idle";
                status = belt.flights.length ? "schedule" : status;
                status = belt.flights.filter((f) => f.status === "active")
                  .length
                  ? "active"
                  : status;
                status = belt.active ? status : "maint";
                return (
                    <BhsSvg
                    tooltipId={bhsBeltId === belt.belt_id ? belt.belt_id : null}
                    key={belt.belt_id}
                    belt_id={belt.belt_id}
                    status={status}
                    active={belt.active}
                    id={belt.id}
                    flight={belt.flights}
                  />
                );
              })}
            </BHSSvg>
          </div>
        )}
      </div>
    </div>
  );
};

export default BHS;

import UiDatePicker from "components/common/date/date";
import React, { useEffect, useState } from "react";

const PDSConstraints = ({ start, end, resetPDSInterval, skip, warning }) => {
  const [interval, setInterval] = useState({});
  const [date, setDate] = useState({});

  useEffect(() => {
    setInterval({ start, end });
    setDate({
      startDate: start || new Date().getTime(),
      endDate: end || new Date().getTime() + 3600 * 1000,
    });
  }, []);

  const confirmResetPDSInterval = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }
    resetPDSInterval(interval);
  };

  const handleStartValue = (start) => {
    setInterval({ ...interval, start });
    setDate({ ...date, startDate: start });
  };

  const handleEndValue = (end) => {
    setInterval({ ...interval, end });
    setDate({ ...date, endDate: end });
  };

  return (
    <div className="emma-modal overlay pds-constraints-layover">
      <div className="modal-content pds-constraints-wrapper">
        <div role="button" className="close" onClick={skip}>
          &times;
        </div>
        <div>PDS constraints</div>
        <div className="pds-constraint interval">
          <div>Set interval</div>
          <div className="date-filter-wrapper mr-top">
            <UiDatePicker
              label="Start"
              value={interval?.start || ""}
              handleChange={handleStartValue}
            />
            <UiDatePicker
              label="End"
              value={interval?.end || ""}
              handleChange={handleEndValue}
            />
          </div>
        </div>
        <div className="modal-btns">
          <div role="presentation" className="skip-btn" onClick={skip}>
            Skip
          </div>
          <div
            role="presentation"
            className="ok-btn"
            onClick={confirmResetPDSInterval}
            onKeyDown={confirmResetPDSInterval}
          >
            Save
          </div>
        </div>
        {warning ? <div className="warning">{warning}</div> : null}
      </div>
    </div>
  );
};

export default PDSConstraints;

import React from "react";

const BlockNoise = (props) => {
  const sizeMultiplier = props.size.width < 1000 ? 1 : 2;
  const XYLocation = props.projection([51.59919037956368, 25.28856647045726]);

  const animateTooltip = () => {
    const block = document.querySelector(`#noise-block`);
    if (block) {
      const origin = offset(block);
      props.showToolTips({ name: `noise-block`, origin });
    }
  };

  const offset = (el) => {
    const rect = el.getBoundingClientRect();
    return { y: rect.top, x: rect.left + rect.width / 2 };
  };

  const showToolTip = (ev) => {
    const { id } = ev.target.dataset;
    animateTooltip(id);
    const tooltipPosition = ev.target.getBoundingClientRect();
    props.toggleNoiseInfo(tooltipPosition, true);
  };

  const preventMouseEvents = (ev) => {
    return ev.preventDefault();
  };

  const renderBlockNoise = () => {
    return (
      <>
        <svg
          name={`noise-block`}
          onMouseEnter={preventMouseEvents}
          onMouseLeave={preventMouseEvents}
        >
          <rect
            id={`noise-block`}
            className="stand"
            data-name={"noise-block"}
            x={XYLocation[0] || "0"}
            y={XYLocation[1] || "0"}
            rx={(20 * sizeMultiplier) / 3.5}
            ry={(20 * sizeMultiplier) / 3.5}
            width={20 * sizeMultiplier}
            height={20 * sizeMultiplier}
            style={{ fill: "black" }}
            onClick={showToolTip}
          />
          <rect
            id={`noise-block`}
            className="block-tooltip"
            data-name={"noise-block"}
            x={XYLocation[0] || "0"}
            y={XYLocation[1] + 15 * sizeMultiplier || "0"}
            rx={(20 * sizeMultiplier) / 3.5}
            ry={(20 * sizeMultiplier) / 3.5}
            width={45 * sizeMultiplier}
            height={17 * sizeMultiplier}
            style={{ fill: "black" }}
          />
          <text
            x={XYLocation[0] + 5 * sizeMultiplier || "0"}
            y={XYLocation[1] + 25 * sizeMultiplier || "0"}
            fontSize={6 * sizeMultiplier}
            fill="white"
            className="block-tooltip"
          >
            View details
          </text>
          <rect
            x={XYLocation[0] + 6 * sizeMultiplier || "0"}
            y={XYLocation[1] + 7 * sizeMultiplier || "0"}
            width={8.5 * sizeMultiplier}
            height={6 * sizeMultiplier}
            rx="1.5"
            stroke="white"
            strokeWidth="2"
            onClick={showToolTip}
            className="stand"
          />
        </svg>
      </>
    );
  };

  return <g>{renderBlockNoise()}</g>;
};

export default BlockNoise;

import React from "react";
import WidgetIcon from "./widget_icon";

const WidgetSelector = ({ widgets, selectedWidgets }) => {
  const isSelected = (e) => {
    return Object.values(selectedWidgets).indexOf(e) > -1 && e !== "ACDM";
  };

  return (
    <div className="widget-selector">
      {Object.keys(widgets).map((e, i) => (
        <WidgetIcon
          drop={() => {}}
          isSelected={isSelected(e)}
          key={i}
          widget={e}
          enabled
          widgets={widgets}
        />
      ))}
    </div>
  );
};

export default WidgetSelector;

import moment from "moment-timezone";

const dataFilter = (data = [], filterBy = {}, timeZone) =>
  data.filter((data_record) => {
    const filterKeys = Object.keys(filterBy).filter((e) => filterBy[e]);
    if (!filterKeys.length) {
      return true;
    }

    let record = true;
    filterKeys.map((key) => {
      const _key = data_record._key || key;
      switch (filterBy[key].type) {
        case "number": {
          const isIncluded =
            _key && data_record[_key]
              ? +data_record[_key] === +filterBy[key].value
              : false;
          if (!isIncluded) {
            record = false;
          }
          break;
        }
        case "date": {
          if (data_record[_key]) {
            const filterStart = moment.tz(
              filterBy[key].value.start,
              "MM/DD/YYYY h:mm A",
              timeZone,
            );
            const filterStartUtc = filterStart.utc();

            const filterEnd = moment.tz(
              filterBy[key].value.end,
              "MM/DD/YYYY h:mm A",
              timeZone,
            );
            const filterEndUtc = filterEnd.utc();

            const isIncluded =
              _key && data_record[_key]
                ? moment(data_record[_key]).isBetween(
                    filterStartUtc,
                    filterEndUtc,
                    null,
                    "[]",
                  )
                : false;
            if (!isIncluded) {
              record = false;
            }
          } else {
            record = false;
          }
          break;
        }
        case "string": {
          const isIncluded =
            _key && data_record[_key]
              ? data_record[_key]
                  .toString()
                  .toLowerCase()
                  .includes(filterBy[key].value.toString().toLowerCase())
              : false;
          if (!isIncluded) {
            record = false;
          }
          break;
        }
        default: {
          const isIncluded =
            _key && data_record[_key]
              ? data_record[_key]
                  .toString()
                  .toLowerCase()
                  .includes(filterBy[key].value.toString().toLowerCase())
              : false;
          if (filterBy[key].type && !isIncluded) {
            record = false;
          }
          break;
        }
      }
    });
    return record;
  });

export default dataFilter;

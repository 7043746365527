import React, { useEffect, useState } from "react";
import styles from "./save_layout.module.css";
import Modal from "components/common/Modal";
import Button from "components/common/Button";
import InputForm from "components/common/UiLibrary/input_form";

const SaveLayoutModal = ({ visible, action, onClose, onSave, layout }) => {
  const [value, setValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const changeHandler = ({ target: { value } }) => {
    setValue(value);
  };

  useEffect(() => {
    setValue(layout.alias);
  }, [layout.alias]);

  const onSubmit = () => {
    if (value) {
      setErrorMsg("");
      layout.alias = value;
      onSave(layout);
      setValue("");
    } else {
      setErrorMsg("Layout name is required");
    }
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      zIndex={1301}
      title={`${action} layout`}
      subTitle="You are creating a new layout for managing widgets"
    >
      <div className={styles.list_container}>
        <InputForm
          onChange={changeHandler}
          label="Layout name"
          value={value}
          error={errorMsg}
        />

        <div className={styles.actions_container}>
          <Button onClick={onClose} type="text">
            Cancel
          </Button>
          <Button onClick={onSubmit} type="primary">
            Add
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SaveLayoutModal;

import React, { useContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { changeLayout } from "redux/actions";
import LayoutsGrid from "./LayoutsGrid";
import { AuthContext } from "context/authWidget";

const NavLayoutMenu = (props) => {
  const dispatch = useDispatch();
  const ref = useRef();

  const { widgetToView } = useContext(AuthContext);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        props.displayLayoutGrid &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        props.toggleLayout(e);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [props.displayLayoutGrid]);

  const handleLayoutChange = ({ alias, id, direction }) => {
    dispatch(changeLayout({ alias, id, direction }));
    props.disableLayoutGrid();
  };

  return (
    <div
      ref={ref}
      className={`layouts-grid-container ${
        location === "/layout" ? "active" : ""
      } nav-btn`}
    >
      {props.auth.token && props.roles && !props.isSmall ? (
        <LayoutsGrid
          widgets={widgetToView}
          user={props.auth.user}
          activeLayout={props.layout}
          display={props.display}
          handleLayoutChange={handleLayoutChange}
          layouts={props.layouts}
          setDisplayLayoutGrid={props.setDisplayLayoutGrid}
        />
      ) : null}
    </div>
  );
};

export default NavLayoutMenu;

import React from "react";
import { TextField } from "@mui/material";
import styles from "./input_form.module.css";

export default function InputForm(props) {
  const {
    name,
    label,
    value = "",
    error = null,
    onChange,
    icon,
    id,
    index,
    disabled = false,
    type,
    ...other
  } = props;

  return (
    <>
      <div className={styles.container}>
        {icon ? <div className={styles.inputIcon}>{icon}</div> : null}
        <TextField
          type={type}
          label={label}
          name={name}
          id={id}
          key={index}
          value={value}
          disabled={disabled}
          variant="filled"
          onChange={onChange}
          onKeyPress={(event) => {
            if (
              event.key === "Enter" ||
              (event.code === "Minus" && type === "number")
            ) {
              event.preventDefault();
              return false;
            }
          }}
          onWheel={(e) => e.target.blur()}
          InputProps={{
            disableUnderline: true,
          }}
          className={styles.uiInput}
          InputLabelProps={{
            shrink: value ? true : undefined,
            sx: {
              "&.MuiInputLabel-shrink": {
                color: "#909fba",
              },
            },
          }}
          {...other}
          {...(error && { error: true, helperText: error })}
        />
      </div>
    </>
  );
}

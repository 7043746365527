import React, { Component } from "react";
import * as d3 from "d3";

export default class AirPlane extends Component {
  constructor(props) {
    super(props);
    this.animateToolTip = this.animateToolTip.bind(this);
    this.pointAtLength = this.pointAtLength.bind(this);
    this.activateFlight = this.activateFlight.bind(this);

    this.state = {
      current_location: {},
      next_location: {},
      scale: this.props.scale,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      JSON.stringify(nextProps.next_location) !==
      JSON.stringify(prevState.next_location)
    ) {
      return { next_location: nextProps.next_location };
    }
    if (
      JSON.stringify(nextProps.current_location) !==
      JSON.stringify(prevState.current_location)
    ) {
      return { current_location: nextProps.current_location };
    }
    if (nextProps.scale !== prevState.scale) {
      return { scale: nextProps.scale };
    }
    return null;
  }

  componentDidMount() {
    const { next_location, current_location, icao } = this.props;
    if (next_location) {
      const point = this.props.projection([
        current_location.x,
        current_location.y,
      ]);
      const nextPoint = this.props.projection([
        next_location.x,
        next_location.y,
      ]);
      const rotate = this.angleAtPoint(point, nextPoint);
      const transform = `translate(${nextPoint[0]}, ${nextPoint[1]}) rotate(${rotate}) scale(${this.state.scale})`;
      d3.select(`g#${icao}`).attr("transform", transform);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!document.hidden && this.props.next_location) {
      this.track(prevProps, prevState);
    }
  }

  /**
   * Event handler, this method will act like event handler to show redux-tooltip component.
   * @param Object eventObject
   */
  animateToolTip() {
    const airplane = document.querySelector(
      `g[data-tail-number="${this.props.aircraft_registration}"]`,
    );
    if (airplane) {
      const origin = this.offset(airplane);
      this.props.showToolTips({
        name: this.props.aircraft_registration,
        origin,
      });
    }
  }

  /**
   * Helper method to get a DOM element position in the page.
   * @param {*} el
   */
  offset(el) {
    const rect = el.getBoundingClientRect();
    return { y: rect.top, x: rect.left + rect.width / 2 };
  }

  /**
   * Handle the animation of airplane on the airport plain, once the new location
   * recieved by this component @componentWillReceiveProps method invoke this method
   * to animate the airplane to the next location on the airport which is actually
   * the real-time current location of the airplane.
   *
   * @param Object next_location
   * @param String planeId
   */
  track(prevProps, prevState) {
    const { icao } = this.props;
    const marker = d3.select(`.${icao}`),
      { current_location, next_location } = this.props;

    const lineData = [],
      purpleLineData = [];
    const point = this.props.projection([
      current_location.x,
      current_location.y,
    ]);
    const nextPoint = this.props.projection([next_location.x, next_location.y]);
    lineData.push({ x: point[0], y: point[1] });
    purpleLineData.push({ x: point[0], y: point[1] });
    lineData.push({ x: nextPoint[0], y: nextPoint[1] });
    purpleLineData.push({ x: nextPoint[0], y: nextPoint[1] });

    const timeOfMove = this.props.speed * 1000;

    const lineFunction = d3
      .line()
      .x((d) => d.x)
      .y((d) => d.y)
      .curve(d3.curveCardinal.tension(0.5));

    d3.selectAll(`.${icao}`).remove();
    d3.selectAll(`.track-${icao}`).remove();

    d3.select(".airport")
      .append("path")
      .attr("d", lineFunction(lineData))
      .attr("class", ` active ${icao}`)
      .attr("stroke", "red")
      .attr("stroke-width", 0)
      .attr("fill", "none");

    if (typeof this.props.current_location !== "undefined") {
      d3.selectAll(".track")
        .data(this.props.current_location)
        .enter()
        .append("circle")
        .attr("r", 5)
        .attr("cx", (d) => this.projection([d.x, d.y])[0])
        .attr("cy", (d) => this.projection([d.x, d.y])[1]);

      d3.select(".airport")
        .append("path")
        .attr("d", lineFunction(purpleLineData))
        .attr("class", `track-${icao}`)
        .attr("stroke", "blue")
        .attr("stroke-width", 0)
        .attr("fill", "none");
    }

    const t = d3.transition().ease(d3.easeLinear).duration(timeOfMove);
    const rotate = this.angleAtPoint(point, nextPoint);
    const transform = `translate(${nextPoint[0]}, ${nextPoint[1]}) rotate(${rotate}) scale(${this.state.scale})`;
    d3.select(`g#${icao}`).attr("transform", transform);
    if (
      JSON.stringify(prevProps.next_location) !==
      JSON.stringify(this.props.next_location)
    ) {
      this.move(icao, marker, t);
    }
  }

  move(icao, marker, t) {
    const totalLength = document.querySelector(`.${icao}`).getTotalLength();
    const prevTotalLength = document
      .querySelector(`.track-${icao}`)
      .getTotalLength();
    let rotate = 0;
    let translate = "";
    if (totalLength && prevTotalLength) {
      if (!this.props.on_block) {
        d3.select(`#${icao}`)
          .transition(t)
          .attrTween("transform", () => (t) => {
            if (marker != null) {
              this.props.hideToolTips({
                name: this.props.aircraft_registration,
              });
              const prevPos = t * prevTotalLength;
              rotate = this.angleAtLength(prevPos);
              translate = this.pointAtLength(prevPos);
              if (
                this.props.active?.flight?.aircraft_registration ===
                this.props.aircraft_registration
              ) {
                this.animateToolTip({ name: this.props.aircraft_registration });
              } else {
                this.props.hideToolTips({
                  name: this.props.aircraft_registration,
                });
              }

              if (!rotate && !this.props.off_block) {
                const { current_location, next_location } = this.props;
                const firstPointCurrentLocation = current_location;
                const lastPointCurrentLocation = next_location;
                const next_locationProjected = this.props.projection([
                  firstPointCurrentLocation.x,
                  firstPointCurrentLocation.y,
                ]);
                const currentLocationProjected = this.props.projection([
                  lastPointCurrentLocation.x,
                  lastPointCurrentLocation.y,
                ]);
                rotate = Math.round(
                  this.angleAtPoint(
                    next_locationProjected,
                    currentLocationProjected,
                  ),
                );
                rotate = this.props.off_block ? rotate - 180 : rotate;
                return `translate(${translate}) rotate(${rotate}) scale(${this.state.scale})`;
              }
              rotate = this.props.off_block ? rotate - 180 : rotate;
              return `translate(${translate}) rotate(${rotate}) scale(${this.state.scale})`;
            }
          });
      } else {
        const { current_location, next_location } = this.props;
        const firstPointCurrentLocation = current_location;
        const lastPointCurrentLocation = next_location;
        const next_locationProjected = this.props.projection([
          firstPointCurrentLocation.x,
          firstPointCurrentLocation.y,
        ]);
        const currentLocationProjected = this.props.projection([
          lastPointCurrentLocation.x,
          lastPointCurrentLocation.y,
        ]);
        const lastPointNext_location =
          this.flatteArr(current_location).slice(-1)[0];
        const lastPointNext_locationProjected = this.props.projection([
          lastPointNext_location.x,
          lastPointNext_location.y,
        ]);
        const rotate = this.angleAtPoint(
          next_locationProjected,
          currentLocationProjected,
        );
        const transform = `translate(${
          +lastPointNext_locationProjected[0] - 20
        }, ${
          +lastPointNext_locationProjected[1] - 12
        }) rotate(${rotate}) scale(${this.state.scale})`;
        d3.select(`g#${icao}`).attr("transform", transform);
        // }, 90);
      }
    }
  }

  flatteArr(arr) {
    return arr.reduce(
      (acc, val) =>
        Array.isArray(val) ? acc.concat(this.flatteArr(val)) : acc.concat(val),
      [],
    );
  }

  /**
   * pointAtLength, wrapper function to compute a point from an svg path string
   * @param {*} l
   * @return Array
   */
  pointAtLength(l) {
    const p = document.querySelector(`.track-${this.props.icao}`);
    if (p) {
      const xy = p.getPointAtLength(l);
      return [xy.x, xy.y];
    }
    return [0, 0];
  }

  /**
   * angleAtLength
   * Approximate tangent
   * @param {*} l
   * @return Angle between two points
   */
  angleAtLength(l) {
    const a = this.pointAtLength(Math.max(l - 0.01, 0)), // this could be slightly negative
      b = this.pointAtLength(l + 0.01); // browsers cap at total length
    return (Math.atan2(b[1] - a[1], b[0] - a[0]) * 180) / Math.PI;
  }

  /**
   * angleAtPoint
   * Approximate tangent
   * @param {*} l
   * @return Angle between two points
   */
  angleAtPoint(p1, p2) {
    return (Math.atan2(p2[1] - p1[1], p2[0] - p1[0]) * 180) / Math.PI;
  }

  /**
   * getTranslation
   * @param Array transform
   */
  getTranslation(transform) {
    // Create a dummy g for calculation purposes only. This will never
    // be appended to the DOM and will be discarded once this function
    // returns.
    const g = document.createElementNS("http://www.w3.org/2000/svg", "g");

    // Set the transform attribute to the provided string value.
    g.setAttributeNS(null, "transform", transform);

    // consolidate the SVGTransformList containing all transformations
    // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
    // its SVGMatrix.
    const matrix = g.transform.baseVal.consolidate().matrix;

    // As per definition values e and f are the ones for the translation.
    return [matrix.e, matrix.f];
  }

  activateFlight(e) {
    e.stopPropagation();
    this.props.activateFlight({
      aircraft_registration: this.props.aircraft_registration,
      flight_number: this.props.flight_number,
      flight: this.props.flight,
    });
  }

  preventMouseEvents(ev) {
    return ev.preventDefault();
  }

  render() {
    const { current_location, next_location, icao } = this.props;
    if (!next_location) {
      return <React.Fragment />;
    }
    const classes =
      this.props.active?.flight?.aircraft_registration ===
      this.props.aircraft_registration
        ? "active aircraft"
        : "aircraft";
    const firstPointCurrentLocation = current_location;
    const lastPointCurrentLocation = next_location;
    const currentLocationProjected = this.props.projection([
      firstPointCurrentLocation.x,
      firstPointCurrentLocation.y,
    ]);
    const next_locationProjected = this.props.projection([
      lastPointCurrentLocation.x,
      lastPointCurrentLocation.y,
    ]);
    const lastPointNext_location = next_location;
    const lastPointNext_locationProjected = this.props.projection([
      lastPointNext_location.x,
      lastPointNext_location.y,
    ]);
    return (
      <g onClick={this.activateFlight}>
        <g
          className={classes}
          data-current-location={JSON.stringify(this.props.current_location)}
          data-next-location={JSON.stringify(this.props.next_location)}
          data-next-initial-location={JSON.stringify(
            this.props.initial_location,
          )}
          data-tail-number={this.props.aircraft_registration}
          id={icao}
        >
          <g
            onMouseEnter={this.preventMouseEvents}
            onMouseLeave={this.preventMouseEvents}
            name={this.props.aircraft_registration}
            className="inner aircraft-icon"
          >
            <svg width="30" height="30" id="airplan" className={classes}>
              <g
                dx="100"
                dy="100"
                width="50"
                height="50"
                transform="scale(0.5)"
              >
                <path
                  d="M22.654 22.552c0.102 0 0.203 0.102 0.406 0.102 0.305 0 0.508 0.102 1.321 0.102s0.813-0.102 1.117-0.102c0.508 0 0.711-0.102 0.711-0.406v-1.016c0-0.305-0.102-0.406-0.711-0.406-0.305 0-0.305-0.102-1.117-0.102-0.305 0-0.508 0-0.711 0l1.625-2.844c4.064 0 8.025 0 10.26 0 1.93 0 4.978-0.508 4.978-1.524s-3.048-1.625-4.978-1.625c-2.337 0-6.197 0-10.26 0l-1.625-2.844c0.203 0 0.406 0 0.711 0 0.813 0 0.813-0.102 1.117-0.102 0.508 0 0.711-0.102 0.711-0.406v-1.016c0-0.305-0.102-0.406-0.711-0.406-0.305 0-0.305-0.102-1.117-0.102s-1.016 0-1.321 0.102c-0.203 0-0.305 0-0.406 0.102l-5.079-9.244c-0.305-0.305-1.625-0.203-1.625-0.203 0 0.102 0.61 2.743 1.219 5.283-0.305 0-0.508 0-0.508 0.102s0.203 0.102 0.61 0.102c0.203 0.914 0.508 1.829 0.61 2.54-0.406 0-0.508 0-0.508 0.102s0.203 0.102 0.61 0.102c0.203 0.711 0.305 1.117 0.305 1.117v1.321c-0.406 0-0.508 0-0.508 0.102s0.203 0.102 0.508 0.102v2.946c-5.283 0-9.448 0-9.448 0-0.813 0-2.032 0.102-2.844 0.203-0.508-0.711-3.149-4.368-3.454-4.775 0 0.102-1.422 0.102-1.422 0.203 0 0.305 1.219 4.673 1.422 5.384-0.406 0.102-1.625 0.305-1.93 0.61-0.102 0-0.203 0-0.203 0.102 0.102 0.102 0.203 0.102 0.305 0.102 0.203 0.305 1.524 0.508 1.829 0.61-0.203 0.711-1.422 5.079-1.422 5.384 0 0.102 1.422 0.203 1.524 0.102 0.305-0.406 2.946-4.064 3.454-4.775 0.813 0.102 1.93 0.305 2.743 0.305 0 0 4.267 0 9.448 0v2.946c-0.406 0-0.508 0-0.508 0.102s0.203 0.102 0.508 0.102v1.321c0 0-0.102 0.406-0.305 1.117-0.406 0-0.61 0-0.61 0.102s0.203 0.102 0.508 0.102c-0.203 0.711-0.406 1.625-0.61 2.54-0.406 0-0.61 0-0.61 0.102s0.102 0.102 0.508 0.102c-0.61 2.54-1.321 5.181-1.321 5.283 0 0 1.321 0.203 1.625-0.102l5.181-9.041z"
                  filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.1))"
                />
              </g>
            </svg>
          </g>
        </g>
      </g>
    );
  }
}

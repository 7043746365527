import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { activateFlight, deActivateFlight } from "redux/actions";
import Fields from "./Fields";
import { isEmpty } from "lodash";

const TableRow = ({
  flight,
  headers,
  selectedFlight,
  onClickFieldHandler,
  clearData,
  setX,
  setY,
}) => {
  const dispatch = useDispatch();
  const { activeFlight } = useSelector((state) => state);

  const getClassName = () => {
    if (
      (activeFlight?.flight_number === flight.flight_number &&
        activeFlight?.flight_date === flight.flight_date) ||
      (selectedFlight.flight_number === flight.flight_number &&
        selectedFlight?.departure_flight_date === flight.departure_flight_date)
    )
      return "activeFlight";
    return null;
  };

  const handleActiveFlight = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }

    const { flight_date, flight_number, aircraft_registration } = flight;

    if (
      !isEmpty(selectedFlight) ||
      activeFlight.aircraft_registration === aircraft_registration
    ) {
      dispatch(deActivateFlight(aircraft_registration));
    } else {
      dispatch(
        activateFlight({
          flight_date,
          flight_number,
          flight,
          aircraft_registration,
        }),
      );
    }
  };

  const getFlightReference = () => {
    return `${flight.flight_number}/${
      flight.departure_flight_date || flight.flight_date
    }`;
  };

  return (
    <tr
      key={getFlightReference()}
      className={getClassName()}
      onClick={handleActiveFlight}
    >
      <Fields
        flight={flight}
        headers={headers}
        onClickFieldHandler={onClickFieldHandler}
        clearData={clearData}
        setY={setY}
        setX={setX}
      />
    </tr>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    _.isEqual(prevProps.flight, nextProps.flight) &&
    prevProps.headers === nextProps.headers &&
    prevProps.activeFlight === nextProps.activeFlight &&
    prevProps?.selectedFlight?.aircraft_registration ===
      nextProps?.selectedFlight?.aircraft_registration
  );
};
export default React.memo(TableRow, areEqual);

import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/APIRequest";
import {
  FETCH_ORGANIZATIONS_START,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAIL,
  DELETE_ORGANIZATIONS_START,
  DELETE_ORGANIZATIONS_SUCCESS,
  DELETE_ORGANIZATIONS_FAIL,
  RESTORE_ORGANIZATIONS_START,
  RESTORE_ORGANIZATIONS_SUCCESS,
  RESTORE_ORGANIZATIONS_FAIL,
  RESTORE_USERS_ORGANIZATIONS_START,
  RESTORE_USERS_ORGANIZATIONS_SUCCESS,
  RESTORE_USERS_ORGANIZATIONS_FAIL,
  GET_ROLES_BY_ORG_ID_START,
  GET_ROLES_BY_ORG_ID_SUCCESS,
  GET_ROLES_BY_ORG_ID_FAIL,
  GET_AIRLINES_BY_ORG_ID_START,
  GET_AIRLINES_BY_ORG_ID_SUCCESS,
  GET_AIRLINES_BY_ORG_ID_FAIL,
  CREATE_ORGANIZATION_START,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAIL,
  UPDATE_ORGANIZATION_START,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAIL,
  FETCH_ORGANIZATION_START,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAIL,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
} from "../constants";

const fetchOrganizationsStart = () => ({
  type: FETCH_ORGANIZATIONS_START,
});

const fetchOrganizationsSuccess = (data, total) => ({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  data,
  total,
});

const fetchOrganizationsFail = () => ({
  type: FETCH_ORGANIZATIONS_FAIL,
});

export const fetchOrganizations = (data) => {
  return async (dispatch) => {
    dispatch(fetchOrganizationsStart());

    const url = `/api/organizations`;
    const response = await getRequest(url, data);

    if (!response.error) {
      dispatch(fetchOrganizationsSuccess(response.data, response.total));
    } else {
      dispatch(fetchOrganizationsFail());
    }
  };
};

const deleteOrganizationStart = () => ({
  type: DELETE_ORGANIZATIONS_START,
});

const deleteOrganizationSuccess = (data) => ({
  type: DELETE_ORGANIZATIONS_SUCCESS,
  data,
});

const deleteOrganizationFail = () => ({
  type: DELETE_ORGANIZATIONS_FAIL,
});

export const deleteOrganization = (ids, cb) => {
  return async (dispatch) => {
    dispatch(deleteOrganizationStart());

    const url = `/api/organizations`;
    const response = await deleteRequest(url, { ids });

    if (!response.error) {
      dispatch({ type: SUCCESS_MESSAGE, message: response.message });
      dispatch(deleteOrganizationSuccess(ids));
      cb && cb();
    } else {
      dispatch(deleteOrganizationFail());
    }
  };
};

const restoreOrganizationStart = () => ({
  type: RESTORE_ORGANIZATIONS_START,
});

const restoreOrganizationSuccess = (data) => ({
  type: RESTORE_ORGANIZATIONS_SUCCESS,
  data,
});

const restoreOrganizationFail = () => ({
  type: RESTORE_ORGANIZATIONS_FAIL,
});

export const restoreOrganization = (ids, cb) => {
  return async (dispatch) => {
    dispatch(restoreOrganizationStart());

    const url = `/api/organizations/restore/multi`;
    const response = await putRequest(url, { ids });
    if (!response.error) {
      dispatch({ type: SUCCESS_MESSAGE, message: response.message });
      dispatch(restoreOrganizationSuccess(ids));
      cb && cb(response.users);
    } else {
      dispatch(restoreOrganizationFail());
    }
  };
};

const restoreUserssOrganizationStart = () => ({
  type: RESTORE_USERS_ORGANIZATIONS_START,
});

const restoreUsersOrganizationSuccess = () => ({
  type: RESTORE_USERS_ORGANIZATIONS_SUCCESS,
});

const restoreUsersOrganizationFail = () => ({
  type: RESTORE_USERS_ORGANIZATIONS_FAIL,
});

export const restoreUsersOrganization = (ids, cb) => {
  return async (dispatch) => {
    dispatch(restoreUserssOrganizationStart());
    const url = `/api/organizations/restore/userOrganization`;
    const response = await putRequest(url, { ids });
    if (!response.error) {
      dispatch({ type: SUCCESS_MESSAGE, message: response.message });
      dispatch(restoreUsersOrganizationSuccess(ids));
      cb && cb();
    } else {
      dispatch(restoreUsersOrganizationFail());
    }
  };
};
const getRolesByOrgIdStart = () => ({
  type: GET_ROLES_BY_ORG_ID_START,
});

const getRolesByOrgIdSuccess = (data) => ({
  type: GET_ROLES_BY_ORG_ID_SUCCESS,
  data,
});

const getRolesByOrgIdFail = () => ({
  type: GET_ROLES_BY_ORG_ID_FAIL,
});

export const getRolesByOrgId = (orgId) => {
  return async (dispatch) => {
    dispatch(getRolesByOrgIdStart());

    const url = `/api/organizations/${orgId}/roles`;
    const response = await getRequest(url);

    if (!response.error) {
      dispatch(getRolesByOrgIdSuccess(response));
    } else {
      dispatch(getRolesByOrgIdFail());
    }
  };
};

const getAirlinesByOrgIdStart = () => ({
  type: GET_AIRLINES_BY_ORG_ID_START,
});

const getAirlinesByOrgIdSuccess = (data) => ({
  type: GET_AIRLINES_BY_ORG_ID_SUCCESS,
  data,
});

const getAirlinesByOrgIdFail = () => ({
  type: GET_AIRLINES_BY_ORG_ID_FAIL,
});

export const getAirlinesByOrgId = (orgId, cb) => {
  return async (dispatch) => {
    dispatch(getAirlinesByOrgIdStart());

    const url = `/api/organizations/${orgId}/airlines`;
    const response = await getRequest(url);

    if (!response.error) {
      dispatch(getAirlinesByOrgIdSuccess(response));
      cb && cb(response);
    } else {
      dispatch(getAirlinesByOrgIdFail());
    }
  };
};

const fetchOrganizationStart = () => ({
  type: FETCH_ORGANIZATION_START,
});

const fetchOrganizationSuccess = (data) => ({
  type: FETCH_ORGANIZATION_SUCCESS,
  data,
});

const fetchOrganizationFail = () => ({
  type: FETCH_ORGANIZATION_FAIL,
});

export const fetchOrganization = (dataId) => {
  return async (dispatch) => {
    dispatch(fetchOrganizationStart());

    const url = `/api/organizations/${dataId}`;
    const response = await getRequest(url);

    if (!response.error) {
      dispatch(fetchOrganizationSuccess(response));
    } else {
      dispatch(fetchOrganizationFail());
    }
  };
};

const createOrganizationStart = () => ({
  type: CREATE_ORGANIZATION_START,
});

const createOrganizationSuccess = (data, total) => ({
  type: CREATE_ORGANIZATION_SUCCESS,
  data,
});

const createOrganizationFail = () => ({
  type: CREATE_ORGANIZATION_FAIL,
});

export const createOrganization = (data) => {
  return async (dispatch) => {
    dispatch(createOrganizationStart());

    const response = await postRequest(
      `/api/organizations/`,
      data,
      {
        Cache: "no-cache",
      },
      true,
    );

    if (!response.error) {
      dispatch(createOrganizationSuccess(response, response));
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "Create organization successfully",
        back: true,
      });
    } else {
      dispatch({ type: ERROR_MESSAGE, message: "Create organization failed" });
      dispatch(createOrganizationFail());
    }
  };
};

const updateOrganizationStart = () => ({
  type: UPDATE_ORGANIZATION_START,
});

const updateOrganizationSuccess = (data) => ({
  type: UPDATE_ORGANIZATION_SUCCESS,
  data,
});

const updateOrganizationFail = () => ({
  type: UPDATE_ORGANIZATION_FAIL,
});

export const updateOrganization = (dataId, data) => {
  return async (dispatch) => {
    dispatch(updateOrganizationStart());

    const response = await putRequest(
      `/api/organizations/${dataId}`,
      data,
      {
        Cache: "no-cache",
      },
      true,
    );

    if (!response.error) {
      dispatch(updateOrganizationSuccess(response));
      dispatch({
        type: SUCCESS_MESSAGE,
        message: "Update organization successfully",
        back: true,
      });
    } else {
      dispatch({ type: ERROR_MESSAGE, message: "Update organization failed" });
      dispatch(updateOrganizationFail());
    }
  };
};

import React from "react";
import { useSelector } from "react-redux";
import { isAuthorized } from "utils/helpers";
import Unauthorized from "components/common/ErrorsPages/UnauthorizedPage";

const checkpermissions = (roles, name, permission) => {
  const permissions = isAuthorized(roles, name);
  return permissions[permission];
};

const AuthorizationRoute = ({ children, name, permission }) => {
  const { user } = useSelector((state) => state.auth);
  const hasPermission = checkpermissions(user.roles, name, permission);
  return <>{hasPermission ? children : <Unauthorized />}</>;
};

export default AuthorizationRoute;

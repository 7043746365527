import React from "react";

const SideBarWidgetItem = ({ saveLayoutConfig, widget }) => {
  const clickHandler = () => {
    saveLayoutConfig(widget.permission_name);
  };
  return (
    <div
      key={widget.id}
      className="sidebar-btn sidebar-widget-icon"
      tabIndex="0"
      role="button"
      onClick={clickHandler}
    >
      <i
        aria-hidden="true"
        className={`sidebar-icon fa fa-${widget.style.icon}`}
      />
      <span>{widget.label}</span>
    </div>
  );
};

export default SideBarWidgetItem;

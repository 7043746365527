import React, { useEffect, useState } from "react";
import DateTimePicker from "./date_time_picker";
import IntegerInput from "./integer_modal";
import { useDispatch, useSelector } from "react-redux";
import { outBoundMessages } from "redux/actions";
import {
  isAuthorized,
  checkEquals,
  validateEobtWindow,
  getOldValue,
  getDefaultData,
  formatValue,
  findFlight,
  validateActualFields,
  valueInTobtLimit,
} from "utils/helpers";
import checkValidationUpdate from "../validateUpdate";
import handleEditableFieldsConditions from "../can_edit";
import { getManualUpdateValidation } from "utils/validation/acdm_manual_update";
import moment from "moment-timezone";

const ChooseField = ({ data, handleClose, modalUpdateCallBack }) => {
  const [errorMessageUpdate, setErrorMessageUpdate] = useState(null);
  const [fieldName, setFieldName] = useState("");
  const [fieldValue, setFieldValue] = useState();
  const [errorMessageInput, setErrorMessageInput] = useState("");

  const { acdmFlights: acdmFlightsObj } = useSelector((state) => state.ACDM);
  const { auth } = useSelector((state) => state);
  const { systemParams } = useSelector((state) => state.SystemParams);

  const { roles } = auth.user;
  const acdmFlights = Object.values(acdmFlightsObj);
  const { editableFields = [], field = {}, flight, action } = data;
  const {
    tobt_update: tobtUpdate = {},
    eobt_window: EobtWindow = {},
    tobt_manual_update: tobtUpdateLimit = {},
  } = systemParams;
  const { type } = field;

  const { timeZone } = useSelector((state) => state.SystemParams);

  const dispatch = useDispatch();

  useEffect(() => {
    const name = editableFields[0];
    const value = getDefaultData(name, field, flight);
    setFieldValue(value);
    setFieldName(name);
  }, [data]);

  useEffect(() => {
    validateInputField({
      name: editableFields[0],
      flight,
      action,
    });
  }, [editableFields]);

  const clearErrorMessage = () => {
    setErrorMessageInput("");
    setErrorMessageUpdate(null);
  };

  const validateInputField = ({ name, flight, action }) => {
    clearErrorMessage();
    const { canEdit, message } = handleEditableFieldsConditions(
      name,
      flight,
      roles,
      tobtUpdate,
      action,
    );

    if (canEdit) return canEdit;
    setErrorMessageInput(message);
    return canEdit;
  };

  const handleTab = (ev) => {
    const name = ev.target.name;
    const value = data.value || getDefaultData(name, field, flight);
    setFieldValue(value);
    setFieldName(name);
    return validateInputField({ name, flight });
  };

  const formatFieldName = (fieldName) => {
    return fieldName.split("_").join(" ").toUpperCase();
  };

  const FormatDate = (date, timeZone) => {
    return date ? moment(date).tz(timeZone).format("DD MMM, HH:mm A") : "N/A";
  };

  const getDateMessage = ({
    flight_number,
    fieldName,
    oldVal = null,
    newVal,
    timeZone,
  }) => {
    return `Flight number ${flight_number} changed its ${formatFieldName(
      fieldName,
    )} from ${FormatDate(oldVal, timeZone)} to ${FormatDate(newVal, timeZone)}`;
  };

  const getNumberMessage = ({ flight_number, fieldName, oldVal, newVal }) => {
    return `Flight number ${flight_number} changed its ${formatFieldName(
      fieldName,
    )} from ${oldVal} to ${newVal}`;
  };

  const dataChanged = (value, fieldName) => {
    const { canUpdate } = isAuthorized(roles, fieldName.toUpperCase());
    if (!canUpdate) return null;

    const { field, flight: flightData, action = "" } = data;
    const { type } = field;
    const { flight_number } = flightData;
    const flight = findFlight(acdmFlights, flight_number);
    const iata = flight.arrival_iata || flight.departure_iata || "";
    const icao = flight.arrival_icao || flight.departure_icao || "";
    const oldVal = getOldValue(flight[fieldName], type);
    const newVal = formatValue(value, type, timeZone);
    const messages = {
      date: getDateMessage({
        flight_number,
        fieldName,
        oldVal,
        newVal,
        timeZone,
      }),
      number: getNumberMessage({ flight_number, fieldName, oldVal, newVal }),
    };
    const outBoundData = {
      queue: `${fieldName}_q`,
      iata: iata.slice(0, 2),
      icao: icao.slice(0, 3),
      field: fieldName.toUpperCase(),
      flight_number: flight.flight_number,
      runway: flight.runway,
      aircraft_registration: flight.aircraft_registration,
      old_value: oldVal,
      new_value: newVal,
      message: messages[type],
      type,
      action,
    };

    dispatch(
      outBoundMessages(JSON.stringify(outBoundData), () => {
        clearErrorMessage();
        handleClose();
        modalUpdateCallBack && modalUpdateCallBack(flight_number);
      }),
    );
  };

  const dataValidation = (value, fieldName, isConfirm) => {
    const isValid = validateData({ value, fieldName, isConfirm });
    if (!isValid) {
      return isValid;
    }
    dataChanged(value, fieldName);
  };

  const handleConfirm = (value, fieldName) => {
    return dataValidation(value, fieldName, true);
  };

  const handleSave = (value, fieldName) => {
    return dataValidation(value, fieldName, false);
  };

  const validateData = ({ value, fieldName, isConfirm }) => {
    const { field, flight: flightData, confirmBtnVal, action = "" } = data;
    const { type } = field;
    const { flight_number } = flightData;
    const flight = findFlight(acdmFlights, flight_number);
    const oldValue = getOldValue(flight[fieldName], type);
    const isEquals = checkEquals(value, oldValue, type, action, timeZone);
    let errorMessage = "";

    if (isEquals && !isConfirm) {
      errorMessage = getManualUpdateValidation(
        fieldName.toUpperCase(),
        confirmBtnVal,
      ).equalValue;
    }

    if (!validateEobtWindow(fieldName, value, flight, EobtWindow, timeZone)) {
      errorMessage = getManualUpdateValidation(
        EobtWindow["after"],
        EobtWindow["before"],
      ).tobtValidation;
    }

    if (!validateActualFields(fieldName, value, timeZone)) {
      errorMessage = getManualUpdateValidation(
        fieldName.toUpperCase(),
      ).actualValidation;
    }

    const { isEnabled: limitIsEnabled, value: minLimit } = tobtUpdateLimit;
    if (
      !isConfirm &&
      limitIsEnabled &&
      !valueInTobtLimit({ fieldName, flight, minLimit, value })
    ) {
      errorMessage = getManualUpdateValidation(minLimit).tobtOutLimit;
    }
    const { isValid, errorMsg } = checkValidationUpdate(
      value,
      fieldName,
      flight,
      timeZone,
    );

    if (!isValid) {
      errorMessage = errorMsg;
    }

    if (errorMessage) {
      setErrorMessageUpdate(errorMessage);
      return false;
    }

    return true;
  };

  return (
    <div>
      <div className="container-tab">
        {editableFields.map((field) => (
          <a
            className={`item-tab ${
              fieldName === field ? "item-tab-active" : ""
            }`}
            onClick={handleTab}
            name={field}
            key={field}
          >
            {field}
          </a>
        ))}
      </div>
      {type === "number" ? (
        <IntegerInput
          value={fieldValue}
          handleSave={handleSave}
          fieldName={fieldName}
          data={data}
          errorMessage={errorMessageInput}
          errorMessageUpdate={errorMessageUpdate}
        />
      ) : (
        <DateTimePicker
          value={fieldValue}
          handleSave={handleSave}
          handleConfirm={handleConfirm}
          fieldName={fieldName}
          data={data}
          errorMessage={errorMessageInput}
          errorMessageUpdate={errorMessageUpdate}
        />
      )}
    </div>
  );
};

export default ChooseField;

import React, { useEffect, useState } from "react";
import projectionFactory from "../common/projection/projection_factory";
import airportTypography from "../common/projection/airport_typography.json";
import ASMGCS from "../../containers/asmgcs_container";
import AcdmGrid from "components/widgets/acdm";
import BHS from "components/widgets/bhs";
import Kpi from "components/widgets/kpi";
import APM from "components/widgets/apm";
import Activity from "components/widgets/activity";
import EMS from "components/widgets/ems";
import Weather from "components/widgets/weather";
import PDS from "components/widgets/pds";

const WidgetRouter = ({
  component,
  fullScreenWidget,
  block,
  fullscreenBlock,
  widgetsData,
  systemParams,
  layoutConfig,
}) => {
  const [size, setSize] = useState({});
  const [projection, setProjection] = useState(null);

  useEffect(() => {
    onSize(size);
  }, [size]);

  function onSize(size) {
    const asmgcsBlock = document.querySelector(`#${block}`);
    const fixedSize = size;
    if (asmgcsBlock) {
      const height = asmgcsBlock.offsetHeight - 10;
      fixedSize.height = size.height || height;
    }
    changeHeight(fixedSize);
  }

  function changeHeight(newSize, sub = false) {
    const sizeWidget = newSize || size;
    let { width, height } = sizeWidget;
    const checkboxFlag =
      document.querySelector(".flights-details-toggle-btn input") !== null
        ? document.querySelector(".flights-details-toggle-btn input").checked
        : false;
    if (sub || checkboxFlag) {
      height -= 150;
    }
    const newProjection = projectionFactory(
      {
        width,
        height,
        topojson: airportTypography,
      },
      ".svgContainer"
    );
    setSize(sizeWidget);
    setProjection(() => newProjection);
  }
  switch (component) {
    case "APM":
      return widgetsData.APM ? <APM {...widgetsData.APM} /> : <div />;
    case "WEATHER":
      return widgetsData.WEATHER ? (
        <Weather
          projection={projection}
          {...widgetsData.WEATHER}
          fullscreenBlock={fullscreenBlock}
        />
      ) : (
        <div />
      );
    case "airport_layout_widget":
      return widgetsData.airport_layout_widget ? (
        <ASMGCS
          projection={projection}
          changeHeight={changeHeight}
          onSize={onSize}
          size={size}
          layout={layoutConfig.alias}
          fullScreenWidget={fullScreenWidget}
          {...widgetsData.airport_layout_widget}
          timeZone={systemParams?.time_zone}
        />
      ) : (
        <div />
      );
    case "ACDM":
      return widgetsData.ACDM ? (
        <AcdmGrid
          fullScreenWidget={fullScreenWidget}
          {...widgetsData.ACDM}
          block={block}
          fullscreenBlock={fullscreenBlock}
        />
      ) : (
        <div />
      );
    case "PDS":
      return widgetsData.PDS ? (
        <PDS fullScreenWidget={fullScreenWidget} {...widgetsData.PDS} />
      ) : (
        <div />
      );
    case "BHS":
      return widgetsData.BHS ? <BHS {...widgetsData.BHS} /> : <div />;
    case "EMS":
      return widgetsData.BHS ? <EMS {...widgetsData.EMS} /> : <div />;
    case "Activity":
      return widgetsData.Activity ? (
        <Activity {...widgetsData.Activity} fullscreenBlock={fullscreenBlock} />
      ) : (
        <div />
      );
    case "KPI":
      return widgetsData.KPI ? (
        <Kpi fullScreenWidget={fullScreenWidget} {...widgetsData.KPI} />
      ) : (
        <div />
      );
    default:
      return <div />;
  }
};
export default WidgetRouter;

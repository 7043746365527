let WORKER;

export const createWebWorker = (user, url) => {
  if (WORKER) return;

  const { roles = [] } = user;
  const loginWithMandatory = roles?.some(({ is_mandatory }) => is_mandatory);
  if (!loginWithMandatory) return;

  const rolesIds = roles.map(({ id }) => id);
  WORKER = new Worker(url);
  WORKER.postMessage({ rolesIds });
};

export const terminateWorker = () => {
  if (WORKER) {
    WORKER.terminate();
    WORKER = null;
  }
};

import { deleteMultiData, updateObject } from "./utility";
import {
  REINIT_ROLES,
  FETCH_ROLES_START,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAIL,
  FETCH_PERMISSIONS_START,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAIL,
  DELETE_ROLES_START,
  DELETE_ROLES_SUCCESS,
  DELETE_ROLES_FAIL,
  RESTORE_ROLES_START,
  RESTORE_ROLES_SUCCESS,
  RESTORE_ROLES_FAIL,
  CREATE_ROLES_START,
  CREATE_ROLES_SUCCESS,
  CREATE_ROLES_FAIL,
  UPDATE_ROLES_START,
  UPDATE_ROLES_FAIL,
  FETCH_ROLE_START,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_FAIL,
} from "../constants";

const initialState = {
  roles: [],
  role: {},
  permissions: [],
  loading: false,
  total: 0,
};

const binaryParser = (bin) => {
  return {
    view: +bin[0] || 0,
    create: +bin[1] || 0,
    update: +bin[2] || 0,
    delete: +bin[3] || 0,
  };
};

const airlinesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINIT_ROLES:
      return updateObject(state, initialState);
    case FETCH_ROLES_START:
      return updateObject(state, { loading: true });
    case FETCH_ROLES_SUCCESS:
      return updateObject(state, {
        loading: false,
        roles: action.data,
        total: action.total,
      });
    case FETCH_ROLES_FAIL:
      return updateObject(state, { loading: false });

    case DELETE_ROLES_START:
      return updateObject(state, { loading: true });
    case DELETE_ROLES_SUCCESS:
      return deleteMultiData(state, action.data, "roles");
    case DELETE_ROLES_FAIL:
      return updateObject(state, { loading: false });

    case RESTORE_ROLES_START:
      return updateObject(state, { loading: true });
    case RESTORE_ROLES_SUCCESS:
      return deleteMultiData(state, action.data, "roles");
    case RESTORE_ROLES_FAIL:
      return updateObject(state, { loading: false });

    case FETCH_PERMISSIONS_START:
      return updateObject(state, { loading: true });
    case FETCH_PERMISSIONS_SUCCESS:
      return updateObject(state, {
        loading: false,
        permissions: action.data.map((e) =>
          Object.assign(
            { ...e },
            { permission_bin: binaryParser(e.permission_bin) },
          ),
        ),
      });
    case FETCH_PERMISSIONS_FAIL:
      return updateObject(state, { loading: true });

    case CREATE_ROLES_START:
      return updateObject(state, { loading: true });
    case CREATE_ROLES_SUCCESS:
      return updateObject(state, {
        loading: false,
        roles: [action.data, ...state.roles],
      });
    case CREATE_ROLES_FAIL:
      return updateObject(state, { loading: false });

    case UPDATE_ROLES_START:
      return updateObject(state, { loading: true });
    case UPDATE_ROLES_FAIL:
      return updateObject(state, { loading: false });

    case FETCH_ROLE_START:
      return updateObject(state, { loading: true });
    case FETCH_ROLE_SUCCESS:
      return updateObject(state, { loading: false, role: action.data });
    case FETCH_ROLE_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default airlinesReducer;
